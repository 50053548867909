import { Container, TextLink } from "@ruter-ds/rds-components";
import React from "react";
import "./RuterBedrift.scss";

const RuterBedrift: React.FC = () => {
  return (
    <Container width="s" className="components-terms-ruterbedrift">
      <h1>RuterBedrift i RuterBillettappen og personvern </h1>
      <p>Personvern, tilganger og statistikk når du bruker Bedriftsbilletten i RuterBillett.</p>
      <p>
        Dersom du ikke ønsker at vi skal behandle personopplysninger om deg, kan du i stedet velge en anonym (uregistrert) billett. Les mer
        om dette på&nbsp;
        <TextLink href="http://www.ruter.no/" target="_blank" rel="noopener noreferrer">
          www.ruter.no
        </TextLink>
      </p>
      <p>
        Du kan lese mer om&nbsp;
        <TextLink href="https://lovdata.no/dokument/NL/lov/2018-06-15-38" target="_blank" rel="noopener noreferrer">
          Personopplysningsloven på lovdata.no
        </TextLink>
      </p>

      <h2>Formål</h2>
      <p>
        Ruter behandler personopplysninger for å kunne oppfylle avtale om bedriftsbilletten med deg og gi deg mer personlig og bedre
        oppfølging av ditt kundeforhold.
      </p>
      <p>
        Så lenge du er knyttet til avtale om bedriftsbilletten vil du automatisk bli trukket i lønn av din arbeidsgiver og din billett være
        gyldig.&nbsp; I tillegg kan vi gi deg følgende tilleggstjenester:
      </p>
      <ul>
        <li>
          vi kan rekonstruere din billett hvis du for eksempel ikke har tilgang til din mobiltelefon og ønsker billetten overført til en
          annen mobil.
        </li>
        <li>vi kan lettere identifisere mulige feilkilder ved reklamasjon eller andre forhold som oppstår ved bruken av din billett. </li>
      </ul>
      <p>Du kan i tillegg velge å samtykke til å motta oppdatert og aktuell informasjon fra Ruter.</p>

      <h2>Dette behandler vi</h2>
      <p>Ruter behandler følgende opplysninger:</p>
      <ul>
        <li>Fornavn.</li>
        <li>Etternavn.</li>
        <li>Ansattnummer.</li>
        <li>Mobilnummer.</li>
        <li>E-postadresse. </li>
        <li>Opplysninger om salget som hvilken billettype, soner og tidspunkt for kjøp.</li>
      </ul>

      <p>Kundeopplysningene og salgsopplysningene lagres i separate databaser, og det er begrenset tilgang til opplysningene.</p>
      <p>
        Vi behandler bare opplysningene dine for å oppfylle en bedriftsavtale om abonnement med deg eller dersom du har samtykket til å
        motta oppdatert og aktuell informasjon fra Ruter. Du kan si opp avtalen etter gjeldende vilkår og du kan når som helst trekke ditt
        samtykke til å motta informasjon.
      </p>

      <h2>Tilgang og bruk</h2>
      <p>
        Tilgangen til opplysninger om deg er begrenset. Du har rett til innsyn i og retting, dataportabilitet og sletting av opplysninger
        som er lagret om deg.
      </p>
      <p>
        <TextLink href="https://ruter.no/fa-hjelp/vilkar/personvern/" target="_blank" rel="noopener noreferrer">
          Les mer om dine rettigheter under Personvern i Ruters tjenester.
        </TextLink>
      </p>
      <p>Vi utleverer ikke personopplysningene dine til en tredjepart så lenge vi ikke blir pålagt det av offentlige myndigheter.</p>

      <h2>Lagring og sletting</h2>
      <p>
        Kundeopplysningene dine blir lagret så lenge du har en gyldig avtale om abonnement på bedriftsbilletten. Dersom du sier opp avtalen
        kan du ikke inngå ny avtale med Ruter før det har&nbsp; gått tre måneder. Ruter vil derfor slette dine kundeopplysninger tre måneder
        etter oppsigelsen av avtalen. Dersom du trekker samtykke til å motta informasjon fra Ruter vil vi slette&nbsp; de tilhørende
        personopplysninger innen 14 dager.
      </p>
      <p>
        Salgsopplysninger lagres i inntil 365 dager etter at billetten er utløpt, eller alternativt 365 dager etter at du har klaget
        til&nbsp; Ruter om et forhold ved din reise. Dette for å kunne håndtere kundens klagerett til Transportklagenemnda.
      </p>
      <p>
        Opplysninger (både reise- og kundeopplysninger) vi trenger for å kunne fakturere deg må vi lagre i 5 år på grunn av
        regnskapslovgivning.
      </p>
      <p>
        <TextLink
          href="https://lovdata.no/dokument/SF/forskrift/2012-01-20-84/KAPITTEL_1#KAPITTEL_1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Se forskrift om transportklagenemda § 5-2
        </TextLink>
      </p>

      <h2>Tilganger</h2>
      <p>
        For at bedriftsbilletten din i RuterBillett skal fungere optimalt må appen ha tilganger til enkelte funksjoner på telefonen din. Du
        kan velge å skru&nbsp; av disse tilgangene, men enkelte funksjoner vil da enten ikke være tilgjengelig eller ikke fungere helt
        optimalt. Du skrur av tilgangene i innstillingene&nbsp; på telefonen din. Hvilke tilganger appen trenger er tilgjengelig i
        app-butikken du laster ned appen fra:
      </p>
      <p>
        <TextLink href="https://itunes.apple.com/no/app/ruterbillett/id581486486?l=nb&mt=8" target="_blank" rel="noopener noreferrer">
          Tilganger for RuterBillett for iPhone i App Store (« skjult tekst»)
        </TextLink>
      </p>

      <h3>Stedstjenester</h3>
      <p>
        Brukes ved billettkjøp for at RuterBillett automatisk kan foreslå en billett for den sonen du befinner deg i. Er stedtjenester&nbsp;
        avslått kan du fremdeles kjøpe billett, men du må manuelt oppgi stedet billetten skal gjelde fra.
      </p>

      <h3>Kontakter</h3>
      <p>Brukes når du skal sende billetter til telefonnummer lagret i dine kontakter.</p>

      <h3>Varslinger</h3>
      <p>Brukes for å sende varslinger før utløp av billetter og betalingskort. Er varslinger avslått, kan du fremdeles kjøpe billett.</p>

      <h3>Bakgrunnsoppdatering</h3>
      <p>
        Brukes for å oppdatere kontrollkode og dagens bilde i løpet av natten slik at du ved billettkontroll alltid har gyldig kontrollkode
        og bilde.&nbsp; Er bakgrunnsoppdatering avslått, kan du fremdeles kjøpe billett.
      </p>

      <h3>Mobildata</h3>
      <p>
        Benyttes for å kommunisere med Ruter ved kjøp av billett. Er mobildata avslått, må du ha tilgang til trådløst nett (Wi-Fi) for å
        kjøpe billett.&nbsp; Du må også ha nettverkstilgang for å kunne vise kontrollkode og dagens bilde ved billettkontroll.
      </p>
      <p>Du må også ha nettverkstilgang for å kunne vise kontrollkode og dagens bilde ved billettkontroll.</p>
      <p>
        <TextLink href="https://play.google.com/store/apps/details?id=no.ruter.mobile&hl=nb" target="_blank" rel="noopener noreferrer">
          Tilganger for RuterBillett for Android på Google Play («skjult tekst»)
        </TextLink>
      </p>

      <h3>Lese telefonstatus og -identitet</h3>
      <p>
        Brukes for å sikre billetten mot forfalskning ved å knytte den til din telefons unike ID. RuterBillett har ikke tilgang til å utføre
        telefonsamtaler.&nbsp;
      </p>

      <h3>Få tilgang til nøyaktig posisjon (GPS- og nettverksbasert)</h3>
      <p>
        Brukes ved billettkjøp for automatisk å foreslå billett for den sonen du befinner deg i. Er stedtjenester avslått, kan du fremdeles
        kjøpe billett,&nbsp; men må manuelt oppgi stedet billetten skal gjelde fra.
      </p>

      <h3>Motta data fra internett</h3>
      <p>Brukes for å kommunisere med Ruter ved kjøp og nedlastning av billetter til din mobil.</p>

      <h3>Få full nettverkstilgang</h3>
      <p>Brukes for å motta feilmeldinger og informasjon fra Ruters billettsystem.</p>

      <h3>Se nettverkstilkoblinger</h3>
      <p>
        Brukes til umiddelbare feilmeldinger hvis nettverket er nede. Dette for at du skal få rask beskjed, og ikke vente unødvendig lenge.
      </p>

      <h3>Kjøre fra oppstart/automatisk start fra oppstart</h3>
      <p>Brukes for å sende varslinger før utløp av billetter og betalingskort.</p>

      <h3>Forhindre telefonen fra å sove</h3>
      <p>Brukes for å sikre kommunikasjonen mellom telefonen og Ruters billettsystem.</p>

      <h3>Kontrollere vibreringen</h3>
      <p>Brukes for at telefonen kan vibrere ved varsling.</p>

      <h3>Lese synkroniseringsinnstillinger</h3>
      <p>Brukes for at periodebilletter skal oppdateres automatisk med kontrollkode og dagens bilde.</p>

      <h3>Bruke fingeravtrykkmaskinvare</h3>
      <p>For å bruke fingeravtrykk som alternativ til passord.</p>

      <h3>Lese kontaktene dine</h3>
      <p>Brukes for å sende billetter til telefonnummer lagret i dine kontakter.</p>

      <h3>Kontroller overføring av data med NFC-teknologi</h3>
      <p>Brukes for å lese innhold på reisekort.</p>

      <h2>Statistikk</h2>
      <p>
        Vi ønsker å gjøre appen bedre, og bruker Google Analytics i RuterBillett for å samle informasjon om bruk.&nbsp; Google Analytics
        sletter personidentifiserende opplysninger ved mottak, og IP-adressen din blir anonymisert.&nbsp; Bruksinformasjonen sendes
        anonymisert til oss, og kan ikke knyttes til deg som bruker. Dersom du allikevel ikke&nbsp; ønsker å dele bruksinformasjonen din kan
        du skru av dette under «Innstillinger» i appen.
      </p>
    </Container>
  );
};

export default RuterBedrift;
