import { UNKNOWN } from "../../constants";

export enum FreeTicketProfile {
  ADULT = "ADULT",
  CHILD = "CHILD",
  RETIRED = "RETIRED",
}

export const parseFreeTicketProfileFromString = (value: string): FreeTicketProfile | typeof UNKNOWN => {
  const parsed = Object.values(FreeTicketProfile).find((x) => x === value);

  if (parsed === undefined) {
    return UNKNOWN;
  }
  return parsed;
};

export const parseFreeTicketProfileFromNumber = (value: number): FreeTicketProfile => {
  if (value === 1) {
    return FreeTicketProfile.ADULT;
  } else if (value === 2) {
    return FreeTicketProfile.CHILD;
  } else if (value === 4) {
    return FreeTicketProfile.RETIRED;
  }

  throw new Error(`${value} is not a recognized profile`);
};
