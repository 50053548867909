import { Button, ButtonGroup, Checkbox, Dropdown, FormGroup, IconButton, Label, Message, Modal, SearchInput, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { UserRight } from "../../../common/userRights";
import { AuthCompany, SimpleCompany } from "../../../types/AuthCompany";
import { Agreement } from "../../../types/Company";
import { UserRoleType } from "../../../types/user/UserRoleType";
import { CancelButton } from "../../common/buttons";
import "./RolesInput.scss";
import userLanguageResource from "./lang-resource";

export interface CompanyWithRoles {
  companyId: string;
  name: string;
  roles: string[];
  allAgreements: Agreement[];
}

const mapToCompanyWithRoles = (c: SimpleCompany): CompanyWithRoles => ({
  companyId: c.id,
  name: c.name,
  roles: [],
  allAgreements: c.allAgreements,
});

const renderCompanyOption = (c: AuthCompany) => {
  return (
    <option key={c.id} value={c.id}>
      {c.name}
    </option>
  );
};

const RolesInput: React.FC = () => {
  const { setValue, register, watch } = useFormContext();
  const lang = useLanguageResource(userLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const userData = authContext.userData;
  const [openCompanySearch, setOpenCompanySearch] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [companyQuery, setCompanyQuery] = useState("");
  const [company, setCompany] = useState<null | CompanyWithRoles>(null);
  const [noResultFor, setNoResultFor] = useState<undefined | string>(undefined);
  const companies = watch("companies", []) as CompanyWithRoles[];

  const allCompanies = userData.companies;
  const companiesWithUserAdmin = allCompanies.filter((c) => c.roles.includes(UserRoleType.USER_ADMIN));

  const anyRolesChecked = company?.roles.length ? company.roles.length > 0 : false;

  const isGlobalUserAdmin = userData.userRights.some((role) => role === UserRight.GlobalUserAdmin);

  const filteredCompanies = useMemo(() => {
    return allCompanies.filter((x) => {
      const searchTerms = companyQuery.split(" ");
      return searchTerms.every((s) => `${x.name} ${x.organisationNumber} ${x.id}`.toLowerCase().includes(s.toLowerCase()));
    });
  }, [allCompanies, companyQuery]);

  useEffect(() => {
    register("companies");
  }, [register]);

  const updateRoles = (role: string, checked: boolean) => {
    if (!company) {
      return;
    }

    if (checked) {
      const updatedCompany = {
        ...company,
        roles: [...company.roles, role],
      };
      setCompany(updatedCompany);
    } else {
      const updatedCompany = {
        ...company,
        roles: [...company.roles.filter((x) => x !== role)],
      };
      setCompany(updatedCompany);
    }
  };

  const closeModal = () => {
    setOpenCompanySearch(false);
    setCompanyQuery("");
    setNoResultFor(undefined);
    setCompany(null);
    setSubmitClicked(false);
  };

  const removeFromForm = (companyId: string) => {
    setValue("companies", [...companies.filter((x) => x.companyId !== companyId)]);
  };

  const addToForm = (c: CompanyWithRoles) => {
    setSubmitClicked(true);
    if (!anyRolesChecked) {
      return;
    }
    const updatedValue = [...companies.filter((x) => x.companyId !== c.companyId), c];

    setValue("companies", updatedValue);
    closeModal();
  };

  const humanReadableRole = (role: string) => {
    const renderRole = (text: string) => <div key={role}>{text}</div>;

    if (role === "SUBSCRIPTION_ADMIN") {
      return renderRole(lang.subscriptionAdminRole);
    }
    if (role === "TICKET_ADMIN") {
      return renderRole(lang.ticketCounterAdminRole);
    }
    if (role === "FREETICKET_ADMIN") {
      return renderRole(lang.freeTicketAdminRole);
    }
    if (role === "USER_ADMIN") {
      return renderRole(lang.userAdminRole);
    }
    if (role === "COMPANY_ADMIN") {
      return renderRole(lang.companyAdminRole);
    }
    if (role === "SCHOOLTICKET_ADMIN") {
      return renderRole(lang.schoolTicketAdminRole);
    }
    throw new Error("role not recognized");
  };

  const renderRow = (c: CompanyWithRoles) => (
    <tr key={c.companyId}>
      <td>{c.name}</td>
      <td style={{ whiteSpace: "normal" }}>{c.roles.map(humanReadableRole)}</td>
      <td className="actions">
        <IconButton
          variant="PencilIcon"
          aria-label={lang.changeRole}
          title={lang.changeRole}
          onClick={() => {
            setCompany(c);
            setOpenCompanySearch(true);
          }}
        />
        <IconButton
          variant="TrashIcon"
          aria-label={lang.deleteRole}
          title={lang.deleteRole}
          onClick={() => removeFromForm(c.companyId)}
          data-test-id="deactivate-user-button"
        />
      </td>
    </tr>
  );

  const onSelectExistingCompany = (e: ChangeEvent<HTMLSelectElement>) => {
    const companyFromDropDown = userData.companies.find((c) => c.id === e.target.value);
    if (companyFromDropDown) {
      setCompany(mapToCompanyWithRoles(companyFromDropDown));
    }
  };

  const onAddCompanyRole = () => {
    setOpenCompanySearch(true);
    if (companiesWithUserAdmin.length === 1 && !isGlobalUserAdmin) {
      setCompany(mapToCompanyWithRoles(companiesWithUserAdmin[0]));
    }
  };

  const renderCompanyButton = (c: AuthCompany) => (
    <button
      key={c.id}
      type="button"
      onClick={() => {
        setCompany({ companyId: c.id, allAgreements: c.allAgreements, name: c.name, roles: c.roles });
      }}
      data-test-id={"company-button"}
    >
      <span className="company-name">{c.name}</span>
    </button>
  );

  return (
    <>
      <FormGroup className="components-useradmin-user-rolesinput">
        <div className="header-row">
          <span className="title">{lang.companyRoles}</span>
          <Button text={lang.add} variant="primary" onClick={onAddCompanyRole} data-test-id="add-roles-button" type="button" />
        </div>
        <Table data-test-id="company-roles-table">
          <thead>
            <tr>
              <th>{lang.company}</th>
              <th>{lang.roles}</th>
              <th aria-label="actions" style={{ width: "110px" }} />
            </tr>
          </thead>
          <tbody>{companies.map(renderRow)}</tbody>
        </Table>
      </FormGroup>

      {openCompanySearch && (
        <Modal isOpen={openCompanySearch} title={lang.addCompanyRole} handleClose={closeModal} className="company-roles-modal" data-test-id="company-roles-modal">
          {!company && (
            <>
              {isGlobalUserAdmin && (
                <FormGroup>
                  <div className="search-row">
                    <Label text={lang.companySearch}>
                      <SearchInput
                        value={companyQuery}
                        onChange={(e) => {
                          setCompanyQuery(e.target.value);
                        }}
                        placeholder={lang.companySearchPlaceholder}
                      />
                    </Label>
                  </div>
                  <div data-test-id="search-result" className="search-result">
                    {filteredCompanies.length > 0 ? (
                      filteredCompanies.map((c) => renderCompanyButton(c))
                    ) : (
                      <div data-test-id="no-result" className="no-result">
                        {lang.noCompanyResult}
                      </div>
                    )}
                  </div>
                </FormGroup>
              )}
              {!isGlobalUserAdmin && companiesWithUserAdmin.length > 0 && (
                <FormGroup>
                  <Label text="Velg selskap">
                    <Dropdown value={companyQuery} onChange={onSelectExistingCompany} id="select-company" name="select-company">
                      <option value=""></option>
                      {companiesWithUserAdmin.map((c) => renderCompanyOption(c))}
                    </Dropdown>
                  </Label>
                </FormGroup>
              )
              }

              {noResultFor && (
                <FormGroup>
                  <Message skin="info">{lang.noResultFor.replace("$query$", noResultFor)}</Message>
                </FormGroup>
              )}
            </>
          )}
          {company && (
            <div data-test-id={"selected-company-roles"}>
              <div className="company-row">
                <div className="label">{lang.companyId}</div>
                <div className="value">{company.companyId}</div>
              </div>
              <div className="company-row">
                <div className="label">{lang.companyName}</div>
                <div className="value">{company.name}</div>
              </div>

              <FormGroup style={{ marginTop: "2rem" }} >
                <Label text={lang.companyRoles} />
                <Checkbox
                  label={lang.companyAdminRole}
                  name="COMPANY_ADMIN"
                  value="COMPANY_ADMIN"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateRoles("COMPANY_ADMIN", e.target.checked)}
                  checked={company.roles.indexOf("COMPANY_ADMIN") !== -1}
                />
                <Checkbox
                  label={lang.ticketCounterAdminRole}
                  name="TICKET_ADMIN"
                  value="TICKET_ADMIN"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateRoles("TICKET_ADMIN", e.target.checked)}
                  checked={company.roles.indexOf("TICKET_ADMIN") !== -1}
                />
                <Checkbox
                  label={lang.userAdminRole}
                  name="USER_ADMIN"
                  value="USER_ADMIN"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateRoles("USER_ADMIN", e.target.checked)}
                  checked={company.roles.indexOf("USER_ADMIN") !== -1}
                />
                {company.allAgreements.some((agreement) => agreement.subTypeId === AgreementSubTypeId.SCHOOLTICKET) && (
                  <Checkbox
                    label={lang.schoolTicketAdminRole}
                    name="SCHOOLTICKET_ADMIN"
                    value="SCHOOLTICKET_ADMIN"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateRoles("SCHOOLTICKET_ADMIN", e.target.checked)}
                    checked={company.roles.indexOf("SCHOOLTICKET_ADMIN") !== -1}
                  />
                )}
              </FormGroup>
              {submitClicked && !anyRolesChecked && (
                <FormGroup>
                  <Message skin="danger" data-test-id="no-roles-validation-message" title={lang.noRoles} />
                </FormGroup>
              )}

              <FormGroup>
                <ButtonGroup>
                  <Button text={lang.add} variant="primary" data-test-id="add-role-button" onClick={() => addToForm(company)} type="button" />
                  <CancelButton onClick={closeModal} />
                </ButtonGroup>
              </FormGroup>
            </div>
          )}
        </Modal>
      )}

    </>
  );
};

export default RolesInput;
