import { compareAsc } from "date-fns";
import { AuthAgreement } from "src/types/AuthCompany";

export const hasValidAgreement = (agreements: AuthAgreement[] | undefined): boolean => {
  if (agreements && agreements.length > 0) {
    if (agreements.some((x) => !x.endDate)) return true;

    if (agreements.some((x) => x.endDate && compareAsc(new Date(x.endDate), new Date()) !== -1)) return true;
  }
  return false;
};
