import { UNKNOWN } from "../../constants";

export enum UserRoleType {
  USER_ADMIN = "USER_ADMIN",
  TECHNICAL_ADMIN = "TECHNICAL_ADMIN",
  GLOBAL_USER_ADMIN = "GLOBAL_USER_ADMIN",
  GLOBAL_COMPANY_ADMIN = "GLOBAL_COMPANY_ADMIN",
  GLOBAL_TRAVEL_CARD_ADMIN = "GLOBAL_TRAVEL_CARD_ADMIN",
  GLOBAL_ADMIN = "GLOBAL_ADMIN",
  GLOBAL_SCHOOLTICKET_ADMIN = "GLOBAL_SCHOOLTICKET_ADMIN",
}

export const parseUserRoleType = (value: string): UserRoleType | typeof UNKNOWN => {
  const parsed = Object.values(UserRoleType).find((x) => x === value);

  if (parsed === undefined) {
    return UNKNOWN;
  }
  return parsed;
};
