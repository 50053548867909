import { SimpleOnboardingContract } from "./simpleOnboarding";

const onboardingApi = {
  requestAccess: (body: SimpleOnboardingContract) => ({
    url: "/onboarding/request-access",
    method: "post" as const,
    body,
  }),
};

export default onboardingApi;