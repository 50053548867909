interface GetBackendUrlProps {
  relativeUrl: string;
  query?: string;
  page?: number;
  pageSize?: number;
}

const getBackendUrl = ({ relativeUrl, query, page, pageSize }: GetBackendUrlProps) => {
  return `${relativeUrl}?maxEntries=${pageSize ?? ""}&offset=${(page ? page - 1 : 0) * 10}&searchFor=${query ? `(${query})` : ""}`;
};

export default getBackendUrl;
