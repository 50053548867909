import React from "react";
import "./SkeletonTicket.scss";

const SkeletonTicket: React.FC = () => {
  return (
    <div className="components-companyagreement-employeetickets-skeletonticket">
      <div className="info">
        <div className="info-line" />
        <div className="info-line" />
        <div className="info-line" />
      </div>
      <div className="actions">
        <div className="action" />
        <div className="action" />
      </div>
    </div>
  );
};


export default SkeletonTicket;