import React from "react";
import "./InputSkeleton.scss";

const InputSkeleton: React.FC = () => {
  return (
    <div className="input-skeleton">
      <div className="label" />
      <div className="input" />
    </div>
  );
};
export default InputSkeleton;
