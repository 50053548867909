import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container, TextLink } from "@ruter-ds/rds-components";
import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";
import { Tenant, getTenant } from "src/types/Tenant";
import "./Footer.scss";
import footerLanguageResource from "./lang-resource";

const Footer: React.FC = (): JSX.Element => {
  const lang = useLanguageResource(footerLanguageResource);
  const authContext = useAuthContext();
  const tenant = getTenant();

  const renderContactLink = (): JSX.Element => {
    if (authContext.authenticated) {
      return (
        <Link data-test-id="contact-us-link" to="/kontaktoss">
          {lang.contactUs}
        </Link>
      );
    }
    return (
      <a
        data-test-id="contact-us-link"
        href="https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/#page=form"
        target="_blank"
        rel="noopener noreferrer"
      >
        {lang.contactUs}
      </a>
    );
  };

  const renderExternalLink = (text: string, link: string, dataTestId?: string) => (
    <a
      data-test-id={dataTestId}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  const renderDefaultFooter = (): JSX.Element => (
    <footer className="components-app-footer">

    </footer>
  );

  const renderAktFooter = (): JSX.Element => (
    <footer className="components-app-footer">
      <Container width="s">
        <div className="footer-content">
          <div className="section">
            <h2>{lang.customerService}</h2>
            {renderExternalLink(lang.contactUs, "https://www.akt.no/kundeservice/kontakt-oss/", "contact-us-link")}
          </div>
          <div className="section">
            <h2>{lang.policiesAndPrivacy}</h2>
            {renderExternalLink(lang.privacy, "https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/")}
            {renderExternalLink(lang.conditionsOfTransportation, "https://www.akt.no/kundeservice/rettigheter-og-regler/transportvilkar-for-akt/")}
            {renderExternalLink(lang.cookies, "https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/informasjonskapsler/")}

          </div>
        </div>
      </Container>
    </footer>
  );

  const renderRuterFooter = (): JSX.Element => (
    <footer className="components-app-footer">
      <Container width="s">
        <div className="footer-content">
          <div className="section">
            <h2>{lang.customerService}</h2>
            {renderContactLink()}
          </div>
          <div className="section">
            <h2>{lang.policiesAndPrivacy}</h2>
            <a href="https://ruter.no/fa-hjelp/vilkar/personvern/ruter.no/" target="_blank" rel="noopener noreferrer">
              {lang.cookies}
            </a>
            <a href="https://ruter.no/fa-hjelp/vilkar/personvern/" target="_blank" rel="noopener noreferrer">
              {lang.privacy}
            </a>
            <TextLink href="https://ruter.no/fa-hjelp/vilkar/reisevilkar/" target="_blank" rel="noopener noreferrer">
              {lang.conditionsOfTravel}
            </TextLink>
            <TextLink element={Link} to="/vilkar/ruterbedrift">
              {lang.policies}
            </TextLink>
          </div>
        </div>
      </Container>
    </footer>
  );

  switch (tenant) {
    case Tenant.Ruter: return renderRuterFooter();
    case Tenant.Akt: return renderAktFooter();
    default: return renderDefaultFooter();
  }

};

export default Footer;
