import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Spinner } from "@ruter-ds/rds-components";
import React from "react";
import "./Loading.scss";
import { appLoadingLanguageResource } from "./lang-resource";

const Loading: React.FC = () => {
  const language = useLanguageResource(appLoadingLanguageResource);

  return (
    <div className="components-app-loading-loading">
      <Spinner size="medium" color="anthracite" className="spinner" />
      <span className="text">{language.appLoading}</span>
    </div>
  );
};

export default Loading;
