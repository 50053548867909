import { CustomerOrderLine } from "./CustomerOrderLine";

interface RefundJson {
  amount: number,
  vatAmount: number,
  invoiceId: string,
  invoiceLineId: string,
  createdAt: string,
  refundForInvoiceLineId: string
}

interface Refund {
  amount: number,
  vatAmount: number,
  invoiceId: string,
  invoiceLineId: string,
  createdAt: Date,
  refundForInvoiceLineId: string
}

const mapRefund = (json: RefundJson): Refund => ({
  amount: json.amount,
  vatAmount: json.vatAmount,
  invoiceId: json.invoiceId,
  invoiceLineId: json.invoiceLineId,
  createdAt: new Date(json.createdAt),
  refundForInvoiceLineId: json.refundForInvoiceLineId,
});

export interface InvoiceLineJson {
  description: string;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  numberOfZones: number;
  phone: string;
  phoneCountryCode: string;
  price: number;
  priceVat: number | null;
  quantity: number;
  ticketId: string;
  timeOfSale: string;
  zones: Array<string>;
  agreementNumber: string;
  refunds: RefundJson[];
}

export interface InvoiceLine {
  type: "INVOICE"
  description: string;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  numberOfZones: number;
  phone: string;
  phoneCountryCode: string;
  price: number;
  priceVat: number | null;
  quantity: number;
  ticketId: string;
  timeOfSale: string;
  zones: Array<string>;
  agreementNumber: string;
  refunds: Refund[];
}

export type InvoiceOrCustomerOrderLine = InvoiceLine | CustomerOrderLine;

export const mapInvoiceLine = (json: InvoiceLineJson): InvoiceLine => ({
  ...json,
  refunds: json.refunds.map(mapRefund),
  type: "INVOICE",
});

export const getValidRefundJson = (changes?: Partial<RefundJson>): RefundJson => ({
  amount: 200,
  vatAmount: 21.43,
  invoiceId: "REFUND_1000000000029137",
  invoiceLineId: "1000000000029137",
  createdAt: "2024-04-24T10:56:30Z",
  refundForInvoiceLineId: "1000000000028382",
  ...changes,
});

export const getValidInvoiceLineJson = (changes?: Partial<InvoiceLineJson>): InvoiceLineJson => ({
  description: "Bedriftsbillett 12439457 Haavard Allanson Agvaldson-Nilsen Stolth 2 soner",
  employeeId: "6668894",
  firstName: "Haavard Allanson",
  id: "1000000000014881",
  lastName: "Agvaldson-Nilsen Stolth",
  numberOfZones: 2,
  phone: "46409346",
  phoneCountryCode: "+47",
  price: 1174,
  priceVat: null,
  quantity: 1,
  ticketId: "12439457",
  timeOfSale: "2020-12-31T23:00:00Z",
  zones: ["1", "2Ø"],
  agreementNumber: "1-4665898157",
  refunds: [],
  ...changes,
});