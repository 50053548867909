import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TicketRequestLanguageResource {
  title: string;
  firstName: string;
  lastName: string;
  phone: string;
  zones: string;
  startDate: string;
  requestRejected: string;
  requestRejectFailed: string;
  requestApproved: string;
  requestApproveFailed: string;
  expired: string;
  approveRequest: string,
  rejectRequest: string,
}

export const ticketRequestLanguageResource: LanguageResource<TicketRequestLanguageResource> = {
  nb: {
    title: "Godkjenn søknad om bedriftsbilletter",
    firstName: "Fornavn",
    lastName: "Etternavn",
    phone: "Telefonnr",
    zones: "Soner",
    startDate: "Ønsket oppstartsdato",
    requestRejected: "Søknad avslått!",
    requestRejectFailed: "Det skjedde en feil, fikk ikke avslått søknad!",
    requestApproved: "Søknad godkjent!",
    requestApproveFailed: "Det skjedde en feil, fikk ikke godkjent søknad!",
    expired: "utløpt",
    approveRequest: "Godkjenn forespørsel om billett",
    rejectRequest: "Avslå forespørsel om billet",
  },
  en: {
    title: "Requests for business tickets",
    firstName: "firstName",
    lastName: "lastName",
    phone: "Phone number",
    zones: "Zones",
    startDate: "Requested start date",
    requestRejected: "Request rejected!",
    requestRejectFailed: "An error occured, could not reject request!",
    requestApproved: "Request approved!",
    requestApproveFailed: "An error occured, could not approve request!",
    expired: "expired",
    approveRequest: "Approve request for ticket",
    rejectRequest: "Reject request for ticket",
  },
};