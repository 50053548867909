export interface CustomerOrderLineJson {
  description: string;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  numberOfZones: number;
  phone: string;
  phoneCountryCode: string;
  price: number;
  priceVat: number | null;
  quantity: number;
  ticketId: string;
  timeOfSale: string;
  zones: Array<string>;
  agreementNumber: string;
}

export interface CustomerOrderLine {
  type: "CUSTOMER_ORDER"
  description: string;
  employeeId: string;
  firstName: string;
  id: string;
  lastName: string;
  numberOfZones: number;
  phone: string;
  phoneCountryCode: string;
  price: number;
  priceVat: number | null;
  quantity: number;
  ticketId: string;
  timeOfSale: string;
  zones: Array<string>;
  agreementNumber: string;
}

export const mapCustomerOrderLine = (json: CustomerOrderLineJson): CustomerOrderLine => ({
  agreementNumber: json.agreementNumber,
  description: json.description,
  employeeId: json.employeeId,
  firstName: json.firstName,
  id: json.id,
  lastName: json.lastName,
  numberOfZones: json.numberOfZones,
  phone: json.phone,
  phoneCountryCode: json.phoneCountryCode,
  price: json.price,
  priceVat: json.priceVat,
  quantity: json.quantity,
  ticketId: json.ticketId,
  timeOfSale: json.timeOfSale,
  type: "CUSTOMER_ORDER",
  zones: json.zones,
});

export const getValidCustomerOrderLineJson = (changes?: Partial<CustomerOrderLineJson>): CustomerOrderLineJson => ({
  description: "Bedriftsbillett 12439457 Haavard Allanson Agvaldson-Nilsen Stolth 2 soner",
  employeeId: "6668894",
  firstName: "Haavard Allanson",
  id: "1000000000014881",
  lastName: "Agvaldson-Nilsen Stolth",
  numberOfZones: 2,
  phone: "46409346",
  phoneCountryCode: "+47",
  price: 1174,
  priceVat: null,
  quantity: 1,
  ticketId: "12439457",
  timeOfSale: "2020-12-31T23:00:00Z",
  zones: ["1", "2Ø"],
  agreementNumber: "1-4665898157",
  ...changes,
});
