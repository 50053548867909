import { LanguageResource, OneParamLanguageResourceTemplate, languageResourceBuilder } from "@ruter-as/web-components-and-tools";

interface FormLanguageResource {
  productName: OneParamLanguageResourceTemplate;
}

export const formLanguageResource: LanguageResource<FormLanguageResource> = {
  nb: {
    productName: languageResourceBuilder.oneParamTemplate("Månedpris per $p1$"),
  },
  en: {
    productName: languageResourceBuilder.oneParamTemplate("Price per month for $p1$"),
  },
};