import { InvoiceReference, TicketApiClient } from "@ruter-as/billettluke-frontend";
import { ButtonGroup, FormGroup, Modal, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import ticketCounterSettingsLanguageResource from "./lang-resource";

interface Props {
  companyId: string
  onSave: (refs: InvoiceReference[]) => void
  handleClose: () => void
}

interface FormData {
  "invoice-reference": string
}

const AddInvoiceRefModal: React.FC<Props> = ({ onSave, handleClose, companyId }) => {
  const language = useLanguageResource(ticketCounterSettingsLanguageResource);
  const [loading, setLoading] = useState(false);
  const { getAccessToken } = useAuthContextAuthenticated();
  const ticketApi = useMemo(() => {
    return new TicketApiClient({ getAccessToken }, "/ticket-api");
  }, [getAccessToken]);

  const formMethods = useForm<FormData>({ defaultValues: { "invoice-reference": "" } });

  const onSaveInvoiceRef = async (data: FormData) => {
    try {
      setLoading(true);
      await ticketApi.invoiceReference.save(companyId, data["invoice-reference"]);
      const result = await ticketApi.invoiceReference.getAll(companyId);
      onSave(result);
      handleClose();
      setLoading(false);
    } catch (e) {
      setLoading(() => {
        throw e;
      });
    }
  };

  return (
    <Modal handleClose={handleClose} isOpen={true} title={language.createInv}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSaveInvoiceRef)}>
          <FormGroup>
            <TextFormInput name="invoice-reference" required label={language.invoiceRefTitle} minLength={2} maxLength={50} />
          </FormGroup>
          <ButtonGroup>
            <SubmitButton text={language.save} submitting={loading} />
            <CancelButton onClick={() => handleClose()} text={language.cancel} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};


export default AddInvoiceRefModal;
