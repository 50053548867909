import { useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./DesktopMenu.scss";
import { headerLanguageResource } from "./languageResource";
import { MenuItem, MenuState } from "./menuState";

interface Props {
  menu: MenuState,
}

const DesktopMenu: React.FC<Props> = ({ menu }) => {
  const language = useLanguageResource(headerLanguageResource);
  const location = useLocation();
  const { setSelectedLanguage, selectedLanguage } = useLanguageContext();

  const renderMenuItem = (item: MenuItem) => {
    const path = location.pathname.toLowerCase();
    const isActive = item.path.toLowerCase() === path || item.subMenu?.some(x => x.path.toLowerCase() === path);
    const classNames = ["item"];
    if (item.focus) {
      classNames.push("focused");
    }
    if (isActive) {
      classNames.push("active");
    }
    return (
      <NavLink className={classNames.join(" ")} key={item.id} to={item.path} data-test-topmenu-id={item.id}>
        {/* eslint-disable-next-line */}
        <span className="text">{(language as any)[item.id]}</span>
        <span className="badge">{item.focus}</span>
      </NavLink>
    );
  };

  const toggleLanguageClick = () => {
    if (selectedLanguage === "en") {
      setSelectedLanguage("nb");
    } else {
      setSelectedLanguage("en");
    }
  };

  return (
    <div className="components-layout-desktopmenu">
      {menu.items.map((item) => renderMenuItem(item))}
      <button type="button" className="item right-align" data-test-id="toggle-language-button" onClick={toggleLanguageClick}>
        {language.changeLanguage}
      </button>
    </div>
  );
};

export default DesktopMenu;