interface InvoiceSummaryJson {
  id: string;
  ownerId: number;
  orderNumber: string;
  reference: string | null;
  createdAt: string;
  companyCustomerNumber: string;
  companyName: string;
  agreementNumber: string;
  year: number;
  month: number;
  totalPrice: number;
  totalVat: number | null;
  ticketCount: number;
  invoiceNumber: string | null;
  invoiceReference: string | null;
  invoicingDate: string | null;
  dueDate: string | null;
  paymentStatus: string | null;
  agreementType: string | null;
  reminderDueDate: string | null;
}

export interface InvoiceSummary {
  id: string;
  month: number;
  year: number;
  ticketCount: number;
  agreementNumber: string;
  totalPrice: number;
  totalVat: number;
}

const mapInvoiceSummary = (json: InvoiceSummaryJson): InvoiceSummary => ({
  id: json.id,
  month: json.month,
  year: json.year,
  ticketCount: json.ticketCount,
  agreementNumber: json.agreementNumber,
  totalPrice: json.totalPrice,
  totalVat: json.totalVat || 0,
});

export const mapInvoiceSummaries = (json: InvoiceSummaryJson[]): InvoiceSummary[] => json.map(mapInvoiceSummary);

export const getValidInvoiceSummary = (changes?: Partial<InvoiceSummaryJson>): InvoiceSummaryJson => ({
  id: "1-3779173843_2021_01",
  ownerId: 3,
  orderNumber: "2021-JANUARY",
  reference: null,
  createdAt: "2021-01-29T21:04:53Z",
  companyCustomerNumber: "1226629",
  companyName: "Steffens Kosmetikk",
  agreementNumber: "1-3779173843",
  year: 2021,
  month: 1,
  totalPrice: 539112.0,
  totalVat: 96967.99,
  ticketCount: 9283,
  invoiceNumber: null,
  invoiceReference: null,
  invoicingDate: null,
  dueDate: null,
  paymentStatus: null,
  agreementType: null,
  reminderDueDate: null,
  ...changes,
});
