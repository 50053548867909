import moment from "moment";
import { SearchResult } from "../../../types/SearchResult";

export interface FreeTicketMassOrderJson {
  id: string;
  appName: string;
  ownerId: number;
  agreementNumber: string;
  requestedAt: string;
  orderedBy: string;
  requestedTicketCount: number;
  failedTicketCount: number;
  ticketCount: number;
  ticketIds: string[];
}

export interface FreeTicketMassOrder {
  id: string;
  appName: string;
  ownerId: number;
  agreementNumber: string;
  requestedAt: Date;
  orderedBy: string;
  requestedTicketCount: number;
  failedTicketCount: number;
  ticketCount: number;
}

const mapMassOrder = (json: FreeTicketMassOrderJson): FreeTicketMassOrder => {
  const requestedAtAsDate = moment(json.requestedAt);

  if (!requestedAtAsDate.isValid()) {
    throw new Error(`${json.requestedAt} is not a valid date`);
  }

  return {
    id: json.id,
    appName: json.appName,
    ownerId: json.ownerId,
    agreementNumber: json.agreementNumber,
    requestedAt: requestedAtAsDate.toDate(),
    orderedBy: json.orderedBy,
    requestedTicketCount: json.requestedTicketCount,
    failedTicketCount: json.failedTicketCount,
    ticketCount: json.ticketCount,
  };
};

export const mapMassOrderSearchResult = (json: SearchResult<FreeTicketMassOrderJson>): SearchResult<FreeTicketMassOrder> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapMassOrder),
});
