// ticket status
export const TICKET_PENDING = "pending";
export const TICKET_EXPIRED = "expired";
export const TICKET_ACTIVE = "active";
export const TICKET_CANCELLED = "cancelled";

// All zones
export const ALL_ZONES_FROM = "Ruter";
export const ALL_ZONES_TO = "";
export const ZONE_1 = "RUT:TariffZone:227";

// zone types
export const ZONE_TO_ZONE = "ZoneToZone";

// user roles
export const USER_ROLE_TICKET_ADMIN = "TICKET_ADMIN";
export const USER_ROLE_SUBSCRIPTION_ADMIN = "SUBSCRIPTION_ADMIN";
export const USER_ROLE_FREETICKET_ADMIN = "FREETICKET_ADMIN";
export const USER_ROLE_COMPANY_ADMIN = "COMPANY_ADMIN";
export const USER_ROLE_SCHOOLTICKET_ADMIN = "SCHOOLTICKET_ADMIN";
export const USER_ROLE_USER_ADMIN = "USER_ADMIN";
export const USER_ROLE_GLOBAL_SCHOOLTICKET_ADMIN = "GLOBAL_SCHOOLTICKET_ADMIN";


// Create Ticket Policies
export const CREATE_POLICY_ALLOW_TICKET_REQUESTS = "ALLOW_TICKET_REQUESTS";
export const CREATE_POLICY_DISABLED = "DISABLED";
export const CREATE_POLICY_ALLOW_TICKET_CREATION = "ALLOW_TICKET_CREATION";

export const UNKNOWN = "UNKNOWN";

// Date format
export const DATE_FORMAT = "dd.MM.yyyy";
