import { PaginationResult, usePaginationAndParameters } from "@ruter-as/web-components-and-tools";

interface PaginationAndQueryResult {
  pagination: PaginationResult
  query: string
  throttledQuery: string
  setQuery: (value: string) => void
  tooFew: boolean
}

const usePaginationAndQuery = (throttle = 500, minLength = 0): PaginationAndQueryResult => {
  const { pagination, searchParameters: [{ setValue, throttledValue, tooFew, value }] } = usePaginationAndParameters([{ name: "q", minLength, throttle }]);

  const applyWildCards = (x: string): string => {
    return "*" + x.replace(" ", "*") + "*";
  };

  return {
    pagination,
    query: value,
    throttledQuery: applyWildCards(throttledValue),
    setQuery: setValue,
    tooFew,
  };
};

export default usePaginationAndQuery;