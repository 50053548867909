import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface NotFoundLanguageResource {
  title: string;
  title2: string;
  text: string;
}

export const notFoundLanguageResource: LanguageResource<NotFoundLanguageResource> = {
  nb: {
    title: "404",
    title2: "Det ser ut som du har gått deg vill",
    text: "siden du leter etter er ikke tilgjengelig!",
  },
  en: {
    title: "404",
    title2: "It looks like you are lost",
    text: "The page you are looking for does not exist!",
  },
};