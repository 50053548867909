import { ButtonGroup, FieldValidationError, Modal, NumberFormInput, TextAreaFormInput, apiClient, formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Invoice } from "src/common/api/invoice/Invoice";
import { InvoiceLine } from "src/common/api/invoice/InvoiceLine";
import invoiceApi from "src/common/api/invoice/invoiceApi";
import { RefundInvoiceLineContract } from "src/common/api/invoice/refundInvoiceLineContract";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import { invoiceDetailsLanguageResource } from "./lang-resource";

interface Props {
  handleClose: () => void;
  handleSubmit: () => void;
  invoiceLine: InvoiceLine
  invoice: Invoice
}

interface FormData {
  amount: string
  comment: string | null
}

const RefundModal: React.FC<Props> = ({ handleClose, handleSubmit, invoiceLine, invoice }) => {
  const lang = useLanguageResource(invoiceDetailsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const refundedAmount = invoiceLine.refunds.reduce((amount, curr) => {
    return curr.amount + amount;
  }, 0);
  const invoiceLineAmount = invoiceLine.price * invoiceLine.quantity;

  const refundableAmount = invoiceLineAmount - refundedAmount;
  const formMethods = useForm<FormData>({
    defaultValues: {
      amount: formatter.number.float(refundableAmount, 2).replace(",", ".").replace(/\s+/g, ""),
      comment: null,
    },
  });

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    setShowError(false);

    const contract: RefundInvoiceLineContract = {
      amount: data.amount,
      description: data.comment || null,
    };

    const response = await apiClient.request(invoiceApi.invoiceAdminApi.invoice.id(invoice.invoiceNumber).postRefundLine(invoiceLine.id, contract));

    if (response.type === "success") {
      handleSubmit();
    } else if (response.type === "HttpError" && response.responseStatus === 429) {
      Sentry.captureException(response.error);
      setShowError(true);
      setLoading(false);
    } else {
      setLoading(() => {
        throw response.error;
      });
    }
  };


  return <Modal isOpen={Boolean(invoiceLine)} handleClose={() => { handleClose(); }} title={lang.refundModal.title}>
    <FormProvider {...formMethods}>
      <h3 style={{ fontSize: "18px" }}>{lang.refundModal.agreementNumber}</h3>
      <p>{invoiceLine.agreementNumber}</p>
      <h3 style={{ fontSize: "18px" }}>{lang.description}</h3>
      <p>{invoiceLine.description}</p>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ marginTop: "2rem" }}>
        <NumberFormInput name="amount" required min={0} max={refundableAmount} label={lang.refundModal.refundAmount} />
        <TextAreaFormInput name="comment" label={lang.refundModal.newDescription} />
        {showError && <FieldValidationError text={lang.refundModal.duplicateRequestError} />}
        <ButtonGroup>
          <SubmitButton submitting={loading} text={lang.refundModal.confirm} />
          <CancelButton onClick={() => { handleClose(); }} />
        </ButtonGroup>
      </form>
    </FormProvider>
  </Modal>;
};

export default RefundModal;