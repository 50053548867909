import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Message,
  Modal,
  SearchInput,
  Table,
  TablePagination,
  apiClient,
  useLanguageResource,
} from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useState } from "react";
import getBackendUrl from "src/common/backendUrl";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import successAlert from "../../../common/toastr";
import { SearchResult } from "../../../types/SearchResult";
import PhoneColumn from "../../common/Table/PhoneColumn/PhoneColumn";

import { useNavigate } from "react-router-dom";
import { LinkButton } from "../../common/buttons";
import "./UsersPage.scss";
import usersLanguageResource from "./lang-resource";
import { User, UserState, mapUserSearchResult } from "./user";

const UsersPage: React.FC = () => {
  const lang = useLanguageResource(usersLanguageResource);
  const { pagination, query, setQuery, throttledQuery } = usePaginationAndQuery();
  const { page, pageSize } = pagination;
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<SearchResult<User> | null>(null);
  const [deleteUser, setDeleteUser] = useState<User | null>(null);
  const [deleteUserInProgress, setDeleteUserInProgress] = useState(false);
  const [reactivateUserInProgress, setReactivateUserInProgress] = useState(false);
  const [deleteUserFailed, setDeleteUserFailed] = useState(false);
  const [reactivateUser, setReactivateUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const url = getBackendUrl({ relativeUrl: "/user-api/user", query: throttledQuery, page, pageSize });
    const response = await apiClient.get(url, mapUserSearchResult);
    if (response.type === "success") {
      setResult(response.result);
      setLoading(false);
    } else {
      setResult(() => {
        throw response.error;
      });
    }
  }, [page, pageSize, throttledQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeModal = () => {
    setDeleteUser(null);
  };

  const deleteUserFunc = async () => {
    setDeleteUserInProgress(true);
    setDeleteUserFailed(false);

    const res = await apiClient.delete(`/user-api/user/${deleteUser?.id}`);

    if (res.error) {
      setDeleteUserFailed(true);
      setDeleteUserInProgress(false);
      return;
    }

    setDeleteUser(null);
    setDeleteUserInProgress(false);
    await fetchData();
  };

  const reactivateUserFunc = async () => {
    setReactivateUserInProgress(true);

    const res = await apiClient.post(`/user-api/user/${reactivateUser?.id}/reactivate`);

    if (res.error) {
      setReactivateUser(() => {
        throw res.error;
      });
    }

    setReactivateUser(null);
    setReactivateUserInProgress(false);
    successAlert(lang.reactivateUserSuccess);
    await fetchData();
  };

  const renderStatusColumn = (state: UserState) => {
    let text = "";
    let stateCssClass = "";
    if (state === UserState.Active) {
      text = lang.stateActive;
      stateCssClass = "active";
    } else if (state === UserState.Deleted) {
      text = lang.stateDeleted;
      stateCssClass = "deactive";
    } else if (state === UserState.Inactive) {
      text = lang.stateInactive;
      stateCssClass = "deactive";
    }

    return (
      <td>
        <div className="user-state-column">
          <div className={`status-icon ${stateCssClass}`} />
          <div className="status-text">{text}</div>
        </div>
      </td>
    );
  };

  const renderActions = (user: User) => {
    const showReactivate = user.state === UserState.Inactive;
    const showEditButton = user.state === UserState.Inactive || user.state === UserState.Active;
    const showDeleteButton = user.state === UserState.Inactive || user.state === UserState.Active;
    return (
      <td className="actions">
        {showReactivate && (
          <IconButton
            variant="EnvelopeIcon"
            data-test-id="reactivate-user-button"
            title={lang.reactivateUser}
            onClick={() => setReactivateUser(user)}
            aria-label={lang.reactivateUser}
          />
        )}
        {showEditButton && (
          <IconButton
            variant="PencilIcon"
            data-test-id="edit-user-button"
            title={lang.editUser}
            onClick={() => navigate(`/brukere/${user.id}`)}
            aria-label={lang.editUser}
          />
        )}

        {showDeleteButton && (
          <IconButton variant="TrashIcon" data-test-id="delete-user-button" title={lang.deleteUser} onClick={() => setDeleteUser(user)} aria-label={lang.deleteUser} />
        )}
      </td>
    );
  };

  const renderRow = (user: User) => (
    <tr key={user.id}>
      <td>{user.email}</td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <PhoneColumn phone={user.phone} phoneCountryCode={user.phoneCountryCode} />
      {renderStatusColumn(user.state)}
      {renderActions(user)}
    </tr>
  );

  return (
    <Container width="m" data-test-id="components-user-admin-users" className="components-user-admin-users">
      <div className="page-header">
        <h1>{lang.title}</h1>
      </div>

      <div className="search-and-new">
        <SearchInput value={query} onChange={(e) => setQuery(e.target.value)} />
        <LinkButton
          onClick={() => {
            navigate("/brukere/ny");
          }}
          text={lang.newUser}
          dataTestId="create-user-button"
        />
      </div>

      <Table breakpoint="860px" loading={loading}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>{lang.userName}</th>
            <th style={{ width: "25%" }}>{lang.firstName}</th>
            <th style={{ width: "25%" }}>{lang.lastName}</th>
            <th style={{ width: "180px" }}>{lang.phone}</th>
            <th style={{ width: "105px" }}>{lang.state}</th>
            <th style={{ width: "160px" }} aria-label="actions" />
          </tr>
        </thead>
        <tbody>{result?.matches.map(renderRow)}</tbody>
      </Table>
      <TablePagination pagination={pagination} totalRecords={result?.totalEntries} />

      <Modal
        data-test-id="reactivate-user-modal"
        isOpen={Boolean(reactivateUser)}
        title={lang.reactivateUser}
        handleClose={() => setReactivateUser(null)}
        className="reactivate-user-modal"
      >
        <p data-test-id="reactivate-user-confirm-text">
          {lang.reactivateUserAreYourSure
            .replace("$firstName", reactivateUser?.firstName || "")
            .replace("$lastName", reactivateUser?.lastName || "")
            .replace("$userName", reactivateUser?.email || "")}
        </p>
        <ButtonGroup>
          <Button
            text={lang.yes}
            variant="primary"
            data-test-id="confirm-button"
            onClick={() => reactivateUserFunc()}
            loading={reactivateUserInProgress}
            type="button"
          />
          <Button text={lang.cancel} variant="cancel" type="button" data-test-id="cancel-button" onClick={() => setReactivateUser(null)} />
        </ButtonGroup>
      </Modal>

      <Modal
        data-test-id="delete-user-modal"
        isOpen={Boolean(deleteUser)}
        title={lang.deleteUser}
        handleClose={closeModal}
        className="delete-user-modal"
      >
        <p data-test-id="delete-user-confirm-text">
          {lang.deleteUserAreYourSure
            .replace("$firstName", deleteUser?.firstName || "")
            .replace("$lastName", deleteUser?.lastName || "")
            .replace("$userName", deleteUser?.email || "")}
        </p>
        {deleteUserFailed && (
          <div style={{ marginTop: "1rem" }}>
            <Message skin="danger" data-test-id="delete-user-error-message">
              {lang.deleteUserFail}
            </Message>
          </div>
        )}
        <ButtonGroup>
          <Button
            text={lang.yes}
            variant="primary"
            data-test-id="confirm-button"
            onClick={() => deleteUserFunc()}
            loading={deleteUserInProgress}
            type="button"
          />
          <Button text={lang.cancel} variant="cancel" type="button" data-test-id="cancel-button" onClick={closeModal} />
        </ButtonGroup>
      </Modal>
    </Container>
  );
};

export default UsersPage;
