import jschardet from "jschardet";
import Papa from "papaparse";

interface ReturnValue {
  name: string;
  data: string[][];
}

const readCsvFile = async (files: FileList | null): Promise<ReturnValue> => {
  const binaryReader = new FileReader();
  const textReader = new FileReader();

  return new Promise((resolve, reject) => {
    if (files === null) {
      reject("no files to read");
      return;
    }

    const csvFile = files[0];
    const { name } = csvFile;

    binaryReader.onload = async (e) => {
      const result = e.target?.result;

      if (result && typeof result === "string") {
        const encodingResult = jschardet.detect(result);
        textReader.readAsText(csvFile, encodingResult.encoding);
      }
    };

    textReader.onload = async (e) => {
      const result = e.target?.result;
      if (result && typeof result === "string") {
        const decodedCsv = Papa.parse<string[]>(result, {
          delimiter: ";",
        });

        resolve({
          name,
          data: decodedCsv.data,
        });
      }
    };

    binaryReader.readAsBinaryString(csvFile);
  });
};

export default readCsvFile;
