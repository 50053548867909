import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface EditCompanyAddressesLanguageResource {
  title: string;
  billAddress: string;
  postAddress: string;
  visitAddress: string;
  address1: string;
  address2: string;
  zipCode: string;
  area: string;
  submitFailure: string;
}

const editCompanyAddressesLanguageResource: LanguageResource<EditCompanyAddressesLanguageResource> = {
  nb: {
    title: "Endre adresser",
    billAddress: "Fakturaadresse",
    postAddress: "Postadresse",
    visitAddress: "Besøksadresse",
    address1: "Adresse linje 1",
    address2: "Adresse linje 2",
    zipCode: "Postnummer",
    area: "Poststed",
    submitFailure: "Det skjedde en feil under lagring av adresser!",
  },
  en: {
    title: "Edit addresses",
    billAddress: "Invoice address",
    postAddress: "Postal address",
    visitAddress: "Visiting address",
    address1: "Address line 1",
    address2: "Address line 2",
    zipCode: "Zip code",
    area: "Area",
    submitFailure: "An error occured while saving new addresses!",
  },
};

export default editCompanyAddressesLanguageResource;