import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface CreateCompanyLanguageResource {
  title: string
  orgNumber: string;
  search: string;
  orgNumberGenericError: string;
  orgNumberInvalidError: string;
  orgNumberNotFoundError: string;
  orgNumberUnsuitable: string;
  submitCompanyError: string;
  companySubmitConfirmationMessage: string;
  agreements: string;
  newAgreement: string;
  agreementNumber: string;
  agreementInvoideRef: string;
  agreementType: string;
  editAgreementButton: string;
  deleteAgreementButton: string;
  userAdmin: string;
  signee: string;
  fullName: string;
  company: string;
  customerNumber: string;
}

const createCompanyLanguageResource: LanguageResource<CreateCompanyLanguageResource> = {
  nb: {
    title: "Legg til ny bedrift",
    orgNumber: "Organisasjonsnummer",
    search: "Søk i Brønnøysundregisteret",
    orgNumberGenericError: "Det skjedde en feil ved henting av informasjon fra brønnøysundregisteret, prøv igjen eller kontakt kundestøtte",
    orgNumberInvalidError: "Ugyldig organisasjonsnummer, er du sikker på at du har skrevet inn riktig organisasjonsnummer?",
    orgNumberNotFoundError: "Fant ikke en bedrift som samsvarte med dette organisasjonsnummeret",
    orgNumberUnsuitable: "Basert på opplysningen fra Brønnøysundregisteret kan ikke denne bedriften bli kunde hos oss",
    submitCompanyError: "Det skjedde en feil ved lagring av ny bedrift!",
    companySubmitConfirmationMessage: "Bedrift opprettet!",
    agreements: "Avtaler",
    newAgreement: "Ny avtale",
    agreementNumber: "Avtalenummer",
    agreementInvoideRef: "Fakturareferanse",
    agreementType: "Type",
    editAgreementButton: "Endre avtale",
    deleteAgreementButton: "Slett avtale",
    userAdmin: "Admin bruker",
    signee: "Signering av kontrakt",
    fullName: "Navn",
    company: "Bedrift",
    customerNumber: "Kundenummer",
  },
  en: {
    title: "Add company",
    orgNumber: "Organization number",
    search: "Search in the Brønnøysund register",
    orgNumberGenericError: "An error occured while fetching info from the Brønnøysund register, try again or conatct customer support",
    orgNumberInvalidError: "Invalid organization number, are you sure that you have entered the correct number?",
    orgNumberNotFoundError: "Could not find a company matching the provided organization number",
    orgNumberUnsuitable: "Based on the information we found, this company can not become a customer with us",
    submitCompanyError: "An error occured while trying to add a new company!",
    companySubmitConfirmationMessage: "Company added!",
    agreements: "Agreements",
    newAgreement: "New agreement",
    agreementNumber: "Agreement number",
    agreementInvoideRef: "Invoice reference",
    agreementType: "Type",
    editAgreementButton: "Edit agreement",
    deleteAgreementButton: "Delete agreement",
    userAdmin: "Admin user",
    signee: "Signing of contract",
    fullName: "Name",
    company: "Company",
    customerNumber: "Customer number",
  },
};

export default createCompanyLanguageResource;