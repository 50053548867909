import React from "react";
import { useAuthContext } from "src/AuthContext";
import { hasValidAgreement } from "src/common/agreements";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRight } from "../../common/userRights";
import { Tenant, getTenant } from "../../types/Tenant";

export interface RequiredUserRight {
  userRight: UserRight[];
  tenants?: Tenant[];
  requireActiveAgreement?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorComponent: React.ComponentType<any>;
}

export interface RequiredRoles {
  roles: CompanyRoleType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorComponent: React.ComponentType<any>;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactNode
  requiredUserRight?: RequiredUserRight;
  requiredRoles?: RequiredRoles
}

const SecureComponent: React.FC<Props> = ({ children, requiredUserRight, requiredRoles }) => {
  const authContext = useAuthContext();

  if (!authContext.authenticated) {
    authContext.login();
    return null;
  }

  const { userRights } = authContext.userData;
  const { freeTicketAgreements, businessTicketAgreements, schoolTicketAgreements, roles } = authContext.userData.selectedCompany;

  if (requiredUserRight) {
    if (!userRights.some((x) => requiredUserRight.userRight.includes(x))) {
      return <requiredUserRight.errorComponent />;
    }
  }

  if (requiredUserRight?.tenants) {
    if (!requiredUserRight.tenants.some((x) => x === getTenant())) {
      return <requiredUserRight.errorComponent />;
    }
  }

  if (requiredUserRight?.requireActiveAgreement) {
    if (requiredUserRight.userRight.some((right) => right === UserRight.FreeTicketAgreeement)) {
      if (!hasValidAgreement(freeTicketAgreements)) {
        return <requiredUserRight.errorComponent />;
      }
    }
    if (requiredUserRight.userRight.some((right) => right === UserRight.BusinessAgreement)) {
      if (!hasValidAgreement(businessTicketAgreements)) {
        return <requiredUserRight.errorComponent />;
      }
    }
    if (requiredUserRight.userRight.some((right) => right === UserRight.SchoolTicketAdmin)) {
      if (!hasValidAgreement(schoolTicketAgreements)) {
        return <requiredUserRight.errorComponent />;
      }
    }
  }

  if (requiredRoles?.roles && requiredRoles?.roles.length > 0) {
    if (!requiredRoles.roles.some(role => roles?.includes(role))) {
      return <requiredRoles.errorComponent />;
    }
  }

  return <>{children}</>;
};

export default SecureComponent;
