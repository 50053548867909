export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phone: string;
  roles: Array<string>;
}

export interface ContactJson {
  city: string;
  country: string;
  customerNumber: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  postCode: string;
  roles: string[];
  status: string;
  streetAddress: string;
  streetAddress2: string;
  type: string;
}

export const mapContact = (json: ContactJson): Contact => ({
  id: json.id,
  firstName: json.firstName,
  lastName: json.lastName,
  email: json.email,
  phoneCountryCode: json.phoneCountryCode,
  phone: json.phone,
  roles: json.roles,
});
