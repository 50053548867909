import { NewTicketBecauseOf, parseNewTicketBecauseOf } from "./newTicketBecauseOf";

interface EditTicketPreviewJson {
  newTicketRequired: boolean;
  newTicketBecauseOf: string[];
}

interface EditTicketPreview {
  newTicketRequired: boolean;
  newTicketBecauseOf: NewTicketBecauseOf[];
}

export const mapEditTicketPreview = (json: EditTicketPreviewJson): EditTicketPreview => ({
  newTicketRequired: json.newTicketRequired,
  newTicketBecauseOf: json.newTicketBecauseOf.map(parseNewTicketBecauseOf),
});
