import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface MissingUserRightsLanguageResource {
  title: string;
  text: string;
  clickForContact: string;
}

export const missingUserRightsLanguageResource: LanguageResource<MissingUserRightsLanguageResource> = {
  nb: {
    title: "Din bruker mangler tilgang til denne siden.",
    text: "Kontakt kundestøtte hvis du mener at dette er en feil",
    clickForContact: "Kontakt kundestøtte",
  },
  en: {
    title: "Your user does not have access to this page.",
    text: "If you mean that this is caused by an error, please contact customer support.",
    clickForContact: "Contact customer support.",
  },
};