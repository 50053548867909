import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Col, Container, Row } from "@ruter-ds/rds-components";
import React from "react";
import byImage from "../../gfx/by.svg";
import { homeLanguageResource } from "./lang-resource";

const HomeBrakar: React.FC = () => {
  const language = useLanguageResource(homeLanguageResource);

  return (
    <Container width="l" className="components-home">
      <Row className="tickets-image">
        <Col s={6} sOffset={3} all={12}>
          <img src={byImage} alt="" />
        </Col>
      </Row>
      <Row className="tickets">
        <Col s={8} sOffset={2} all={12}>
          <h1>Brakar bedrift</h1>
          <p>{language.product1Text}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeBrakar;
