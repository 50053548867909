import { EmployeeInvoiceLine, EmployeeInvoiceLineJson, getValidEmployeeInvoiceLineJson } from "./EmployeeInvoiceLine";

export interface InvoiceEmployeeJson {
  id: string;
  invoiceNumber: null;
  year: number;
  month: number;
  invoiceLine: EmployeeInvoiceLineJson;
}

export interface EmployeeInvoice {
  id: string;
  invoiceNumber: null;
  year: number;
  month: number;
  invoiceLine: EmployeeInvoiceLine;
}

const mapEmployeeInvoice = (json: InvoiceEmployeeJson): EmployeeInvoice => (json);

export const mapEmployeeInvoices = (json: InvoiceEmployeeJson[]): EmployeeInvoice[] => json.map(mapEmployeeInvoice);

export const getValidInvoiceEmployeeJson = (changes?: Partial<InvoiceEmployeeJson>): InvoiceEmployeeJson => ({
  id: "1-3369898706_2021_01",
  invoiceLine: getValidEmployeeInvoiceLineJson(),
  invoiceNumber: null,
  month: 1,
  year: 2021,
  ...changes,
});
