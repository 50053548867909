import { apiClient } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { Widget, mapWidget } from "src/types/companyAgreement/Widget";

const useWidget = () => {
  const authContext = useAuthContextAuthenticated();
  const { selectedCompany } = authContext.userData;
  const [widget, setWidget] = useState<Widget | undefined>(undefined);

  if (!selectedCompany) {
    throw new Error("selectedCompany is not set");
  }

  const { id: selectedCompanyId, businessTicketAgreements } = selectedCompany;

  useEffect(() => {
    const ensureWidget = async () => {
      const response = await apiClient.get(`/api/widget/by-company-id/${selectedCompanyId}`, mapWidget);
      if (response.type === "success") {
        setWidget(response.result);
      } else if (response.type === "HttpError" && response.responseStatus === 404) {
        const body = {
          agreementNumbers: businessTicketAgreements.map((x) => x.id),
          format: "horisontal",
          width: "320",
          colourScheme: "light",
          logo: "",
          employeeIdHelpText: "",
          employeeIdRegex: "",
        };
        const postResponse = await apiClient.post("/api/auth/widget/create", body, mapWidget);

        if (postResponse.type === "success") {
          setWidget(postResponse.result);
        } else {
          setWidget(() => { throw postResponse.error; });
        }

      } else {
        setWidget(() => { throw response.error; });
      }
    };
    ensureWidget();
  }, [selectedCompanyId, businessTicketAgreements]);

  if (widget && !widget.clientId) {
    throw new Error("clientId can not be undefined");
  }

  if (widget && !widget.clientSecret) {
    throw new Error("clientSecret can not be undefined");
  }


  return widget;
};

export default useWidget;