import { Button, ButtonGroup, Message, Modal, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { Company } from "src/types/Company";
import companyPageLanguageResource from "./lang-resource";

interface Props {
  company: Company;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteCompanyModal: React.FC<Props> = ({ company, onClose, onSuccess }) => {
  const lang = useLanguageResource(companyPageLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);

  const deleteAgreement = async () => {
    setSubmitFailure(false);
    setLoading(true);
    const response = await apiClient.delete(`/onboarding/company/${company.id}`);

    if (response.type === "success") {
      setLoading(false);
      onSuccess();
    } else {
      Sentry.captureException(response.error);
      setLoading(false);
      setSubmitFailure(true);
    }
  };

  return (
    <Modal isOpen={true} title={lang.deleteCompanyTitle(company.name)} data-test-id="delete-company-modal" handleClose={onClose}>
      <p data-test-id="confirm-message">{lang.deleteCompanyConfirmationMessage(company.name, company.organisationNumber)}</p>
      {submitFailure && (
        <Message skin="danger" data-test-id="submit-failure" style={{ marginTop: "2rem" }}>
          <p>{lang.deleteFailure}</p>
        </Message>
      )}
      <ButtonGroup>
        <Button
          text={formLang.yes}
          variant="danger"
          type="button"
          onClick={deleteAgreement}
          loading={loading}
          data-test-id="confirm-button"
        />
        <Button text={formLang.cancel} variant="cancel" type="button" onClick={onClose} data-test-id="cancel-button" />
      </ButtonGroup>
    </Modal>
  );
};

export default DeleteCompanyModal;
