import { mapProductTemplate } from "@ruter-as/billettluke-frontend";
import { AddOn, mapZones } from "src/common/api/commonTypes/Zone";
import { mapEmployeeInvoices } from "src/common/api/companyAgreementApi/EmployeeInvoice";
import { mapCompanyAgreementOrder, mapCompanyAgreementOrderSearchResult } from "src/common/api/companyAgreementApi/order";
import { mapActiveSubscriptions } from "src/types/backendContracts/companyAgreementApi/ActiveSubscription";
import ChangeZonePostContract from "src/types/backendContracts/companyAgreementApi/ChangeZonePostContract";
import { UpdateEmployeeDetails } from "src/types/backendContracts/companyAgreementApi/UpdateEmployeeDetails";
import { mapPayrollArray } from "src/types/companyAgreement/Payroll";
import { mapPayrollChangeArray } from "src/types/companyAgreement/PayrollChange";
import { TicketRequestJson, mapTicketRequestSearchResult } from "src/types/companyAgreement/ticketRequest";
import CreateticketPostContract from "./createTicketPostContract";
import { mapPrices } from "./prices";


const companyAgreementApi = {
  addOn: {
    getByTicketId: (orderId: string) => ({
      method: "get" as const,
      url: `/api/add-on/by-ticket-id/${orderId}`,
      mapper: mapActiveSubscriptions,
    }),
    listByCompanyId: (companyId: string) => ({
      method: "get" as const,
      url: `/api/add-on/list-by-company-id/${companyId}`,
      mapper: (json: AddOn[]): AddOn[] => json,
    }),
  },
  ticket: {
    getByTicketId: (ticketId: string) => ({
      method: "get" as const,
      url: `/api/ticket/${ticketId}`,
      mapper: mapCompanyAgreementOrder,
    }),
    getEmployeeTicketsByTicketId: (ticketId: string) => ({
      method: "get" as const,
      url: `/api/ticket/all-entries-given-to-person-with-same-phone-number-as-in-this-order-id/${ticketId}`,
      mapper: mapCompanyAgreementOrderSearchResult,
    }),
    updateContactDetailsForAlltTickets: (ticketId: string, body: UpdateEmployeeDetails) => ({
      method: "post" as const,
      url: `/api/ticket/update-contact-details-for-all-entries-with-same-phone-number-as-this-order-id/${ticketId}`,
      body,
    }),
    changeZone: (ticketId: string, body: ChangeZonePostContract) => ({
      method: "post" as const,
      url: `/api/ticket/change-zone/${ticketId}`,
      body,
    }),
    cancelActiveTicket: (ticketId: string, expiryDate: Date) => ({
      method: "post" as const,
      url: `/api/ticket/change-expiry/by-id/${ticketId}/${encodeURIComponent(expiryDate.toISOString())}`,
    }),
    cancelPendingTicket: (ticketId: string) => ({
      method: "post" as const,
      url: `/api/ticket/cancel-pending/by-id/${ticketId}`,
    }),
    sendPickupCode: (ticketId: string) => ({
      method: "post" as const,
      url: `/api/ticket/resend-pickup-code/${ticketId}`,
    }),
    resendTicket: (ticketId: string) => ({
      method: "post" as const,
      url: `/api/ticket/resend/${ticketId}`,
    }),
    create: (body: CreateticketPostContract) => ({
      method: "post" as const,
      url: `/api/ticket/create`,
      body,
    }),
  },
  invoice: {
    getByTicketId: (ticketId: string) => ({
      method: "get" as const,
      url: `/api/invoice/by-ticket-id/${ticketId}`,
      mapper: mapEmployeeInvoices,
    }),
    getByAgreementAndDate: (agreementNumber: string, date: Date) => ({
      method: "get" as const,
      url: `/api/invoice/orders/${agreementNumber}/${encodeURIComponent(date.toISOString())}`,
      mapper: mapPayrollArray,
    }),
    getChangesByAgreementNumberAndDate: (agreementNumber: string, oldDate: Date, newDate: Date) => ({
      method: "get" as const,
      url: `/api/invoice/changed-subscriptions?agreement=${agreementNumber}&oldDate=${encodeURIComponent(oldDate.toISOString())}&newDate=${encodeURIComponent(newDate.toISOString())}`,
      mapper: mapPayrollChangeArray,
    }),
  },
  zones: {
    getAll: () => ({
      method: "get" as const,
      url: "/api/zone/list",
      mapper: mapZones,
    }),
  },
  product: {
    list: {
      get: (productTemplateId: string) => {
        return {
          method: "get" as const,
          url: `/api/product/list/${productTemplateId}`,
          mapper: mapPrices,
        };
      },
    },
    getByProductTemplateId: (id: string) => ({
      method: "get" as const,
      url: `/api/product/by-product-template-id/${id}`,
      mapper: mapProductTemplate,
    }),
  },
  ticketRequest: {
    getByCompanyId: (companyId: string) => {
      return {
        method: "get" as const,
        url: `/api/ticket-request/by-company-id/${companyId}`,
        mapper: mapTicketRequestSearchResult,
      };
    },
    reject: (ticketRequestId: string) => ({
      method: "delete" as const,
      url: `/api/ticket-request/${ticketRequestId}`,
    }),
    approve: (ticketRequest: TicketRequestJson) => ({
      method: "post" as const,
      url: `/api/ticket-request/approve`,
      body: ticketRequest,
    }),
  },
};

export default companyAgreementApi;