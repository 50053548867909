/* eslint-disable jsx-a11y/control-has-associated-label */
import { Table, apiClient, formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container } from "@ruter-ds/rds-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { CustomerOrder } from "src/common/api/invoice/CustomerOrder";
import invoiceApi from "src/common/api/invoice/invoiceApi";
import { Tenant, getTenant } from "src/types/Tenant";
import GotoColumn from "../../common/Table/GotoColumn/GotoColumn";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import invoiceLanguageResource from "./lang-resource";
import { getAgreementType, getInvoiceId } from "./utils";

const InvoicePage: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;
  const lang = useLanguageResource(invoiceLanguageResource);
  const [invoices, setInvoices] = useState<CustomerOrder[] | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const tenant = getTenant();
  if (!companyId) {
    throw new Error("companyId cannot be undefined");
  }

  useEffect(() => {
    const fetch = async () => {

      const response = tenant === Tenant.Ruter ? await apiClient.request(invoiceApi.api.invoice.getByCompanyId.ruter(companyId)) : await apiClient.request(invoiceApi.invoiceAdminApi.invoiceBase.byCompanyId.get(companyId));
      if (response.type === "success") {
        setInvoices(response.result);
        setLoading(false);
      } else {
        setInvoices(() => {
          throw response.error;
        });
      }
    };
    fetch();
  }, [companyId, tenant]);

  const gotoInvoiceDetails = (invoiceNumber: string) => {
    navigate(`/bedriftsavtale/faktura/${invoiceNumber}/`);
  };

  const renderRow = (invoice: CustomerOrder) => {
    return (
      <tr
        key={invoice.id}
        data-invoice-id={invoice.id}
        onClick={() => {
          gotoInvoiceDetails(invoice.id);
        }}
        style={{ cursor: "pointer" }}
      >
        <td>{getInvoiceId(invoice)}</td>
        <td>{invoice.invoicingDate ? formatter.date.toShortDateString(invoice.invoicingDate) : ""}</td>
        <td>{getAgreementType(invoice.agreementSubTypeId, lang)}</td>
        <td>{formatter.date.toMonthAndYear(new Date(invoice.year, invoice.month - 1))}</td>
        <td>{invoice.ticketCount}</td>
        <td>{invoice.reference}</td>
        <td>{invoice.paymentStatus}</td>
        <td>{invoice.dueDate ? formatter.date.toShortDateString(invoice.dueDate) : ""}</td>
        <PriceColumn price={invoice.totalPrice} showDecimals={true} />
        <GotoColumn />
      </tr>
    );
  };

  return (
    <Container width="l" className="components-company-tickets-page" data-test-id="components-company-tickets-page">
      <h1>{lang.title}</h1>

      <Table breakpoint="600px" loading={loading}>
        <thead>
          <tr>
            <th scope="col">{lang.invoiceNumber}</th>
            <th scope="col">{lang.date}</th>
            <th scope="col">{lang.agreementType}</th>
            <th scope="col">{lang.month}</th>
            <th scope="col">{lang.numberOfTickets}</th>
            <th scope="col">{lang.invoiceRef}</th>
            <th scope="col">{lang.paymentStatus}</th>
            <th scope="col">{lang.dueDate}</th>
            <th scope="col" style={{ textAlign: "right" }}>
              {lang.amount}
            </th>
            {/* <th scope="col" /> */}
            <th className="goto-column" />
          </tr>
        </thead>
        <tbody>{invoices?.map(renderRow)}</tbody>
      </Table>
    </Container>
  );
};

export default InvoicePage;
