/* eslint-disable jsx-a11y/control-has-associated-label */
import { SearchResult } from "@ruter-as/billettluke-frontend";
import { Button, SearchInput, Table, TablePagination, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container } from "@ruter-ds/rds-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { hasValidAgreement } from "src/common/agreements";
import getBackendUrl from "src/common/backendUrl";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import { CompanyAgreementOrder, mapCompanyAgreementOrderSearchResult } from "../../../common/api/companyAgreementApi/order";
import getTicketOwnerFieldName from "../../../common/ticketOwnerFieldName";
import GotoColumn from "../../common/Table/GotoColumn/GotoColumn";
import StatusColumn from "../../common/Table/StatusColumn/StatusColumn";
import "./EmployeesPage.scss";
import employeesLanguageResource from "./lang-resource";


const EmployeesPage: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const { selectedCompany } = authContext.userData;
  const companyId = selectedCompany.id;
  const lang = useLanguageResource(employeesLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(authContext);
  const { pagination, query, throttledQuery, setQuery } = usePaginationAndQuery();
  const [orders, setOrders] = useState<SearchResult<CompanyAgreementOrder> | undefined>();
  const [fetching, setFetching] = useState(true);
  const navigate = useNavigate();
  const { page, pageSize } = pagination;
  const businessAgreements = selectedCompany?.businessTicketAgreements;
  const hasValidBusinessAgreement = hasValidAgreement(businessAgreements);

  if (!companyId) {
    throw new Error(`${companyId} is not valid`);
  }

  if (!businessAgreements) {
    throw new Error(`${companyId} has no business agreement`);
  }


  useEffect(() => {
    const fetch = async () => {
      setFetching(true);
      const response = await apiClient.get(getBackendUrl({
        relativeUrl: `/api/ticket/by-company-id/${companyId}`,
        page: page,
        pageSize: pageSize,
        query: throttledQuery,
      }), mapCompanyAgreementOrderSearchResult);
      if (response.type === "success") {
        setOrders(response.result);
      } else {
        setOrders(() => { throw response.error; });
      }
      setFetching(false);
    };
    fetch();
    return () => { setOrders(undefined); setFetching(false); };
  }, [companyId, page, pageSize, throttledQuery]);

  const renderRow = (ticket: CompanyAgreementOrder): JSX.Element => {
    return (
      <tr key={ticket.id} onClick={() => navigate(`/bedriftsavtale/ansatt/${ticket.id}`)}>
        <td>{ticket.firstName}</td>
        <td>{ticket.lastName}</td>
        <td>{ticket.employeeId}</td>
        <td>{ticket.phone}</td>
        <StatusColumn status={ticket.status} />
        <GotoColumn />
      </tr>
    );
  };

  return (
    <Container width="s" className="components-company-tickets-page" data-test-id="employee-page">
      <h1>{lang.title}</h1>
      <div className="search-and-new-ticket">
        <SearchInput
          placeholder={lang.searchPlaceholder(ticketOwnerFieldName.toLowerCase())}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button type="button" variant="primary" data-test-id="create-ticket-button" text={lang.newTicket} onClick={() => navigate("/bedriftsavtale/billett/opprett")} disabled={!hasValidBusinessAgreement} />
      </div>

      <div>
        <Table
          breakpoint="600px"
          loading={fetching}
        >
          <thead>
            <tr>
              <th scope="col">{lang.firstName}</th>
              <th scope="col">{lang.lastName}</th>
              <th scope="col">{ticketOwnerFieldName}</th>
              <th scope="col">{lang.phoneNumber}</th>
              <th scope="col">{lang.status}</th>
              <th className="goto-column" />
            </tr>
          </thead>
          <tbody>{orders?.matches.map(renderRow)}</tbody>
        </Table>

        <TablePagination pagination={pagination} totalRecords={orders?.totalEntries} />
      </div>
    </Container>
  );
};

export default EmployeesPage;
