import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { FieldMessage, FormGroup, Label } from "@ruter-ds/rds-components";
import React from "react";
import { useFormContext } from "react-hook-form";
import { validationMessagesLanguageResource } from "src/common/validation-messages-language-resource";

interface Props {
  name: string;
  label?: string;
  validationLabel?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

const DropdownInput: React.FC<Props> = ({ name, label, required, validationLabel, children }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const lang = useLanguageResource(validationMessagesLanguageResource);

  let cssClass = "rds-field rds-select rds-select--medium";

  if (errors[name]) {
    cssClass += " rds-input--danger";
  }

  if (!validationLabel && !label) {
    throw new Error("validationLabel and label can not both be falsy");
  }

  const validationLabelText = validationLabel || label || "";

  return (
    <FormGroup>
      {label && <Label labelText={label} htmlFor={name} />}
      <select {...register(name, { required })} className={cssClass}>
        {children}
      </select>
      {errors[name]?.type === "required" && (
        <FieldMessage text={lang.required.replace("$fieldLabel$", validationLabelText)} skin="danger" />
      )}
    </FormGroup>
  );
};

export default DropdownInput;
