interface PriceForZoneJson {
  nrOfZones: number;
  pricePerMonthInclVAT: number;
}

export interface PriceForZone {
  nrOfZones: number;
  pricePerMonthInclVAT: number;
}

interface PricesJson {
  nrOfZonesGetAll: 3;
  templateId: string;
  prices: Array<PriceForZoneJson>;
}

export interface Prices {
  nrOfZonesGetAll: 3
  templateId: string;
  prices: Array<PriceForZone>;
}

export const mapPrices = (json: PricesJson): Prices => ({
  nrOfZonesGetAll: json.nrOfZonesGetAll,
  prices: json.prices,
  templateId: json.templateId,
});
