import { Container, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { Company, mapCompany } from "src/types/Company";
import EditCompanyAddress from "../common/modal/editCompanyAddress/EditCompanyAddress";
import AddressesCard from "./AddressesCard";
import CompanyCard from "./CompanyCard";
import ContactCard from "./ContactCard";
import myPageLanguageResource from "./lang-resource";

const MyPage: React.FC = () => {
  const lang = useLanguageResource(myPageLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const { contact, selectedCompany } = authContext.userData;
  const selectedCompanyId = selectedCompany.id;
  const [editAddresses, setEditAddresses] = useState(false);
  const [company, setCompany] = useState<Company | null>(null);

  if (!contact || !selectedCompanyId) {
    throw new Error("user can not be empty");
  }

  const fetch = useCallback(async () => {
    const response = await apiClient.get(`/onboarding/company/${selectedCompanyId}`, mapCompany);
    if (response.type === "success") {
      setCompany(response.result);
    } else {
      setCompany(() => { throw response.error; });
    }

  }, [selectedCompanyId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!company) {
    return null;
  }

  const onModalSuccess = () => {
    setEditAddresses(false);
    fetch();
  };

  return (
    <Container width="s" className="components-my-page" data-test-id="my-page">
      <h1>{lang.title}</h1>
      <ContactCard contact={contact} />
      <CompanyCard customerNumber={company.id} name={company.name} orgNumber={company.organisationNumber} />
      <AddressesCard companyType={company.type} companyAddresses={company.addresses} onChangeClick={() => setEditAddresses(true)} />
      {editAddresses && <EditCompanyAddress companyType={company.type} type="saveToBackend" onClose={() => setEditAddresses(false)} onSuccess={onModalSuccess} companyAddresses={company.addresses} companyId={company.id} />}
    </Container>
  );
};

export default MyPage;
