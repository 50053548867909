import { Zone } from "src/common/api/commonTypes/Zone";
import { Tenant, getTenant } from "src/types/Tenant";
import ZoneInput from "../form-hooks/ZoneInput";
import ZoneInputBrakar from "../form-hooks/ZoneInputBrakar";

interface Props {
  zones: Zone[]
}

const ZoneSelector: React.FC<Props> = ({ zones }) => {
  const tenant = getTenant();

  switch (tenant) {
    case Tenant.Brakar:
      return <ZoneInputBrakar zones={zones} />;
    case Tenant.Akt:
      return <ZoneInput zones={zones} />;
    case Tenant.Ruter:
      return <ZoneInput zones={zones} />;
    default:
      throw new Error(`tenant: ${tenant} dont have specified Zone input`);
  }
};

export default ZoneSelector;
