import { Container, Table, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import createAndDownloadCSV, { showNumberAsText } from "../../../common/csvFileCreator";
import formatter from "../../../common/formatter";
import { FreeTicketInvoice, mapFreeTicketInvoice } from "../../../types/freeTicketAgreement/freeTicketInvoice";
import { InvoiceLine } from "../../../types/freeTicketAgreement/invoiceLine";
import { ButtonLink } from "../../common/buttons";
import DetailComponent from "../../common/layout/Detail";
import PageHeaderComponent from "../../common/layout/PageHeader";
import "./FreeTicketInvoiceBaseDetailsPage.scss";
import { freeTicketInvoiceBaseDetailsLanguageResource } from "./lang-resource";

const FreeTicketInvoiceBaseDetailsPage: React.FC = () => {
  const lang = useLanguageResource(freeTicketInvoiceBaseDetailsLanguageResource);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const authContext = useAuthContextAuthenticated();
  const { freeTicketAgreements } = authContext.userData.selectedCompany;
  const [freeTicketInvoice, setFreeTicketInvoice] = useState<FreeTicketInvoice | null>(null);

  const findInvoiceRef = useCallback(
    (agreementNumberInput: string) => {
      const agreement = freeTicketAgreements.find((x) => x.id === agreementNumberInput);
      return agreement?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumberInput})`;
    },
    [lang.missingDepartmentName, freeTicketAgreements],
  );

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get(`/freeticket-api/invoice/by-id/${id}`, mapFreeTicketInvoice);
      if (response.type === "success") {
        setFreeTicketInvoice(response.result);
        setLoading(false);
      } else {
        setLoading(() => {
          throw response.error;
        });
      }
    };
    fetch();
  }, [id]);

  let title = "";

  if (freeTicketInvoice) {
    const { year, month, agreementNumber } = freeTicketInvoice;
    const period = moment(new Date(year, month - 1, 1)).format("MMM YYYY");
    const agreementInvoiceReference = findInvoiceRef(agreementNumber);
    title = lang.title.replace("{department}", agreementInvoiceReference).replace("{period}", period);
  }

  const renderRow = (row: InvoiceLine) => (
    <tr key={row.id}>
      <td>{row.employeeId}</td>
      <td>{row.description}</td>
      <td className="right-align">{formatter.number.integer(row.quantity)}</td>
      <td className="right-align">{formatter.number.currency(row.price, true)}</td>
      <td className="right-align">{formatter.number.currency(row.priceVat, true)}</td>
      <td className="right-align">{formatter.number.currency(row.totPrice, true)}</td>
      <td className="right-align">{formatter.number.currency(row.totVat, true)}</td>
    </tr>
  );

  const download = () => {
    const fileName = `${title}.csv`;

    if (freeTicketInvoice) {
      const { year, month, agreementNumber } = freeTicketInvoice;
      const period = moment(new Date(year, month - 1, 1)).format("MMMM");
      const agreementInvoiceReference = findInvoiceRef(agreementNumber);

      const headers = [
        lang.employeeId,
        `${agreementInvoiceReference}, ${period}`,
        lang.quantity,
        lang.amountIncVat,
        lang.amountVat,
        lang.totalAmountIncVat,
        lang.totalAmountVat,
      ];

      let data: string[][] = [];
      let totPriceSumFooter: string[][] = [];

      if (freeTicketInvoice?.invoiceLines) {
        data = freeTicketInvoice.invoiceLines.map((x) => [
          showNumberAsText(x.employeeId || ""),
          x.description || "",
          formatter.number.integer(x.quantity),
          formatter.number.currency(x.price, true),
          formatter.number.currency(x.priceVat, true),
          formatter.number.currency(x.totPrice, true),
          formatter.number.currency(x.totVat, true),
        ]);

        totPriceSumFooter = [[lang.totalSumIncVat], [formatter.number.currency(freeTicketInvoice?.totalPrice || 0, true)]];
      }

      createAndDownloadCSV(fileName, headers, data, totPriceSumFooter);
    }
  };

  return (
    <Container
      width="l"
      className="components-freeticketagreement-invoicebasedetails"
      data-test-id="components-freeticketagreement-invoicebasedetails"
    >
      <PageHeaderComponent loading={loading} text={title} />
      <div className="details">
        <DetailComponent loading={loading} label={lang.quantity} value={formatter.number.integer(freeTicketInvoice?.ticketCount || 0)} />
        <DetailComponent
          loading={loading}
          label={lang.totalAmountIncVat}
          value={formatter.number.currency(freeTicketInvoice?.totalPrice || 0, true)}
        />
        <DetailComponent
          loading={loading}
          label={lang.totalAmountVat}
          value={formatter.number.currency(freeTicketInvoice?.totalVat || 0, true)}
        />
      </div>

      <div className="download">
        <ButtonLink text={lang.downloadAsCSV} onClick={download} dataTestId="download-button" />
      </div>
      <Table loading={loading}>
        <thead>
          <tr>
            <th>{lang.employeeId}</th>
            <th>{lang.description}</th>
            <th style={{ width: "100px" }} className="right-align">
              {lang.quantity}
            </th>
            <th style={{ width: "190px" }} className="right-align">
              {lang.amountIncVat}
            </th>
            <th style={{ width: "130px" }} className="right-align">
              {lang.amountVat}
            </th>
            <th style={{ width: "180px" }} className="right-align">
              {lang.totalAmountIncVat}
            </th>
            <th style={{ width: "130px" }} className="right-align">
              {lang.totalAmountVat}
            </th>
          </tr>
        </thead>
        <tbody>{freeTicketInvoice?.invoiceLines.map(renderRow)}</tbody>
      </Table>
    </Container>
  );
};

export default FreeTicketInvoiceBaseDetailsPage;
