import { apiClient as wcatApiClient } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import { UserManager } from "oidc-client-ts";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import userApi from "./common/api/userApi/userApi";
import apiClient from "./common/apiClient/apiClient";
import getEnvironment from "./common/env";
import calculateUserRights, { UserRight } from "./common/userRights";
import Loading from "./components/app/loading/Loading";
import SelectCompany from "./components/app/selectCompany/SelectCompany";
import UserSetupError from "./components/app/userSetupError/UserSetupError";
import { AuthCompany, mapAuthCompany } from "./types/AuthCompany";
import { Contact, mapContact } from "./types/Contact";
import { UserRoleType } from "./types/user/UserRoleType";

interface UserData {
  contact: Contact;
  companies: AuthCompany[]
  selectedCompany: AuthCompany
  userRights: UserRight[]
  setSelectedCompany: (company: AuthCompany) => void;
  reload: () => Promise<void>
}

interface UserStateNotLoggedIn {
  status: "not-logged-in"
}

interface UserStateNotDetermined {
  status: "not-determined"
}

interface UserStateError {
  status: "error",
  error: Error
}

interface UserStateFetchSuccess {
  status: "fetch-success"
  contact: Contact
  companies: AuthCompany[]
}

type UserState = UserStateNotLoggedIn | UserStateNotDetermined | UserStateError | UserStateFetchSuccess;

interface AuthContextValuesNotAuthenticated {
  authenticated: false
  login: () => Promise<void>;
}

interface AuthContextValuesAuthenticated {
  authenticated: true
  getAccessToken: () => Promise<string>
  userData: UserData
  logout: () => Promise<void>
}

export type AuthContextValues = AuthContextValuesNotAuthenticated | AuthContextValuesAuthenticated;


const authConfig = {
  ...window.auth,
  response_type: "code",
  redirect_uri: `${window.location.origin}/implicit/callback`,
  post_logout_redirect_uri: window.location.origin,
  automaticSilentRenew: true,

};

const userManager = new UserManager(authConfig);

userManager.events.addAccessTokenExpired(async () => {
  try {
    await userManager.removeUser();
    await userManager.clearStaleState();
    await userManager.signinRedirect();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  window.location.href = window.origin;
});


const getAccessToken = async () => {
  const user = await userManager.getUser();
  return user?.access_token || "";
};

const login = async () => {
  await userManager.clearStaleState(); // cleans local storage for leftover state from failed auth attempts
  await userManager.signinRedirect();
};

const logout = async () => {
  const response = await wcatApiClient.get(`${window.auth.authority}/.well-known/openid-configuration`, (json) => json);
  await userManager.removeUser();
  await userManager.clearStaleState();
  if (response.type === "success") {
    const baseUrl = (response.result as any).end_session_endpoint;
    const clientId = encodeURIComponent(window.auth.client_id);
    const returnUri = encodeURIComponent(`${window.origin}/`);
    const logoutUrl = `${baseUrl}?client_id=${clientId}&logout_uri=${returnUri}`;

    window.location.href = logoutUrl;
  }
};

apiClient.setAuthenticationService({ getAccessToken, login });
wcatApiClient.setAuthenticationService({ getAccessToken, login });

const AuthContext = React.createContext<AuthContextValues>({ authenticated: false, login });

const selectedCompanyStorageKey = `${getEnvironment()}:selected-company-id`;

const AuthContextProvider: React.FC<PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => {
  const [userState, setUserState] = useState<UserState>({ status: "not-determined" });
  const [userSelectedCompany, setUserSelectedCompany] = useState<AuthCompany | undefined>();
  const navigate = useNavigate();
  const selectedCompany = useMemo(() => {
    if (userSelectedCompany) {
      return userSelectedCompany;
    }
    if (userState.status !== "fetch-success") {
      return undefined;
    }

    if (userState.companies.length === 1) {
      return userState.companies[0];
    }

    let selectedCompanyId = sessionStorage.getItem(selectedCompanyStorageKey);
    if (selectedCompanyId) {
      const company = userState.companies.find(x => x.id === selectedCompanyId);
      if (company) {
        return company;
      }
    }

    selectedCompanyId = localStorage.getItem(selectedCompanyStorageKey);
    if (selectedCompanyId) {
      const company = userState.companies.find(x => x.id === selectedCompanyId);
      if (company) {
        return company;
      }
    }
    return undefined;


  }, [userSelectedCompany, userState]);

  const updateSelectedCompany = (company: AuthCompany) => {
    setUserSelectedCompany(company);
    sessionStorage.setItem(selectedCompanyStorageKey, company.id);
    localStorage.setItem(selectedCompanyStorageKey, company.id);
    navigate("/");
  };

  const fetchUserData = useCallback(async () => {
    const [contactResponse, companiesResponse] = await Promise.all([
      wcatApiClient.request(userApi.auth.getContactNoMapping()),
      wcatApiClient.request(userApi.auth.getCompaniesNoMapping()),
    ]);

    if (contactResponse.error) {
      setUserState({ status: "error", error: contactResponse.error });
      return;
    }

    if (companiesResponse.error) {
      setUserState({ status: "error", error: companiesResponse.error });
      return;
    }

    try {
      const contact = mapContact(contactResponse.result);
      let companies = companiesResponse.result.map((company) => mapAuthCompany(company, contact));

      const companyFilter = (company: AuthCompany) => {
        return (
          company.businessTicketAgreements.length > 0 ||
          company.ticketCounterAgreements.length > 0 ||
          company.freeTicketAgreements.length > 0 ||
          company.schoolTicketAgreements.length > 0 ||
          company.roles.length > 0 ||
          contactResponse.result.roles.some((x) => x === UserRoleType.GLOBAL_TRAVEL_CARD_ADMIN)
        );
      };

      companies = companies.filter(companyFilter);

      if (companies.length === 0) {
        setUserState({ status: "error", error: new Error("company list cannot be empty") });
        return;
      }

      setUserState({
        status: "fetch-success",
        companies,
        contact,
      });
    } catch (error) {
      setUserState({ status: "error", error: error as Error });
    }
  }, []);

  useEffect(() => {
    const initializeApp = async () => {
      if (window.location.pathname.indexOf("implicit/callback") !== -1) {
        if (window.location.search) {
          await userManager.signinRedirectCallback();
        }
        window.location.href = "/";
        return;
      }

      const accessToken = (await userManager.getUser())?.access_token;
      if (accessToken) {
        await fetchUserData();
      } else {
        setUserState({ status: "not-logged-in" });
      }
    };
    initializeApp();

  }, [fetchUserData]);

  useEffect(() => {
    if (userState.status === "error") {
      Sentry.captureException(userState.error);
    }

  }, [userState]);

  if (userState.status === "error") {
    // error from backend when fetching userdata
    return <UserSetupError />;
  }

  if (userState.status === "not-determined") {
    return <Loading />;
  }

  if (userState.status === "not-logged-in") {
    return (
      <AuthContext.Provider value={{ authenticated: false, login }}>
        {children}
      </AuthContext.Provider>
    );
  }

  if (userState.contact && userState.companies.length === 0) {
    // userdata fetched and no companies in result
    return <UserSetupError />;
  }

  if (!selectedCompany) {
    // more than company available for user, user must select one
    return <SelectCompany companies={userState.companies} selectCompany={updateSelectedCompany} />;
  }

  Sentry.setUser({ email: userState.contact.email });

  return (
    <AuthContext.Provider value={{
      authenticated: true,
      getAccessToken,
      userData: {
        companies: userState.companies,
        contact: userState.contact,
        reload: fetchUserData,
        selectedCompany,
        setSelectedCompany: updateSelectedCompany,
        userRights: calculateUserRights(userState.contact, selectedCompany),
      },

      logout,
    }}>
      {children}
    </AuthContext.Provider >
  );
};

export const useAuthContext = (): AuthContextValues => {
  return useContext(AuthContext);
};

export const useAuthContextAuthenticated = (): AuthContextValuesAuthenticated => {
  const returnValue = useAuthContext();

  if (!returnValue.authenticated) {
    throw new Error("expected user to be authenticated");
  }

  return returnValue;
};

export default AuthContextProvider;
