import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import bus from "../../../gfx/illustrasjon-buss-404.svg";
import CallToActionLink from "../../common/buttons/CallToActionLink";
import "./NoAgreement.scss";
import { noAgreementLanguageResource } from "./lang-resource";

const NoBusinessAgreement: React.FC = () => {
  const lang = useLanguageResource(noAgreementLanguageResource);

  return (
    <Container width="m" className="components-companyagreement-noagreement" data-test-id="no-business-agreement-page">
      <img src={bus} alt="" />
      <h1>{lang.titleBusinessAgreement}</h1>
      <div>{lang.textBusinessAgreement}</div>
      <div className="actions">
        <CallToActionLink path="/kontaktoss" text={lang.clickForContact} showIcon={true} />
      </div>
    </Container>
  );
};

export default NoBusinessAgreement;
