import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface FreeTicketInvoiceBaseLanguageResource {
  title: string;
  month: string;
  ticketCount: string;
  department: string;
  totalPrice: string;
  missingDepartmentName: string;
}

export const freeTicketInvoiceBaseLanguageResource: LanguageResource<FreeTicketInvoiceBaseLanguageResource> = {
  nb: {
    title: "Fakturagrunnlag",
    month: "Måned",
    ticketCount: "Antall billetter",
    department: "Avdeling",
    totalPrice: "Fakturabeløp inkl mva",
    missingDepartmentName: "Avdelingsnavn ikke satt",
  },
  en: {
    title: "Invoice base",
    month: "Month",
    ticketCount: "Ticket count",
    department: "Department",
    totalPrice: "Total price inc vat",
    missingDepartmentName: "Department name not set",
  },
};