import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface ContactUsLanguageResource {
  title: string;
  message: string;
  sendButton: string;
  companyId: string;
  firstName: string;
  lastName: string;
  email: string;
  responseTitle: string;
}

const contactUsLanguageResource: LanguageResource<ContactUsLanguageResource> = {
  nb: { 
    title: "Kontakt oss",
    message: "Melding",
    sendButton: "Send",
    companyId: "Selskapsid",
    firstName: "Fornavn",
    lastName: "Etternavn",
    email: "E-post",
    responseTitle: "Takk for tilbakemeldingen! Vi har mottatt følgende:",
  },
  en: {
    title: "Contact us",
    message: "Message",
    sendButton: "Send",
    companyId: "Company id",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    responseTitle: "Thank you for the feedback! We have recieved the following information:",
  },
};

export default contactUsLanguageResource;
