import { UNKNOWN } from "../../constants";

export enum CompanyRoleType {
  SUBSCRIPTION_ADMIN = "SUBSCRIPTION_ADMIN",
  TICKET_ADMIN = "TICKET_ADMIN",
  FREETICKET_ADMIN = "FREETICKET_ADMIN",
  SUBSCRIPTION_CREATE_OR_REQUEST = "SUBSCRIPTION_CREATE_OR_REQUEST",
  USER_ADMIN = "USER_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  SCHOOLTICKET_ADMIN = "SCHOOLTICKET_ADMIN",
}

export const parseCompanyRoleType = (value: string): CompanyRoleType | typeof UNKNOWN => {
  const parsed = Object.values(CompanyRoleType).find((x) => x === value);

  if (parsed === undefined) {
    return UNKNOWN;
  }
  return parsed;
};
