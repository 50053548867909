import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Icon } from "@ruter-ds/rds-components";
import { CrossIcon } from "@ruter-ds/rds-icons";
import moment from "moment";
import React, { useState } from "react";
import { ActiveSubscription } from "src/types/backendContracts/companyAgreementApi/ActiveSubscription";
import apiClient from "../../../common/apiClient/apiClient";
import formatter from "../../../common/formatter";
import successAlert, { failAlert } from "../../../common/toastr";
import { TICKET_ACTIVE, TICKET_CANCELLED, TICKET_EXPIRED, TICKET_PENDING } from "../../../constants";
import ottoLogo from "../../../gfx/otto-logo.svg";
import "./Ticket.scss";
import employeeTicketsLanguageResource from "./lang-resource";

const formatExpirationDate = (expirationDate: Date | null) => {
  if (expirationDate === null) {
    return "–";
  }
  formatter.date.shortDate(expirationDate);
};

const calculatePrice = (addOn: ActiveSubscription) => {
  const price = addOn.pricePerMonth;

  return formatter.number.currency(price, false);
};

interface Props {
  addOn: ActiveSubscription;
  orderId: string;
}

const addOnStatus = (addOn: ActiveSubscription) => {
  const now = moment();
  if (addOn.endDate && now.isAfter(addOn.endDate)) {
    return TICKET_EXPIRED;
  }
  if (now.isSameOrAfter(addOn.startDate, "day")) {
    return TICKET_ACTIVE;
  } else if (now.isBefore(addOn.startDate, "day")) {
    return TICKET_PENDING;
  }
  return TICKET_CANCELLED;
};

const AddOn: React.FC<Props> = ({ addOn, orderId }) => {
  const language = useLanguageResource(employeeTicketsLanguageResource);
  const status = addOnStatus(addOn);
  const [loading, setLoading] = useState(false);

  const onCancel = async () => {
    try {
      setLoading(true);
      await apiClient.put(`/add-on/expiry/by-ticket-id/${orderId}/${addOn.type}`, {});
      successAlert(language.cancelAddOnSuccess(addOn.type));
    } catch (e) {
      failAlert(language.cancelAddOnFailed(addOn.type));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="components-agreement-ticket-ticket">
      <div className={`status ${status}`} />
      <div className="add-on-logo">
        <img src={ottoLogo} alt="" />
      </div>
      <div className="info">
        <div className="row">
          <div className="label">{language.status}</div>
          <div className="value">{language.statuses[status]}</div>
        </div>

        <div className="row">
          <div className="label">{language.validFrom}</div>
          <div className="value">{moment(addOn.startDate).format("L")}</div>
        </div>
        <div className="row">
          <div className="label">{language.validTo}</div>
          <div className="value">{formatExpirationDate(addOn.endDate)}</div>
        </div>
        <div className="row">
          <div className="label">{language.pricePerMonth}</div>
          <div className="value">{calculatePrice(addOn)}</div>
        </div>
      </div>
      <div className="actions">
        <button type="button" onClick={onCancel} disabled={loading}>
          <Icon component={<CrossIcon />} size="small" />
          <span>{status === TICKET_ACTIVE ? language.cancelActiveAddOn : language.cancelPendingAddOn}</span>
        </button>
      </div>
    </div>
  );
};

export default AddOn;
