enum OnboardingNextStep {
  PROVIDE_SIGNEE_INFO = "PROVIDE_SIGNEE_INFO",
  CONTRACT_SIGNED = "CONTRACT_SIGNED",
  CONTACT_SUPPORT_ERROR_OCCURRED = "CONTACT_SUPPORT_ERROR_OCCURRED",
  CONTACT_SUPPORT_COMPANY_EXISTS = "CONTACT_SUPPORT_COMPANY_EXISTS",

}

const parseOnboardingNextStep = (value: string | undefined | null): OnboardingNextStep => {
  const parsed = Object.values(OnboardingNextStep).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error(`${value} is not a valid value for OnboardingNextStep`);
  }
  return parsed;
};

export { OnboardingNextStep, parseOnboardingNextStep };

