import { Container, Table, apiClient, formatter, parser, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import schoolTicketApi from "src/common/api/schoolTicketApi/schoolTicketApi";
import { TravelCard, TravelCardManufacture } from "src/common/api/schoolTicketApi/travelCardAdmin";
import { TravelCardState } from "src/types/TravelCardState";
import "./TravelCardDetails.scss";
import travelCardDetailsLanguageResource, { TravelCardDetailsLanguageResource } from "./lang-resource";

const getStatus = (status: TravelCardState | undefined, lang: TravelCardDetailsLanguageResource): string => {
  if (status === TravelCardState.CREATED) {
    return lang.statuses.created;
  }
  if (status === TravelCardState.DEACTIVATED) {
    return lang.statuses.deactivated;
  }
  if (status === TravelCardState.REQUESTED) {
    return lang.statuses.requested;
  }
  if (status === TravelCardState.CANCELLED) {
    return lang.statuses.cancelled;
  }
  if (status === TravelCardState.ANONYMISED) {
    return lang.statuses.anonymised;
  }
  return lang.statuses.unknown;
};


const TravelCardDetails: React.FC = () => {
  const { date, companyId } = useParams();
  const lang = useLanguageResource(travelCardDetailsLanguageResource);
  const [travelCardManufacture, setTravelCardManufacture] = useState<TravelCardManufacture | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await apiClient.request(schoolTicketApi.travelCards.manufactureReport.get({ companyId: companyId, manufacturedDate: parser.date.fromShortDateString((date || "").replaceAll("-", ".")) }));
      if (response.type === "success") {
        setTravelCardManufacture(response.result.matches[0]);
        setLoading(false);
      } else {
        setTravelCardManufacture(() => {
          throw response.error;
        });
      }
    };
    fetch();
  }, [companyId, date]);

  const renderRow = (row: TravelCard) => {
    return (
      <tr key={row.cardNumber}>
        <td>{row.cardNumber}</td>
        <td>{row.cardName}</td>
        <td>{formatter.date.toShortDateAndTimeString(row.startDate)}</td>
      </tr>
    );
  };

  const cards = travelCardManufacture?.cards;
  return (
    <Container width="l" className="travel-card-details" data-test-id="travel-card-details">
      <h1>{lang.title}</h1>
      <dl>
        <dt className="label">{lang.company}</dt>
        <dd className="value">{travelCardManufacture?.company?.name || "–"}</dd>
        <dt className="label">{lang.status}</dt>
        <dd className="value">{getStatus(travelCardManufacture?.state, lang)}</dd>
        <dt className="label">{lang.productionDate}</dt>
        <dd className="value">{travelCardManufacture?.manufacturedDate ? formatter.date.toShortDateAndTimeString(travelCardManufacture.manufacturedDate) : "–"}</dd>
      </dl>
      <Table loading={loading}>
        <thead>
          <tr>
            <th scope="col">{lang.travelCarNumber}</th>
            <th scope="col">{lang.cardName}</th>
            <th scope="col">{lang.startDate}</th>
          </tr>
        </thead>
        <tbody>
          {cards?.map(renderRow)}
        </tbody>
      </Table>
    </Container>
  );
};

export default TravelCardDetails;