import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface ToolsLanguageResource {
  title: string;
  travelPlannerTitle: string;
  travelPlannerIngress: string;
  travelPlannerLink: string;
  realTimeTitle: string;
  realTimeIngress: string;
  realTimeLink: string;
  companyTicketsWidgetTitle: string;
  companyTicketsWidgetIngress: string;
  companyTicketsWidgetLink: string;
  companyTicketsLinkTitle: string;
  companyTicketsLinkIngress: string;
  companyTicketsLinkLink: string;
}

export const toolsLanguageResource: LanguageResource<ToolsLanguageResource> = {
  nb: {
    title: "Verktøy og informasjonstjenester",
    travelPlannerTitle: "Reiseplanlegger for bedrift",
    travelPlannerIngress:
      "Bedrifter kan også få en egen reiseplanlegger på nettsiden eller intranettet sitt, for å gjøre det enklere for ansatte å reise kollektivt.",
    travelPlannerLink: "Lag reiseplanlegger-widget",
    realTimeTitle: "Sanntidsskjerm for bedrift",
    realTimeIngress: "Bedrifter kan lage sin egen sanntidsskjerm som viser avgangsinformasjon for utvalgte holdeplasser.",
    realTimeLink: "Sett opp din egen sanntidsskjerm",
    companyTicketsWidgetTitle: "Bestillingskjema for bedriftsbillett som widget",
    companyTicketsWidgetIngress:
      "Integrer bestillingskjema for bedriftsbilletter på ditt intranett slik at ansatte selv kan bestille bedriftsbilletter til seg selv.",
    companyTicketsWidgetLink: "Sett opp bestillingsskjema-widget",
    companyTicketsLinkTitle: "Bestillingskjema for bedriftsbillett som lenke uten innlogging",
    companyTicketsLinkIngress:
      "Få en lenke til vår portal for bestilling av bedriftsbilletter for din bedrift, brukere som trykker på lenken vil ikke trenge å logge inn for å bestille.",
    companyTicketsLinkLink: "Hent link til bestillingsskjema",
  },
  en: {
    title: "Tools and information services",
    travelPlannerTitle: "Travel planner for business",
    travelPlannerIngress:
      "Companies can also get their own travel planner on their website or intranet, to make it easier for employees to travel collectively.",
    travelPlannerLink: "Create travel planner widget",
    realTimeTitle: "Real-time schedule for business",
    realTimeIngress: "Companies can create their own real-time display that shows retrieval information for selected stops",
    realTimeLink: "Create your own real-time display.",
    companyTicketsWidgetTitle: "Order form for tickets as a widget",
    companyTicketsWidgetIngress: "Integrate an order form on your intranet.",
    companyTicketsWidgetLink: "Create order form",
    companyTicketsLinkTitle: "Order form for tickets as a link",
    companyTicketsLinkIngress: "Get a link to our portal for ordering of tickets, users do not have to log in.",
    companyTicketsLinkLink: "Get link to order form",
  },
};
