export enum TicketStatus {
  active = "active",
  pending = "pending",
  expired = "expired",
  cancelled = "cancelled",
  failed = "failed",
  unknown = "unknown",
}

export const parseTicketStatus = (value: string): TicketStatus => {
  const parsed = Object.values(TicketStatus).find((x) => x === value);

  if (parsed === undefined) {
    return TicketStatus.unknown;
  }
  return parsed;
};
