import { InvoiceLine, InvoiceLineJson, getValidInvoiceLine, mapInvoiceLine } from "./invoiceLine";

interface FreeTicketInvoiceJson {
  id: string;
  ownerId: number;
  orderNumber: string;
  reference: string | null;
  createdAt: string;
  companyCustomerNumber: string;
  companyName: string;
  agreementNumber: string;
  year: number;
  month: number;
  totalPrice: number;
  totalVat: number | null;
  ticketCount: number;
  invoiceNumber: string | null;
  invoiceReference: string | null;
  invoicingDate: string | null;
  dueDate: string | null;
  paymentStatus: string | null;
  agreementType: string | null;
  reminderDueDate: string | null;
  invoiceLines: InvoiceLineJson[];
}

export interface FreeTicketInvoice {
  id: string;
  agreementNumber: string;
  year: number;
  month: number;
  ticketCount: number;
  totalPrice: number;
  totalVat: number;
  invoiceLines: InvoiceLine[];
}

export const mapFreeTicketInvoice = (json: FreeTicketInvoiceJson): FreeTicketInvoice => ({
  id: json.id,
  agreementNumber: json.agreementNumber,
  year: json.year,
  month: json.month,
  ticketCount: json.ticketCount,
  totalPrice: json.totalPrice,
  totalVat: json.totalVat || 0,
  invoiceLines: json.invoiceLines.map(mapInvoiceLine),
});

export const getValidFreeTicketInvoiceJson = (changes: Partial<FreeTicketInvoiceJson> = {}): FreeTicketInvoiceJson => ({
  agreementNumber: "1-3779173843",
  agreementType: null,
  companyCustomerNumber: "1226629",
  companyName: "Steffens Kosmetikk",
  createdAt: "2021-01-29T21:04:53Z",
  dueDate: null,
  id: "1-3779173843_2021_01",
  invoiceNumber: null,
  invoiceReference: null,
  invoicingDate: null,
  month: 1,
  orderNumber: "2021-JANUARY",
  ownerId: 3,
  paymentStatus: null,
  reference: null,
  reminderDueDate: null,
  ticketCount: 9283,
  totalPrice: 539112,
  totalVat: 96967.99,
  year: 2021,
  invoiceLines: [getValidInvoiceLine()],
  ...changes,
});
