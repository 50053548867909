import { EmployeeInvoiceLine, EmployeeInvoiceLineJson, getValidEmployeeInvoiceLineJson, mapInvoiceLine } from "../../common/api/companyAgreementApi/EmployeeInvoiceLine";
import { PayrollChangeType, parsePayrollChangeType } from "./PayrollChangeType";

interface PayrollChangeJson {
  changeType: string;
  priceDifference: number;
  oldOrder: EmployeeInvoiceLineJson;
  newOrder: EmployeeInvoiceLineJson;
}

export interface PayrollChange {
  changeType: PayrollChangeType;
  priceDifference: number;
  oldOrder: EmployeeInvoiceLine;
  newOrder: EmployeeInvoiceLine;
}

const mapPayrollChange = (json: PayrollChangeJson): PayrollChange => ({
  changeType: parsePayrollChangeType(json.changeType),
  priceDifference: json.priceDifference,
  oldOrder: mapInvoiceLine(json.oldOrder),
  newOrder: mapInvoiceLine(json.newOrder),
});

export const mapPayrollChangeArray = (json: PayrollChangeJson[]): PayrollChange[] => json.map(mapPayrollChange);

export const getValidPayrollChangeJson = (changes?: Partial<PayrollChangeJson>): PayrollChangeJson => ({
  changeType: PayrollChangeType.PRICE_CHANGED.toString(),
  priceDifference: 0,
  oldOrder: getValidEmployeeInvoiceLineJson(),
  newOrder: getValidEmployeeInvoiceLineJson(),
  ...changes,
});
