import { AuthCompany } from "../types/AuthCompany";
import { Contact } from "../types/Contact";
import { UserRoleType } from "../types/user/UserRoleType";

export enum UserRight {
  BusinessAgreement = "BusinessAgreement",
  TicketCounterAgreeement = "TicketCounterAgreeement",
  FreeTicketAgreeement = "FreeTicketAgreeement",
  GlobalUserAdmin = "GlobalUserAdmin",
  GlobalTravelCardAdmin = "GlobalTravelCardAdmin",
  UserAdmin = "UserAdmin",
  CompanyAdmin = "CompanyAdmin",
  SchoolTicketAdmin = "SchoolTicketAdmin",
  GlobalSchoolTicketAdmin = "GlobalSchoolTicketAdmin",
}

const calculateUserRights = (contact: Contact, selectedCompany: AuthCompany): Array<UserRight> => {
  const output: Array<UserRight> = [];

  if ((selectedCompany.businessTicketAgreements.length || 0) > 0) {
    output.push(UserRight.BusinessAgreement);
  }
  if ((selectedCompany.ticketCounterAgreements.length || 0) > 0) {
    output.push(UserRight.TicketCounterAgreeement);
  }
  if ((selectedCompany.freeTicketAgreements.length || 0) > 0) {
    output.push(UserRight.FreeTicketAgreeement);
  }
  if ((selectedCompany.schoolTicketAgreements.length || 0) > 0) {
    output.push(UserRight.SchoolTicketAdmin);
  }
  if (selectedCompany.roles.some((x) => x === UserRoleType.USER_ADMIN)) {
    output.push(UserRight.UserAdmin);
  }
  if (contact.roles.some((x) => x === UserRoleType.GLOBAL_USER_ADMIN)) {
    output.push(UserRight.GlobalUserAdmin);
  }
  if (contact.roles.some((x) => x === UserRoleType.GLOBAL_TRAVEL_CARD_ADMIN)) {
    output.push(UserRight.GlobalTravelCardAdmin);
  }
  if (contact.roles.some((x) => x === UserRoleType.GLOBAL_COMPANY_ADMIN)) {
    output.push(UserRight.CompanyAdmin);
  }
  if (contact.roles.some((x) => x === UserRoleType.GLOBAL_SCHOOLTICKET_ADMIN)) {
    output.push(UserRight.GlobalSchoolTicketAdmin);
  }
  return output;
};

export default calculateUserRights;
