import { CompanyAddresses } from "./Company";

export interface CompanyPrefillJson {
  name: string;
  organisationNumber: string;

  billAddress: {
    postArea: string | null;
    countryCode: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    postCode: string | null;
  },
  postAddress: {
    postArea: string | null;
    countryCode: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    postCode: string | null;
  },
  visitAddress: {
    postArea: string | null;
    countryCode: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    postCode: string | null;
  }
}

export interface CompanyPrefill {
  name: string;
  organisationNumber: string;
  addresses: CompanyAddresses;
}

export const mapCompanyPrefill = (json: CompanyPrefillJson): CompanyPrefill => ({
  name: json.name,
  organisationNumber: json.organisationNumber,
  addresses: {
    bill: {
      city: json.billAddress.postArea || "",
      country: json.billAddress.countryCode || "",
      line1: json.billAddress.addressLine1 || "",
      line2: json.billAddress.addressLine2 || "",
      postCode: json.billAddress.postCode || "",
    },
    post: {
      city: json.postAddress.postArea || "",
      country: json.postAddress.countryCode || "",
      line1: json.postAddress.addressLine1 || "",
      line2: json.postAddress.addressLine2 || "",
      postCode: json.postAddress.postCode || "",
    },
    visit: {
      city: json.visitAddress.postArea || "",
      country: json.visitAddress.countryCode || "",
      line1: json.visitAddress.addressLine1 || "",
      line2: json.visitAddress.addressLine2 || "",
      postCode: json.visitAddress.postCode || "",
    },
  },
});

export const getValidCompanyPrefillJson = (): CompanyPrefillJson => ({
  "organisationNumber": "921366663",
  "name": "SØRNES CONSULTING",
  "visitAddress": {
    "addressLine1": "Maridalsveien 311",
    "addressLine2": null,
    "postCode": "0872",
    "postArea": "OSLO",
    "countryCode": "NO",
  },
  "billAddress": {
    "addressLine1": "Maridalsveien 311",
    "addressLine2": null,
    "postCode": "0872",
    "postArea": "OSLO",
    "countryCode": "NO",
  },
  "postAddress": {
    "addressLine1": "Maridalsveien 311",
    "addressLine2": null,
    "postCode": "0872",
    "postArea": "OSLO",
    "countryCode": "NO",
  },
}
);