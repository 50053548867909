import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { yesNoColumnLanuageResource } from "./lang-resource";

interface Props {
  yes: boolean;
  "data-label"?: string;
}

const YesNoColumn: React.FC<Props> = (props) => {
  const { yes } = props;
  const dataLabel = props["data-label"]; // eslint-disable-line
  const language = useLanguageResource(yesNoColumnLanuageResource);

  return <td data-label={dataLabel}>{yes ? language.yes : language.no}</td>;
};

export default YesNoColumn;
