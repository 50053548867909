import { LanguageResource, OneParamLanguageResourceTemplate, languageResourceBuilder } from "@ruter-as/web-components-and-tools";

interface InvoiceDetailsLanguageResource {
  actions: string;
  title: OneParamLanguageResourceTemplate;
  sumTitle: OneParamLanguageResourceTemplate;
  invoiceBase: string;
  paymentStatus: string;
  invoiceDate: string;
  invoiceRef: string;
  dueDate: string;
  overdueNoticeStatus: string;
  downloadAsPdf: string;
  totalSum: string;
  mva: string;
  name: string;
  description: string;
  employeeNumber: string;
  zones: string;
  amount: string;
  quantity: string;
  price: string;
  agreementType: string;
  refundInvoiceLine: string
  refund: string
  refunded: string
  refundModal: {
    title: string
    refundAmount: string
    totalAmount: string
    confirm: string
    cancel: string
    duplicateRequestError: string
    agreementNumber: string
    newDescription: string
  }
}


export const invoiceDetailsLanguageResource: LanguageResource<InvoiceDetailsLanguageResource> = {
  nb: {
    actions: "handlinger",
    title: languageResourceBuilder.oneParamTemplate("Faktura $p1$"),
    sumTitle: languageResourceBuilder.oneParamTemplate("Faktura detaljer for $p1$"),
    invoiceBase: "Fakturagrunnlag",
    paymentStatus: "Status",
    overdueNoticeStatus: "Purring sendt",
    invoiceDate: "Fakturadato",
    invoiceRef: "Referanse",
    dueDate: "Forfallsdato",
    agreementType: "Avtaletype",

    downloadAsPdf: "Last ned faktura som PDF",
    totalSum: "Total sum",
    mva: "MVA",

    description: "Beskrivelse",
    name: "Navn",
    employeeNumber: "Ansattnr",
    zones: "Soner",
    amount: "Beløp inkl. mva",
    quantity: "Antall",
    price: "Pris",
    refundInvoiceLine: "Refunder fakturalinje",
    refund: "Refunder",
    refunded: "Kreditert",
    refundModal: {
      title: "Refunder ordre",
      refundAmount: "Kreditert beløp",
      totalAmount: "Totalbeløp",
      cancel: "Avbryt",
      confirm: "Bekreft",
      duplicateRequestError: "Denne fakturalinjen har nylig vært kreditert, vent 2 minutter for ny kredittering",
      agreementNumber: "Avtalenummer",
      newDescription: "Ny beskrivelse – Valgfri",
    },
  },
  en: {
    actions: "actions",
    title: languageResourceBuilder.oneParamTemplate("Invoice $p1$"),
    sumTitle: languageResourceBuilder.oneParamTemplate("Invoice details for $p1$"),
    invoiceBase: "Invoice base",
    paymentStatus: "Status",
    overdueNoticeStatus: "Overdue notice sent",
    invoiceDate: "Invoice date",
    invoiceRef: "Invoice reference",
    dueDate: "Due date",
    agreementType: "Agreement type",

    downloadAsPdf: "Download invoice as PDF",
    totalSum: "Total sum",
    mva: "VAT",

    description: "Description",
    name: "Name",
    employeeNumber: "Employee number",
    zones: "Zones",
    amount: "Amount incl. Vat",
    quantity: "Quantity",
    price: "Price",
    refundInvoiceLine: "Refund invoice line",
    refund: "Refund",
    refunded: "Refunded",
    refundModal: {
      title: "Refund order",
      refundAmount: "Refunded amount",
      totalAmount: "Total amount",
      cancel: "Cancel",
      confirm: "Confirm",
      duplicateRequestError: "This invoice line has newly been refunded, wait 2 minutes before making a new refund request",
      agreementNumber: "Agreement number",
      newDescription: "New description – Optional",
    },
  },
};
