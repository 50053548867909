import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { TicketZonesLanguageResource, ticketZonesLanguageResource } from "./lang-resource";

interface Props {
  zones: string[],
  nrOfZones: number,
  zoneType: "Radius" | "ZoneToZone",
}

interface GetZonesTextProps extends Props {
  language: TicketZonesLanguageResource
}

export const getZonesText = ({ language, zoneType, nrOfZones, zones }: GetZonesTextProps) => {
  let text = "";

  if (zoneType === "ZoneToZone") {
    if (nrOfZones === 1) {
      text = language.zoneOne.replace("{zoneFrom}", zones[0]);
    } else if (nrOfZones === 2) {
      if (zones.length < 2) {
        text = language.unknown;
      } else {
        const zone1 = zones[0];
        const zone2 = zones[1];
        text = language.zoneTwo.replace("{zoneFrom}", zone1).replace("{zoneTo}", zone2);
      }
    } else {
      text = language.allZones;
    }
  } else if (zoneType === "Radius") {
    if (nrOfZones === 1) {
      text = language.zoneOne.replace("{zoneFrom}", zones[0]);
    } else {
      text = language.radiusZones.replace("{zoneFrom}", zones[0]).replace("{zoneCount}", nrOfZones.toString());
    }
  }
  return text;
};

export const TicketZonesComponent: React.FC<Props> = ({ zoneType, nrOfZones, zones }) => {
  const language = useLanguageResource(ticketZonesLanguageResource);
  const text = getZonesText({ language, zoneType, nrOfZones, zones });

  return <span>{text}</span>;
};
