import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Card, Container } from "@ruter-ds/rds-components";
import React from "react";
import getEnvironment, { EnvName } from "../../../common/env";
import successAlert from "../../../common/toastr";
import { WhiteButton } from "../../common/buttons";
import "./LinkPage.scss";
import widgetLanguageResource from "./lang-resource";
import useWidget from "./useWidget";

const LinkPage: React.FC = () => {
  const lang = useLanguageResource(widgetLanguageResource);
  const widget = useWidget();

  if (!widget) return null;

  const getUrl = () => {
    const { clientId, clientSecret } = widget;

    const env = getEnvironment();

    let origin = "http://localhost:4000";
    if (env === EnvName.Test) {
      origin = "https://bigcustomer-widget.test.transhub.io";
    } else if (env === EnvName.Stage) {
      origin = "https://bigcustomer-widget.stage.transhub.io";
    } else if (env === EnvName.Production) {
      origin = "https://bigcustomer-widget.transhub.io";
    }

    return `${origin}/?id=${clientId}&secret=${clientSecret}&viewmode=link`;
  };

  const copyUrl = () => {
    (document.getElementById("code-textarea") as HTMLTextAreaElement).select();
    document.execCommand("copy");

    const selection = window.getSelection();
    if (selection) {
      if (selection.empty) {
        selection.empty();
      }
      if (selection.removeAllRanges) {
        selection.removeAllRanges();
      }
    }
    (document.getElementById("code-textarea") as HTMLTextAreaElement).blur();

    successAlert(lang.copiedToClipboard);
  };

  const code = getUrl();

  return (
    <Container data-test-id="link-page" width="m" className="link-page">
      <h1>{lang.linkTitle}</h1>
      <Card className="code-card" id="code-card" data-test-id="code-card">
        <textarea id="code-textarea" value={code} readOnly />
      </Card>
      <div className="buttons">
        <WhiteButton text={lang.copyUrl} onClick={copyUrl} dataTestId="copy-code-button" />
      </div>
    </Container>
  );
};

export default LinkPage;
