import { Product, ProductTemplate } from "@ruter-as/billettluke-frontend";
import { apiClient, formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { ALL_ZONES_FROM, ZONE_1 } from "src/constants";
import "./ProductPrice.scss";
import { formLanguageResource } from "./lang-resource";

interface Props {
  gray?: boolean;
}

const getMatchingProduct = (products: Product[], zoneFrom: string, zoneTo: string): Product => {
  const filteredProducts = products.filter(product => product.profileId === "ADULT");

  if (zoneFrom === "1" && zoneTo === "1") {
    const exactMatch = filteredProducts.find(product =>
      product.filters?.zoneListMustEqual.length === 1 && product.filters?.zoneListMustEqual[0] === ZONE_1);
    if (exactMatch) {
      return exactMatch;
    }
  }

  return filteredProducts.filter(product => product.filters === null)[0];
};

const ProductPrice: React.FC<Props> = ({ gray }) => {
  const lang = useLanguageResource(formLanguageResource);
  const [productTemplate, setProductTemplate] = useState<ProductTemplate>();

  useEffect(() => {
    const fetchPrices = async () => {
      const response = await apiClient.request(companyAgreementApi.product.getByProductTemplateId("3"));
      if (response.type === "success") {
        setProductTemplate(response.result);
      } else {
        setProductTemplate(() => {
          throw response.error;
        });
      }
    };
    fetchPrices();
    return () => { setProductTemplate(undefined); };
  }, []);

  const zoneFrom = useWatch({ name: "zoneFrom" });
  const zoneTo = useWatch({ name: "zoneTo" });

  let zoneCount = 1;
  if (zoneFrom === ALL_ZONES_FROM) {
    zoneCount = 3;
  } else if (zoneFrom !== zoneTo) {
    zoneCount = 2;
  }


  const priceInfo = productTemplate && getMatchingProduct(productTemplate.products, zoneFrom, zoneTo).prices.find(x => x.nrOfZones === zoneCount);
  const price = priceInfo ? priceInfo.priceInclVAT / 12 : undefined;

  const formattedPrice = price ? formatter.number.integer(Math.round(price)) : "-";
  const formattedDate = formatter.date.toMonthAndYear(new Date());

  const cssClassNames = ["components-common-form-productpricefield"];
  if (gray) {
    cssClassNames.push("gray");
  }

  return (
    <div className={cssClassNames.join(" ")}>
      <div className="label">{lang.productName(formattedDate)}</div>
      <div>
        <span className="price" data-test-id="product-price-field">
          {formattedPrice}
        </span>
        <span className="label">kr</span>
      </div>
    </div>
  );
};

export default ProductPrice;