import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface OnboardingLanguageResources {
  title: string;
  description: string;
  errorUnknown: string;
  errorInvalidOrgNumber: string;
  errorCompanyNotFound: string;
  errorCompanyUnsuitable: string;
  emailLabel: string;
  mobileLabel: string;
  organizationNumberLabel: string;
  gotoBankId: string;
  companyExistsHeader: string;
  companyExistsText: string;
  verifyPhone: {
    title: string;
    text: string;
    confirmationTitle: string;
    phoneVerifiedConfirmationText: string;
    contractSignedConfirmationText: string;
    contractNotSignedConfirmationText: string;
    codeLabel: string;
    wrongCodeErrorMessage: string;
  }
  provideSigneeInfo: {
    title: string;
    text: string;
    signee: string;
    signeeEmail: string;
    continue: string;
  },
}

export const onboardingLanguageResources: LanguageResource<OnboardingLanguageResources> = {
  nb: {
    title: "Velkommen til RuterBedrift onboarding",
    description:
      "Fyll inn kontaktinfo og organisasjonsnummer for bedriften din, så lager vi en brukerkonto til deg og din bedrift. Legitimering skjer automatisk via BankID",
    errorUnknown: "Det oppstod en ukjent feil. Forsøk igjen og kontakt kundeservice dersom problemet vedvarer.",
    emailLabel: "E-postadresse",
    mobileLabel: "Mobilnummer",
    organizationNumberLabel: "Organisasjonsnummer",
    gotoBankId: "Autentiser med BankID",
    errorInvalidOrgNumber: "Ugyldig organisasjonsnummer",
    errorCompanyNotFound: "Fant ikke selskap i brønnøysundregisteret",
    errorCompanyUnsuitable: "Vi kan ikke opprette konto for dette selskapet, ta kontakt med kundeservice",
    companyExistsHeader: "Allerede kunde",
    companyExistsText: "Organisasjonsnummeret du oppga har allerede en konto. Kontakt kundestøtte hvis du har spørsmål.",
    verifyPhone: {
      title: "Verifiser telefonnummer",
      text: "Til sist trenger vi å verifisere telefonnummeret ditt. Du skal ha fått en bekreftelseskode på sms, vennligst skriv den inn under.",
      confirmationTitle: "Ferdig!",
      phoneVerifiedConfirmationText: "Du vil om kort tid motta en e-post med påloggingsinformasjon. Følg informasjonen i e-posten for å logge på.",
      contractSignedConfirmationText: "Kontrakten er signert og bruker er opprettet",
      contractNotSignedConfirmationText: "Bruker er opprettet, men du vil oppleve begrenset funksjonalitet fram til kontrakten har blitt signert.",
      codeLabel: "Kode fra sms",
      wrongCodeErrorMessage: "Feil kode, prøv igjen",
    },
    provideSigneeInfo: {
      title: "Velg person for å signere avtale",
      text: "Ifølge Brønnøysund registerene er du ikke autorisert til å signere på vegne av bedriften. Velg hvem som skal signere fra listen under og oppgi e-post adressen til personen som skal signere.",
      signee: "Person som skal signere",
      signeeEmail: "E-post til person som skal signere",
      continue: "Fortsett",
    },
  },
  en: {
    title: "Welcome to onboarding to RuterBedrift",
    description: "Fill in your contact info and organization number for your company in order du create an account. Authentication will be performed by BankID",
    errorUnknown: "An unknown error occurred. Try again and contact customer service if the problem persists.",
    emailLabel: "Email address",
    mobileLabel: "Mobile number",
    organizationNumberLabel: "Organization number",
    gotoBankId: "Authenticate with BankID",
    errorInvalidOrgNumber: "Invalid organization number",
    errorCompanyNotFound: "We did not find this company in brønnøysundregisteret",
    errorCompanyUnsuitable: "We can not create an account for this company, please contact customer service",
    companyExistsHeader: "Already a customer",
    companyExistsText: "The organization number you provided already has an account. Contact customer support if you have further questions.",
    verifyPhone: {
      title: "Verify phone number",
      text: "In the final step, we need to verify your phone number. You should have recieved a confirmation code by sms. Please enter it in the text box below to complete registration.",
      confirmationTitle: "Registration complete!",
      phoneVerifiedConfirmationText: "You will shortly recieve an email with your log in information. Follow the instructions in the email to log in.",
      contractSignedConfirmationText: "Contract is signed and user is created.",
      contractNotSignedConfirmationText: "User is created, but you will experience reduced functionality until the contract has been signed.",
      codeLabel: "Confirmation code from sms",
      wrongCodeErrorMessage: "Wrong code entered, please try again",
    },
    provideSigneeInfo: {
      title: "Choose person to sign contract",
      text: "According to the Brønnøysund register, you are not authorized to sign on behalf of the company. Please choose a person to sign from the list below, then provide an email address to that person.",
      signee: "Person that should sign the agreement",
      signeeEmail: "Email address to the person that should sign the agreement",
      continue: "Continue",
    },
  },
};
