import type { Method } from "./apiClient";
import ApiClientError from "./ApiClientError";
import normalizeUrl from "./normalizeUrl";

export default class InvalidResponseError extends ApiClientError {
  public constructor(method: Method, url: string, responseStatus: number, responseBody: string, requestBody?: unknown) {
    super(
      `ApiClient.${method}(${url}) => [${responseStatus}] response is not valid json`,
      {
        method,
        url,
        responseStatus,
        requestBody,
        responseBody,
      },
      [method, normalizeUrl(url), responseStatus.toString()],
    );
  }
}
