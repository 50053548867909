export enum AdultAge {
  Eighteen = "18",
  Nineteen = "19",
  Twenty = "20",
}

const mapAdultAge = (input: number | null): AdultAge => {
  if (input === 18) {
    return AdultAge.Eighteen;
  }
  if (input === 19) {
    return AdultAge.Nineteen;
  }
  if (input === 20) {
    return AdultAge.Twenty;
  }
  return AdultAge.Eighteen;
};

export interface FreeTicketSettingsJson {
  companyId: string;
  companyType: string;
  expireChildTickets: "NEVER" | "AT_END_OF_MONTH" | "AT_END_OF_YEAR" | null;
  expireChildTicketsAtAge: number;
  invoiceReference: string | null;
  key: string;
}
export interface FreeTicketSettings {
  automaticallyEndChildrenTickets: "yes" | "no";
  age: AdultAge;
  expiryType: "AT_END_OF_MONTH" | "AT_END_OF_YEAR";
  invoiceReference: string;
}

export type FreeTicketSettingsUpdateContract =
  | {
    expireChildTickets: "NEVER";
    expireChildTicketsAtAge: null;
    invoiceReference: string;
  }
  | {
    expireChildTickets: "AT_END_OF_MONTH" | "AT_END_OF_YEAR";
    expireChildTicketsAtAge: AdultAge;
    invoiceReference: string;
  };

export const getDefaultFreeTicketSettingsJson = (): FreeTicketSettingsJson => ({
  key: "SIEBEL_CUSTOMER_NUMBER:1229805",
  companyId: "1229805",
  companyType: "SIEBEL_CUSTOMER_NUMBER",
  expireChildTickets: "AT_END_OF_MONTH",
  expireChildTicketsAtAge: 19,
  invoiceReference: "test",
});

export const getDefaultFreeTicketSettings = (): FreeTicketSettings => ({
  automaticallyEndChildrenTickets: "no",
  age: AdultAge.Eighteen,
  expiryType: "AT_END_OF_MONTH",
  invoiceReference: "",
});

export const mapFreeTicketSettings = (json: FreeTicketSettingsJson): FreeTicketSettings => {
  if (json.expireChildTickets === "AT_END_OF_MONTH" || json.expireChildTickets === "AT_END_OF_YEAR") {
    return {
      invoiceReference: json.invoiceReference || "",
      automaticallyEndChildrenTickets: "yes",
      expiryType: json.expireChildTickets,
      age: mapAdultAge(json.expireChildTicketsAtAge),
    };
  }
  return {
    invoiceReference: json.invoiceReference || "",
    automaticallyEndChildrenTickets: "no",
    expiryType: "AT_END_OF_MONTH",
    age: mapAdultAge(json.expireChildTicketsAtAge),
  };
};

export const mapFreeTicketSettingsUpdateContract = (settings: FreeTicketSettings): FreeTicketSettingsUpdateContract => {
  if (settings.automaticallyEndChildrenTickets === "yes") {
    return {
      expireChildTickets: settings.expiryType,
      expireChildTicketsAtAge: settings.age,
      invoiceReference: settings.invoiceReference,
    };
  }

  return {
    expireChildTickets: "NEVER",
    expireChildTicketsAtAge: null,
    invoiceReference: settings.invoiceReference,
  };
};
