import React from "react";
import "./PageSubheader.scss";

interface Props {
  text: string;
  loading?: boolean;
}

const PageSubheaderComponent: React.FC<Props> = ({ text, loading = true }) => {
  if (loading) {
    return <h2 className="skeleton">fads</h2>;
  }

  return <h2>{text}</h2>;
};

export default PageSubheaderComponent;
