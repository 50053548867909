import { InAppInvoiceEligible, InvoiceReference, TicketApiClient } from "@ruter-as/billettluke-frontend";
import { Button, ButtonGroup, Container, FormGroup, IconButton, Modal, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { CancelButton } from "src/components/common/buttons";
import PhoneColumn from "src/components/common/Table/PhoneColumn/PhoneColumn";
import EditEligibleUser from "./EditEligibleUser";
import ticketCounterWhiteListLanguageResource from "./lang-resource";
import NewUserModal from "./NewUserModal";
import "./TicketCounterWhitelist.scss";

const getValidInAppEligible = (): InAppInvoiceEligible => ({
  id: "test",
  invoiceReferenceId: "ecf664db-a143-4b77-8819-7e3c22aaa2a0",
  invoiceReference: "SR",
  name: "Fornavn og etternavn",
  phone: "45859654",
  phoneCountryCode: "+47",
});

const TicketCounterWhitelist: React.FC = () => {
  const lang = useLanguageResource(ticketCounterWhiteListLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [eligibleUsers, setEligibleUsers] = useState<InAppInvoiceEligible[]>();
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [editUser, setEditUser] = useState<InAppInvoiceEligible>();
  const [deleteUser, setDeleteUser] = useState<InAppInvoiceEligible>();
  const [invoiceRefs, setInvoiceRefs] = useState<InvoiceReference[]>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;
  const { getAccessToken } = useAuthContextAuthenticated();
  const ticketApi = useMemo(() => {
    return new TicketApiClient({ getAccessToken }, "/ticket-api");
  }, [getAccessToken]);

  const formMethods = useForm();

  const fetch = useCallback(async () => {
    try {
      //const result = await ticketApi.inAppInvoiceEligible.getByCompanyId(companyId);
      const [fetchedEligibleUsers, fetchedInvoiceRefs] = await Promise.all([[getValidInAppEligible()], ticketApi.invoiceReference.getAll(companyId)]);
      setEligibleUsers(fetchedEligibleUsers);
      setInvoiceRefs(fetchedInvoiceRefs);
    } catch (e) {
      setEligibleUsers(() => {
        throw e;
      });
    }
  }, [ticketApi, companyId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const renderRow = (row: InAppInvoiceEligible) => {
    return (
      <tr key={row.id}>
        <td>{row.name}</td>
        <PhoneColumn phone={row.phone} phoneCountryCode={row.phoneCountryCode} />
        <td>{row.invoiceReference}</td>
        <td>
          <div className="actions">
            <IconButton aria-label={lang.editUser.replace("$p1$", row.name)} onClick={() => { setEditUser(row); }} variant="PencilIcon" />
            <IconButton aria-label={lang.deleteUser.replace("$p1$", row.name)} onClick={() => { setDeleteUser(row); }} variant="TrashIcon" />
          </div>
        </td>
      </tr>
    );
  };

  const removeUser = async () => {
    if (!deleteUser) {
      throw new Error("User to delete is not selected");
    }
    try {
      setDeleteLoading(true);
      await ticketApi.inAppInvoiceEligible.deleteUser(deleteUser.id);
      setDeleteUser(undefined);
      fetch();
    } catch (e) {
      setDeleteLoading(() => {
        throw e;
      });
    }
  };

  return <Container width="l" className="component-ticketcounter-whitelist">
    <h1>{lang.title}</h1>
    <div className="new-user">
      <Button text={lang.newUser} type="button" variant="primary" onClick={() => { setShowNewUserModal(true); }} />
    </div>
    <Table>
      <thead>
        <tr>
          <th scope="col">
            {lang.name}
          </th>
          <th scope="col">
            {lang.phoneNumber}
          </th>
          <th scope="col">
            {lang.invoiceReference}
          </th>
          <th scope="col" style={{ opacity: 0.01 }}>
            {lang.actions}
          </th>
        </tr>
      </thead>
      <tbody>{eligibleUsers?.map(renderRow)}</tbody>
    </Table>
    {showNewUserModal && invoiceRefs &&
      <NewUserModal
        handleClose={() => setShowNewUserModal(false)}
        handleSuccess={() => { setShowNewUserModal(false); fetch(); }}
        invoiceRefOptions={invoiceRefs} />
    }
    {editUser && invoiceRefs &&
      <EditEligibleUser
        editUser={editUser}
        handleClose={() => setEditUser(undefined)}
        handleSuccess={() => { setEditUser(undefined); fetch(); }}
        invoiceRefOptions={invoiceRefs} />
    }
    {deleteUser &&
      <Modal isOpen handleClose={() => { setDeleteUser(undefined); }} title={lang.deleteUser.replace("$p1$", deleteUser.name)}>
        <p>Er du sikker på at du ønsker å slette denne brukeren?</p>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(removeUser)}>
            <FormGroup>
              <ButtonGroup>
                <Button text={formLang.yes} type="submit" variant="primary" loading={deleteLoading} />
                <CancelButton onClick={() => { setDeleteUser(undefined); }} />
              </ButtonGroup>
            </FormGroup>
          </form>
        </FormProvider>
      </Modal>
    }
  </Container>;
};

export default TicketCounterWhitelist;