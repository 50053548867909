import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface CompaniesLanguageResource {
  title: string;
  name: string;
  agreements: string;
  companyAgreement: string;
  freeTicketAgreement: string;
  ticketCounterAgreement: string;
  filterBy: string;
  schoolTicketAgreement: string;
  fixedPriceTravelCardAgreement: string;
  companySubscriptionAgreement: string;
  noAgreements: string;
  organizationNumber: string;
  customerNumber: string;
  newCompany: string;
}

const companiesLanguageResource: LanguageResource<CompaniesLanguageResource> = {
  nb: {
    title: "Bedrifter",
    name: "Navn",
    agreements: "Avtaler",
    companyAgreement: "Bedrift",
    freeTicketAgreement: "Fribillett",
    ticketCounterAgreement: "Billettluke",
    filterBy: "Filtrer på organisasjonnummer og bedriftsnavn",
    schoolTicketAgreement: "Skole oslo",
    fixedPriceTravelCardAgreement: "Fastpris reisekort",
    companySubscriptionAgreement: "Bedriftsabb.",
    noAgreements: "Ingen avtaler",
    organizationNumber: "Org. nummer",
    customerNumber: "Kundenummer",
    newCompany: "Ny bedrift",
  },
  en: {
    title: "Companies",
    name: "Name",
    agreements: "Agreements",
    companyAgreement: "Company",
    freeTicketAgreement: "Free ticket",
    ticketCounterAgreement: "Ticket counter",
    filterBy: "Search for organization number and company name",
    schoolTicketAgreement: "School Oslo",
    fixedPriceTravelCardAgreement: "Fixed price travel card",
    companySubscriptionAgreement: "Company subscription",
    noAgreements: "No agreements",
    organizationNumber: "Org. no.",
    customerNumber: "Customer no.",
    newCompany: "New company",
  },
};

export default companiesLanguageResource;