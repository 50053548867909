import { format } from "date-fns";
import { mapSchoolTicketSearchResult } from "src/types/schoolTicketAgreement/schoolTicket";
import { mapTravelCardManufactureSearchResult } from "./travelCardAdmin";

interface TravelCardProductionListSearchParams {
  companyId?: string
  manufacturedDate?: Date
  page?: number
  pageSize?: number
}

const addPaginationParameters = (page: number, pageSize: number) => {
  return `maxEntries=${pageSize}&offset=${(page - 1) * 10}`;
};

const addQueryParameters = (query: string) => {
  if (query === "**") return "searchFor=";
  return `searchFor=${query}`;
};

const schoolTicketApi = {
  travelCards: {
    manufactureReport: {
      get: ({ companyId, manufacturedDate, page, pageSize }: TravelCardProductionListSearchParams) => {
        const query = [];
        if (companyId) {
          query.push(`companyId=${companyId}`);
        }
        if (manufacturedDate) {
          query.push(`manufacturedDate=${format(manufacturedDate, "yyyy-MM-dd")}`);
        }

        const paginationParameters = page && pageSize ? addPaginationParameters(page, pageSize) : "";
        return {
          method: "get" as const,
          url: `/schoolticket-api/travel-card/manufacture-report?${paginationParameters}&${query.join("&")}`,
          mapper: mapTravelCardManufactureSearchResult,
        };
      },
    },
  },
  ticket: {
    byCompanyId: {
      get: (pageSize: number, page: number, id: string, query: string, year: string) => ({
        method: "get" as const,
        url: `/schoolticket-api/ticket/by-company-id/${id}?${addPaginationParameters(page, pageSize)}&${addQueryParameters(query)}&year=${year}`,
        mapper: mapSchoolTicketSearchResult,
      }),
      download: (companyId: string) => ({
        method: "get" as const,
        url: `/schoolticket-api/ticket/by-company-id/${companyId}?maxEntries=10000`,
        mapper: mapSchoolTicketSearchResult,
      }),
    },
  },
};

export default schoolTicketApi;