/* eslint-disable react/destructuring-assignment */
import { Icon } from "@ruter-ds/rds-components";
import { CellPhoneIcon, TicketOutlineIcon } from "@ruter-ds/rds-icons";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import React from "react";
import { FreeTicket } from "../../../common/api/freeTicketApi/freeTicket";
import MediaType from "../../../types/mediaType";
import "./MediaTypeColumn.scss";

interface Props {
  freeTicket: FreeTicket;
}

const MediaTypeColumn: React.FC<Props> = ({ freeTicket }) => {
  if (freeTicket.mediaType === MediaType.TRAVEL_CARD) {
    return (
      <td className="components-freeticketagreeemnt-mediatypecolumn travel-card">
        <Icon component={<TicketOutlineIcon />} />
        {freeTicket.travelCardNumber}
      </td>
    );
  }

  let text = `${freeTicket.phoneCountryCode || ""} ${freeTicket.phone || ""}`;

  const parsedNumber = parsePhoneNumberFromString((freeTicket.phoneCountryCode || "") + (freeTicket.phone || ""));

  if (parsedNumber && parsedNumber.isValid()) {
    text = parsedNumber.formatInternational();
  }
  return (
    <td className="components-freeticketagreeemnt-mediatypecolumn mobile-ticket">
      <Icon component={<CellPhoneIcon />} />
      {text}
    </td>
  );
};

export default MediaTypeColumn;
