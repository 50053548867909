const normalizeUrl = (url: string): string => {
  const integerRegex = /([0-9]+)/g;
  const guidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/g;

  const urlWithoutSearch = url.split("?")[0];

  return urlWithoutSearch.replace(guidRegex, "{guid}").replace(integerRegex, "{int}");
};

export default normalizeUrl;
