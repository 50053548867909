import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TaxBaseChangesLanguageResource {
  title: string;
  cause: string;
  causeSubscribed: string;
  causeUnsubscribed: string;
  causePriceChange: string;
  lastName: string;
  firstName: string;
  employeeNumber: string;
  priceDifference: string;
  noTaxBaseChanges: string;
  department: string;
  missingDepartmentName: string;
  all: string;
  downloadAsCSV: string;
  csvFileName: string;
  oldPrice: string;
  newPrice: string;
  description: string;
}

export const taxBaseChangesLanguageResource: LanguageResource<TaxBaseChangesLanguageResource> = {
  nb: {
    title: "Endringer i skattegrunnlag",
    cause: "Årsak",
    causeSubscribed: "Ny",
    causeUnsubscribed: "Avsluttet",
    causePriceChange: "Endret",
    lastName: "Etternavn",
    firstName: "Fornavn",
    employeeNumber: "Ansattnr.",
    priceDifference: "Skattegrunnlag",
    noTaxBaseChanges: "Det er ikke registrert noen endringer i skattegrunnlag",
    department: "Avdeling",
    missingDepartmentName: "Avdelingsnavn ikke satt",
    all: "Alle avdelinger",
    downloadAsCSV: "Last ned endringer i skattegrunnlags som CSV",
    csvFileName: "Endringer i skattegrunnlag for {department}, {date}.csv",
    oldPrice: "Gammel pris",
    newPrice: "Ny pris",
    description:
      "Her vises endringer i skattegrunnlaget (innberetningspliktig beløp) for hver ansatt" +
      " i inneværende måned ({period1}) sammenlignet med forrige måned ({period2}).",
  },
  en: {
    title: "Changes in tax base",
    cause: "Cause",
    causeSubscribed: "New",
    causeUnsubscribed: "Cancelled",
    causePriceChange: "Price change",
    lastName: "Last name",
    firstName: "First name",
    employeeNumber: "Employee no.",
    priceDifference: "Tax base",
    noTaxBaseChanges: "There are noe changes in tax base",
    department: "Department",
    missingDepartmentName: "Department name not set",
    all: "All departments",
    downloadAsCSV: "Download as CSV",
    csvFileName: "Changes in tax base for {department}, {period}.csv",
    oldPrice: "Old price",
    newPrice: "New price",
    description: "This pages shows the difference employee tax base calculation between {period1} and {period2}",

  },
};