import { AgreementSubTypeId, parseAgreementSubTypeId } from "../../../types/AgreementSubTypeId";
import { SearchResult } from "../../../types/SearchResult";
import { mapDateFromIso, mapDateFromIsoOrNull } from "../../../types/commonMapper";
import { CustomerOrderLine, CustomerOrderLineJson, getValidCustomerOrderLineJson, mapCustomerOrderLine } from "./CustomerOrderLine";

export interface CustomerOrderJson {
  id: string;
  agreementNumber: string;
  agreementSubTypeId: string | null;
  companyCustomerNumber: string;
  companyName: string;
  createdAt: string;
  dueDate: string | null;
  invoiceLines: CustomerOrderLineJson[];
  invoiceNumber: string | null;
  invoicingDate: string | null;
  month: number;
  orderNumber: string;
  ownerId: null | number;
  reference: string | null;
  paymentStatus: string | null;
  invoiceReference: string | null;
  reminderDueDate: string | null;
  ticketCount: number;
  totalPrice: number;
  totalVat: number | null;
  year: number;
}

export interface CustomerOrder {
  type: "CUSTOMERORDER"
  id: string;
  agreementNumber: string;
  agreementSubTypeId: AgreementSubTypeId | null;
  companyCustomerNumber: string;
  companyName: string;
  createdAt: Date;
  dueDate: Date | null;
  invoiceLines: CustomerOrderLine[];
  invoiceNumber: null | string;
  invoicingDate: Date | null;
  month: number;
  orderNumber: string;
  ownerId: null | number;
  reference: string | null;
  paymentStatus: string | null;
  invoiceReference: string | null;
  reminderDueDate: Date | null;
  ticketCount: number;
  totalPrice: number;
  totalVat: number | null;
  year: number;
}

export const mapCustomerOrder = (json: CustomerOrderJson): CustomerOrder => ({
  type: "CUSTOMERORDER",
  id: json.id,
  agreementNumber: json.agreementNumber,
  agreementSubTypeId: json.agreementSubTypeId ? parseAgreementSubTypeId(json.agreementSubTypeId) : null,
  companyCustomerNumber: json.companyCustomerNumber,
  companyName: json.companyName,
  createdAt: mapDateFromIso(json.createdAt),
  dueDate: mapDateFromIsoOrNull(json.dueDate),
  invoiceLines: json.invoiceLines.map(mapCustomerOrderLine),
  invoiceNumber: json.invoiceNumber,
  invoicingDate: mapDateFromIsoOrNull(json.invoicingDate),
  month: json.month,
  orderNumber: json.orderNumber,
  ownerId: json.ownerId,
  reference: json.reference,
  paymentStatus: json.paymentStatus,
  invoiceReference: json.invoiceReference,
  reminderDueDate: mapDateFromIsoOrNull(json.reminderDueDate),
  ticketCount: json.ticketCount,
  totalPrice: json.totalPrice,
  totalVat: json.totalVat,
  year: json.year,
});

export const mapCustomerOrders = (json: SearchResult<CustomerOrderJson>): CustomerOrder[] => json.matches.map(mapCustomerOrder);

export const getValidCustomerOrderJson = (changes?: Partial<CustomerOrderJson>): CustomerOrderJson => ({
  agreementNumber: "1-3369898706",
  agreementSubTypeId: "SUBSCRIPTIONS",
  companyCustomerNumber: "1226629",
  companyName: "Steffens Kosmetikk",
  createdAt: "2021-01-07T21:09:06Z",
  dueDate: null,
  id: "1-3369898706_2021_01",
  invoiceLines: [getValidCustomerOrderLineJson()],
  invoiceNumber: null,
  invoiceReference: null,
  invoicingDate: null,
  month: 1,
  orderNumber: "2021-JANUARY",
  ownerId: null,
  paymentStatus: null,
  reference: null,
  reminderDueDate: null,
  ticketCount: 35,
  totalPrice: 34440,
  totalVat: null,
  year: 2021,
  ...changes,
});

export const getValidCustomerOrderJsonSearchResult = (changes?: Partial<SearchResult<CustomerOrderJson>>): SearchResult<CustomerOrderJson> => ({
  totalEntries: 1,
  matches: [getValidCustomerOrderJson()],
  ...changes,
});
