import { LanguageResource, TwoParamsLanguageResourceTemplate, languageResourceBuilder } from "@ruter-as/web-components-and-tools";

interface OrderHistoryLanguageResource {
  title: string;
  searchPlaceHolder: string;
  orderDate: string;
  orderedBy: string;
  mediaType: string;
  ticketCount: string;
  ticketPrice: string;
  invoiceReference: string;
  personalReference: string;
  mobileTicket: string;
  impulsCard: string
  travelCard: string;
  ticketCountStatus: TwoParamsLanguageResourceTemplate;
}

const orderHistoryLanguageResource: LanguageResource<OrderHistoryLanguageResource> = {
  nb: {
    title: "Ordrehistorikk",
    searchPlaceHolder: 'Filtrer på "bestilt av" eller kommentar',
    orderDate: "Kjøpsdato",
    orderedBy: "Bestilt av",
    mediaType: "Billettbærer",
    ticketCount: "Antall",
    ticketPrice: "Pris",
    invoiceReference: "Fakturaref",
    personalReference: "Kommentar",
    mobileTicket: "Mobilbillett",
    impulsCard: "Impulskort",
    travelCard: "Reisekort",
    ticketCountStatus: languageResourceBuilder.twoParamTemplate("$p1$ av $p2$"),
  },
  en: {
    title: "Order history",
    searchPlaceHolder: 'Filter by "ordered by" or comment',
    orderDate: "Order date",
    orderedBy: "Ordered by",
    mediaType: "Ticket media",
    ticketCount: "Ticket count",
    ticketPrice: "Price",
    invoiceReference: "Invoice reference",
    personalReference: "Comment",
    mobileTicket: "Mobile ticket",
    impulsCard: "Impuls card",
    travelCard: "Travel card",
    ticketCountStatus: languageResourceBuilder.twoParamTemplate("$p1$ of $p2$"),
  },
};

export default orderHistoryLanguageResource;