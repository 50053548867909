import { mapZones } from "src/common/api/commonTypes/Zone";
import { mapEditTicketPreview } from "src/types/freeTicketAgreement/editTicketPreview";
import { FreeTicketEditPostContract, FreeTicketEditPreviewPostContract } from "./editFreeticket";
import { mapFreeTicketSearchResult } from "./freeTicket";
import { MassOrderPostContract } from "./freeTicketMassOrderCreate";
import { mapMassOrderValidation } from "./freeTicketMassOrderValidation";
import FreeTicketPostContract from "./freeTicketPostContract";

const addPaginationParameters = (page: number, pageSize: number) => {
  return `maxEntries=${pageSize}&offset=${(page - 1) * 10}`;
};

const addQueryParameters = (query: string) => {
  return `searchFor=${query}`;
};

const freeTicketApi = {
  distance: {
    zones: {
      get: () => {
        return {
          method: "get" as const,
          url: "/freeticket-api/distance/zones",
          mapper: mapZones,
        };
      },
    },
  },
  ticket: {
    create: {
      post: (body: FreeTicketPostContract) => {
        return {
          method: "post" as const,
          url: "/freeticket-api/ticket/create",
          body,
        };
      },
    },
    byCompanyId: {
      get: (companyId: string, query: string, page: number, pageSize: number) => {
        return {
          method: "get" as const,
          url: `/freeticket-api/ticket/by-company-id/${companyId}?${addPaginationParameters(page, pageSize)}&${addQueryParameters(query)}`,
          mapper: mapFreeTicketSearchResult,
        };
      },
      download: (companyId: string, filter: "active,pending" | "expired") => {
        return {
          method: "get" as const,
          url: `/freeticket-api/ticket/by-company-id/${companyId}?statusFilter=${filter}&maxEntries=10000`,
          mapper: mapFreeTicketSearchResult,
        };
      },
    },
    id: (ticketId: string) => ({
      expiry: {
        put: (expiry: Date) => {
          return {
            method: "put" as const,
            url: `/freeticket-api/ticket/${ticketId}/expiry/${expiry.toISOString()}`,
          };
        },
      },
      updateMultipleFieldsPreview: {
        post: (body: FreeTicketEditPreviewPostContract) => {
          return {
            method: "post" as const,
            url: `/freeticket-api/ticket/${ticketId}/update-multiple-fields-preview`,
            body,
            mapper: mapEditTicketPreview,
          };
        },
      },
      updateMultipleFields: {
        post: (body: FreeTicketEditPreviewPostContract | FreeTicketEditPostContract) => {
          return {
            method: "post" as const,
            url: `/freeticket-api/ticket/${ticketId}/update-multiple-fields`,
            body,
          };
        },
      },
      delete: () => {
        return {
          method: "delete" as const,
          url: `/freeticket-api/ticket/${ticketId}`,
        };
      },
      resendPickupCode: () => {
        return {
          method: "post" as const,
          url: `/freeticket-api/ticket/${ticketId}/resend-pickup-code`,
        };
      },
      resend: () => {
        return {
          method: "post" as const,
          url: `/freeticket-api/ticket/${ticketId}/resend`,
        };
      },
    }),
    forSameEmployeeAsThisOrder: {
      get: (id: string) => {
        return {
          method: "get" as const,
          url: `/freeticket-api/ticket/for-same-employee-as-this-order/${id}`,
          mapper: mapFreeTicketSearchResult,
        };
      },
    },
    travelCard: {
      id: (id: string) => ({
        replace: () => {
          return {
            method: "put" as const,
            url: `/freeticket-api/ticket/travel-card/${id}/replace`,
          };
        },
      }),
    },
    validateCreateMultiple: {
      post: (body: MassOrderPostContract) => {
        return {
          method: "post" as const,
          url: "/freeticket-api/ticket/validate-create-multiple",
          body,
          mapper: mapMassOrderValidation,
        };
      },
    },
    createMultiple: {
      post: (body: MassOrderPostContract) => {
        return {
          method: "post" as const,
          url: "/freeticket-api/ticket/create-multiple",
          body,
        };
      },
    },
  },
};

export default freeTicketApi;