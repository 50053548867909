import React from "react";
import TicketStatus from "../../text/ticketStatus/TicketStatus";
import "./StatusColumn.scss";

interface Props {
  status: string
  "data-label"?: string
}

const StatusColumn: React.FC<Props> = ({ status, "data-label": dataLabel }) => {
  return (
    <td data-label={dataLabel}>
      <div className="status-column">
        <div className={`status-icon ${status}`} />
        <div className="status-text">
          <TicketStatus status={status as any} />
        </div>
      </div>
    </td>
  );
};

export default StatusColumn;
