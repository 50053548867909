import React from "react";
import "./ButtonLink.scss";

interface Props {
  text: string;
  onClick: () => void;
  dataTestId?: string;
}

const ButtonLink: React.FC<Props> = ({ text, onClick, dataTestId }) => {
  return (
    <button className="components-common-buttons-buttonlink" onClick={onClick} type="button" data-test-id={dataTestId}>
      {text}
    </button>
  );
};

export default ButtonLink;
