import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import React from "react";
import { useLocation } from "react-router-dom";
import "./NotFoundComponent.scss";
import caveman from "./caveman.gif";
import { notFoundLanguageResource } from "./lang-resource";

const NotFoundComponent: React.FC = () => {
  const location = useLocation();

  Sentry.captureException(new Error(`Invalid route: ${location.pathname}${location.search}`));

  const lang = useLanguageResource(notFoundLanguageResource);

  return (
    <Container width="m" className="components-app-notfound" data-test-id="components-app-notfound">
      <h1>{lang.title}</h1>
      <img src={caveman} alt="caveman eating cable..." />
      <h2>{lang.title2}</h2>
      <p>{lang.text}</p>
    </Container>
  );
};

export default NotFoundComponent;
