import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { TicketZonesLanguageResource, ticketZonesLanguageResource } from "./lang-resource";
interface Props {
  allZones: boolean;
  nrOfZones: number;
  zoneFrom: string;
  zoneTo: string;
  zoneType: "ZoneToZone" | "Radius";
}

export const getTicketZonesString = (
  language: TicketZonesLanguageResource,
  allZones: boolean,
  nrOfZones: number,
  zoneFrom: string,
  zoneTo: string,
  zoneType: "ZoneToZone" | "Radius",
): string => {
  if (allZones) {
    return language.allZones;
  }
  if (nrOfZones === 1) {
    return language.zoneOne.replace("{zoneFrom}", zoneFrom);
  }
  if (zoneType === "ZoneToZone") {
    return language.multipleZones.replace("{zoneFrom}", zoneFrom).replace("{zoneTo}", zoneTo).replace("{zoneCount}", nrOfZones.toString());
  }
  return language.radiusZones.replace("{zoneFrom}", zoneFrom).replace("{zoneCount}", nrOfZones.toString());
};

const TicketZonesField: React.FC<Props> = ({ allZones, nrOfZones, zoneFrom, zoneTo, zoneType }) => {
  const language = useLanguageResource(ticketZonesLanguageResource);
  return <>{getTicketZonesString(language, allZones, nrOfZones, zoneFrom, zoneTo, zoneType)}</>;
};

export default TicketZonesField;
