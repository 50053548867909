/* eslint-disable jsx-a11y/control-has-associated-label */
import { MediaInfo, OrderAndTicket, ProductTemplate, SearchResult, TicketApiClient } from "@ruter-as/billettluke-frontend";
import { Container, SearchInput, Table, TablePagination, formatter, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useAuthContextAuthenticated } from "src/AuthContext";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import { formatPhonenumber } from "../../common/Table/PhoneColumn/PhoneColumn";
import StatusColumn from "../../common/Table/StatusColumn/StatusColumn";
import "./TicketHistory.scss";
import ticketHistoryLanguageResource from "./lang-resource";

const TicketHistory: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;

  if (!companyId) {
    throw new Error("company should be set");
  }

  const language = useLanguageResource(ticketHistoryLanguageResource);
  const { getAccessToken } = useAuthContextAuthenticated();
  const ticketApi = useMemo(() => {
    return new TicketApiClient({ getAccessToken }, "/ticket-api");
  }, [getAccessToken]);
  const { selectedLanguage } = useLanguageContext();
  const [tickets, setTickets] = useState<undefined | SearchResult<OrderAndTicket>>(undefined);
  const [productTemplates, setProductTemplates] = useState<ProductTemplate[] | undefined>(undefined);

  const { throttledQuery, query, setQuery, pagination } = usePaginationAndQuery();
  const { page, pageSize } = pagination;

  const fullLoading = !productTemplates || !tickets;

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await Promise.all([
          ticketApi.tickets.getByCompanyId(companyId, throttledQuery, page, pageSize),
          ticketApi.productTemplates.getAll(false),
        ]);
        setTickets(result[0]);
        setProductTemplates(result[1]);
      } catch (e) {
        setTickets(() => {
          throw e;
        });
      }
    };
    fetch();
  }, [throttledQuery, companyId, page, pageSize, ticketApi.tickets, ticketApi.productTemplates]);

  const getProductName = (productTemplateId: string, productName: string | null) => {
    if (!productTemplates) {
      throw new Error("productTemplates must be set");
    }

    const productTemplate = productTemplates.find((x) => x.id === productTemplateId);

    if (productTemplate) {
      if (selectedLanguage === "en") {
        return productTemplate.name.en;
      }
      return productTemplate.name.nb;
    }

    if (productName) {
      return productName;
    }

    return language.unknownProduct;
  };

  const getMediaId = (mediaInfo: MediaInfo) => {
    switch (mediaInfo.type) {
      case "MOBILE_TICKET": {
        const { phoneCountryCode, phone } = mediaInfo;
        return formatPhonenumber(phoneCountryCode, phone);
      }
      case "TRAVEL_CARD": return "# " + mediaInfo.travelCardNumber;
      default: return "–";
    }
  };

  const renderRow = (ticketAndOrder: OrderAndTicket) => {
    const mediaId = getMediaId(ticketAndOrder.ticket.mediaInfo);

    return (
      <tr key={ticketAndOrder.ticket.id}>
        <td>{formatter.date.toShortDateString(ticketAndOrder.order.purchaseDate)}</td>
        <td>{ticketAndOrder.ticket.id}</td>
        <td>{mediaId}</td>
        <td>{getProductName(ticketAndOrder.ticket.productTemplateId, ticketAndOrder.ticket.productName)}</td>
        <StatusColumn status={ticketAndOrder.ticket.status} />
        <td>{ticketAndOrder.order.invoiceReference}</td>
        <td>{ticketAndOrder.order.personalReference}</td>
      </tr>
    );
  };

  return (
    <Container width="m" className="components-ticketcounter-ticket-history" data-test-id="ticket-history-page">
      <h1>{language.title}</h1>
      <div className="search-and-new-ticket">
        <SearchInput
          placeholder={language.searchPlaceHolder}
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
        />
      </div>
      <div>
        <Table
          breakpoint="600px"
          loading={fullLoading}
        >
          <thead>
            <tr>
              <th scope="col" style={{ width: "112px" }}>
                {language.purchaseDate}
              </th>
              <th scope="col" style={{ width: "123px" }}>
                {language.ticketNumber}
              </th>
              <th scope="col" style={{ width: "160px" }}>
                {language.mediaId}
              </th>
              <th scope="col">{language.product}</th>
              <th scope="col" style={{ width: "120px" }}>
                {language.status}
              </th>
              <th scope="col">{language.invoiceReference}</th>
              <th scope="col">{language.personalReference}</th>
            </tr>
          </thead>
          <tbody>{!fullLoading && tickets.matches.map(renderRow)}</tbody>
        </Table>
        <TablePagination pagination={pagination} totalRecords={tickets?.totalEntries} />
      </div>
    </Container>
  );
};
export default TicketHistory;
