import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface YesNoColumnLanuageResource {
  yes: string;
  no: string;
}

export const yesNoColumnLanuageResource: LanguageResource<YesNoColumnLanuageResource> = {
  nb: {
    yes: "Ja",
    no: "Nei",
  },
  en: {
    yes: "Yes",
    no: "No",
  },
};