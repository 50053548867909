import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface AdvantageLanguageResource {
  header: string;
  text: string;
}

interface AgreementInfoLanguageResource {
  title: string;
  agreementContainsHeader: string;
  agreementContainsList: Array<string>;
  pricelistHeader: string;
  pricelistNumberOfZones: string;
  pricelist30Ticket: string;
  pricelistMonthTicket: string;
  pricelist1Zone: string;
  pricelist1ZoneSpecial: string
  pricelist2Zones: string;
  pricelistAllZones: string;
  advantagesHeader: string;

  advantages: Array<AdvantageLanguageResource>;
  callToAction: string;
}

const agreementInfoLanguageResource: LanguageResource<AgreementInfoLanguageResource> = {
  nb: {
    title: "Bedriftsavtale",
    agreementContainsHeader: "Hva inngår i bedriftsavtalen?",
    agreementContainsList: [
      "De ansatte som ønsker det får en billett som er rimeligere enn vanlig 30-dagersbillett, trukket av lønn hver måned.",
      "Vi sender billetten til den ansattes mobil. Den fungerer som et abonnement og kan brukes også på fritiden.",
      "Som arbeidsgiver kan du tilby å dekke hele eller deler av abonnementet for alle eller noen utvalgte medarbeidere.",
      "Vi sender samlefaktura og lønnstrekksliste til bedriften hver måned.",
    ],
    pricelistHeader: "Prisliste",
    pricelistNumberOfZones: "Antall soner",
    pricelist30Ticket: "Ordinær 30-dagersbillett",
    pricelistMonthTicket: "Månedspris bedriftsbillett",
    pricelist1Zone: "Én sone",
    pricelist1ZoneSpecial: "Én sone (sone 1)",
    pricelist2Zones: "To soner",
    pricelistAllZones: "Alle soner",
    advantagesHeader: "Hvilke fordeler får bedriften med bedriftsavtale?",
    advantages: [
      {
        header: "1. Miljøinnsatsen til bedriften løftes frem",
        text: "Det er en fin måte å oppfordre de ansatte til å velge kollektivt fremfor bil og taxi. Sammen reduserer vi utslippene i hovedstadsområdet.",
      },
      {
        header: "2. Dere sparer kostnader med taxi og parkering",
        text: "Det er lettere å velge kollektivt når man er trygg på at man alltid har billett, særlig hvis arbeidsgiver sponser hele eller deler av den.",
      },
      {
        header: "3. Arbeidsplassen blir mer attraktiv",
        text: "I rekrutteringssammenheng er bedriftsavtalen en ansattegode på lik linje med tilbud om treningsmedlemskap og aviser.",
      },
    ],
    callToAction: "Jeg ønsker å bli kontaktet for mer informasjon om bedriftsavtale",
  },
  en: {
    title: "Company agreement",
    agreementContainsHeader: "What is included in a company agreement?",
    agreementContainsList: [
      "The employees that opt in get a cheaper ticket, deducted from their salary each month.",
      "Tickets are sent to the employee's phone. It works like a normal ticket and can also be used outside of work hours.",
      "As an employer, you can choose wether you would like to cover the whole or part of the cost for a ticket. You can also choose to give to a ticket to some or all employees.",
      "Each month, we will send you one invoice and a preliminary tax report for your employees.",
    ],
    pricelistHeader: "Prices",
    pricelistNumberOfZones: "Number of zones",
    pricelist30Ticket: "Ordinary 30-day ticket",
    pricelistMonthTicket: "Monthly company ticket",
    pricelist1Zone: "One zone",
    pricelist1ZoneSpecial: "One zone (Zone 1)",
    pricelist2Zones: "Two zones",
    pricelistAllZones: "All zones",
    advantagesHeader: "What are the benefits of entering a company agreement with Ruter?",
    advantages: [
      {
        header: "1. Improving your company's environmental profile",
        text: "By encouraging your employee to choose public transport over car and taxi, your company will help reduce emissions in the greater Oslo area.",
      },
      {
        header: "2. Save cost on taxy and parking",
        text: "It is easier to choose public transport when you always have a ticket at hand, especially when your employer sponsor part or all of the ticket cost.",
      },
      {
        header: "3. Become a more attractive work place",
        text: "In terms of recruiting, sponsored public transport is a benifit in the likes of sponsored training membership or newspapers.",
      },
    ],
    callToAction: "I wish to be contacted with more information on a company agreement with Ruter",
  },
};

export default agreementInfoLanguageResource;
