import { Button, Container, SearchInput, Table, TablePagination, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import AgreementTypeColumn from "src/components/common/Table/AgreementTypeColumn/AgreementTypeColumn";
import { Company, mapCompanySearchResult } from "src/types/Company";
import { SearchResult } from "src/types/SearchResult";
import "./CompaniesPage.scss";
import companiesLanguageResource from "./lang-resource";

const CompaniesPage: React.FC = () => {
  const lang = useLanguageResource(companiesLanguageResource);
  const { pagination, query, throttledQuery, setQuery } = usePaginationAndQuery();
  const { page, pageSize } = pagination;
  const [companies, setCompanies] = useState<null | SearchResult<Company>>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await apiClient.get(`/onboarding/company?maxEntries=${pageSize}&offset=${(page - 1) * pageSize}&searchFor=${throttledQuery}`, mapCompanySearchResult);

      if (response.type === "success") {
        setCompanies(response.result);
      } else {
        setCompanies(() => { throw response.error; });
      }

      setLoading(false);
    };
    fetch();

  }, [page, pageSize, throttledQuery]);

  const goToCompanyDetail = (id: string) => {
    navigate(`/bedrifter/${id}`);
  };

  const renderRow = (company: Company) => {
    return (
      <tr key={company.id} onClick={() => goToCompanyDetail(company.id)}>
        <td>{company.organisationNumber}</td>
        <td>{company.id}</td>
        <td>{company.name}</td>
        <td><AgreementTypeColumn agreements={company.allAgreements} /></td>
      </tr>
    );
  };

  return (
    <Container width="m" data-test-id="components-company-admin-companies" className="components-company-admin-companies">
      <h1>{lang.title}</h1>
      <div className="search-and-new-button">
        <SearchInput value={query} onChange={(e) => setQuery(e.target.value)} placeholder={lang.filterBy} />
        <Button type="button" variant="primary" data-test-id="create-company-button" text={lang.newCompany} onClick={() => navigate("/bedrifter/ny")} />
      </div>
      <Table loading={loading} breakpoint="660px">
        <thead>
          <tr>
            <th style={{ width: "130px" }}>{lang.organizationNumber}</th>
            <th style={{ width: "140px" }}>{lang.customerNumber}</th>
            <th>{lang.name}</th>
            <th>{lang.agreements}</th>
          </tr>
        </thead>
        <tbody>
          {companies?.matches.map(renderRow)}
        </tbody>
      </Table>
      <TablePagination
        pagination={pagination}
        totalRecords={companies?.totalEntries}
      />
    </Container>
  );

};

export default CompaniesPage;