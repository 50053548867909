import { AuthCompanyJson, mapAuthCompany } from "src/types/AuthCompany";
import { Contact, ContactJson, mapContact } from "src/types/Contact";


const userApi = {
  auth: {
    getContact: () => ({ method: "get" as const, url: "/user-api/auth/contact", mapper: (mapContact) }),
    getCompanies: (contact: Contact) => ({ method: "get" as const, url: "/user-api/auth/companies", mapper: (companiesJson: AuthCompanyJson[]) => companiesJson.map((companyJson) => mapAuthCompany(companyJson, contact)) }),
    getContactNoMapping: () => ({ method: "get" as const, url: "/user-api/auth/contact", mapper: (json: ContactJson) => json }),
    getCompaniesNoMapping: () => ({ method: "get" as const, url: "/user-api/auth/companies", mapper: (json: AuthCompanyJson[]) => json }),
  },
};

export default userApi;
