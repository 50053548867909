import React from "react";
import { getTenant, Tenant } from "../../types/Tenant";
import HomeBrakar from "./HomeBrakar";
import HomeRuter from "./HomeRuter";
import HomeAkt from "./HomeAkt";
const Home: React.FC = () => {
  const tenant = getTenant();

  if (tenant === Tenant.Ruter) {
    return <HomeRuter />;
  }
  if (tenant === Tenant.Akt) {
    return <HomeAkt />;
  }
  return <HomeBrakar />;
};

export default Home;
