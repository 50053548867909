import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface SelectCompanyLanguageResource {
  title: string;
}

export const selectCompanyLanguageResource: LanguageResource<SelectCompanyLanguageResource> = {
  nb: {
    title: "Velg selskap",
  },
  en: {
    title: "Select company",
  },
};
