import { Container, Dropdown, Label, SearchInput, Table, TablePagination, apiClient, formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import schoolTicketApi from "src/common/api/schoolTicketApi/schoolTicketApi";
import createAndDownloadCSV, { showNumberAsText } from "src/common/csvFileCreator";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import { ButtonLink } from "src/components/common/buttons";
import ticketStatusLanguageResource from "src/components/common/text/ticketStatus/lang-resource";
import MediaType from "src/types/mediaType";
import { TicketStatus } from "src/types/schoolTicketAgreement/ticketStatus";
import { SearchResult } from "../../../types/SearchResult";
import { SchoolTicket, SchoolTicketTravelCard } from "../../../types/schoolTicketAgreement/schoolTicket";
import GotoColumn from "../../common/Table/GotoColumn/GotoColumn";
import StatusColumn from "../../common/Table/StatusColumn/StatusColumn";
import { concatName } from "../utils";
import MediaTypeColumn from "./MediaTypeColumn";
import "./SchoolTicketOrderHistory.scss";
import schoolTicketsLanguageResource from "./lang-resource";

interface SchoolYear {
  text: string,
  value: number
}

const formatSchoolYear = (year: number) => `${year}/${year + 1}`;

const SchoolTicketOrderHistory = () => {
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;
  const companyName = authContext.userData.selectedCompany.name;
  const lang = useLanguageResource(schoolTicketsLanguageResource);
  const statusLang = useLanguageResource(ticketStatusLanguageResource);
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<SearchResult<SchoolTicket>>();
  const [loading, setLoading] = useState(false);
  const [schoolYears, setSchoolYears] = useState<SchoolYear[]>();
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<number>();
  const [activeAndPendingTickets, setActiveAndPendingTickets] = useState<SchoolTicketTravelCard[]>();
  const { pagination, query, throttledQuery, setQuery } = usePaginationAndQuery();
  const { page, pageSize } = pagination;

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const lastYear = currentYear - 1;
    const newSchoolYears: SchoolYear[] = [];
    if (currentDate.getMonth() < 6) {
      newSchoolYears.push({ value: lastYear, text: formatSchoolYear(lastYear) });
    } else {
      newSchoolYears.push({ value: currentYear, text: formatSchoolYear(currentYear) });
      newSchoolYears.push({ value: lastYear, text: formatSchoolYear(lastYear) });
    }
    setSchoolYears(newSchoolYears);
    setSelectedSchoolYear(newSchoolYears[0].value);
  }, []);

  useEffect(() => {
    const fetchActiveAndWaitingTickets = async () => {
      const response = await apiClient.request(schoolTicketApi.ticket.byCompanyId.download(companyId));

      if (response.type === "success") {
        const sortedTickets = response.result.matches.filter((ticket) => ticket.status === TicketStatus.active || ticket.status === TicketStatus.pending);
        const travelCards = sortedTickets.filter((ticket) => ticket.mediaType === MediaType.TRAVEL_CARD) as SchoolTicketTravelCard[];

        setActiveAndPendingTickets(travelCards);
      } else {
        setActiveAndPendingTickets(() => {
          throw response.error;
        });
      }
    };

    fetchActiveAndWaitingTickets();
  }, [companyId]);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      if (selectedSchoolYear) {
        const response = await apiClient.request(schoolTicketApi.ticket.byCompanyId.get(pageSize, page, companyId, throttledQuery, selectedSchoolYear.toString()));
        if (response.type === "success") {
          const result = response.result;
          setTickets(result);
        } else {
          setTickets(() => {
            throw response.error;
          });
        }
      }
    };
    fetch();
    setLoading(false);
  }, [throttledQuery, page, pageSize, companyId, selectedSchoolYear]);

  const renderRow = (schoolTicket: SchoolTicket) => {
    return (
      <tr key={schoolTicket.id} onClick={() => navigate(`/skole/${schoolTicket.id}`)}>
        <td data-test-id="name">{concatName(schoolTicket.firstName, schoolTicket.lastName) || "–"}</td>
        <td data-test-id="class-name">{schoolTicket.className || "–"}</td>
        <td data-test-id="address">{schoolTicket.address || "–"}</td>
        <MediaTypeColumn schoolTicket={schoolTicket} label={lang.ticketCarrier} />
        <td data-test-id="card-name" data-label={lang.cardName} className="desktop">
          {schoolTicket.mediaType === MediaType.TRAVEL_CARD ? schoolTicket.cardName : "–"}
        </td>
        <td data-test-id="card-name" data-label={lang.cardName} className="mobile">
          {schoolTicket.mediaType === MediaType.TRAVEL_CARD ? schoolTicket.cardName : "–"}
        </td>
        <StatusColumn status={schoolTicket.status} />
        <GotoColumn />
      </tr>
    );
  };

  const download = async () => {
    if (!activeAndPendingTickets) {
      throw new Error("activeAndPendingTickets is undefined");
    }
    const getZoneFrom = (zoneFrom: string, allZones: boolean): string => {
      if (allZones) {
        return lang.all;
      }
      return zoneFrom;
    };
    const getZoneTo = (zoneTo: string, zoneFrom: string, nrOfZones: number, allZones: boolean): string => {
      if (allZones) {
        return lang.all;
      }
      if (nrOfZones === 1) {
        return zoneFrom;
      }
      return zoneTo;
    };

    const csvModel = activeAndPendingTickets.map((x) => {
      const name = concatName(x.firstName, x.lastName);
      return [
        name,
        x.className,
        x.address,
        showNumberAsText(x.cardNumber),
        x.cardName,
        statusLang.statuses[x.status],
        getZoneFrom(x.zoneFrom, x.allZones),
        getZoneTo(x.zoneTo, x.zoneFrom, x.nrOfZones || 1, x.allZones),
      ];
    });

    const headers = [lang.name, lang.class, lang.address, lang.cardNumber, lang.cardName, lang.status, lang.zoneFrom, lang.zoneTo];

    const dateAsString = formatter.date.toShortDateString(new Date());
    const fileName = `Aktive og ventende skolebilletter for ${companyName} per ${dateAsString}.csv`;

    createAndDownloadCSV(fileName, headers, csvModel);
  };

  return (
    <Container width="l" className="components-schoolticketagreement-schooltickets" data-test-id="components-school-tickets-page">
      <h1>{lang.title}</h1>
      <div className="search-field">
        <SearchInput
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          placeholder={lang.search}
          name="searchFor"
        />
      </div>
      <hr />
      <div className="filter-and-download">
        <div className="filter">
          <Label text={lang.filter}>
            <Dropdown onChange={(e) => { setSelectedSchoolYear(Number(e.target.value)); }} name="year">
              {schoolYears?.map(year => <option value={year.value}>{year.text}</option>)}
            </Dropdown>
          </Label>
        </div>
        {activeAndPendingTickets && <div className="download">
          <ButtonLink
            text={lang.downloadActiveAndPendingAsCSV(String(activeAndPendingTickets?.length))}
            onClick={() => download()}
            dataTestId="download-active-and-pending-tickets-button"
          />
        </div>}
      </div>
      <div>
        <Table loading={loading} breakpoint="600px">
          <thead>
            <tr>
              <th scope="col">{lang.name}</th>
              <th scope="col" className="class">
                {lang.class}
              </th>
              <th scope="col">{lang.address}</th>
              <th scope="col" className="ticket-carrier">
                {lang.ticketCarrier}
              </th>
              <th scope="col">{lang.cardName}</th>
              <th scope="col">{lang.status}</th>
              <th className="goto-column" />
            </tr>
          </thead>
          <tbody>{tickets?.matches.map(renderRow)}</tbody>
        </Table>
      </div>
      <TablePagination
        pagination={pagination}
        totalRecords={tickets?.totalEntries}
      />
    </Container>
  );
};

export default SchoolTicketOrderHistory;
