import { useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Col, Container, Row } from "@ruter-ds/rds-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import byImage from "../../gfx/by.svg";
import kaffeImage from "../../gfx/kaffe-mann.svg";
import kontorImage from "../../gfx/kontor-mann.svg";
import CallToActionLink from "../common/buttons/CallToActionLink";
import "./HomeRuter.scss";
import { homeLanguageResource } from "./lang-resource";

const HomeRuter: React.FC = () => {
  const language = useLanguageResource(homeLanguageResource);
  const lang = useLanguageContext();
  const navigate = useNavigate();

  return (
    <Container width="l" className="components-home">
      <Row className="tickets-image">
        <Col s={6} sOffset={3} all={12}>
          <img src={byImage} alt="" />
        </Col>
      </Row>
      <Row className="tickets">
        <Col s={8} sOffset={2} all={12}>
          <h1>{language.product1Title}</h1>
          <p>{language.product1Text}</p>
        </Col>
      </Row>
      <div className="products">
        {lang.selectedLanguage === "nb" && <Row className="product">
          <Col s={6} all={12} className="text">
            <h1>{language.productTicketCounterTitle}</h1>
            <p>{language.productTicketCounterText}</p>
            <br />
            <p>{language.productTicketCounterText2}<span><a href={language.ruterLink}>{language.ruterLinkText}</a></span>{language.productTicketCounterText3}</p>
            <br />
            <p>{language.productTicketCounterText4}</p>
            <CallToActionLink text={language.productTicketCounterButtonText} onClick={() => navigate("/billettluka/info")} showIcon={true} />
          </Col>
        </Row>}
        <Row className="product">
          <Col s={6} all={12} className="image">
            <img src={kontorImage} alt="" />
          </Col>
          <Col s={6} all={12} className="text">
            <h1>{language.product2Title}</h1>
            <p>{language.product2Text}</p>
            <CallToActionLink text={language.product2ButtonText} onClick={() => navigate("/bedriftsavtale/info")} showIcon={true} />
          </Col>
        </Row>

        <Row className="product">
          <Col s={6} all={12} className="image">
            <img src={kaffeImage} alt="" />
          </Col>
          <Col s={6} all={12} className="text">
            <h1>{language.product3Title}</h1>
            <p>{language.product3Text}</p>
            {language.product3Text2 && <p>{language.product3Text2}</p>}
            <CallToActionLink text={language.product3ButtonText} externalUrl="https://barekraft.ruter.no/" showIcon={true} />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default HomeRuter;
