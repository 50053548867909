import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface ErrorBoundaryLanguageResource {
  title: string;
  text: string;
  clickForContact: string;
}

export const errorBoundaryLanguageResource: LanguageResource<ErrorBoundaryLanguageResource> = {
  nb: {
    title: "Det har skjedd en feil!",
    text: "Du kan prøve å gå tilbake og prøve igjen",
    clickForContact: "Hvis problemet vedrarer, kontakt kundestøtte",
  },
  en: {
    title: "An error occured!",
    text: "You can try and go back and try again.",
    clickForContact: "If the problem persists, contact customer support",
  },
};