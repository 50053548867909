import { apiClient, Button, ButtonGroup, DateFormInput, DropdownFormInput, FieldValidationError, FormGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import { addMonths } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FreeTicket } from "src/common/api/freeTicketApi/freeTicket";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { failAlert } from "src/common/toastr";
import { CancelButton } from "src/components/common/buttons";
import { TicketStatus } from "src/types/freeTicketAgreement/ticketStatus";
import MediaType from "src/types/mediaType";
import { getTenant, Tenant } from "src/types/Tenant";
import { TravelCardState } from "src/types/TravelCardState";
import { freeTicketsFamilyLanguageResource } from "./lang-resource";

interface Props {
  allActiveTickets: FreeTicket[] | undefined;
  onSuccess: () => Promise<void>;
  onCancel: () => void;
}

interface FormData {
  expiryDate: string
}

interface CancelTicketSuccess {
  type: "success",
}

interface CancelTicketError {
  type: "error",
  id: string,
}

type CancelTicketRequestOrError = CancelTicketSuccess | CancelTicketError;

const CancelAllTicketsModal: React.FC<Props> = ({ allActiveTickets: allActiveAndPendingTickets, onCancel, onSuccess }) => {
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const lang = useLanguageResource(freeTicketsFamilyLanguageResource);
  const [loading, setLoading] = useState(false);
  const [requestErrors, setRequestErrors] = useState<CancelTicketError[]>();
  const tenant = getTenant();
  const dates = getExpiryDates(32, formLang.today, 7);
  const formMethods = useForm<FormData>({ defaultValues: { expiryDate: tenant === Tenant.Ruter ? "" : dates[0].value } });
  const expiryDate = useWatch({ name: "expiryDate", control: formMethods.control });

  if (!allActiveAndPendingTickets) return null;

  const cancelTicket = async (ticket: FreeTicket, endDate: string) => {
    if (ticket.mediaType === MediaType.TRAVEL_CARD && ticket.travelCardState && ticket.travelCardState === TravelCardState.REQUESTED) {
      const response = await apiClient.request(freeTicketApi.ticket.id(ticket.id).delete());
      return response;
    }
    if (ticket.status === TicketStatus.pending) {
      const response = await apiClient.request(freeTicketApi.ticket.id(ticket.id).delete());
      return response;
    }
    const expiry = ensureExpiryDateIsNotInThePastAndFormatForBackend(endDate, 0);
    const response = await apiClient.request(freeTicketApi.ticket.id(ticket.id).expiry.put(expiry));
    return response;
  };


  const handleSubmit = async (data: FormData) => {
    setLoading(true);
    const cancelTicketRequestOrErrors: CancelTicketRequestOrError[] = await Promise.all(allActiveAndPendingTickets.map(async (ticket) => {
      const response = await cancelTicket(ticket, data.expiryDate);

      if (!response) {
        throw new Error(`ticket: ${ticket.id} status is not active or pending`);
      }

      if (response.type === "success") {
        return { type: "success" };
      }

      Sentry.captureException(response.error);
      return { type: "error", id: ticket.id };
    }));
    if (cancelTicketRequestOrErrors.every(requestOrError => requestOrError.type === "success")) {
      onSuccess();
    } else {
      failAlert(lang.cancelActiveTicketFail);
      const errors = cancelTicketRequestOrErrors.filter(req => req.type === "error") as CancelTicketError[];
      setRequestErrors(errors);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen handleClose={() => { onCancel(); }} title={lang.cancelAllTickets} data-test-id="cancel-all-tickets-modal">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <FormGroup>
            {tenant === Tenant.Ruter && (
              <DateFormInput name="expiryDate" required minDate={new Date()} maxDate={addMonths(new Date(), 6)} label={lang.from} />
            )}
            {tenant !== Tenant.Ruter && (
              <DropdownFormInput name="expiryDate" label={lang.from} required>
                {dates.map((d) => (
                  <option value={d.value} key={d.value}>
                    {d.text}
                  </option>
                ))}
              </DropdownFormInput>
            )}
          </FormGroup>
          <FormGroup>
            <Message skin="info" title={lang.cancelActiveTicketInfoTitle}>
              <p style={{ marginBottom: "1rem" }}>{lang.cancelActiveTicketInfoPrivacy.replace("$expiryDate$", expiryDate)}</p>
            </Message>
          </FormGroup>
          <FormGroup>
            {requestErrors && <FieldValidationError text={lang.cancelAllTicketsError(requestErrors.map(error => error.id).join(", "))} />}
            <ButtonGroup>
              <Button
                type="submit"
                variant="primary"
                loading={loading}
                text={formLang.yes}
                data-test-id="confirm-button"
              />
              <CancelButton onClick={() => onCancel()} data-test-id="cancel-button" />
            </ButtonGroup>
          </FormGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CancelAllTicketsModal;