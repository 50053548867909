import { UNKNOWN } from "../../../constants";

enum GeneralErrorType {
  START_DATE_TOO_EARLY = "START_DATE_TOO_EARLY",
  START_DATE_TOO_LATE = "START_DATE_TOO_LATE",
  TRAVEL_CARD_NO_COMPANY_POST_ADDRESS = "TRAVEL_CARD_NO_COMPANY_POST_ADDRESS",
}

export enum RecipientErrorType {
  CONFLICTING_TICKET = "CONFLICTING_TICKET",
  INVALID_ZONE_FROM = "INVALID_ZONE_FROM",
  INVALID_ZONE_TO = "INVALID_ZONE_TO",
  BIRTH_DATE_TOO_RECENT = "BIRTH_DATE_TOO_RECENT",
  PROFILE_MISMATCH = "PROFILE_MISMATCH",
  INVALID_FIRST_NAME = "INVALID_FIRST_NAME",
  INVALID_LAST_NAME = "INVALID_LAST_NAME",
  INVALID_EMPLOYEE_ID = "INVALID_EMPLOYEE_ID",
  MOBILE_TICKET_NO_PHONE = "MOBILE_TICKET_NO_PHONE",
  MOBILE_TICKET_INVALID_PHONE = "MOBILE_TICKET_INVALID_PHONE",
}

const parseGeneralErrorType = (value: string): GeneralErrorType | typeof UNKNOWN => {
  const parsed = Object.values(GeneralErrorType).find((x) => x === value);

  if (parsed === undefined) {
    return UNKNOWN;
  }
  return parsed;
};

const parseRecipientErrorType = (value: string): RecipientErrorType | typeof UNKNOWN => {
  const parsed = Object.values(RecipientErrorType).find((x) => x === value);

  if (parsed === undefined) {
    return UNKNOWN;
  }
  return parsed;
};

interface RecipientErrorJson {
  indexInInput: number;
  errors: string[];
  conflictingTickets: string[];
}

interface RecipientError {
  indexInInput: number;
  errors: (RecipientErrorType | typeof UNKNOWN)[];
}

export interface MassOrderValidationJson {
  id: string | null;
  okCount: number;
  errorCount: number;
  generalErrors: string[];
  recipientErrors: RecipientErrorJson[];
}

export interface MassOrderValidation {
  okCount: number;
  errorCount: number;
  generalErrors: (GeneralErrorType | typeof UNKNOWN)[];
  recipientErrors: RecipientError[];
}

export const mapMassOrderValidation = (json: MassOrderValidationJson): MassOrderValidation => ({
  okCount: json.okCount,
  errorCount: json.errorCount,
  generalErrors: json.generalErrors.map(parseGeneralErrorType),
  recipientErrors: json.recipientErrors.map((recpientErrorJson) => ({
    indexInInput: recpientErrorJson.indexInInput,
    errors: recpientErrorJson.errors.map(parseRecipientErrorType),
  })),
});

