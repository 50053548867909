import { apiClient } from "@ruter-as/web-components-and-tools";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "./AuthContext";
import companyAgreementApi from "./common/api/companyAgreementApi/companyAgreementApi";
import { TicketRequestJson } from "./types/companyAgreement/ticketRequest";

interface TicketRequestForbidden {
  state: "not-applicable"
}

interface TicketRequestLoading {
  state: "loading",
  requests: TicketRequestJson[],
}

interface TicketRequestError {
  state: "error"
}

interface TicketRequestAllowed {
  state: "ticket-request-allowed"
  requests: TicketRequestJson[],
  refetch: () => Promise<void>
}

type TicketRequestContextValues = TicketRequestForbidden | TicketRequestAllowed | TicketRequestLoading | TicketRequestError;

const TicketRequestContext = React.createContext<TicketRequestContextValues>({ state: "not-applicable" });

let fetchTicketRequestsTimout: NodeJS.Timeout;

const TicketRequestContextProvider: React.FC<PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => {
  const authContext = useAuthContext();
  const allowTicketRequest = authContext.authenticated && authContext.userData.selectedCompany.businessTicketAgreements[0] && authContext.userData.selectedCompany.businessTicketAgreements[0].settings.widgetAccess === "ALLOW_TICKET_REQUESTS";
  const companyId = authContext.authenticated && authContext.userData.selectedCompany.id;
  const [requests, setRequests] = useState<TicketRequestJson[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();

  const fetch = useCallback(async () => {
    clearTimeout(fetchTicketRequestsTimout);

    if (allowTicketRequest && companyId) {
      const response = await apiClient.request(companyAgreementApi.ticketRequest.getByCompanyId(companyId));

      if (response.type === "success") {
        setRequests(response.result.matches);
        setError(undefined);
        fetchTicketRequestsTimout = setTimeout(fetch, 3000);
      } else {
        setError(response.error);
      }
    } else {
      setRequests([]);
    }
    setLoading(false);

  }, [allowTicketRequest, companyId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const contextValue = useMemo((): TicketRequestContextValues => {
    if (error) {
      return {
        state: "error",
      };
    }
    if (loading) {
      return {
        state: "loading",
        requests,
      };
    }
    if (!allowTicketRequest || !companyId) {
      return {
        state: "not-applicable",
      };
    }

    return {
      state: "ticket-request-allowed",
      requests,
      refetch: async () => {
        setLoading(true);
        await fetch();
      },
    };

  }, [error, allowTicketRequest, loading, requests, companyId, fetch]);

  return (
    <TicketRequestContext.Provider value={contextValue}>
      {children}
    </TicketRequestContext.Provider>
  );
};

export const useTicketRequestContext = (): TicketRequestContextValues => {
  return useContext(TicketRequestContext);
};

export default TicketRequestContextProvider;
