import { Slide, toast } from "react-toastify";

// set faster timeouts in test context
const errorAutoClose = window.Cypress ? 1000 : 5000;
const successAutoClose = window.Cypress ? 1000 : 3000;

export default function successAlert(text: string): void {
  setTimeout(() => {
    toast.success(text, {
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: false,
      autoClose: successAutoClose,
      transition: Slide,
      hideProgressBar: true,
    });
  }, 500);
}

export function failAlert(text: string): void {
  setTimeout(() => {
    toast.error(text, {
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: false,
      autoClose: errorAutoClose,
      transition: Slide,
      hideProgressBar: true,
    });
  }, 0);
}
