import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import bus from "../../../gfx/illustrasjon-buss-404.svg";
import { CallToActionLink } from "../../common/buttons";
import "./GenericError.scss";
import { errorBoundaryLanguageResource } from "./lang-resource";

const GenericError: React.FC = () => {
  const lang = useLanguageResource(errorBoundaryLanguageResource);

  return (
    <Container width="m" className="components-app-loadingerror" data-test-id="loading-error-page">
      <img src={bus} alt="" />
      <h1>{lang.title}</h1>
      <div>{lang.text}</div>

      <div className="actions">
        <CallToActionLink
          externalUrl="https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/#page=form"
          text={lang.clickForContact}
          showIcon={true}
        />
      </div>
    </Container>
  );
};

export default GenericError;
