import { getValidInvoiceLine, InvoiceLine, InvoiceLineJson, mapInvoiceLine } from "./invoiceLine";
import { parseTaxBaseChangeCause, TaxBaseChangeCause } from "./taxBaseChangeCause";

interface TaxBaseChangeJson {
  agreementNumber: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  old: InvoiceLineJson[];
  new: InvoiceLineJson[];
  changeType: string;
  oldPrice: number;
  newPrice: number;
  priceDifference: number;
}

interface TaxBaseChangeSubscribed {
  agreementNumber: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  new: InvoiceLine[];
  changeType: typeof TaxBaseChangeCause.SUBSCRIBED;
  newPrice: number;
}

interface TaxBaseChangeUnsubscribed {
  agreementNumber: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  old: InvoiceLine[];
  changeType: typeof TaxBaseChangeCause.UNSUBSCRIBED;
  oldPrice: number;
}

interface TaxBaseChangePriceChange {
  agreementNumber: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  old: InvoiceLine[];
  new: InvoiceLine[];
  changeType: typeof TaxBaseChangeCause.PRICE_CHANGED;
  oldPrice: number;
  newPrice: number;
}

export type TaxBaseChange = TaxBaseChangeSubscribed | TaxBaseChangeUnsubscribed | TaxBaseChangePriceChange;

const mapTaxBaseChange = (json: TaxBaseChangeJson): TaxBaseChange => {
  const changeCause = parseTaxBaseChangeCause(json.changeType);

  const common = {
    agreementNumber: json.agreementNumber,
    firstName: json.firstName,
    lastName: json.lastName,
    employeeId: json.employeeId,
  };

  const oldInvoiceLines = json.new.map(mapInvoiceLine);
  const newInvoiceLines = json.old.map(mapInvoiceLine);

  if (changeCause === TaxBaseChangeCause.SUBSCRIBED) {
    return {
      ...common,
      changeType: TaxBaseChangeCause.SUBSCRIBED,
      new: newInvoiceLines,
      newPrice: json.newPrice,
    };
  }

  if (changeCause === TaxBaseChangeCause.UNSUBSCRIBED) {
    return {
      ...common,
      changeType: TaxBaseChangeCause.UNSUBSCRIBED,
      old: oldInvoiceLines,
      oldPrice: json.oldPrice,
    };
  }

  if (changeCause === TaxBaseChangeCause.PRICE_CHANGED) {
    return {
      ...common,
      changeType: TaxBaseChangeCause.PRICE_CHANGED,
      old: oldInvoiceLines,
      new: newInvoiceLines,
      oldPrice: json.oldPrice,
      newPrice: json.newPrice,
    };
  }

  throw new Error(`TaxBaseChangeCause=${changeCause} is not handled`);
};

export const mapTaxBaseChanges = (json: TaxBaseChangeJson[]): TaxBaseChange[] => {
  const result = json.map(mapTaxBaseChange);
  return result;
};

export const getValidTaxBaseChange = (changes?: Partial<TaxBaseChangeJson>): TaxBaseChangeJson => ({
  agreementNumber: "1-34256",
  changeType: "SUBSCRIBED",
  employeeId: "kiha",
  firstName: "kim",
  lastName: "hansen",
  old: [],
  new: [getValidInvoiceLine()],
  oldPrice: 0,
  newPrice: 650,
  priceDifference: 450,
  ...changes,
});
