import { Checkbox, FormGroup, FieldMessage } from "@ruter-ds/rds-components";
import React, { ChangeEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  requiredValidationMessage?: string;
}

const CheckboxInput: React.FC<Props> = ({ name, required = false, label, requiredValidationMessage }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (required && !requiredValidationMessage) {
    throw new Error("you need to provide a validationMessage when checkbox is required");
  }

  const rules = required ? { required: requiredValidationMessage } : {};

  return (
    <FormGroup>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        rules={rules}
        render={({ field }) => (
          <Checkbox
            name={name}
            onChange={(event: ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
            checked={field.value}
            label={label}
            value={name}
            hasError={Boolean(errors[name])}
          />
        )}
      />

      {errors[name]?.message && <FieldMessage text={errors[name].message} skin="danger" />}
    </FormGroup>
  );
};

export default CheckboxInput;
