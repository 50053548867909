import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { ticketCounterInfoLanguageResource } from "./language-resource";
import "./TicketCounterInfo.scss";

const TicketCounterInfo: React.FC = () => {
  const lang = useLanguageResource(ticketCounterInfoLanguageResource);

  return (
    <Container width="s" className="components-ticket-counter-info">
      <h1>{lang.title}</h1>
      <p>{lang.preamble.text1}</p>
      <br />
      <p>{lang.preamble.text2} <span><a href={lang.ruterLink}>{lang.ruterLinkText}</a></span>{lang.preamble.text3}</p>
      <br />
      <p>{lang.preamble.text4}</p>
      <p>{lang.preamble.text5}</p>

      <h2 className="title" >{lang.advantages.title}</h2>
      <ol>
        <li>{lang.advantages.text1}</li>
        <li>{lang.advantages.text2}</li>
        <li>{lang.advantages.text3}</li>
      </ol>

      <h2 className="title">{lang.ticketTypes.title}</h2>
      <ul>
        <li>{lang.ticketTypes.singleTicket}</li>
        <li>{lang.ticketTypes.periodTicket["24-hour"]}</li>
        <li>{lang.ticketTypes.periodTicket["30-days"]}</li>
        <li>{lang.ticketTypes.periodTicket["7-days"]}</li>
        <li>{lang.ticketTypes.periodTicket["365-days"]}</li>
        <li>{lang.ticketTypes.rufus}</li>
      </ul>

      <h2 className="title">{lang.orderingMobileTicket.title}</h2>
      <h3>{lang.orderingMobileTicket.subTitle}</h3>
      <dl>
        <dt>{lang.orderingMobileTicket.sms.title}</dt>
        <dd><p>{lang.orderingMobileTicket.sms.text}</p></dd>
        <dt>{lang.orderingMobileTicket.notSms.title}</dt>
        <dd>
          <p style={{ marginBottom: "0.5rem" }}>{lang.orderingMobileTicket.notSms.text1}</p>
          <p>{lang.orderingMobileTicket.notSms.text2}</p>
          <p>{lang.orderingMobileTicket.notSms.text3}</p>
        </dd>
      </dl>
      <h3>{lang.orderingMobileTicket.invoice.title}</h3>
      <p>{lang.orderingMobileTicket.invoice.text}</p>

      <h2 className="title">{lang.orderingTravelCard.title}</h2>
      <h3>{lang.orderingTravelCard.price.title}</h3>
      <p>{lang.orderingTravelCard.price.text}</p>
      <p><a href={lang.ruterLink}>{lang.orderingTravelCard.price.urlText}</a></p>
      <h3>{lang.orderingTravelCard.delivery.title}</h3>
      <p>{lang.orderingTravelCard.delivery.text1}</p>
      <p style={{ fontWeight: "bold" }}>{lang.orderingTravelCard.delivery.text2}</p>
      <p>{lang.orderingTravelCard.delivery.text3}</p>
      <h3>{lang.orderingTravelCard.invoice.title}</h3>
      <ul>
        <li>{lang.orderingTravelCard.invoice.text1}</li>
        <li>{lang.orderingTravelCard.invoice.text2}</li>
        <li>{lang.orderingTravelCard.invoice.text3}</li>
      </ul>
      <h3>{lang.orderingTravelCard.lostOrDestroyed.title}</h3>
      <p>{lang.orderingTravelCard.lostOrDestroyed.text1} <span><a href={lang.ruterLink}>{lang.ruterLinkText}</a></span>. {lang.orderingTravelCard.lostOrDestroyed.text2}</p>
      <p><a href={lang.ruterLink}>{lang.orderingTravelCard.lostOrDestroyed.urlText}</a></p>
      <h3>{lang.orderingTravelCard.cancelSubscription.title}</h3>
      <p>{lang.orderingTravelCard.cancelSubscription.text1} <span><a href={lang.ruterLink}>{lang.ruterLinkText}</a></span>. {lang.orderingTravelCard.cancelSubscription.text2}</p>
      <p><a href={lang.ruterLink}>{lang.orderingTravelCard.cancelSubscription.urlText}</a></p>
      <h3>{lang.orderingTravelCard.refund.title}</h3>
      <p>{lang.orderingTravelCard.refund.text}</p>
      <p><a href={lang.ruterLink}>{lang.orderingTravelCard.refund.urlText}</a></p>
    </Container>
  );
};

export default TicketCounterInfo;
