import { AuthenticationService, Tenant as BillettLukeTenant, OrderTicketWizard, TicketApiClient } from "@ruter-as/billettluke-frontend";
import { Container } from "@ruter-ds/rds-components";
import React, { useEffect, useMemo, useState } from "react";
import { hasValidAgreement } from "src/common/agreements";
import { useAuthContextAuthenticated } from "../../../AuthContext";
import { Tenant, getTenant } from "../../../types/Tenant";
import { getBlacklistedProducts } from "../../../types/ticketCounter/ticketCounterBlacklist";
import "./TicketCounterOrder.scss";

const TicketCounterOrder: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const ticketCounterAgreements = authContext.userData.selectedCompany.ticketCounterAgreements;
  const agreementNumber = ticketCounterAgreements && ticketCounterAgreements[0].id;
  const companyId = authContext.userData.selectedCompany.id;
  const localTenant = getTenant();
  const [allowTicketAdminsToUpdateInvoiceReferences, setAllowTicketAdminsToUpdateInvoiceReferences] = useState<boolean>(false);
  let blacklistedProducts: string[] = [];

  const { getAccessToken } = useAuthContextAuthenticated();
  const ticketApi = useMemo(() => {
    return new TicketApiClient({ getAccessToken }, "/ticket-api");
  }, [getAccessToken]);

  useEffect(() => {
    if (companyId) {
      try {
        const fetch = async () => {
          const settings = await ticketApi.company.getSettings(companyId);
          setAllowTicketAdminsToUpdateInvoiceReferences(settings.allowTicketAdminsToUpdateInvoiceReferences);
        };
        fetch();
      } catch (e) {
        setAllowTicketAdminsToUpdateInvoiceReferences(() => {
          throw e;
        });
      }
    }
  }, [companyId, ticketApi.company]);

  const ticketCounterTenant = (() => {
    switch (localTenant) {
      case Tenant.Ruter:
        return BillettLukeTenant.Ruter;
      case Tenant.Brakar:
        return BillettLukeTenant.Brakar;
      case Tenant.Akt:
        blacklistedProducts = getBlacklistedProducts(Tenant.Akt);
        return BillettLukeTenant.AKT;
      default:
        return BillettLukeTenant.Ruter;
    }
  })();

  if (!hasValidAgreement(ticketCounterAgreements)) {
    throw new Error("Missing valid ticket counter agreement");
  }

  if (!authContext) {
    throw new Error("AuthContext can not be null");
  }

  if (!agreementNumber) {
    throw new Error("agreementnumber must have value");
  }

  if (!companyId) {
    throw new Error("companyId must exist");
  }

  const authService: AuthenticationService = {
    getAccessToken: async () => {
      const token = await authContext.getAccessToken();
      if (!token) {
        throw new Error("could not find token");
      }
      return token;
    },
  };

  return (
    <Container width="s" className="components-ticketcounter-order">
      <OrderTicketWizard
        consumer="company"
        agreementNumber={agreementNumber}
        tenant={ticketCounterTenant}
        authService={authService}
        errorLink="https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/"
        companyId={companyId}
        quickCheckoutEnabled={true}
        blacklistedProducts={blacklistedProducts}
        allowCreateInvoiceReference={allowTicketAdminsToUpdateInvoiceReferences}
      />
    </Container>
  );
};

export default TicketCounterOrder;
