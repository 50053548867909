import React from "react";

const WidgetSkeleton: React.FC = () => {
  return (
    <>
      <div className="skeleton configuration">
        <div className="h1" />
        <div className="h1" />
        <div className="h2" />
        <div className="text" />
        <div className="h2" />
        <div className="text" />
        <div className="h2" />
        <div className="text" />
        <div className="text" />
        <div className="h2" />
        <div className="block" />
      </div>
      <div className="skeleton widget">
        <div className="h2" />
        <div className="block" />
      </div>
    </>
  );
};

export default WidgetSkeleton;