export enum NewTicketBecauseOf {
  ZONE = "ZONE",
  AGREEMENT = "AGREEMENT",
  COMPANY = "COMPANY",
  NAME = "NAME",
  DATE_OF_BIRTH = "DATE_OF_BIRTH",
  PROFILE_ID = "PROFILE_ID",
  EMPLOYEE_ID = "EMPLOYEE_ID",
  TICKET_HOLDER_TYPE = "TICKET_HOLDER_TYPE",
  MEDIA_TYPE = "MEDIA_TYPE",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export const parseNewTicketBecauseOf = (value: string): NewTicketBecauseOf => {
  const parsed = Object.values(NewTicketBecauseOf).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error(`${value} is not a valid NewTicketBecauseOf`);
  }
  return parsed;
};
