import { hasValidAgreement } from "src/common/agreements";
import { EnvName } from "src/common/env";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRight } from "../../../common/userRights";
import { AuthAgreement } from "../../../types/AuthCompany";
import { Tenant, getTenant } from "../../../types/Tenant";

type FreeTicketUrls =
  | "/fribilletter"
  | "/fribilletter/ny"
  | "/fribilletter/:id"
  | "/fribilletter/endringer"
  | "/fribilletter/skattegrunnlag"
  | "/fribilletter/fakturagrunnlag"
  | "/fribilletter/fakturagrunnlag/:id"
  | "/fribilletter/info"
  | "/fribilletter/massebestillinger"
  | "/fribilletter/massebestillinger/ny"
  | "/fribilletter/massebestillinger/avslutt"
  | "/fribilletter/massebestillinger/byttavdeling"
  | "/fribilletter/innstillinger"
  | "/fribilletter/reisekort";
type CompanyAgreementUrls =
  | "/bedriftsavtale"
  | "/bedriftsavtale/lonnstrekk"
  | "/bedriftsavtale/faktura/:id"
  | "/bedriftsavtale/innstillinger"
  | "/bedriftsavtale/godkjenning"
  | "/bedriftsavtale/ansatt/:id"
  | "/bedriftsavtale/billett/opprett"
  | "/bedriftsavtale/info";
type TicketCounterUrls =
  | "/billettluka"
  | "/billettluka/ordrehistorikk"
  | "/billettluka/ordrehistorikk/:id"
  | "/billettluka/billetthistorikk"
  | "/billettluka/innstillinger"
  | "/billettluka/bedriften-betaler"
  | "/billettluka/info";

type SchoolTicketUrls = "/skole" | "/skole/bestill" | "/skole/:id";

type SchoolUrls = "/schools/info";

type ToolsUrls = "/verktoy" | "/verktoy/widget" | "/verktoy/link";

type UserUrls = "/brukere" | "/brukere/ny" | "/brukere/:id";

type MyPageUrls = "/minside" | "/minside/kontakt/endre" | "/minside/selskap/endre";

type OtherUrls = "/kontaktoss";

type InvoiceUrls = "/faktura";

type CompanyUrls = "/bedrifter/ny" | "/bedrifter" | "/bedrifter/:id";

type TravelCardAdminUrls = "/reisekort" | "/reisekort/:companyId/:date";

export type Urls =
  | FreeTicketUrls
  | CompanyAgreementUrls
  | TicketCounterUrls
  | SchoolUrls
  | ToolsUrls
  | UserUrls
  | MyPageUrls
  | OtherUrls
  | InvoiceUrls
  | CompanyUrls
  | SchoolTicketUrls
  | TravelCardAdminUrls;

export interface SubMenuItem {
  id: string;
  path: Urls;
  focus?: number;
}

export interface MenuItem {
  id: string;
  path: Urls;
  focus?: number;
  subMenu?: Array<SubMenuItem>;
}

export interface MenuState {
  showAccountMenu: boolean;
  showMobileMenu: boolean;
  openItems: Array<string>;
  items: Array<MenuItem>;
}

export function getMenuItems(
  userRights: Array<UserRight>,
  agreements: Array<AuthAgreement>,
  roles: Array<string>,
  tenant: Tenant,
  env: EnvName,
  widgetRequestPolicy?: boolean,
  numberOfTicketRequests = 0,
): Array<MenuItem> {
  const hasTicketCounterRights = userRights.some((x) => x === UserRight.TicketCounterAgreeement);
  const hasBusinessTicketRights = userRights.some((x) => x === UserRight.BusinessAgreement);
  const hasFreeTicketRights = userRights.some((x) => x === UserRight.FreeTicketAgreeement);
  const hasUserAdminRights = userRights.some((x) => x === UserRight.GlobalUserAdmin || x === UserRight.UserAdmin);
  const hasGlobalTravelCardAdmin = userRights.some((x) => x === UserRight.GlobalTravelCardAdmin);
  const hasGlobalCompanyAdmin = userRights.some((x) => x === UserRight.CompanyAdmin);
  const hasSchoolTicketAdminRights = userRights.some((x) => x === UserRight.SchoolTicketAdmin || x === UserRight.GlobalSchoolTicketAdmin);
  const hasAnyRights = userRights.length > 0;
  const showNumberOfTicketRequests = hasBusinessTicketRights && widgetRequestPolicy;
  const schoolTicketAgreements = agreements.filter((agreement) => agreement.subTypeId === AgreementSubTypeId.SCHOOLTICKET);
  const ticketCounterAgreements = agreements.filter((agreement) => agreement.subTypeId === AgreementSubTypeId.TICKETOFFICE);
  const hasValidSchoolTicketAgreement = hasValidAgreement(schoolTicketAgreements);
  const hasValidTicketCounterAgreement = hasValidAgreement(ticketCounterAgreements);
  const hasCompanyAdminRole = roles.some(role => role === CompanyRoleType.COMPANY_ADMIN);
  const hasTicketAdminRole = roles.some(role => role === CompanyRoleType.TICKET_ADMIN);
  const hasUserAdminRole = roles.some(role => role === CompanyRoleType.USER_ADMIN);
  const focus = showNumberOfTicketRequests ? numberOfTicketRequests : 0;

  const showInvoice = (hasBusinessTicketRights || hasTicketCounterRights || hasFreeTicketRights || hasSchoolTicketAdminRights) && !(tenant === Tenant.Akt && env === EnvName.Production);

  if (!hasAnyRights) {
    if (tenant === Tenant.Brakar) {
      return [{ id: "freeTicketAgreement", path: "/fribilletter/info" }];
    }
    if (tenant === Tenant.Akt) {
      return [];
    }
    return [
      { id: "companyAgreement", path: "/bedriftsavtale/info" },
      { id: "billettluka", path: "/billettluka/info" },
      { id: "toolsInfo", path: "/verktoy" },
    ];
  }

  const menu: MenuItem[] = [];

  if (hasBusinessTicketRights && tenant === Tenant.Ruter) {
    const menuItem: MenuItem = {
      id: "companyAgreement",
      path: "/bedriftsavtale",
      focus,
      subMenu: [
        { id: "employees", path: "/bedriftsavtale" },
        { id: "companyPayroll", path: "/bedriftsavtale/lonnstrekk" },
        { id: "companySettings", path: "/bedriftsavtale/innstillinger" },
        { id: "companyInfo", path: "/bedriftsavtale/info" },
      ],
    };

    if (showNumberOfTicketRequests && menuItem.subMenu) {
      menuItem.subMenu.splice(0, 0, { id: "ticketRequests", path: "/bedriftsavtale/godkjenning", focus });
    }

    menu.push(menuItem);
  }

  if (hasFreeTicketRights) {
    const freeTicketMenu: MenuItem = {
      id: "freeTicketAgreement",
      path: "/fribilletter",
      subMenu: [
        { id: "freeTickets", path: "/fribilletter" },
        { id: "freeTicketTaxBaseChanges", path: "/fribilletter/endringer" },
        { id: "freeTicketTaxBase", path: "/fribilletter/skattegrunnlag" },
        { id: "freeTicketInvoiceBase", path: "/fribilletter/fakturagrunnlag" },
        { id: "freeTicketMassOrders", path: "/fribilletter/massebestillinger" },
        { id: "freeTicketInfo", path: "/fribilletter/info" },
        { id: "settings", path: "/fribilletter/innstillinger" },
      ],
    };
    if (hasGlobalTravelCardAdmin && tenant === Tenant.Brakar) {
      freeTicketMenu.subMenu?.splice(5, 0, { id: "freeTicketTravelCard", path: "/fribilletter/reisekort" });
    }

    menu.push(freeTicketMenu);
  } else if (hasGlobalTravelCardAdmin && tenant === Tenant.Brakar) {
    menu.push({
      id: "freeTicketAgreement",
      path: "/fribilletter/reisekort",
      subMenu: [{ id: "freeTicketTravelCard", path: "/fribilletter/reisekort" }],
    });
  }

  if (hasSchoolTicketAdminRights && schoolTicketAgreements.length > 0) {
    const subMenu: SubMenuItem[] = [{ path: "/skole", id: "schoolTickets" }];
    if (hasValidSchoolTicketAgreement) {
      subMenu.push({ path: "/skole/bestill", id: "schoolTicketsOrder" });
    }
    menu.push({
      id: "schoolTicketAgreement",
      path: "/skole",
      subMenu,
    });
  }

  if (hasTicketCounterRights) {
    let subMenu: SubMenuItem[] = [];
    let path: Urls = "/billettluka/ordrehistorikk";

    subMenu = [{ id: "billettlukaOrdrehistorikk", path: "/billettluka/ordrehistorikk" }];
    if (hasTicketAdminRole && hasValidTicketCounterAgreement) {
      subMenu = [
        { id: "billettlukaBestill", path: "/billettluka" },
        { id: "billettlukaOrdrehistorikk", path: "/billettluka/ordrehistorikk" },
      ];
      if (env !== EnvName.Production) {
        subMenu.push({ id: "billettlukaBedriftenBetaler", path: "/billettluka/bedriften-betaler" });
      }
      path = "/billettluka";
    }

    subMenu.push({ id: "billettlukaBilletthistorikk", path: "/billettluka/billetthistorikk" });

    if (hasUserAdminRole || hasCompanyAdminRole) {
      subMenu.push({ id: "billettlukaInnstillinger", path: "/billettluka/innstillinger" });
    }

    menu.push({
      id: "billettluka",
      path,
      subMenu,
    });
  }

  if (showInvoice) {
    menu.push({
      id: "invoice",
      path: "/faktura",
    });
  }

  if (hasGlobalCompanyAdmin) {
    menu.push({
      id: "companies",
      path: "/bedrifter",
    });
  }

  if (hasUserAdminRights) {
    menu.push({
      id: "useradmin",
      path: "/brukere",
    });
  }

  if (hasGlobalTravelCardAdmin && tenant === Tenant.Ruter) {
    menu.push({
      id: "travelCardAdmin",
      path: "/reisekort",
    });
  }

  if (tenant === Tenant.Ruter) {
    menu.push({ id: "toolsInfo", path: "/verktoy" });
  }

  return menu;
}

export const initalMenuState: MenuState = {
  showAccountMenu: false,
  showMobileMenu: false,
  openItems: [],
  items: getMenuItems([], [], [], getTenant(), EnvName.Test),
};

