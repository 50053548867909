import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface ValidationMessagesLanguageResource {
  required: string;
  defaultMessage: string;
  invalidDate: string;
  minDate: string;
  maxDate: string;
  minLength: string;
  maxLength: string;
  minValue: string;
  maxValue: string;
  invalidPhoneNumber: string;
  consentRequired: string;
  invalidEmail: string;
  onlyNumbers: string;
  invalidMobileNumber: string;
  mustChangeZone: string;
  invalidInternationalPhoneNumber: string;
  invalidNorwegianPhoneNumber: string;
  invalidInternationalMobileNumber: string;
}

export const validationMessagesLanguageResource: LanguageResource<ValidationMessagesLanguageResource> = {
  nb: {
    required: "$fieldLabel$ er påkrevd",
    defaultMessage: "$fieldLabel$ er feil format",
    invalidDate: "$fieldLabel$ må være en gyldig dato (DD.MM.YYYY)",
    minDate: "$fieldLabel$ må være større enn eller lik $minDate$",
    maxDate: "$fieldLabel$ må være mindre enn eller lik $minDate$",
    minLength: "$fieldLabel$ må inneholde minst $minLength$ tegn",
    maxLength: "$fieldLabel$ må ikke ha flere enn $maxLength$ tegn",
    minValue: "$fieldLabel$ kan ikke være mindre enn $minValue$",
    maxValue: "$fieldLabel$ kan ikke være større enn $maxValue$",
    invalidPhoneNumber: "$fieldLabel$ må bestå av 8 siffer",
    consentRequired: "Aksept kreves",
    invalidEmail: "Ugyldig e-post format",
    onlyNumbers: "$fieldLabel$ kan bare bestå av tall",
    invalidMobileNumber: "$fieldLabel$ må starte med 9 eller 4",
    mustChangeZone: "Velg en annen sone enn den som allerede er på billetten",
    invalidInternationalPhoneNumber: "$fieldLabel$ må være et gyldig telefonnummer",
    invalidNorwegianPhoneNumber: "$fieldLabel$ må være et norskt telefonnummer",
    invalidInternationalMobileNumber: "$fieldLabel$ må være et gyldig mobiltelefonnummer",
  },
  en: {
    required: "$fieldLabel$ is required",
    defaultMessage: "$fieldLabel$ is wrongly formatted",
    invalidDate: "$fieldLabel$ must be a valid date",
    minDate: "$fieldLabel$ must be greater than or equal to $minDate$",
    maxDate: "$fieldLabel$ must be less than or equal to $minDate$",
    minLength: "$fieldLabel$ must contains at least $minLength$ characters",
    maxLength: "$fieldLabel$ must not be longer than $maxLength$ characters",
    minValue: "$fieldLabel$ must not be less than $minValue$",
    maxValue: "$fieldLabel$ must not be greater than $maxValue$",
    invalidPhoneNumber: "$fieldLabel$ must be 8 digits",
    consentRequired: "Consent required",
    invalidEmail: "Invalid email format",
    onlyNumbers: "$fieldLabel$ must be numbers only",
    invalidMobileNumber: "$fieldLabel$ must start with a 4 or a 9",
    mustChangeZone: "Select a different zone than the one currently on the ticket",
    invalidInternationalPhoneNumber: "$fieldLabel$ must be a valid phone number",
    invalidNorwegianPhoneNumber: "$fieldLabel$ must be a Norwegian phone number",
    invalidInternationalMobileNumber: "$fieldLabel$ must be a mobile phone number",
  },
};