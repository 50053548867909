import { AgreementSubTypeId, parseAgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { CustomerOrder } from "./CustomerOrder";
import { InvoiceLine, InvoiceLineJson, getValidInvoiceLineJson, mapInvoiceLine } from "./InvoiceLine";

interface InvoiceJson {
  invoiceNumber: string,
  invoiceDate: string,
  dueDate: string,
  paymentStatus: string,
  reminderDueDate: string | null,
  payerRef: string,
  agreementNumber: string,
  companyId: string | null,
  kid: string,
  theirReference: string,
  ourReference: string,
  invoiceRef: string,
  totalAmount: number,
  outstandingAmount: number,
  agreementSubTypeId: string,
  totalVat: number,
}

export interface Invoice {
  type: "INVOICE"
  invoiceNumber: string,
  invoiceDate: Date,
  dueDate: Date,
  paymentStatus: string,
  reminderDueDate: Date | null,
  payerRef: string,
  agreementNumber: string,
  companyId: string | null,
  kid: string,
  theirReference: string,
  ourReference: string,
  invoiceRef: string,
  totalAmount: number,
  outstandingAmount: number,
  invoiceLines: InvoiceLine[]
  agreementSubTypeId: AgreementSubTypeId,
  totalVat: number,
}


export const mapInvoice = (json: InvoiceAndInvoiceLineJson): Invoice => ({
  type: "INVOICE",
  invoiceDate: new Date(json.invoice.invoiceDate),
  agreementNumber: json.invoice.agreementNumber,
  companyId: json.invoice.companyId,
  dueDate: new Date(json.invoice.dueDate),
  invoiceNumber: json.invoice.invoiceNumber,
  invoiceRef: json.invoice.invoiceRef,
  kid: json.invoice.kid,
  ourReference: json.invoice.ourReference,
  outstandingAmount: json.invoice.outstandingAmount,
  payerRef: json.invoice.payerRef,
  paymentStatus: json.invoice.paymentStatus,
  reminderDueDate: json.invoice.reminderDueDate ? new Date(json.invoice.reminderDueDate) : null,
  theirReference: json.invoice.theirReference,
  totalAmount: Math.round(json.invoice.totalAmount * 100) / 100,
  agreementSubTypeId: parseAgreementSubTypeId(json.invoice.agreementSubTypeId),
  totalVat: Math.round(json.invoice.totalVat * 100) / 100,
  invoiceLines: json.lines.map(mapInvoiceLine),
});

export const getValidInvoiceJson = (json: Partial<InvoiceJson> = {}): InvoiceJson => ({
  invoiceNumber: "100001263",
  invoiceDate: "2024-03-31",
  dueDate: "2024-04-10",
  paymentStatus: "Åpen",
  reminderDueDate: null,
  payerRef: "1226629",
  agreementNumber: "1-3779173843",
  companyId: null,
  kid: "1000012631",
  theirReference: "Oslo",
  ourReference: "1226629",
  invoiceRef: "5001256",
  totalAmount: 58.31,
  outstandingAmount: 58.31,
  agreementSubTypeId: AgreementSubTypeId.SUBSCRIPTIONS,
  totalVat: 10,
  ...json,
});


export interface InvoiceAndInvoiceLineJson {
  invoice: InvoiceJson,
  lines: InvoiceLineJson[],
}

export interface InvoicePdf {
  pdfLink: string | null;
}

export type InvoiceOrCustomerOrder = Invoice | CustomerOrder;

export const getValidInvoiceAndInvoiceLineJson = (json: Partial<InvoiceAndInvoiceLineJson> = {}): InvoiceAndInvoiceLineJson => (
  {
    invoice: getValidInvoiceJson(),
    lines: [
      getValidInvoiceLineJson(),
    ],
    ...json,
  });