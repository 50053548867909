import { LanguageResource, TwoParamsLanguageResourceTemplate, languageResourceBuilder } from "@ruter-as/web-components-and-tools";

interface OrderDetailsLanguageResource {
  title: string;
  orderedBy: string;
  date: string;
  product: string;
  profile: string;
  medium: string;
  invoiceReference: string;
  zones: string;
  comment: string;
  tickets: string;
  filter: string;
  status: string;
  downloaded: string;
  phone: string;
  pickUpCode: string;
  cancelTicket: string;
  resendCode: string;
  TRAVELCARD: string;
  MOBILETICKET: string;
  IMPULSCARD: string;
  count: string;
  ticketNumber: string;
  price: string;
  personalReference: string;
  resendPickupCodeSuccess: string;
  resendPickupCodeFail: string;
  confirmPendingTicketCancel: string;
  yes: string;
  cancel: string;
  cancelTicketFail: string;
  ticketCancelled: string;
  ticketCountStatus: TwoParamsLanguageResourceTemplate;
  ticketPrice: string;
  orderPrice: string;
  downloadNonFetchedCodeAsCsv: string;
  orderIsIncompleteWarning: string;
  refundSuccess: string
  refundOrder: string
  refund: string
  confirmRefund: string
  refundFailed: string
  downloadErrorMessage: string
}

const orderDetailsLanguageResource: LanguageResource<OrderDetailsLanguageResource> = {
  nb: {
    title: "Din ordre",
    orderedBy: "Bestilt av",
    date: "Dato",
    product: "Produkt",
    profile: "Passasjerkategori",
    medium: "Billettbærer",
    invoiceReference: "Fakturaref",
    zones: "Soner",
    comment: "Kommentar",
    tickets: "Billetter",
    filter: "Filtrer på spesifikk informasjon",
    status: "Status",
    downloaded: "Hentet",
    phone: "Telefon",
    pickUpCode: "Hentekode",
    cancelTicket: "Kanseller billett",
    resendCode: "Send hentekode",
    TRAVELCARD: "Reisekort",
    MOBILETICKET: "Mobilbillett",
    IMPULSCARD: "Impulskort",
    count: "Antall",
    ticketNumber: "Billettnr.",
    price: "Pris",
    personalReference: "Kommentar",
    resendPickupCodeSuccess: "Hentekode sendt!",
    resendPickupCodeFail: "Det skjedde en feil under sending av hentekode!",
    confirmPendingTicketCancel: "Er du sikker på at du ønsker å kansellere billetten?",
    yes: "Ja",
    cancel: "Avbryt",
    cancelTicketFail: "Det skjedde en feil ved kansellering av billetten!",
    ticketCancelled: "Billett kansellert!",
    ticketCountStatus: languageResourceBuilder.twoParamTemplate("$p1$ av $p2$"),
    ticketPrice: "Billettpris",
    orderPrice: "Sum ordre", downloadNonFetchedCodeAsCsv: "Last ned ubrukte hentekoder",
    orderIsIncompleteWarning: "Vi henter fortsatt billetter, listen vil derfor være ufullstendig",
    refundSuccess: "Billett refundert!",
    refundOrder: "Refunder ordre",
    refund: "Refunder",
    confirmRefund: "Er du sikker på at du ønsker å refundere billetten(e)?",
    refundFailed: "Det skjedde en feil ved refundering!",
    downloadErrorMessage: "Det er ingen ubrukte hentekoder",
  },
  en: {
    title: "Your order",
    orderedBy: "Ordered by",
    date: "Date",
    product: "Product",
    profile: "Passenger category",
    medium: "Medium",
    invoiceReference: "Invoice reference",
    zones: "Zones",
    comment: "Comment",
    tickets: "Tickets",
    filter: "Filter",
    status: "Status",
    downloaded: "Downloaded",
    phone: "Phone number",
    pickUpCode: "Pickup code",
    cancelTicket: "Cancel ticket",
    resendCode: "Resend pickup code",
    TRAVELCARD: "Travel card",
    MOBILETICKET: "Mobile ticket",
    IMPULSCARD: "Impuls card",
    count: "Count",
    ticketNumber: "Ticket number",
    price: "Price",
    personalReference: "Comment",
    resendPickupCodeSuccess: "Pickup code sent!",
    resendPickupCodeFail: "An error occured while sending pickup code!",
    confirmPendingTicketCancel: "Are you sure you want to cancel the ticket?",
    yes: "Yes",
    cancel: "Cancel",
    cancelTicketFail: "An error occured while trying to cancel the ticket!",
    ticketCancelled: "Ticket cancelled!",
    ticketCountStatus: languageResourceBuilder.twoParamTemplate("$p1$ of $p2$"),
    ticketPrice: "Ticket price",
    orderPrice: "Total price",
    downloadNonFetchedCodeAsCsv: "Download unused pickup codes",
    orderIsIncompleteWarning: "We are still fetching tickets, the list will be incomplete",
    refundSuccess: "Refunding succeeded!",
    refundOrder: "Refund order",
    refund: "Refund",
    confirmRefund: "Are you sure you want to refund the ticket(s)?",
    refundFailed: "An error occured while refunding!",
    downloadErrorMessage: "There are no unused pickup codes",
  },
};

export default orderDetailsLanguageResource;