import { UNKNOWN } from "../../constants";
import { parseTicketHolderType, TicketHolderType } from "./ticketHolderType";

export interface InvoiceLineJson {
  id: string | null;
  timeOfSale: string | null;
  description: string | null;
  ticketId: string;
  firstName: string | null;
  lastName: string | null;
  employeeId: string | null;
  ticketHolderType: string | null;
  phone: string;
  phoneCountryCode: string;
  zones: string[];
  numberOfZones: number;
  price: number;
  priceVat: number;
  quantity: number;
}

export interface InvoiceLine {
  id: string | null;
  timeOfSale: Date | null;
  description: string | null;
  ticketId: string;
  firstName: string | null;
  lastName: string | null;
  employeeId: string | null;
  ticketHolderType: TicketHolderType | typeof UNKNOWN | null;
  phone: string;
  phoneCountryCode: string;
  zones: string[];
  numberOfZones: number;
  price: number;
  priceVat: number;
  totPrice: number;
  totVat: number;
  quantity: number;
}

export const mapInvoiceLine = (json: InvoiceLineJson): InvoiceLine => ({
  id: json.id,
  timeOfSale: json.timeOfSale ? new Date(json.timeOfSale) : null,
  description: json.description,
  ticketId: json.ticketId,
  firstName: json.firstName,
  lastName: json.lastName,
  employeeId: json.employeeId,
  ticketHolderType: json.ticketHolderType ? parseTicketHolderType(json.ticketHolderType) : null,
  phone: json.phone,
  phoneCountryCode: json.phoneCountryCode,
  zones: json.zones,
  numberOfZones: json.numberOfZones,
  price: json.price,
  priceVat: json.priceVat,
  quantity: json.quantity,
  totPrice: json.quantity * json.price,
  totVat: json.quantity * json.priceVat,
});

export const getValidInvoiceLine = (changes?: Partial<InvoiceLineJson>): InvoiceLineJson => ({
  id: "il-id",
  timeOfSale: "2020-12-21T07:22:47Z",
  description: "description",
  ticketId: "ticket-id",
  firstName: "kimon",
  lastName: "hansonas",
  employeeId: "kiha1",
  ticketHolderType: "EMPLOYEE",
  phone: "93864800",
  phoneCountryCode: "+47",
  zones: ["1"],
  numberOfZones: 1,
  price: 50,
  priceVat: 5,
  quantity: 1,
  ...changes,
});
