import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container } from "@ruter-ds/rds-components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";
import { useTicketRequestContext } from "src/TicketRequestContext";
import getEnvironment from "src/common/env";
import { CompanyRoleType, parseCompanyRoleType } from "src/types/user/CompanyRoleType";
import aktLogo from "../../../gfx/akt-logo.svg";
import brakarLogo from "../../../gfx/brakar-logo.png";
import ruterLogo from "../../../gfx/ruterbedrift-logo.svg";
import { Tenant, getTenant } from "../../../types/Tenant";
import DesktopMenu from "./DesktopMenu";
import DropDownMenu from "./DropDownMenu";
import "./Header.scss";
import MobileMenu from "./MobileMenu";
import SignInButton from "./SignInButton";
import SubMenu from "./SubMenu";
import { headerLanguageResource } from "./languageResource";
import { MenuState, getMenuItems, initalMenuState } from "./menuState";

const Header: React.FC = () => {
  const language = useLanguageResource(headerLanguageResource);
  const authContext = useAuthContext();
  const ticketRequestContext = useTicketRequestContext();
  const env = getEnvironment();
  const tenant = getTenant();

  const allowTicketRequest = ticketRequestContext.state === "ticket-request-allowed";
  const requestCount = ticketRequestContext.state === "loading" || ticketRequestContext.state === "ticket-request-allowed" ? ticketRequestContext.requests.length : 0;

  const [menu, setMenu] = useState<MenuState>({
    items: authContext.authenticated ? getMenuItems(authContext.userData.userRights, authContext.userData.selectedCompany.allAgreements, authContext.userData.selectedCompany.roles, tenant, env) : (getMenuItems([], [], [], tenant, env, allowTicketRequest, requestCount)),
    showAccountMenu: false,
    showMobileMenu: false,
    openItems: [],
  });


  const tenantClass = (() => {
    switch (tenant) {
      case Tenant.Brakar:
        return "brakar";
      case Tenant.Ruter:
        return "ruter";
      case Tenant.Akt:
        return "akt";
    }
  })();

  const logo = (() => {
    switch (tenant) {
      case Tenant.Brakar:
        return brakarLogo;
      case Tenant.Ruter:
        return ruterLogo;
      case Tenant.Akt:
        return aktLogo;
    }
  })();

  const showRegisterButton = tenant === Tenant.Ruter && !authContext.authenticated;

  const userData = authContext.authenticated && authContext.userData;

  useEffect(() => {
    if (userData) {
      const roles = userData.selectedCompany.roles.map(parseCompanyRoleType);
      const filteredRoles: CompanyRoleType[] = roles.filter(x => x !== "UNKNOWN") as CompanyRoleType[];

      setMenu((previousMenu) => ({
        ...previousMenu,
        items: getMenuItems(userData.userRights, userData.selectedCompany.allAgreements, filteredRoles, tenant, env, allowTicketRequest, requestCount),
      }),
      );
    } else {
      setMenu(initalMenuState);
    }


  }, [tenant, userData, allowTicketRequest, requestCount, env]);

  const hideAccountMenu = () => {
    setMenu((state) => ({
      ...state,
      showAccountMenu: false,
    }));
  };

  const showAccountMenu = () => {
    setMenu((state) => ({
      ...state,
      showAccountMenu: true,
      showMobileMenu: false,
    }));
  };

  const hideMobileMenu = () => {
    setMenu((state) => ({
      ...state,
      showMobileMenu: false,
    }));
  };

  const showMobileMenu = () => {
    setMenu((state) => ({
      ...state,
      showAccountMenu: false,
      showMobileMenu: true,
    }));
  };

  const closeMenuItem = (id: string) => {
    setMenu((state) => ({
      ...state,
      openItems: state.openItems.filter(x => x !== id),
    }));
  };

  const expandMenuItem = (id: string) => {
    setMenu((state) => ({
      ...state,
      openItems: [...state.openItems, id],
    }));
  };

  const RegisterButton = () => (
    <Link to="/register">
      <button className="components-app-header-signinbutton" data-test-topmenu-id="register" type="button">
        <div>{language.register}</div>
      </button>
    </Link>
  );

  return (
    <header>
      <div className={`top-menu ${tenantClass}`}>
        <Container width="l">
          <Link to="/" className="logo" data-test-id="header-logo">
            <img src={logo} alt="" />
          </Link>
          <DesktopMenu menu={menu} />
          {authContext.authenticated && <DropDownMenu menu={menu} hideAccountMenu={hideAccountMenu} showAccountMenu={showAccountMenu} />}
          {showRegisterButton && <RegisterButton />}
          {!authContext.authenticated && <SignInButton onClick={() => authContext.login()} text={language.logIn} />}
          <MobileMenu menu={menu} closeMenuItem={closeMenuItem} expandMenuItem={expandMenuItem} hideMobileMenu={hideMobileMenu} showMobileMenu={showMobileMenu} />
        </Container>
      </div>
      <SubMenu menu={menu} />
    </header>
  );
};
export default Header;
