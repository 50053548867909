import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface FreeTicketTravelCardLanguageResource {
  title: string;
  cardNumber: string;
  yes: string;
  employeeId: string;
  status: string;
  confirmChangeToCancelled: string;
  deactivateTitle: string;
  createdTitle: string;
  unknown: string;
  company: string;
  department: string;
  fromDate: string;
  fromZone: string;
  toZone: string;
  profile: string;
  birthDate: string;
  createRequested: string;
  cancelRequested: string;
  editButton: string;
  deleteButton: string;
}

export const freeTicketTravelCardLanguageResource: LanguageResource<FreeTicketTravelCardLanguageResource> = {
  nb: {
    title: "Reisekort",
    cardNumber: "Kortnummer",
    yes: "Ja",
    employeeId: "Ansattnr.",
    status: "Status",
    confirmChangeToCancelled: 'Er du sikker på at du ønsker å endre status for reisekort med kortnummer: "{cardnumber}" til sperret?',
    deactivateTitle: "Endre status for reisekort til sperret",
    createdTitle: "Endre status for reisekort til opprettet",
    unknown: "Ukjent",
    company: "Bedrift",
    department: "Avdeling",
    fromDate: "Fra dato",
    fromZone: "Fra zone",
    toZone: "Til zone",
    profile: "Profil",
    birthDate: "Fødselsdato",
    createRequested: "Bestilt",
    cancelRequested: "Avsluttet",
    deleteButton: "Slett reisekort",
    editButton: "Endre reisekort",
  },
  en: {
    title: "Reisekort",
    cardNumber: "Card number",
    yes: "Yes",
    employeeId: "Employee no.",
    status: "Status",
    confirmChangeToCancelled:
      'Are you sure that you want to change the status of the travel card with card number: "{cardnumber}" to cancelled?',
    deactivateTitle: "Change status of travel card to cancelled",
    createdTitle: "Change status of travel card to created",
    unknown: "Unknown",
    company: "Company",
    department: "Department",
    fromDate: "From date",
    fromZone: "From zone",
    toZone: "to zone",
    profile: "Profile",
    birthDate: "Birth date",
    createRequested: "Ordered",
    cancelRequested: "Cancelled",
    deleteButton: "Delete travel card",
    editButton: "Edit travel card",
  },
};
