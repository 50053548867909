import { Card, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { CompanyAddresses } from "src/types/Company";
import { CompanyType } from "src/types/CompanyType";
import { ButtonLink } from "../common/buttons";
import "./InfoCard.scss";
import myPageLanguageResource from "./lang-resource";

interface Props {
  companyAddresses: CompanyAddresses
  onChangeClick: () => void;
  companyType: CompanyType;
}

const AddressesCard: React.FC<Props> = ({ companyAddresses: companyAddresses, onChangeClick, companyType }) => {
  const lang = useLanguageResource(myPageLanguageResource);

  return (
    <Card className="components-info-card">
      <div className="header">
        <h2>{lang.addresses}</h2>
        {companyType !== CompanyType.RUTER_CONTRACTOR && (<ButtonLink onClick={onChangeClick} text={lang.buttons.edit} dataTestId="change-company-button" />)}
      </div>
      <div className="body">
        <div className="info" data-test-id="address-type-bill">
          <div className="label">{lang.billAddress}</div>
          <div className="value">
            <div className="address-line" data-test-id="line1">
              {companyAddresses.bill.line1}
            </div>
            <div className="address-line" data-test-id="line2">
              {companyAddresses.bill.line2}
            </div>
            <div
              className="address-line"
              data-test-id="code-and-area"
            >{`${companyAddresses.bill.postCode} ${companyAddresses.bill.city}`}</div>
          </div>
        </div>
        <div className="info" data-test-id="address-type-post">
          <div className="label">{lang.postAddress}</div>
          <div className="value">
            <div className="address-line" data-test-id="line1">
              {companyAddresses.post.line1}
            </div>
            <div className="address-line" data-test-id="line2">
              {companyAddresses.post.line2}
            </div>
            <div
              className="address-line"
              data-test-id="code-and-area"
            >{`${companyAddresses.post.postCode} ${companyAddresses.post.city}`}</div>
          </div>
        </div>
        <div className="info" data-test-id="address-type-visit">
          <div className="label">{lang.visitAddress}</div>
          <div className="value">
            <div className="address-line" data-test-id="line1">
              {companyAddresses.visit.line1}
            </div>
            <div className="address-line" data-test-id="line2">
              {companyAddresses.visit.line2}
            </div>
            <div
              className="address-line"
              data-test-id="code-and-area"
            >{`${companyAddresses.visit.postCode} ${companyAddresses.visit.city}`}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AddressesCard;