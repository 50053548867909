import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface TravelCardSearchPageLanguageResource {
  title: string;
  productionDate: string;
  company: string
  amount: string;
  orders: string
  address: string
  status: string;
  showOrders: string;
  copyAddress: string;
  copyAddressSuccess: string;
  copyAddressFail: string
  filter: {
    date: string;
    fromDate: string;
    toDate: string
    company: string;
    clearFilter: string
  },
  statuses: {
    requested: string;
    created: string;
    deactivated: string;
    cancelled: string;
    anonymised: string;
    unknown: string;
  }
}

export const travelCardSearchPageLanguageResource: LanguageResource<TravelCardSearchPageLanguageResource> = {
  nb: {
    title: "Produksjonsliste",
    address: "Adresse",
    company: "Skole/bedrift",
    amount: "Antall",
    orders: "Reisekort",
    productionDate: "Produksjonsdato",
    status: "Status",
    showOrders: "Vis",
    copyAddress: "Kopier adresse",
    copyAddressSuccess: "Adresse er nå kopiert!",
    copyAddressFail: "Klarte ikke å kopiere addressen",
    filter: {
      date: "Dato:",
      fromDate: "Fra produksjonsdato",
      toDate: "Til produksjondato",
      company: "Skole/bedrift:",
      clearFilter: "Tøm filter",
    },
    statuses: {
      requested: "Bestilt",
      created: "Produsert",
      deactivated: "Sperret",
      cancelled: "Kansellert",
      anonymised: "Anonymisert",
      unknown: "Ukjent",
    },
  }, en: {
    address: "Address",
    company: "School/company",
    amount: "Amount",
    orders: "Travelcard",
    productionDate: "Production date",
    title: "Production list",
    status: "Status",
    showOrders: "Show",
    copyAddress: "Copy address",
    copyAddressSuccess: "Address is now compied!",
    copyAddressFail: "Failed to copy the address",
    filter: {
      date: "Date:",
      fromDate: "From production date",
      toDate: "To production date",
      company: "School/company:",
      clearFilter: "Clear filter",
    },
    statuses: {
      requested: "Requested",
      created: "Created",
      deactivated: "Deactivated",
      cancelled: "Cancelled",
      anonymised: "Anonymised",
      unknown: "Unknown",
    },
  },
};

