import { Icon, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useState } from "react";
import { Tenant, getTenant } from "src/types/Tenant";
import { TravelCardState } from "src/types/TravelCardState";
import { TicketStatus } from "src/types/freeTicketAgreement/ticketStatus";
import { FreeTicket, FreeTicketTravelCard } from "../../../common/api/freeTicketApi/freeTicket";
import { UNKNOWN } from "../../../constants";
import TicketStatusText from "../../common/text/ticketStatus/TicketStatus";

import TicketZonesField from "../../common/text/ticketZones/TicketZonesField";
import EditFreeTicketComponent from "./EditFreeTicket";
import { formatCurrencyAmount, formatDate, formatProfile, formatStringOrNull, formatTicketHolderType } from "./MobileTicket";
import "./TravelCardTicket.scss";
import { FreeTicketsFamilyLanguageResource, freeTicketsFamilyLanguageResource } from "./lang-resource";

interface Props {
  ticket: FreeTicketTravelCard;
  cancelActiveTicket: React.Dispatch<React.SetStateAction<FreeTicket | null>>;
  orderReplacementCard: React.Dispatch<React.SetStateAction<FreeTicketTravelCard | null>>;
  cancelPendingTicket: React.Dispatch<React.SetStateAction<FreeTicket | null>>;
  refetch: () => Promise<void>;
}

const getStatus = (status: TravelCardState | typeof UNKNOWN, lang: FreeTicketsFamilyLanguageResource): string => {
  if (status === TravelCardState.CREATED) {
    return lang.statuses.created;
  }
  if (status === TravelCardState.DEACTIVATED) {
    return lang.statuses.deactivated;
  }
  if (status === TravelCardState.REQUESTED) {
    return lang.statuses.requested;
  }
  if (status === TravelCardState.CANCELLED) {
    return lang.statuses.cancelled;
  }
  if (status === TravelCardState.ANONYMISED) {
    return lang.statuses.anonymised;
  }
  return lang.unknown;
};

const TravelCardTicket: React.FC<Props> = ({ ticket, cancelActiveTicket, orderReplacementCard, cancelPendingTicket, refetch }) => {
  const lang = useLanguageResource(freeTicketsFamilyLanguageResource);
  const tenant = getTenant();
  const travelCardStateIsCreated = ticket.travelCardState === TravelCardState.CREATED;
  const disableCancelActiveTicketButton = Boolean(ticket.travelCardState) && (!travelCardStateIsCreated || Boolean(ticket.expirationDate));
  const disableReplacementCardButton = !travelCardStateIsCreated || tenant === Tenant.Akt;

  const showCancelPendingTicketButton = ticket.travelCardState ? ticket.travelCardState === TravelCardState.REQUESTED : ticket.status === TicketStatus.pending;
  const showCancelActiveTicketButton = !showCancelPendingTicketButton;

  const fullName = `${ticket.firstName} ${ticket.lastName}`;
  const [showEditTicket, setShowEditTicket] = useState(false);

  const enableEditButton = (ticket.travelCardState === TravelCardState.CREATED || ticket.travelCardState === TravelCardState.REQUESTED) || !ticket.travelCardState;

  return (
    <div className="components-agreement-freeticket-travelcard" data-test-id="travel-card-ticket">
      <div className={`status ${ticket.status}`} />
      <div className="employee-name">
        <div className="value" title={fullName} data-test-id="full-name">
          {fullName}
        </div>
      </div>
      <div className="info">
        <div className="row">
          <div className="label">{lang.relationType}</div>
          <div className="value" data-test-id="relation">
            {formatTicketHolderType(ticket.ticketHolderType, lang)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.status}</div>
          <div className="value" data-test-id="ticket-status">
            <TicketStatusText status={ticket.status} />
          </div>
        </div>
        {ticket.travelCardState && (
          <div className="row">
            <div className="label">{lang.productionStatus}</div>
            <div className="value" data-test-id="travel-card-state">
              {getStatus(ticket.travelCardState, lang)}
            </div>
          </div>
        )}
        <div className="row">
          <div className="label">{lang.travelCardNumber}</div>
          <div className="value" data-test-id="travel-card-number">
            {formatStringOrNull(ticket.travelCardNumber)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.birthDate}</div>
          <div className="value" data-test-id="birth-date">
            {formatDate(ticket.employeeBirthDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.manufacturedAt}</div>
          <div className="value" data-test-id="manufactured-at">
            {formatDate(ticket.manufacturedAt)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validFrom}</div>
          <div className="value" data-test-id="valid-from">
            {formatDate(ticket.startDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.validTo}</div>
          <div className="value" data-test-id="valid-to">
            {formatDate(ticket.expirationDate)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.pricePerMonth}</div>
          <div className="value" data-test-id="price-per-month">
            {formatCurrencyAmount(ticket, ticket.pricePerMonth)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.taxAmount}</div>
          <div className="value" data-test-id="tax-amount">
            {formatCurrencyAmount(ticket, ticket.taxAmount)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.zones}</div>
          <div className="value" data-test-id="zones">
            <TicketZonesField
              allZones={ticket.allZones}
              zoneType="ZoneToZone"
              nrOfZones={ticket.nrOfZones || 0}
              zoneFrom={ticket.zoneFrom}
              zoneTo={ticket.zoneTo}
            />
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.profile}</div>
          <div className="value" data-test-id="profile">
            {formatProfile(ticket.profile, lang)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.invoiceRef}</div>
          <div className="value" data-test-id="invoice-reference">
            {formatStringOrNull(ticket.invoiceReference)}
          </div>
        </div>
      </div>
      <div className="actions">
        <button type="button" disabled={!enableEditButton} onClick={() => setShowEditTicket(true)} data-test-id="edit-ticket-button">
          <Icon variant="PencilIcon" size="small" />
          <span>{lang.editTicket}</span>
        </button>
        <button type="button" disabled={disableReplacementCardButton} onClick={() => orderReplacementCard(ticket)}>
          <Icon variant="ArrowsSwapIcon" size="small" />
          <span>{lang.orderReplacementCard}</span>
        </button>

        {showCancelActiveTicketButton && (
          <button
            type="button"
            disabled={disableCancelActiveTicketButton}
            onClick={() => cancelActiveTicket(ticket)}
            data-test-id="cancel-active-ticket-button"
          >
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelActiveTicket}</span>
          </button>
        )}
        {showCancelPendingTicketButton && (
          <button type="button" onClick={() => cancelPendingTicket(ticket)} data-test-id="cancel-pending-ticket-button">
            <Icon variant="CrossIcon" size="small" />
            <span>{lang.cancelPendingTicket}</span>
          </button>
        )}
      </div>
      {showEditTicket && (
        <EditFreeTicketComponent
          submitted={() => {
            setShowEditTicket(false);
            refetch();
          }}
          ticket={ticket}
          hide={() => setShowEditTicket(false)}
        />
      )}
    </div>
  );
};

export default TravelCardTicket;
