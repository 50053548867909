import React from "react";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { Agreement } from "src/types/Company";
import AgreementTypeBadge from "../../badges/agreement/AgreementTypeBadge";
import "./AgreementTypeColumn.scss";

interface Props {
  agreements: Array<Agreement>
}

const sortOrder = [
  AgreementSubTypeId.FREETICKET,
  AgreementSubTypeId.SUBSCRIPTIONS,
  AgreementSubTypeId.TICKETOFFICE,
  AgreementSubTypeId.SCHOOLTICKET,
  AgreementSubTypeId.TRAVELCARD_FIXED_PRICE,
  AgreementSubTypeId.TRAVELCARD_SUBSCRIPTION,
  AgreementSubTypeId.NO_SUBSCRIPTIONS,
  AgreementSubTypeId.OTHER,
];

const AgreementTypeColumn: React.FC<Props> = ({ agreements }) => {
  if (agreements.length === 0) {
    return <AgreementTypeBadge />;
  }

  const sortedAgreement = [...agreements].sort((a: Agreement, b: Agreement) => {
    return sortOrder.indexOf(a.subTypeId) - sortOrder.indexOf(b.subTypeId);
  });

  return (
    <div className="agreement-type-column-badges">
      {sortedAgreement.map(x => <AgreementTypeBadge key={x.id} agreementSubTypeId={x.subTypeId} />)}
    </div>
  );
};

export default AgreementTypeColumn;