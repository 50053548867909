import React from "react";
import "./PageHeader.scss";

interface Props {
  text: string;
  loading?: boolean;
}

const PageHeaderComponent: React.FC<Props> = ({ text, loading = true }) => {
  if (loading) {
    return <h1 className="skeleton">fads</h1>;
  }

  return <h1>{text}</h1>;
};

export default PageHeaderComponent;
