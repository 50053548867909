import { mapCustomerOrder, mapCustomerOrders } from "./CustomerOrder";
import { InvoicePdf, mapInvoice } from "./Invoice";
import { RefundInvoiceLineContract } from "./refundInvoiceLineContract";

const invoiceApi = {
  api: {
    invoice: {
      getByInvoiceId: (invoiceId: string) => ({
        method: "get" as const,
        url: `/api/invoice/by-invoice-id/${invoiceId}`,
        mapper: mapCustomerOrder,
      }),
      getByCompanyId: {
        ruter: (companyId: string) => ({
          method: "get" as const,
          url: `/api/invoice/by-company-id/${companyId}`,
          mapper: mapCustomerOrders,
        }),
      },
      getPdfLinkById: (invoiceId: string) => ({
        method: "get" as const,
        url: `/api/invoice/pdf/${invoiceId}`,
        mapper: (json: InvoicePdf): InvoicePdf => json,
      }),
    },
  },
  invoiceAdminApi: {
    invoiceBase: {
      byInvoiceId: {
        get: (id: string) => ({
          method: "get" as const,
          url: `/invoice-admin-api/invoice-base/${id}`,
          mapper: mapCustomerOrder,
        }),
      },
      byCompanyId: {
        get: (companyId: string) => ({
          method: "get" as const,
          url: `/invoice-admin-api/invoice-base/by-company-id/${companyId}`,
          mapper: mapCustomerOrders,
        }),
      },
    },
    invoice: {
      byInvoiceId: {
        get: (id: string) => ({
          method: "get" as const,
          url: `/invoice-admin-api/invoice/${id}`,
          mapper: mapInvoice,
        }),
      },
      id: (id: string) => ({
        postRefundLine: (invoiceId: string, body: RefundInvoiceLineContract) => ({
          method: "post" as const,
          url: `/invoice-admin-api/invoice/${id}/refund-line/${invoiceId}`,
          body,
        }),
      }),
    },
  },
};

export default invoiceApi;