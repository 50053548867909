import { LanguageResource, TwoParamsLanguageResourceTemplate, languageResourceBuilder } from "@ruter-as/web-components-and-tools";

interface CreateTicketLanguageResource {
  title: string;
  orderTicket: string;
  acceptPrivay: string;
  addOnTitle: string;
  acceptOnBehalfOfAdministrator: string;
  readPolicies: string;
  ticketOrdered: string;
  gapToSmall: TwoParamsLanguageResourceTemplate;
  acceptOtto: string;
  errorExistingOpenTicket: string;
}


export const createTicketLanguageResource: LanguageResource<CreateTicketLanguageResource> = {
  nb: {
    title: "Bestill billett",
    orderTicket: "Bestill billett",
    acceptPrivay:
      "Jeg bekrefter at den ansatte har fått informasjon om behandling av personopplysninger og godkjenner Ruters vilkår, " +
      "samt at jeg som administrator har fått tillatelse til å inngå avtale på hans / hennes vegne.",
    acceptOnBehalfOfAdministrator: "Jeg aksepterer Ruters vilkår på vegne av meg selv som administrator.",
    addOnTitle: "Tilleggstjenester",
    readPolicies: "Les Ruters vilkår",
    ticketOrdered: "Billett bestilt!",
    acceptOtto: "Jeg bekrefter at den ansatte har fått informasjon om at personopplysninger overføres til Otto",
    gapToSmall:
      languageResourceBuilder.twoParamTemplate("Bestilling av ny billett ble avvist, fordi det finnes en billett med sluttdato $p1$. " +
        "I henhold til reglene om karantenetid på 90 dager, kan du ikke bestille en billett med startdato før $p2$"),
    errorExistingOpenTicket: "Det eksisterer allerede en gyldig eller ventende billett på dette telefonnummeret.",
  }, en: {
    title: "Order new ticket",
    orderTicket: "Order new ticket",
    acceptPrivay:
      "I hereby confirm that the employee has accepted ruters terms and policies, and that I have been given consent by the employee to order a ticket on his/hers behalf",
    acceptOnBehalfOfAdministrator: "I accept Ruters policies.",
    addOnTitle: "Additional services",
    readPolicies: "Read Ruters policies",
    ticketOrdered: "Ticket ordered!",
    acceptOtto: "I hereby confirm that the employee has been informed that personal data will be transferred to Otto",
    gapToSmall: languageResourceBuilder.twoParamTemplate("Could not order a new ticket. A ticket that has expired on $p1$ already exists, according to our rules, the first available start date for a new ticket is $p2$"),
    errorExistingOpenTicket: "There already exists a pending or active ticket for this phonenumber.",
  },
};