import { SearchResult } from "@ruter-as/billettluke-frontend";

export interface TicketRequestJson {
  id: string;
  requestedAt: string;
  state: string;
  phoneCountryCode: string;
  phone: string;
  agreementNumber: string;
  employeeId: string;
  lastName: string;
  firstName: string;
  startDate: string;
  zoneFrom: string;
  zoneTo: string;
  zoneVia: string;
  pricePerMonth: number;
  nrOfZones: number;
  zones: string[];
  expired: boolean;
}

export const mapTicketRequestSearchResult = (json: TicketRequestJson[]): SearchResult<TicketRequestJson> => {
  return { matches: json, totalEntries: json.length };
};

export const getValidTicketRequestJson = (changes?: Partial<TicketRequestJson>): TicketRequestJson => ({
  id: "123456",
  requestedAt: "2020-12-31T23:00:00Z",
  state: "NOT_PROCESSED",
  phoneCountryCode: "+47",
  phone: "93864800",
  agreementNumber: "1-3369898706",
  employeeId: "kiha",
  lastName: "hansen",
  firstName: "kim",
  startDate: "2020-12-31T23:00:00Z",
  zoneFrom: "1",
  zoneTo: "1",
  zoneVia: "",
  pricePerMonth: 320,
  nrOfZones: 1,
  zones: ["1"],
  expired: false,
  ...changes,
});
