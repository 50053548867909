import { Button } from "@ruter-as/web-components-and-tools";
import React from "react";

interface Props {
  text: string;
  onClick: () => void;
  dataTestId: string;
}

const WhiteButton: React.FC<Props> = ({ text, onClick, dataTestId }) => {
  if (onClick) {
    return <Button text={text} type="button" variant="cancel" onClick={onClick} data-test-id={dataTestId} />;
  }
  return null;
};

export default WhiteButton;
