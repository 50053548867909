import { Button, ButtonGroup, Card, Container, Dropdown, FormGroup, Label, Message, Modal, ProgressRadial, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { TravelCardState } from "src/types/TravelCardState";
import { FreeTicket, mapFreeTicketSearchResult } from "../../../common/api/freeTicketApi/freeTicket";
import createAndDownloadCSV from "../../../common/csvFileCreator";
import readCsvFile from "../../../common/csvFileReader";
import { getExpiryDates } from "../../../common/expiryDate";
import formatter from "../../../common/formatter";
import successAlert from "../../../common/toastr";
import { SearchResult } from "../../../types/SearchResult";
import { TicketStatus } from "../../../types/freeTicketAgreement/ticketStatus";
import MediaType from "../../../types/mediaType";
import { CancelButton } from "../../common/buttons";
import PageHeaderComponent from "../../common/layout/PageHeader";
import "./MassEndDate.scss";
import { massEndDateLanguageResource } from "./lang-resource";

const ALL_AGREEMENTS_VALUE = "all";

interface InvalidFileRow {
  type: "invalid";
  employeeNumber: string;
  birthDate: string;
}

interface ValidRow {
  type: "valid";
  employeeNumber: string;
  birthDate: Date;
}

type Row = InvalidFileRow | ValidRow;

interface File {
  name: string;
  rows: Row[];
}

const MassEndDate: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;

  if (!companyId) {
    throw new Error("company should be set");
  }

  const [showModal, setShowModal] = useState(false);
  const [ticketCancellationNumber, setTicketCancellationNumber] = useState<number>(0);
  const [tickets, setTickets] = useState<undefined | SearchResult<FreeTicket>>(undefined);
  const lang = useLanguageResource(massEndDateLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const agreements = authContext.userData.selectedCompany.freeTicketAgreements;
  const formMethods = useForm<FormData>();
  const [file, setFile] = useState<File | null>(null);

  const findInvoiceRef = useCallback(
    (agreementNumberInput: string) => {
      const agreementFromInput = agreements.find((x) => x.id === agreementNumberInput);
      return agreementFromInput?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumberInput})`;
    },
    [lang.missingDepartmentName, agreements],
  );

  let agreementsOptions = agreements.map((x) => ({ value: x.id, text: findInvoiceRef(x.id) }));
  agreementsOptions = [{ value: ALL_AGREEMENTS_VALUE, text: lang.allDepartments }, ...agreementsOptions];
  const dates = getExpiryDates(32, formLang.today);

  const [endDate, setEndDate] = useState(dates[0].date);
  const [agreement, setAgreement] = useState(agreementsOptions[0].value);

  const fetch = useCallback(async () => {
    const response = await apiClient.get(`/freeticket-api/ticket/by-company-id/${companyId}`, mapFreeTicketSearchResult);
    if (response.type === "success") {
      setTickets(response.result);
    } else {
      setTickets(() => {
        throw response.error;
      });
    }

  }, [companyId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!tickets) {
    return null;
  }

  const activeAndPendingTicketsFilter = (freeTicket: FreeTicket): boolean => {
    return freeTicket.status === TicketStatus.active || freeTicket.status === TicketStatus.pending;
  };

  const departmentFilter = (freeTicket: FreeTicket): boolean => {
    if (agreement === ALL_AGREEMENTS_VALUE) {
      return true;
    } else {
      return freeTicket.agreementNumber === agreement;
    }
  };

  const fileFilter = (freeTicket: FreeTicket): boolean => {
    if (!file) {
      return true;
    }
    const employeeIdMatch = file.rows.some(
      (x) => x.type === "valid" && x.employeeNumber.trim().toLocaleLowerCase() === freeTicket.employeeId.trim().toLocaleLowerCase(),
    );
    const birthDateMatch = file.rows.some((x) => {
      if (x.type === "invalid") {
        return false;
      }

      const freeTicketBirthDate = moment(freeTicket.employeeBirthDate).format("DD.MM.YYYY");
      const fileRowBirthDate = moment(x.birthDate).format("DD.MM.YYYY");
      return freeTicketBirthDate === fileRowBirthDate;
    });

    return employeeIdMatch && birthDateMatch;
  };

  const endDateFilter = (freeTicket: FreeTicket): boolean => {
    return freeTicket.expirationDate === null || freeTicket.expirationDate.getTime() > moment(endDate).endOf("day").toDate().getTime();
  };

  const activeAndPendingTicketsAfterEndDate = tickets.matches.filter(activeAndPendingTicketsFilter).filter(endDateFilter);
  const activeAndPendingTicketsAfterEndDateAndDepartment = activeAndPendingTicketsAfterEndDate.filter(departmentFilter);
  const ticketsMatchingAllFilters = activeAndPendingTicketsAfterEndDateAndDepartment.filter(fileFilter);

  const cancelTickets = async () => {
    let currentTicketNumber = 1;
    for (const ticket of ticketsMatchingAllFilters) {
      setTicketCancellationNumber(currentTicketNumber);

      if (ticket.mediaType === MediaType.TRAVEL_CARD && ticket.travelCardState === TravelCardState.REQUESTED) {
        const response = await apiClient.delete(`/freeticket-api/ticket/${ticket.id}`);
        if (response.error) {
          setTicketCancellationNumber(() => {
            throw response.error;
          });
        }
      } else {
        const response = await apiClient.put(`/freeticket-api/ticket/${ticket.id}/expiry/${endDate.toISOString()}`);
        if (response.error) {
          setTicketCancellationNumber(() => {
            throw response.error;
          });
        }
      }

      currentTicketNumber += 1;
    }
    successAlert(lang.ticketsCancelSuccessToastr.replace("${ticketCount}", ticketsMatchingAllFilters.length.toString()));
    setFile(null);
    await fetch();

    setTicketCancellationNumber(0);
    setShowModal(false);
  };

  const showNoTicketsMessage = ticketsMatchingAllFilters.length === 0;

  const replaceDateInText = (template: string) => {
    return template.replace("${endDate}", formatter.date.shortDate(endDate));
  };

  const renderSummaryItem = (text: string, value: number, dataTestId: string, highLightPositiveNumber: boolean, bold: boolean) => {
    const className = `row ${highLightPositiveNumber && value > 0 ? "red " : ""} ${bold ? "bold" : ""}`;

    return (
      <div className={className}>
        <div className="label">{text}</div>
        <div className="value" data-test-id={dataTestId}>
          {value}
        </div>
      </div>
    );
  };

  const readFile = async (files: FileList | null) => {
    const mapRow = (row: string[]): Row => {
      let valid = true;

      if (row[0].trim().length === 0) {
        valid = false;
      }
      const parsedDate = moment(row[1]?.trim(), "DD.MM.YYYY");
      if (!parsedDate.isValid()) {
        valid = false;
      }

      if (valid) {
        return {
          type: "valid",
          employeeNumber: row[0],
          birthDate: parsedDate.toDate(),
        };
      } else {
        return {
          type: "invalid",
          employeeNumber: row[0],
          birthDate: row[1],
        };
      }
    };

    const result = await readCsvFile(files);
    setFile({
      name: result.name,
      rows: result.data.map(mapRow),
    });
  };

  const downloadExample = () => {
    createAndDownloadCSV(`${lang.exampleFileName}.csv`, [lang.employeeNumber, lang.birthDate], [["NO123", "02.04.1984"]]);
  };

  const fileRowCount = file ? file.rows.length : 0;
  const fileInvalidRowCount = file ? file.rows.filter((x) => x.type === "invalid").length : 0;
  const fileValidRowCount = fileRowCount - fileInvalidRowCount;
  const fileUnmatchedRows = fileValidRowCount - ticketsMatchingAllFilters.length;

  return (
    <Container width="s" className="components-freeticketagreement-massenddate" data-test-id="free-ticket-mass-end-date-page">
      <PageHeaderComponent text={lang.title} loading={false} />
      <FormProvider {...formMethods}>
        <div className="narrow">
          <FormGroup>
            <Label text={formLang.endDate}>
              <Dropdown
                name="endDate"
                value={endDate.toISOString()}
                onChange={(e) => setEndDate(new Date(e.target.value))}
              >
                {dates.map((d) => (
                  <option value={d.date.toISOString()} key={d.value}>
                    {d.text}
                  </option>
                ))}
              </Dropdown>
            </Label>
          </FormGroup>

          <Card className="summary" data-test-id="end-date-filter-tickets-calculation">
            {renderSummaryItem(lang.freeTicketCount, tickets.matches.length, "all-tickets-count", false, false)}
            {renderSummaryItem(
              replaceDateInText(lang.ticketsFilteredByEndDate),
              tickets.matches.length - activeAndPendingTicketsAfterEndDate.length,
              "removed-by-filter-count",
              true,
              false,
            )}
            {renderSummaryItem(
              lang.ticketsEligibleCount,
              activeAndPendingTicketsAfterEndDate.length,
              "eligible-tickets-count",
              false,
              true,
            )}
          </Card>
          <FormGroup>
            <Label text={lang.agreementFilter}>
              <Dropdown
                name="agreement"
                value={agreement}
                onChange={(e) => setAgreement(e.target.value)}
              >
                {agreementsOptions.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.text}
                  </option>
                ))}
              </Dropdown>
            </Label>
          </FormGroup>

          <Card className="summary" data-test-id="department-filter-tickets-calculation">
            {renderSummaryItem(lang.freeTicketCount, activeAndPendingTicketsAfterEndDate.length, "all-tickets-count", false, false)}
            {renderSummaryItem(
              lang.ticketsFilteredByDepartment,
              activeAndPendingTicketsAfterEndDate.length - activeAndPendingTicketsAfterEndDateAndDepartment.length,
              "removed-by-filter-count",
              true,
              false,
            )}
            {renderSummaryItem(
              lang.ticketsEligibleCount,
              activeAndPendingTicketsAfterEndDateAndDepartment.length,
              "eligible-tickets-count",
              false,
              true,
            )}
          </Card>
        </div>
      </FormProvider>
      <div>
        <div className="file-header">{lang.fileHeader}</div>
        <div className="file-description">
          <span>{lang.fileDescriptionPreLink}</span>
          <button className="file-example" data-test-id="download-example" onClick={downloadExample}>
            {lang.fileDesciptionLink}
          </button>
          <span>{lang.fileDescriptionPostLink}</span>
        </div>

        {!file && (
          <>
            <input id="file" type="file" value="" onChange={(event) => readFile(event.target.files)} />
            <label htmlFor="file">{lang.addFromCsv}</label>
          </>
        )}

        {file && (
          <Card className="summary" data-test-id="file-filter-tickets-calculation">
            <div className="header-row">
              <div className="file-name" data-test-id="file-name">
                {file.name}
              </div>
              <button className="remove-file" data-test-id="remove-file-button" onClick={() => setFile(null)} type="button">
                {lang.remove}
              </button>
            </div>
            {renderSummaryItem(lang.rowInFileCount, fileRowCount, "row-count", false, false)}
            {renderSummaryItem(lang.invalidRowCount, fileInvalidRowCount, "invalid-row-count", true, false)}
            {renderSummaryItem(lang.validRowCount, fileValidRowCount, "valid-row-count", false, false)}
            {renderSummaryItem(lang.unmatcedRows, fileUnmatchedRows, "unmatched-row-count", true, false)}
            {renderSummaryItem(lang.ticketsEligibleCount, ticketsMatchingAllFilters.length, "eligible-tickets-count", false, true)}
          </Card>
        )}
      </div>
      {!showNoTicketsMessage && (
        <Button
          variant="danger"
          type="button"
          text={lang.cancelTickets}
          data-test-id="open-confirm-modal-button"
          onClick={() => setShowModal(true)}
        />
      )}
      {showNoTicketsMessage && (
        <Message skin="warning" data-test-id="no-tickets-for-agreement-and-date-message">
          <p>{lang.noTickets}</p>
        </Message>
      )}

      <Modal
        isOpen={showModal}
        title={lang.confirmModalTitle}
        handleClose={() => setShowModal(false)}
        data-test-id="confirm-modal"
        className="mass-end-date-confirm-modal"
      >
        {Boolean(ticketCancellationNumber) && (
          <p data-test-id="confirm-status">
            {lang.confirmStatus
              .replace("${ticketNumber}", ticketCancellationNumber.toString())
              .replace("${totalTickets}", ticketsMatchingAllFilters.length.toString())}
            <ProgressRadial
              type="Detirmate"
              value={ticketCancellationNumber}
              min={0}
              max={ticketsMatchingAllFilters.length}
            />
          </p>
        )}
        {!ticketCancellationNumber && (
          <p data-test-id="confirm-text">{lang.confirmModalText.replace("${number}", ticketsMatchingAllFilters.length.toString())}</p>
        )}
        {!ticketCancellationNumber && (
          <ButtonGroup>
            <Button variant="primary" type="button" text={formLang.yes} data-test-id="confirm-button" onClick={cancelTickets} />
            <CancelButton onClick={() => setShowModal(false)} data-test-id="cancel-button" />
          </ButtonGroup>
        )}
      </Modal>
    </Container>
  );
};

export default MassEndDate;
