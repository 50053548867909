import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface UserSetupErrorLanguageResource {
  title: string;
  text: string;
  text2: string;
  clickForHomePage: string;
  clickForContact: string;
}

export const userSetupErrorLanguageResource: LanguageResource<UserSetupErrorLanguageResource> = {
  nb: {
    title: "Vi klarte ikke å hente informasjon om brukeren din!",
    text: "Det kan skyldes at brukeren din er feil satt opp, eller at tjenesten er midlertidig nede. ",
    text2: "Forsøk på nytt litt senere.",
    clickForHomePage: "Gå tilbake til forsiden",
    clickForContact: "Kontakt kundestøtte",
  },
  en: {
    title: "We could not fetch information about your user!",
    text: "Your user might be set up incorrectly, or the service may be temporarily down.",
    text2: "Try again later.",
    clickForHomePage: "Go back to homepage",
    clickForContact: "Contact customer support",
  },
};