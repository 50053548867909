import { AKTProducts } from "@ruter-as/billettluke-frontend";
import { Tenant } from "../Tenant";

const AktProductBlacklist = [
  AKTProducts["UiA-billetten-V2"],
];

export const getBlacklistedProducts = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Akt:
      return AktProductBlacklist;
    default:
      throw new Error(`Blacklist does not exist for Tenant ${tenant}`);
  }
};
