import { Button } from "@ruter-as/web-components-and-tools";
import React from "react";

interface Props {
  text: string;
  submitting: boolean;
  "data-test-id"?: string;
  disabled?: boolean;
}

const SubmitButton: React.FC<Props> = ({ text, submitting, disabled, "data-test-id": dataTestId = "confirm-button" }) => {
  return <Button variant="primary" loading={submitting} text={text} type="submit" disabled={disabled} data-test-id={dataTestId} />;
};

export default SubmitButton;
