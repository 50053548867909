const createAndDownloadCSV = (fileName: string, headers: string[], data: string[][], footer?: string[][]): void => {
  let csvFile = "data:text/csv;charset=utf-8,\uFEFF";
  const headerLine = headers.join(";");
  csvFile += `${headerLine}\r\n`;

  const lines: string[] = [];

  data.forEach((rowValues, index) => {
    if (rowValues.length !== headers.length) {
      throw new Error(`rowValues.length(${rowValues.length}) on row with index=${index} does not equal headers.length(${headers.length})`);
    }

    lines.push(rowValues.join(";"));
  });

  csvFile += lines.join("\r\n");

  if (footer) {
    csvFile += `\r\n\r\n${footer.join("\r\n")}`;
  }

  const file = encodeURI(csvFile);
  const link = document.createElement("a");
  link.setAttribute("href", file);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const showNumberAsText = (value?: number | string) => {
  if (!value) return "";
  return `"=""${value}"""`;
};

export default createAndDownloadCSV;
