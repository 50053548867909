import moment from "moment";

export const mapDateFromIsoOrNull = (dateString: string | null | undefined): Date | null => {
  if (!dateString || !moment(dateString).isValid()) {
    return null;
  }
  return moment(dateString).toDate();
};

export const mapDateFromIso = (dateString: string): Date => {
  if (!dateString || !moment(dateString).isValid()) {
    throw new Error(`"${dateString}" is not a valid date"`);
  }

  return moment(dateString).toDate();
};

export const mapDateOnlyOrNull = (dateString: string | null | undefined): Date | null => {
  if (!dateString || !moment(dateString, "YYYY-MM-DD").isValid()) {
    return null;
  }
  return moment(dateString, "YYYY-MM-DD").toDate();
};

export const mapDateOnly = (dateString: string): Date => {
  if (!dateString || !moment(dateString, "YYYY-MM-DD").isValid()) {
    throw new Error(`"${dateString}" is not a valid date"`);
  }

  return moment(dateString, "YYYY-MM-DD").toDate();
};
