interface PeriodJson {
  year: number;
  month: number;
}

interface AgreementWithPeriodJson {
  agreement: string;
  dates: PeriodJson[];
}

export interface TaxBaseDatesJson {
  byCompany: PeriodJson[];
  byAgreement: AgreementWithPeriodJson[];
}

export interface Period {
  year: number;
  month: number;
}

interface TaxBaseDates {
  byCompany: Period[];
  byAgreement: {
    agreement: string;
    dates: Period[];
  }[];
}

const mapMonthAndYear = (json: PeriodJson): Period => ({
  year: json.year,
  month: json.month,
});

export const mapTaxBaseDates = (json: TaxBaseDatesJson): TaxBaseDates => ({
  byCompany: json.byCompany.map(mapMonthAndYear),
  byAgreement: json.byAgreement.map((x) => ({
    agreement: x.agreement,
    dates: x.dates.map(mapMonthAndYear),
  })),
});

export const getValidPeriodJson = (changes?: Partial<PeriodJson>): PeriodJson => ({
  year: 2020,
  month: 12,
  ...changes,
});

export const getValidAgreementWithPeriodJson = (changes?: Partial<AgreementWithPeriodJson>): AgreementWithPeriodJson => ({
  agreement: "1-1234566",
  dates: [getValidPeriodJson()],
  ...changes,
});

export const getValidTaxBaseDatesJson = (
  agreementWithPeriods: AgreementWithPeriodJson[] = [{ agreement: "1-2345", dates: [getValidPeriodJson()] }],
): TaxBaseDatesJson => {
  const allPeriods = agreementWithPeriods.reduce<PeriodJson[]>(
    (previousValue, currentValue) => [...previousValue, ...currentValue.dates],
    [],
  );
  const uniquePeriods = [] as PeriodJson[];

  allPeriods.forEach((x) => {
    if (!uniquePeriods.some((y) => y.year === x.year && y.month === x.month)) {
      uniquePeriods.push(x);
    }
  });

  return {
    byCompany: uniquePeriods,
    byAgreement: agreementWithPeriods,
  };
};
