import {
  ButtonGroup,
  Container,
  DateFormInput,
  FieldValidationError,
  FormGroup,
  MediaFormInput,
  NumberFormInput,
  PhoneFormInput,
  TextFormInput,
  apiClient,
  parser,
  useLanguageResource,
} from "@ruter-as/web-components-and-tools";
import { endOfDay, isAfter, startOfDay } from "date-fns";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { hasValidAgreement } from "src/common/agreements";
import { Zone, mapZones } from "src/common/api/commonTypes/Zone";
import getEnvironment, { EnvName } from "src/common/env";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import successAlert, { failAlert } from "src/common/toastr";
import ZoneInput from "src/components/common/form-hooks/ZoneInput";
import { ALL_ZONES_FROM, ALL_ZONES_TO } from "src/constants";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend } from "../../../common/expiryDate";
import MediaType from "../../../types/mediaType";
import SchoolTicketFormData from "../../../types/schoolTicketAgreement/schoolTicketFormData";
import SchoolTicketPostContract, { SchoolTicketContractRecipient } from "../../../types/schoolTicketAgreement/schoolTicketPostContract";
import { CancelButton, SubmitButton } from "../../common/buttons";
import { splitName } from "../utils";
import schoolTicketOrderLanguageResource from "./lang-resource";

const SchoolTicketOrder = () => {
  const lang = useLanguageResource(schoolTicketOrderLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [submitting, setSubmitting] = useState(false);
  const [isDuplicateTicket, setIsDuplicateTicket] = useState(false);
  const [zones, setZones] = useState<Zone[]>();
  const env = getEnvironment();
  const navigate = useNavigate();
  const authContext = useAuthContextAuthenticated();
  const agreements = authContext.userData.selectedCompany.schoolTicketAgreements;
  const validAgreementExist = hasValidAgreement(agreements);

  if (!validAgreementExist) throw new Error("Missing valid school ticket agreement");

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get("/schoolticket-api/distance/zones", mapZones);
      if (response.type === "success") {
        setZones(response.result);
      } else {
        setZones(() => {
          throw response.error;
        });
      }
    };
    fetch();
  }, []);

  const formMethods = useForm<SchoolTicketFormData>({
    defaultValues: {
      phone: "",
      name: "",
      className: "",
      studentId: "",
      address: "",
      validAllZones: false,
    },
  });

  const mediaType = useWatch({ name: "mediaType", defaultValue: undefined, control: formMethods.control });

  if (!zones) return null;

  const mapRecipients = (data: SchoolTicketFormData) => {
    const recipients: SchoolTicketContractRecipient[] = [];
    if (data.mediaType === MediaType.TRAVEL_CARD) {
      for (let i = 0; i < data.numberOfTravelCards; i++) {
        recipients.push({ mediaType: data.mediaType });
      }
    } else {
      const phone = parsePhoneNumberFromString(data.phone);
      if (!phone || !phone.isValid()) {
        throw new Error("invalid phone number should be impossible as it is validated by the form");
      }

      const { firstName, lastName } = splitName(data.name);

      recipients.push({
        firstName,
        lastName,
        phone: phone.nationalNumber.toString(),
        phoneCountryCode: `+${phone.countryCallingCode}`,
        mediaType: data.mediaType,
        className: data.className,
        address: data.address,
      });
    }
    return recipients;
  };

  const mapToPostContract = (data: SchoolTicketFormData): SchoolTicketPostContract | undefined => {
    if (!zones) throw new Error("Cant find zones");
    const zoneFrom = data.zoneFrom;
    const zoneTo = data.zoneTo;
    const validAllZones = zoneFrom === ALL_ZONES_FROM && zoneTo === ALL_ZONES_TO;
    const agreementNumber = agreements[0].id;
    const recipients = mapRecipients(data);

    if (validAllZones) {
      return {
        agreementNumber,
        startDate: ensureExpiryDateIsNotInThePastAndFormatForBackend(data.startDate).toISOString(),
        recipients: recipients,
        validAllZones,
      };
    } else {
      const zoneIdFrom = zones.find((zone) => zone.name === zoneFrom)?.id;
      const zoneIdTo = zones.find((zone) => zone.name === zoneTo)?.id;

      if (!zoneIdFrom) {
        setSubmitting(() => {
          throw new Error(`Cant find zoneIdFrom for ${zoneIdFrom}`);
        });
        return;
      }
      if (!zoneIdTo) {
        setSubmitting(() => {
          throw new Error(`Cant find zoneIdTo for ${zoneIdTo}`);
        });
        return;
      }

      return {
        agreementNumber,
        startDate: ensureExpiryDateIsNotInThePastAndFormatForBackend(data.startDate).toISOString(),
        recipients: recipients,
        validAllZones,
        zoneIdFrom,
        zoneIdTo,
      };
    }
  };

  const onSubmit = async (data: SchoolTicketFormData) => {
    if (!validAgreementExist) return;
    setSubmitting(true);
    const contract = mapToPostContract(data);
    const response = await apiClient.post("/schoolticket-api/ticket/create", contract);
    if (response.type === "success") {
      successAlert(lang.orderTicketSuccess);
      setSubmitting(false);
      navigate("/skole");
    } else if (response.type === "HttpError") {
      if (response.responseStatus === 422) {
        failAlert(lang.orderTicketFail);
        setIsDuplicateTicket(true);
        setSubmitting(false);
      } else {
        failAlert(lang.orderTicketFail);
        setSubmitting(() => {
          throw response.error;
        });
      }
    } else {
      failAlert(lang.orderTicketFail);
      setSubmitting(() => {
        throw response.error;
      });
    }
  };
  const schoolStartDate = startOfDay(parser.date.fromShortDateString("19.08.2024"));
  const minDate = env === EnvName.Production && isAfter(schoolStartDate, new Date()) ? schoolStartDate : new Date();
  return (
    <Container width="xs" className="components-schoolticketagreement-schoolticket" data-test-id="components-schoolticketorder">
      <h1>{lang.title}</h1>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <MediaFormInput
            label={lang.chooseMediaType}
            name={"mediaType"}
            option1={{ value: MediaType.MOBILE_TICKET, disabled: getEnvironment() === EnvName.Production }}
            option2={{ value: MediaType.TRAVEL_CARD, disabled: false }}
          />
          {mediaType === MediaType.MOBILE_TICKET && <PhoneFormInput name="phone" label={formLang.mobile} required mobileOnly />}
          {mediaType === MediaType.MOBILE_TICKET && <TextFormInput name="name" label={lang.name} required minLength={2} maxLength={100} />}
          {mediaType === MediaType.MOBILE_TICKET && (
            <TextFormInput name="className" label={lang.schoolClass} required minLength={1} maxLength={100} />
          )}
          {mediaType === MediaType.TRAVEL_CARD && (
            <NumberFormInput name="numberOfTravelCards" label={lang.numberOfTravelCards} required min={1} />
          )}
          {mediaType === MediaType.MOBILE_TICKET && (
            <TextFormInput name="address" label={lang.address} required minLength={2} maxLength={100} />
          )}
          {mediaType && <DateFormInput name="startDate" label={formLang.startDate} minDate={minDate} maxDate={endOfDay(parser.date.fromShortDateString("15.06.2025"))} required />}
          {mediaType && zones && <ZoneInput zones={zones} />}
          {mediaType && (
            <FormGroup style={{ marginTop: "3rem" }} >
              {isDuplicateTicket && (
                <div data-test-id="duplicate-error-message">
                  <FieldValidationError
                    text={lang.duplicateTicket}
                  />
                </div>
              )}
              <ButtonGroup>
                <SubmitButton text={lang.orderTicket} submitting={submitting} disabled={!validAgreementExist} />
                <CancelButton onClick={() => navigate("/skole")} />
              </ButtonGroup>
            </FormGroup>
          )}
        </form>
      </FormProvider>
    </Container>
  );
};

export default SchoolTicketOrder;
