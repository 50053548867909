import { SearchResult } from "@ruter-as/billettluke-frontend";
import { AgreementInvoicingInterval, parseAgreementInvoicingInterval } from "./AgreementInvoicingInterval";
import { AgreementSubTypeId, parseAgreementSubTypeId } from "./AgreementSubTypeId";
import { CompanyType, parseCompanyType } from "./CompanyType";
import { mapDateOnlyOrNull } from "./commonMapper";

interface Address {
  line1: string;
  line2: string;
  postCode: string;
  city: string;
  country: string;
}
export interface AgreementJson {
  id: string;
  subTypeId: string;
  invoiceReference: string;
  fromDate: undefined | string;
  endDate: undefined | string;
  invoicingInterval: string | undefined
  paymentDeadline: number | undefined
}

export interface Agreement {
  id: string;
  subTypeId: AgreementSubTypeId;
  invoiceReference: string;
  fromDate: Date | null;
  endDate: Date | null;
  invoicingInterval: AgreementInvoicingInterval | undefined
  paymentDeadline: number | undefined;
}

export interface CompanyAddresses {
  bill: Address;
  post: Address;
  visit: Address;
}

export interface CommonCompanyJson {
  billAddressCity: string | null;
  billAddressCountry: string | null;
  billAddressLine1: string | null;
  billAddressLine2: string | null;
  billAddressPostCode: string | null;
  id: string;
  name: string;
  organisationNumber: string;
  postAddressCity: string | null;
  postAddressCountry: string | null;
  postAddressLine1: string | null;
  postAddressLine2: string | null;
  postAddressPostCode: string | null;
  type: string;
  visitAddressCity: string | null;
  visitAddressCountry: string | null;
  visitAddressLine1: string | null;
  visitAddressLine2: string | null;
  visitAddressPostCode: string | null;
}

export interface CompanyJson extends CommonCompanyJson {
  agreements: Array<AgreementJson>
}

export interface CommonCompany {
  id: string;
  name: string;
  organisationNumber: string;
  addresses: CompanyAddresses;
  type: CompanyType;
}

export interface Company extends CommonCompany {
  allAgreements: Array<Agreement>;
}


export const mapAgreement = (json: AgreementJson): Agreement => ({
  id: json.id,
  subTypeId: parseAgreementSubTypeId(json.subTypeId),
  invoiceReference: json.invoiceReference,
  fromDate: mapDateOnlyOrNull(json.endDate),
  endDate: mapDateOnlyOrNull(json.endDate),
  invoicingInterval: parseAgreementInvoicingInterval(json.invoicingInterval),
  paymentDeadline: json.paymentDeadline,
});

export const mapCommonCompany = (json: CommonCompanyJson): CommonCompany => ({
  id: json.id,
  name: json.name,
  organisationNumber: json.organisationNumber,
  type: parseCompanyType(json.type),
  addresses: {
    bill: {
      line1: json.billAddressLine1 || "",
      line2: json.billAddressLine2 || "",
      postCode: json.billAddressPostCode || "",
      city: json.billAddressCity || "",
      country: json.billAddressCountry || "",
    },
    post: {
      line1: json.postAddressLine1 || "",
      line2: json.postAddressLine2 || "",
      postCode: json.postAddressPostCode || "",
      city: json.postAddressCity || "",
      country: json.postAddressCountry || "",
    },
    visit: {
      line1: json.visitAddressLine1 || "",
      line2: json.visitAddressLine2 || "",
      postCode: json.visitAddressPostCode || "",
      city: json.visitAddressCity || "",
      country: json.visitAddressCountry || "",
    },
  },
});

export const mapCompany = (json: CompanyJson): Company => ({
  ...mapCommonCompany(json),
  allAgreements: json.agreements.map(mapAgreement),
});

export const mapCompanySearchResult = (json: SearchResult<CompanyJson>): SearchResult<Company> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapCompany),
});

export const getValidAgreementJson = (): AgreementJson => ({
  id: "1-4577541490",
  invoiceReference: "sr",
  endDate: undefined,
  fromDate: undefined,
  subTypeId: "SUBSCRIPTIONS",
  invoicingInterval: "MONTHLY_AT_END",
  paymentDeadline: 30,
});

export const getValidCompanyJson = (): CompanyJson => ({
  id: "1226629",
  type: "SIEBEL_CUSTOMER_NUMBER",
  organisationNumber: "111122223",
  name: "Steffens Kosmetikk",
  agreements: [
    {
      id: "1-3779173843",
      invoiceReference: "KIM",
      fromDate: undefined,
      endDate: undefined,
      subTypeId: "SUBSCRIPTIONS",
      invoicingInterval: "MONTHLY_AT_END",
      paymentDeadline: 30,
    },
    {
      id: "1-3058717731",
      invoiceReference: "KÅRE",
      fromDate: undefined,
      endDate: undefined,
      subTypeId: "SUBSCRIPTIONS",
      invoicingInterval: "MONTHLY_AT_END",
      paymentDeadline: 30,
    },
  ],
  visitAddressLine1: "Visit Address 1 It2",
  visitAddressLine2: "Visit Address 2 It2",
  visitAddressCity: "visit city it2",
  visitAddressCountry: null,
  visitAddressPostCode: "7921",
  billAddressLine1: "Bill Address 1 It2",
  billAddressLine2: "Bill Address 2 It2",
  billAddressCity: "bill city it2",
  billAddressCountry: null,
  billAddressPostCode: "7923",
  postAddressLine1: "Post Address 1 It2",
  postAddressLine2: "Post Address 2 It2",
  postAddressCity: "post city it2",
  postAddressCountry: null,
  postAddressPostCode: "7922",
});