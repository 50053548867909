interface TaxBaseLineJson {
  agreementNumber: string;
  employeeId: string;
  employeeTickets: number | null;
  familyAdultTickets: number | null;
  familyChildTickets: number | null;
  familyRetiredTickets: number | null;
  retiredTickets: number | null;
  firstName: string;
  lastName: string;
  retiredTicketsIncludingFamily: number | null;
  totalPrice: number;
}

export interface TaxBaseLine {
  agreementNumber: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  ticketCountEmployee: number;
  ticketCountFamilyChildAndReducedRate: number;
  ticketCountFamilyAdult: number;
  ticketCountRetired: number;
  totalPrice: number;
}

const mapTaxBaseLine = (json: TaxBaseLineJson): TaxBaseLine => ({
  agreementNumber: json.agreementNumber,
  employeeId: json.employeeId,
  firstName: json.firstName,
  lastName: json.lastName,
  ticketCountEmployee: json.employeeTickets || 0,
  ticketCountFamilyChildAndReducedRate: (json.familyChildTickets || 0) + (json.familyRetiredTickets || 0),
  ticketCountFamilyAdult: json.familyAdultTickets || 0,
  ticketCountRetired: json.retiredTickets || 0,
  totalPrice: json.totalPrice,
});

export const mapTaxBaseLines = (json: TaxBaseLineJson[]): TaxBaseLine[] => json.map(mapTaxBaseLine);

export const getValidTaxBaseLine = (changes?: Partial<TaxBaseLineJson>): TaxBaseLineJson => ({
  agreementNumber: "1-3779173843",
  employeeId: "81d28ef1-a55f-44b4-8bf9-26b0f3b5b32e",
  employeeTickets: 1,
  familyAdultTickets: 0,
  familyChildTickets: 0,
  familyRetiredTickets: 0,
  retiredTickets: 0,
  firstName: "9955912b-8997-4284-a9ba-68a519b180c6",
  lastName: "0008bd82-c313-4253-a861-94694ccd2512",
  totalPrice: 625,
  retiredTicketsIncludingFamily: 0,
  ...changes,
});
