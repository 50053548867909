import { Icon } from "@ruter-as/web-components-and-tools";
import React from "react";
import { Link } from "react-router-dom";
import "./CallToActionLink.scss";
interface ExternalLinkProps {
  text: string;
  externalUrl: string;
  showIcon: boolean;
  className?: string
}

interface InternalLinkProps {
  text: string;
  path: string;
  showIcon: boolean;
  className?: string
}

interface ButtonProps {
  text: string;
  onClick: () => void;
  showIcon: boolean;
  className?: string
}

type Props = ExternalLinkProps | InternalLinkProps | ButtonProps;

const CallToActionLink: React.FC<Props> = (props) => {
  const { text, showIcon, className } = props;

  const cn = ["cta-link", className];
  const renderText = () => {
    return (
      <>
        {showIcon && <Icon variant="ArrowRightIcon" size="small" className="icon-left" />}
        {text}
      </>
    );
  };

  if ("onClick" in props) {
    return (
      <button className={cn.join(" ")} onClick={props.onClick}>
        {renderText()}
      </button>
    );
  }

  if ("externalUrl" in props) {
    return (
      <a href={props.externalUrl} target="_blank" rel="noopener noreferrer" className={cn.join(" ")}>
        {renderText()}
      </a>
    );
  }

  if ("path" in props) {
    return (
      <Link to={props.path} className={cn.join(" ")}>
        {renderText()}
      </Link>
    );
  } else {
    throw new Error("Missing onClick, path or externalUrl props");
  }
};

export default CallToActionLink;
