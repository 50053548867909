import { Card, useLanguageResource } from "@ruter-as/web-components-and-tools";
import "./InfoCard.scss";
import myPageLanguageResource from "./lang-resource";

interface Props {
  name?: string;
  orgNumber?: string;
  customerNumber?: string;
}

const CompanyCard: React.FC<Props> = ({ name, orgNumber, customerNumber }) => {
  const lang = useLanguageResource(myPageLanguageResource);

  return (
    <Card className="components-info-card">
      <div className="header">
        <h2>{lang.companyInfo}</h2>
      </div>
      <div className="body">
        {name && (
          <div className="info">
            <div className="label">{lang.company}</div>
            <div className="value" data-test-id="company-name">
              {name}
            </div>
          </div>
        )}
        {customerNumber && (
          <div className="info">
            <div className="label">{lang.customerNumber}</div>
            <div className="value" data-test-id="company-customer-number">
              {customerNumber}
            </div>
          </div>
        )}
        {orgNumber && (
          <div className="info">
            <div className="label">{lang.organizationMumber}</div>
            <div className="value" data-test-id="company-organization-number">
              {orgNumber}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default CompanyCard;