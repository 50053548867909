import { SearchResult } from "../SearchResult";
import { TravelCardState, parseTravelCardState } from "../TravelCardState";
import { mapDateFromIsoOrNull } from "../commonMapper";
import MediaType from "../mediaType";
import AllowedActions, { mapAllowedActions } from "./allowedActions";
import { TicketStatus, parseTicketStatus } from "./ticketStatus";
export interface SchoolTicketJSON {
  id: string;
  agreementNumber: string;
  appId: string | null;
  address: string | null;
  allowedActions: Array<string>;
  creationConfirmed: boolean;
  dateOrdered: string;
  downloadedToPhone: boolean;
  employeeId: string;
  expirationDate: null | string;
  firstName: string | null;
  lastName: string | null;
  mediaType: string;
  nodOrderStatusId: null | number;
  orderStatusId: null | number;
  ownerId: number;
  parentOrderId: null | number;
  payerId: null | string;
  phone: null | string;
  phoneCountryCode: null | string;
  pickupCode: null | string;
  pricePerMonth: number | null;
  productId: number;
  productPrice: number | null;
  productTemplateId: number;
  productVatAmount: number | null;
  startDate: string;
  status: string;
  ticketHolderType: string | null;
  totalPrice: null | number;
  zoneFrom: string;
  zoneTo: string;
  zoneVia: string | null;
  zones: Array<string>;
  allZones: boolean;
  invoiceReference: string | null;
  salaryId: string;
  travelCardState: string;
  className: string;
  travelCard: TravelCardJson;
  nrOfZones: number | null;
}

interface TravelCardJson {
  manufacturedAt?: string | null;
  cardNumber?: string | null;
  state?: string;
  cardName?: string | null;
}

export type SchoolTicket = SchoolTicketPhone | SchoolTicketTravelCard;

export interface SchoolTicketPhone {
  appId: string | null;
  agreementNumber: string;
  address: string;
  allowedActions: { [key in AllowedActions]: boolean };
  id: string;
  downloadedToPhone: boolean;
  studentId: string;
  expirationDate: Date | null;
  firstName: string;
  invoiceReference: string | null;
  lastName: string;
  phone?: string;
  phoneCountryCode?: string;
  pickupCode: string | null;
  pricePerMonth: number | null;
  startDate: Date | null;
  status: TicketStatus;
  mediaType: typeof MediaType.MOBILE_TICKET;
  className: string;
  zoneFrom: string;
  zoneTo: string;
  allZones: boolean;
  nrOfZones: number;
}

export interface SchoolTicketTravelCard {
  agreementNumber: string;
  address: string;
  allowedActions: { [key in AllowedActions]: boolean };
  id: string;
  studentId: string;
  expirationDate: Date | null;
  firstName: string;
  invoiceReference: string | null;
  lastName: string;
  pricePerMonth: number | null;
  startDate: Date | null;
  status: TicketStatus;
  travelCardState: TravelCardState;
  mediaType: typeof MediaType.TRAVEL_CARD;
  className: string;
  manufacturedAt: Date | null;
  cardNumber: string;
  cardName: string;
  zoneFrom: string;
  zoneTo: string;
  allZones: boolean;
  nrOfZones: number;
}

const mapMobileTicket = (json: SchoolTicketJSON): SchoolTicketPhone => ({
  agreementNumber: json.agreementNumber,
  address: json.address || "",
  appId: json.appId,
  id: json.id,
  downloadedToPhone: json.downloadedToPhone,
  studentId: json.employeeId,
  expirationDate: mapDateFromIsoOrNull(json.expirationDate),
  firstName: json.firstName || "",
  invoiceReference: json.invoiceReference,
  lastName: json.lastName || "",
  phone: json.phone || "",
  phoneCountryCode: json.phoneCountryCode || "",
  pricePerMonth: json.pricePerMonth,
  pickupCode: json.pickupCode,
  status: parseTicketStatus(json.status),
  startDate: mapDateFromIsoOrNull(json.startDate),
  mediaType: MediaType.MOBILE_TICKET,
  className: json.className,
  allowedActions: mapAllowedActions(json.allowedActions),
  allZones: json.allZones,
  zoneFrom: json.zoneFrom,
  zoneTo: json.zoneTo,
  nrOfZones: json.nrOfZones || 0,
});

const mapTravelCardTicket = (json: SchoolTicketJSON): SchoolTicketTravelCard => ({
  agreementNumber: json.agreementNumber,
  address: json.address || "",
  id: json.id,
  studentId: json.employeeId,
  expirationDate: mapDateFromIsoOrNull(json.expirationDate),
  firstName: json.firstName || "",
  invoiceReference: json.invoiceReference,
  lastName: json.lastName || "",
  pricePerMonth: json.pricePerMonth,
  travelCardState: parseTravelCardState(json.travelCard.state ?? ""),
  startDate: mapDateFromIsoOrNull(json.startDate),
  status: parseTicketStatus(json.status),
  mediaType: MediaType.TRAVEL_CARD,
  className: json.className,
  allowedActions: mapAllowedActions(json.allowedActions),
  cardName: json.travelCard.cardName || "",
  cardNumber: json.travelCard.cardNumber || "",
  manufacturedAt: mapDateFromIsoOrNull(json.travelCard.manufacturedAt),
  allZones: json.allZones,
  zoneFrom: json.zoneFrom,
  zoneTo: json.zoneTo,
  nrOfZones: json.nrOfZones || 0,
});

export const mapSchoolTicket = (json: SchoolTicketJSON) => {
  if (json.mediaType === MediaType.TRAVEL_CARD) {
    return mapTravelCardTicket(json);
  }
  if (json.mediaType === MediaType.MOBILE_TICKET) {
    return mapMobileTicket(json);
  }

  throw new Error(`${json.mediaType} is not a valid mediatype`);
};

export const mapSchoolTicketSearchResult = (json: SearchResult<SchoolTicketJSON>): SearchResult<SchoolTicket> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapSchoolTicket),
});

export const getValidSchoolTicket = (): SchoolTicketJSON => ({
  id: "4321b183-8fd1-4f93-b246-3d1b58568555",
  ownerId: 3,
  travelCardState: "REQUESTED",
  salaryId: "",
  agreementNumber: "1-3779173843",
  invoiceReference: "KIM",
  dateOrdered: "2020-12-21T07:46:32Z",
  ticketHolderType: "EMPLOYEE",
  mediaType: MediaType.TRAVEL_CARD,
  payerId: "1226629",
  employeeId: "employee-id",
  lastName: "nordmann",
  firstName: "test",
  address: "Jernbanetorget 1",
  allZones: false,
  phone: null,
  phoneCountryCode: null,
  productPrice: null,
  productVatAmount: null,
  totalPrice: null,
  pricePerMonth: 8,
  productTemplateId: 97,
  productId: 21991,
  appId: null,
  startDate: "2020-12-31T23:00:00Z",
  expirationDate: null,
  zoneFrom: "1",
  zoneTo: "2Ø",
  zoneVia: null,
  zones: ["1", "2Ø"],
  nrOfZones: 2,
  nodOrderStatusId: null,
  orderStatusId: null,
  parentOrderId: null,
  creationConfirmed: true,
  pickupCode: null,
  status: "active",
  downloadedToPhone: false,
  className: "3A",
  allowedActions: [],
  travelCard: {
    cardName: null,
    cardNumber: "1234",
    manufacturedAt: null,
    state: "REQUESTED",
  },
});
