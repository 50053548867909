import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

export interface FreeTicketsFamilyLanguageResource {
  title: string;
  status: string;
  mobileNumber: string;
  ticketHolderType: string;
  birthDate: string;
  validFrom: string;
  validTo: string;
  pickedUp: string;
  pricePerMonth: string;
  taxAmount: string;
  zones: string;
  invoiceReference: string;
  ticketHoldertypes: string;
  employee: string;
  retired: string;
  yes: string;
  no: string;
  confirmSendPickupCode: string;
  sendPickupCode: string;
  confirmResendTicket: string;
  resendTicket: string;
  cancelActiveTicket: string;
  cancelPendingTicket: string;
  cancelAllTickets: string;
  statuses: {
    requested: string;
    created: string;
    deactivated: string;
    cancelled: string;
    anonymised: string;
  };
  travelCardNumber: string;
  invoiceRef: string;
  pickupCode: string;
  appiId: string;
  confirmPendingTicketCancel: string;
  from: string;
  cancelActiveTicketInfoTitle: string;
  cancelActiveTicketInfoPrivacy: string;
  orderNumber: string;
  profile: string;
  adult: string;
  child: string;
  reducedRate: string;
  productionStatus: string;
  orderReplacementCard: string;
  confirmReplaceCardInfo: string;
  confirmReplaceCard: string;
  manufacturedAt: string;
  sendPickCodeSuccess: string;
  sendPickCodeFail: string;
  resendTicketSuccess: string;
  resendTicketFail: string;
  cancelActiveTicketSuccess: string;
  cancelActiveTicketFail: string;
  cancelPendingTicketSuccess: string;
  cancelPendingTicketFail: string;
  unknown: string;
  editTicket: string;
  unkownTicketHolderType: string;
  editZoneOrDepartment: string;
  missingDepartmentName: string;
  changesRequireNewTicket: string;
  mediaType: string;
  name: string;
  relationType: string;
  activeTickets: string;
  expiredTickets: string;
  allZones: string;
  cancelAllTicketsError: OneParamLanguageResourceTemplate
}

export const freeTicketsFamilyLanguageResource: LanguageResource<FreeTicketsFamilyLanguageResource> = {
  nb: {
    title: "Fribilletter tilknyttet ansattnummer: $employeeId$",
    status: "Billettstatus",
    mobileNumber: "Mobilnr.",
    ticketHolderType: "Type reisende",
    birthDate: "Fødselsdato",
    validFrom: "Gyldig fra",
    validTo: "Gyldig til",
    pickedUp: "Hentet",
    pricePerMonth: "Pris/mnd",
    taxAmount: "Skattegrunnlag/mnd",
    zones: "Soner",
    invoiceReference: "Fakturaref.",
    ticketHoldertypes: "Familie",
    employee: "Ansatt",
    retired: "Pensjonist",
    yes: "Ja",
    no: "Nei",
    confirmSendPickupCode: "Vil du sende ut ny SMS med hentekode til den ansatte?",
    confirmResendTicket:
      "Vil du lage ny billett og sende ut SMS med hentekode til den ansatte? Den opprinnelige billetten vil bli avsluttet umiddelbart.",
    sendPickupCode: "Send hentekode",
    resendTicket: "Send billett på nytt",
    cancelActiveTicket: "Avslutt billett",
    cancelPendingTicket: "Kanseller billett",
    cancelAllTickets: "Avslutt alle billetter",
    statuses: {
      requested: "Bestilt",
      created: "Produsert",
      deactivated: "Sperret",
      cancelled: "Kansellert",
      anonymised: "Anonymisert",
    },
    travelCardNumber: "Reisekort #",
    invoiceRef: "Avtale/avdeling",
    pickupCode: "Hentekode",
    appiId: "App id",
    confirmPendingTicketCancel: "Er du sikker på at du ønsker å kansellere billetten?",
    cancelActiveTicketInfoTitle: "Vedrørende avslutning av fribillett",
    cancelActiveTicketInfoPrivacy: "Billetten avsluttes $expiryDate$. Personopplysninger slettes 3 måneder senere.",
    from: "Gjelder fra",
    orderNumber: "Billettnummer",
    profile: "Profil",
    adult: "Voksen",
    child: "Barn",
    reducedRate: "Honnør",
    productionStatus: "Produksjonsstatus",
    orderReplacementCard: "Bestill erstatningskort",
    confirmReplaceCardInfo:
      "Nytt reisekort vil bli produsert og sendt ut så snart som mulig (faktureres 50 kroner). Gammelt reisekort vil sperres umiddelbart.",
    confirmReplaceCard: "Er du sikker på at du ønsker å bestille erstatningskort?",
    manufacturedAt: "Produksjonsdato",
    sendPickCodeSuccess: "Hentekode sendt",
    sendPickCodeFail: "Klarte ikke sende hentekode",
    resendTicketSuccess: "Billett sendt på nytt!",
    resendTicketFail: "Klarte ikke å sende billett på nytt",
    cancelActiveTicketSuccess: "Billett avsluttet!",
    cancelActiveTicketFail: "Det skjedde en feil ved avslutting av billett",
    cancelPendingTicketSuccess: "Billett kansellert!",
    cancelPendingTicketFail: "Det skjedde en feil ved kansellering av billett",
    unknown: "Ukjent",
    editTicket: "Endre billett",
    unkownTicketHolderType: "Ukjent tilknytting",
    editZoneOrDepartment: "Endre sone/avdeling",
    missingDepartmentName: "Avdelingsnavn ikke satt",
    changesRequireNewTicket: "Følgende endringer gjør at vi må avslutte nåværende billett og opprette en ny:",
    mediaType: "Billettbærer",
    name: "Navn",
    relationType: "Relasjonstype",
    activeTickets: "Aktive billetter",
    expiredTickets: "Utløpte billetter",
    allZones: "Alle soner",
    cancelAllTicketsError: languageResourceBuilder.oneParamTemplate("Avslutning av billetten(e): $p1$ feilet"),
  },
  en: {
    title: "Free-tickets associated with employee id: $employeeId$",
    status: "Ticket status",
    mobileNumber: "Mobile #.",
    ticketHolderType: "Profile",
    birthDate: "Date of birth",
    validFrom: "Valid from",
    validTo: "Valid to",
    pickedUp: "Picked up",
    pricePerMonth: "Price/month",
    taxAmount: "Salary effect per month",
    zones: "Zones",
    invoiceReference: "Invoide ref.",
    ticketHoldertypes: "Family",
    employee: "Employee",
    retired: "Retired",
    yes: "yes",
    no: "no",
    confirmSendPickupCode: "Are you sure you want to send an SMS with a new pickup code to the employee?",
    confirmResendTicket:
      "Are you sure you want to create a new ticket and send an SMS with a new pickup code to the employee? The original ticket will be cancelled immediately.",
    sendPickupCode: "Send pickup code",
    resendTicket: "Resend ticket",
    cancelActiveTicket: "Cancel ticket",
    cancelPendingTicket: "Cancel ticket",
    cancelAllTickets: "Cancel all tickets",
    statuses: {
      requested: "Ordered",
      created: "Produced",
      deactivated: "Cancelled",
      cancelled: "Cancelled",
      anonymised: "Anonymised",
    },
    travelCardNumber: "Travel card #",
    invoiceRef: "Agreement/department",
    pickupCode: "Pickup code",
    appiId: "App id",
    confirmPendingTicketCancel: "Are you sure you want to cancel the ticket?",
    from: "From",
    cancelActiveTicketInfoTitle: "Regarding cancellation of ticket.",
    cancelActiveTicketInfoPrivacy:
      "The ticket will be cancelled on $expiryDate$. Stored information about the employee holding this ticket, will be deleted 3 months later.",
    orderNumber: "Ticket number",
    profile: "Profile",
    adult: "Adult",
    child: "Child",
    reducedRate: "Reduced rate",
    productionStatus: "Production status",
    orderReplacementCard: "Order replacement card",
    confirmReplaceCardInfo:
      "A new travel card will be produced and sent as soon as possible (Fee of 50 kroner will apply). The old card will be cancelled effective immediately.",
    confirmReplaceCard: "Are you sure you want to order a replacement card?",
    manufacturedAt: "Production date",
    sendPickCodeSuccess: "Pickup code sent",
    sendPickCodeFail: "Could not send pickup code",
    resendTicketSuccess: "Ticket resent",
    resendTicketFail: "Could not resend ticket",
    cancelActiveTicketSuccess: "Ticket cancelled!",
    cancelActiveTicketFail: "An error occured while we tried to cancel a ticket!",
    cancelPendingTicketSuccess: "Ticket cancelled!",
    cancelPendingTicketFail: "An error occured while we tried to cancel a ticket!",
    unknown: "Unknown",
    editTicket: "Edit ticket",
    unkownTicketHolderType: "Unknown relation",
    editZoneOrDepartment: "Edit zone/department",
    missingDepartmentName: "Department name not set",
    changesRequireNewTicket: "The following changes requires a new ticket:",
    mediaType: "Media type",
    name: "Name",
    relationType: "Relation type",
    activeTickets: "Active tickets",
    expiredTickets: "Expired tickets",
    allZones: "All zones",
    cancelAllTicketsError: languageResourceBuilder.oneParamTemplate("Cancelation of tickets: $p1$ failed"),
  },
};
