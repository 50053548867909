import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface SimpleOnboardingLanguageResource {
  title: string;
  description: string;
  emailLabel: string;
  mobileLabel: string;
  organizationNumberLabel: string;
  firstName: string,
  lastName: string
  companyName: string
  confirm: string
  cancel: string
  errorUnknown: string;
  errorInvalidOrgNumber: string;
  errorCompanyNotFound: string;
  errorCompanyUnsuitable: string;
  confirmation: {
    title: string,
    description: string
    finished: string
  }
}

export const simpleOnboardingLanguageResources: LanguageResource<SimpleOnboardingLanguageResource> = {
  nb: {
    title: "Velkommen til RuterBedrift onboarding",
    description: "Fyll inn kontaktinfo og organisasjonsnummer for bedriften din, så lager vi en brukerkonto til deg og din bedrift.",
    emailLabel: "E-postadresse",
    mobileLabel: "Mobilnummer",
    organizationNumberLabel: "Organisasjonsnummer",
    companyName: "Bedriftsnavn",
    firstName: "Fornavn",
    lastName: "Etternavn",
    confirm: "Bekreft",
    cancel: "Avbryt",
    errorInvalidOrgNumber: "Ugyldig organisasjonsnummer",
    errorCompanyNotFound: "Fant ikke selskap i brønnøysundregisteret",
    errorUnknown: "Det oppstod en ukjent feil. Forsøk igjen og kontakt kundeservice dersom problemet vedvarer.",
    errorCompanyUnsuitable: "Vi kan ikke opprette konto for dette selskapet, ta kontakt med kundeservice",
    confirmation: {
      title: "Takk for fullført registrering!",
      description: "Det har blitt opprettet en sak som vi vil behandle fortløpende. Du vil om kort tid motta en e-post med tilleggsinformasjon.",
      finished: "Registrering fullført",
    },
  },
  en: {
    title: "Welcome to onboarding to RuterBedrift",
    description: "Fill in your contact info and organization number for your company in order du create an account. Authentication will be performed by BankID",
    emailLabel: "Email address",
    mobileLabel: "Mobile number",
    organizationNumberLabel: "Organization number",
    companyName: "Company name",
    firstName: "First name",
    lastName: "Last name",
    confirm: "Confirm",
    cancel: "Cancel",
    errorInvalidOrgNumber: "Invalid organization number",
    errorCompanyNotFound: "We did not find this company in brønnøysundregisteret",
    errorCompanyUnsuitable: "We can not create an account for this company, please contact customer service",
    errorUnknown: "An unknown error occurred. Try again and contact customer service if the problem persists.",
    confirmation: {
      title: "Thank you for signing up!",
      description: "A case has been created. You will shortly recieve an email with additional information.",
      finished: "Sign up finished!",
    },
  },
};
