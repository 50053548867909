import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface MissingRoleLanguageResource {
  title: string;
  text: string;
  clickForContact: string;
}

const missingRoleLanguageResource: LanguageResource<MissingRoleLanguageResource> = {
  nb: {
    title: "Din bruker mangler en gyldig rolle for å få tilgang til denne siden",
    text: "Kontakt bedriftens brukeradministrator eller kundestøtte",
    clickForContact: "Kontakt kundestøtte",
  },
  en: {
    title: "Your user is missing a valid role",
    text: "Contact the company's user administrator or customer support",
    clickForContact: "Contact customer support.",
  },
};

export default missingRoleLanguageResource;
