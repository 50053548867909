import { Container, Datalist, DatalistOnChangeEvent, IconButton, Table, TablePagination, apiClient, formatter, useLanguageResource, usePaginationAndParameters } from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContextAuthenticated } from "src/AuthContext";
import schoolTicketApi from "src/common/api/schoolTicketApi/schoolTicketApi";
import { TravelCardManufacture } from "src/common/api/schoolTicketApi/travelCardAdmin";
import successAlert, { failAlert } from "src/common/toastr";
import { UNKNOWN } from "src/constants";
import { Company } from "src/types/Company";
import { SearchResult } from "src/types/SearchResult";
import { TravelCardState } from "src/types/TravelCardState";
import { CallToActionLink } from "../../common/buttons";
import "./TravelCardSearchPage.scss";
import { TravelCardSearchPageLanguageResource, travelCardSearchPageLanguageResource } from "./lang-resource";

interface DateValidResult {
  valid: true
}
interface DateInvalidResult {
  valid: false
  type: "fromDate" | "toDate" | "range" | "maxDate",
  message: string;
}

type DateValidationResult = DateValidResult | DateInvalidResult;

const getStatus = (status: TravelCardState | typeof UNKNOWN, lang: TravelCardSearchPageLanguageResource): string => {
  if (status === TravelCardState.CREATED) {
    return lang.statuses.created;
  }
  if (status === TravelCardState.DEACTIVATED) {
    return lang.statuses.deactivated;
  }
  if (status === TravelCardState.REQUESTED) {
    return lang.statuses.requested;
  }
  if (status === TravelCardState.CANCELLED) {
    return lang.statuses.cancelled;
  }
  if (status === TravelCardState.ANONYMISED) {
    return lang.statuses.anonymised;
  }
  return lang.statuses.unknown;
};


const TravelCardSearchPage: React.FC = () => {
  const lang = useLanguageResource(travelCardSearchPageLanguageResource);
  const [manufactureList, setManufactureList] = useState<SearchResult<TravelCardManufacture>>();
  // const [dateValidationResult, setDateValidationResult] = useState<DateValidationResult>({ valid: true });
  const [loading, setLoading] = useState(false);
  // const [backendFromDate, setBackendFromDate] = useState("");
  // const [backendToDate, setBackendToDate] = useState("");
  // const [clearAllInProgress, setClearAllInProgress] = useState(false);

  const { pagination, searchParameters: [
    // { setValue: setFromDate, value: fromDate },
    // { setValue: setToDate, value: toDate },
    { setValue: setSelectedCompany, value: selectedCompany },
    { setValue: setSelectedComapnyId, value: selectedCompanyId },
  ] } = usePaginationAndParameters([
    // { name: "fromDate", minLength: 0, throttle: 0 },
    // { name: "toDate", minLength: 0, throttle: 0 },
    { name: "selectedCompany", minLength: 0, throttle: 0 },
    { name: "selectedCompanyId", minLength: 0, throttle: 0 },
  ]);

  const { pageSize, page } = pagination;

  const fetch = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.request(schoolTicketApi.travelCards.manufactureReport.get({ companyId: selectedCompanyId, page, pageSize }));
    if (response.type === "success") {
      setManufactureList(response.result);
      setLoading(false);
    } else {
      setManufactureList(() => {
        throw response.error;
      });
    }
  }, [selectedCompanyId, page, pageSize]);

  const authContext = useAuthContextAuthenticated();
  const companies = authContext.userData.companies;

  // useEffect(() => {
  //   const validateDates = (): DateValidationResult => {
  //     const parsedFromDate = parse(fromDate, "dd.MM.yyyy", new Date());
  //     const parsedToDate = parse(toDate, "dd.MM.yyyy", new Date());

  //     const fromDateIsValid = isValid(parsedFromDate) && formatter.date.toShortDateString(parsedFromDate) === fromDate;
  //     const toDateIsValid = isValid(parsedToDate) && formatter.date.toShortDateString(parsedToDate) === toDate;

  //     if (fromDate && !fromDateIsValid) {
  //       return {
  //         valid: false,
  //         type: "fromDate",
  //         message: "Fra dato må enten være tom eller en dato med formatet dd.mm.yyyy",
  //       };
  //     }
  //     if (toDate && !toDateIsValid) {
  //       return {
  //         valid: false,
  //         type: "toDate",
  //         message: "Til dato må enten være tom eller en dato med formatet dd.mm.yyyy",
  //       };
  //     }
  //     if (parsedFromDate.getTime() > parsedToDate.getTime()) {
  //       return {
  //         valid: false,
  //         type: "range",
  //         message: "Fra dato må være mindre enn eller lik til dato",
  //       };
  //     }

  //     if (new Date().getTime() < parsedFromDate.getTime() || new Date().getTime() < parsedToDate.getTime()) {
  //       const today = formatter.date.toShortDateString(new Date());
  //       return {
  //         valid: false,
  //         type: "maxDate",
  //         message: `Fra og til dato må være mindre enn eller lik til dato ${today}`,
  //       };
  //     }

  //     setBackendFromDate(fromDate ? parsedFromDate.toISOString() : "");
  //     setBackendToDate(toDate ? parsedToDate.toISOString() : "");

  //     return {
  //       valid: true,
  //     };

  //   };
  //   setDateValidationResult(validateDates());

  // }, [fromDate, toDate]);


  useEffect(() => {
    fetch();

  }, [fetch]);


  const copyAddressToClipBoard = (company: Company) => {
    const address = `${company.name}\r\n${company.addresses.post.line1}\r\n${company.addresses.post.postCode} ${company.addresses.post.city}`;
    try {
      navigator.clipboard.writeText(address);
      successAlert(lang.copyAddressSuccess);
    } catch (e) {
      failAlert(lang.copyAddressFail);
    }
  };

  const renderRow = (row: TravelCardManufacture) => {
    const company = row.company;
    return (
      <tr key={`${company.id} ${row.manufacturedDate}`}>
        <td data-test-id="productionDate">{row.manufacturedDate ? formatter.date.toShortDateString(row.manufacturedDate) : ""}</td>
        <td data-test-id="companyName">{company.name}</td>
        <td data-test-id="status">{getStatus(row.state, lang)}</td>
        <td data-test-id="amount">{row.cards.length}</td>
        <td data-test-id="copyAddresses"><IconButton aria-label={lang.copyAddress} variant="ClipBoardIcon" onClick={() => { copyAddressToClipBoard(row.company); }} /></td>
        <td data-test-id="showOrders">{row.manufacturedDate && row.state === TravelCardState.CREATED ? <CallToActionLink text={lang.showOrders} path={`/reisekort/${company.id}/${encodeURIComponent(formatter.date.toShortDateString(row.manufacturedDate).replaceAll(".", "-"))}`} showIcon={false} className="show-orders" /> : null}</td>
      </tr>
    );
  };

  // const clearFilter = () => {
  //   setClearAllInProgress(true);
  //   setTimeout(() => {
  //     setFromDate("");
  //     setToDate("");
  //     setSelectedCompany("");
  //   }, 100);
  //   setTimeout(() => {
  //     setClearAllInProgress(false);
  //   }, 200);
  // };

  // const fromDateHasError = !dateValidationResult.valid && (dateValidationResult.type === "fromDate" || dateValidationResult.type === "range");
  // const toDateHasError = !dateValidationResult.valid && (dateValidationResult.type === "toDate" || dateValidationResult.type === "range");


  const DatalistOnChange = (event: DatalistOnChangeEvent) => {
    if (event.found) {
      setSelectedComapnyId(event.optionValue);
    } else if (event.value === "") {
      setSelectedComapnyId("");
    }
    setSelectedCompany(event.value);
  };

  const options = companies.map(company => ({ value: company.id, text: company.name }));

  return (
    <Container width="m" className="production-list" data-test-id="production-list">
      <h1>{lang.title}</h1>
      <div className="filter" role="group">
        {/* <div className="date-search" aria-labelledby="dateLabel">
          <label id="dateLabel">
            {lang.filter.date}
          </label>
          <label htmlFor="fromDate" className="date-label">{lang.filter.fromDate}</label>
          <DateInput name="fromDate" onChange={(e) => { setFromDate(e); }} value={fromDate} hasError={fromDateHasError} maxDate={new Date()} />
          <span className="dash">–</span>
          <label htmlFor="toDate" className="date-label">{lang.filter.toDate}</label>
          <DateInput name="toDate" onChange={(e) => { setToDate(e); }} value={toDate} hasError={toDateHasError} maxDate={new Date()} />
        </div>
        {!dateValidationResult.valid && (
          <div className="date-validation-message" role="alert">
            <FieldValidationError text={dateValidationResult.message} />
          </div>

        )} */}
        <div className="dropdown-row">
          <label htmlFor="selectedCompany">{lang.filter.company}</label>
          <Datalist name="selectedCompany" id="selectedCompany" listId="company-list" value={selectedCompany} onChange={DatalistOnChange} options={options} showIcons={true} maxHeight={200} className="datalist" />
        </div>
        {/* <div className="clear-filter">
          <CallToActionLink onClick={clearFilter} text={lang.filter.clearFilter} showIcon={false} />
        </div> */}
      </div>
      <Table loading={loading}>
        <thead>
          <tr>
            <th scope="col">{lang.productionDate}</th>
            <th scope="col" style={{ width: 220 }}>{lang.company}</th>
            <th scope="col">{lang.status}</th>
            <th scope="col">{lang.amount}</th>
            <th scope="col">{lang.address}</th>
            <th scope="col">{lang.orders}</th>
          </tr>
        </thead>
        <tbody>
          {manufactureList?.matches.map(renderRow)}
        </tbody>
      </Table>
      <TablePagination pagination={pagination} totalRecords={manufactureList?.totalEntries} />
    </Container>
  );
};

export default TravelCardSearchPage;