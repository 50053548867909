export enum TravelCardState {
  REQUESTED = "REQUESTED",
  CREATED = "CREATED",
  DEACTIVATED = "DEACTIVATED",
  CANCELLED = "CANCELLED",
  ANONYMISED = "ANONYMISED",
}

export const parseTravelCardState = (value: string): TravelCardState => {
  const parsed = Object.values(TravelCardState).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error(`"${value}" is not a known TravelCardState`);
  }
  return parsed;
};
