import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import "./AgreementTypeBadge.scss";
import { AgreementTypeBadgeLanguageResource, agreementTypeBadgeLanguageResource } from "./lang-resource";

interface Props {
  agreementSubTypeId?: AgreementSubTypeId
}

const getColorAndText = (agreementSubTypeId: AgreementSubTypeId | undefined, lang: AgreementTypeBadgeLanguageResource): { color: string, text: string } => {
  switch (agreementSubTypeId) {
    case undefined:
      return { color: "white", text: lang.noAgreements };
    case AgreementSubTypeId.SUBSCRIPTIONS:
      return { color: "lightblue", text: lang.companyAgreement };
    case AgreementSubTypeId.TICKETOFFICE:
      return { color: "lightcoral", text: lang.ticketCounterAgreement };
    case AgreementSubTypeId.FREETICKET:
      return { color: "lightgreen", text: lang.freeTicketAgreement };
    case AgreementSubTypeId.SCHOOLTICKET:
      return { color: "#d3d3d3", text: lang.schoolTicketAgreement };
    case AgreementSubTypeId.TRAVELCARD_FIXED_PRICE:
      return { color: "#d3d3d3", text: lang.fixedPriceTravelCardAgreement };
    case AgreementSubTypeId.TRAVELCARD_SUBSCRIPTION:
      return { color: "#d3d3d3", text: lang.travelCardSubscription };
    case AgreementSubTypeId.OTHER:
      return { color: "#d3d3d3", text: lang.other };
    case AgreementSubTypeId.NO_SUBSCRIPTIONS:
      return { color: "#d3d3d3", text: lang.noSubscripion };
  }
};

export const getAgreementSubTypeIdText = (agreementSubTypeId: AgreementSubTypeId | undefined, lang: AgreementTypeBadgeLanguageResource): string => {
  return getColorAndText(agreementSubTypeId, lang).text;
};

const AgreementTypeBadge: React.FC<Props> = ({ agreementSubTypeId }) => {
  const lang = useLanguageResource(agreementTypeBadgeLanguageResource);
  const { color, text } = getColorAndText(agreementSubTypeId, lang);

  return <div className="agreement-type-badge" data-test-id="agreement-type-badge" data-test-badge-type={agreementSubTypeId || "no-agreement"} style={{ backgroundColor: color }}>{text}</div>;
};

export default AgreementTypeBadge;