import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface AppLoadingLanguageResource {
  appLoading: string;
}

export const appLoadingLanguageResource: LanguageResource<AppLoadingLanguageResource> = {
  nb: {
    appLoading: "Laster Storkundeportalen...",
  }, en: {
    appLoading: "Loading Ruter Business portal...",
  },
};