import { Company, CompanyJson, mapCompany } from "src/types/Company";
import { SearchResult } from "src/types/SearchResult";
import { TravelCardState, parseTravelCardState } from "src/types/TravelCardState";


export interface TravelCardJson {
  id: string,
  requestedAt: string,
  replacementCount: number,
  manufacturedAt: string | null,
  cardNumber: string | null,
  cardName: string | null,
  ownerId: number,
  companyId: string,
  agreementNumber: string,
  productId: number,
  productTemplateId: number,
  profileId: number,
  startDate: string,
  expirationDate: string | null,
  employeeId: string | null,
  lastName: string | null,
  firstName: string | null,
  dateOfBirth: string | null,
  zoneFrom: string,
  zoneTo: string | null,
  nrOfZones: number | null,
  validAllZones: string | null,
  deliveryTo: {
    companyName: string,
    attention: string,
    address: string,
    addressLine2: string,
    postCode: string,
    postArea: string
  },
  state: string,
  originId: string | null,
  hideCardHolderExternally: boolean,
  firstNameExternal: string | null,
  lastNameExternal: string | null,
  addressExternal: string | null
}

export interface TravelCard {
  id: string
  requestedAt: Date
  manufacturedAt: Date | null
  cardNumber: string,
  startDate: Date,
  expirationDate: Date | null,
  cardName: string
}

const mapTravelCard = (json: TravelCardJson): TravelCard => ({
  cardNumber: json.cardNumber || "",
  expirationDate: json.expirationDate ? new Date(json.expirationDate) : null,
  id: json.id,
  manufacturedAt: json.manufacturedAt ? new Date(json.manufacturedAt) : null,
  requestedAt: new Date(json.requestedAt),
  startDate: new Date(json.startDate),
  cardName: json.cardName || "–",
});

export const getValidTravelCard = (json: Partial<TravelCardJson> = {}): TravelCardJson => ({
  id: "3d04c457-a8b2-4f73-b5dc-50ddcd2d158c",
  requestedAt: "2023-08-07T19:59:25Z",
  replacementCount: 0,
  manufacturedAt: null,
  cardNumber: null,
  ownerId: 3,
  companyId: "1226629",
  agreementNumber: "1-3112587081",
  productId: 21251,
  productTemplateId: 12,
  profileId: 1,
  startDate: "2023-08-07T10:58:36Z",
  expirationDate: null,
  employeeId: null,
  lastName: null,
  firstName: null,
  dateOfBirth: null,
  zoneFrom: "1",
  zoneTo: null,
  nrOfZones: null,
  validAllZones: null,
  deliveryTo: {
    companyName: "Unibuss",
    attention: "Alexander Sørnes",
    address: "Post Address 1 It2",
    addressLine2: "Post Address 2 It2",
    postCode: "7922",
    postArea: "post city it2",
  },
  state: "REQUESTED",
  originId: null,
  hideCardHolderExternally: false,
  firstNameExternal: null,
  lastNameExternal: null,
  addressExternal: null,
  cardName: "Card-id 123",
  ...json,
});

export interface TravelCardManufactureJson {
  cards: Array<TravelCardJson>
  company: CompanyJson
  manufacturedDate: string | null
  state: string
}

export interface TravelCardManufacture {
  cards: Array<TravelCard>
  company: Company
  manufacturedDate: Date | null
  state: TravelCardState
}

const mapTravelCardManufacture = (json: TravelCardManufactureJson): TravelCardManufacture => ({
  cards: json.cards.map(mapTravelCard),
  company: mapCompany(json.company),
  manufacturedDate: json.manufacturedDate ? new Date(json.manufacturedDate) : null,
  state: parseTravelCardState(json.state),
});

export const mapTravelCardManufactureSearchResult = (json: SearchResult<TravelCardManufactureJson>): SearchResult<TravelCardManufacture> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapTravelCardManufacture),
});
