import { Button, ButtonGroup, DropdownFormInput, Message, Modal, TextFormInput, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import React, { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { AgreementInvoicingInterval } from "src/types/AgreementInvoicingInterval";
import { AgreementSubTypeId, parseAgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { Agreement } from "src/types/Company";
import { Tenant, getTenant } from "src/types/Tenant";
import companyPageLanguageResource from "./lang-resource";

interface CallbackProps {
  type: "callback";
  onClose: () => void;
  agreement: Agreement;
  onSave: (agreement: Agreement) => void;
}

interface SaveToBackendProps {
  type: "saveToBackend",
  onClose: () => void;
  agreement: Agreement;
  companyId: string;
  onSuccess: () => void;
}

type Props = SaveToBackendProps | CallbackProps;

interface AgreementPutContract {
  invoiceReference: string;
  invoicingInterval: AgreementInvoicingInterval;
  paymentDeadlineDays: number;
}

interface AgreementPostContract extends AgreementPutContract {
  subType: AgreementSubTypeId;
}

interface FormFields {
  subType: string;
  invoiceReference: string;
  invoicingInterval: AgreementInvoicingInterval | "";
  paymentDeadlineDays: string;
}

const EditAgreementModal: React.FC<Props> = (props) => {
  const { agreement, onClose } = props;
  const lang = useLanguageResource(companyPageLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);
  const [submitFailure, setSubmitFailure] = useState(false);

  const disableAgreementSubTypeId = Boolean(props.type === "saveToBackend" && agreement.id);

  const formMethods = useForm<FormFields>({
    defaultValues: {
      subType: agreement.subTypeId,
      invoiceReference: agreement.invoiceReference,
      invoicingInterval: agreement.invoicingInterval === AgreementInvoicingInterval.OTHER ? "" : agreement.invoicingInterval,
      paymentDeadlineDays: agreement.paymentDeadline?.toString(),
    },
  });

  const saveAgreement = async (data: FormFields) => {
    if (data.invoicingInterval === "") {
      throw new Error("invoicinginterval is empty string, this should have been caught in validation");
    }
    if (props.type === "callback") {
      const { onSave } = props;
      onSave({
        endDate: null,
        fromDate: null,
        id: agreement.id,
        invoiceReference: data.invoiceReference,
        invoicingInterval: data.invoicingInterval,
        paymentDeadline: parseInt(data.paymentDeadlineDays),
        subTypeId: parseAgreementSubTypeId(data.subType),
      });
      return;
    }

    const { companyId, onSuccess } = props;

    setLoading(true);
    setSubmitFailure(false);

    if (agreement.id) {
      const requestData: AgreementPutContract = {
        invoiceReference: data.invoiceReference,
        invoicingInterval: data.invoicingInterval,
        paymentDeadlineDays: parseInt(data.paymentDeadlineDays),
      };
      const result = await apiClient.put(`/onboarding/company/${companyId}/agreement/${agreement.id}`, requestData);

      if (result.type === "success") {
        onSuccess();
      } else {
        setLoading(false);
        setSubmitFailure(true);
        Sentry.captureException(result.error);
      }

    } else {
      const requestData: AgreementPostContract = {
        subType: parseAgreementSubTypeId(data.subType),
        invoiceReference: data.invoiceReference,
        invoicingInterval: data.invoicingInterval,
        paymentDeadlineDays: parseInt(data.paymentDeadlineDays),
      };
      const result = await apiClient.post(`/onboarding/company/${companyId}/agreement/create`, requestData);

      if (result.type === "success") {
        onSuccess();
      } else {
        setLoading(false);
        setSubmitFailure(true);
        Sentry.captureException(result.error);
      }
    }
  };

  const agreementTypeOptions = useMemo(() => {
    const tenant = getTenant();
    const travelCardFixedPrice = { value: AgreementSubTypeId.TRAVELCARD_FIXED_PRICE, text: lang.travelCardFixedPrice };
    const companyAgreement = { value: AgreementSubTypeId.SUBSCRIPTIONS, text: lang.subscriptions };
    const ticketOffice = { value: AgreementSubTypeId.TICKETOFFICE, text: lang.ticketOffice };
    const freeTicket = { value: AgreementSubTypeId.FREETICKET, text: lang.freeTicket };
    const schoolTicket = { value: AgreementSubTypeId.SCHOOLTICKET, text: lang.schoolTicket };
    const noSubscriptions = { value: AgreementSubTypeId.NO_SUBSCRIPTIONS, text: lang.noSubscriptions };
    const travelCardSubscription = { value: AgreementSubTypeId.TRAVELCARD_SUBSCRIPTION, text: lang.travelCardSubscription };

    switch (tenant) {
      case Tenant.Ruter: return [travelCardSubscription, travelCardFixedPrice, companyAgreement, ticketOffice, freeTicket, schoolTicket, noSubscriptions];
      case Tenant.Akt: return [ticketOffice, freeTicket];
      case Tenant.Brakar: return [ticketOffice, freeTicket];
    }

  }, [lang.travelCardFixedPrice, lang.subscriptions, lang.ticketOffice, lang.freeTicket, lang.schoolTicket, lang.noSubscriptions, lang.travelCardSubscription]);

  const title = agreement.id ? lang.editAgreement.replace("$agreement$", agreement.id) : lang.newAgreement;

  return (
    <Modal isOpen={true} title={title} data-test-id="edit-agreement-modal" handleClose={onClose}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(saveAgreement)}>
          <DropdownFormInput name="subType" disabled={disableAgreementSubTypeId} label={lang.agreementType} required>
            {agreementTypeOptions.map(x => <option value={x.value}>{x.text}</option>)}
          </DropdownFormInput>
          <DropdownFormInput name="invoicingInterval" required label={lang.invoicingInterval}>
            <option value=""></option>
            <option value={AgreementInvoicingInterval.DAILY}>{lang.invoicingIntervalDaily}</option>
            <option value={AgreementInvoicingInterval.WEEKLY}>{lang.invoicingIntervalWeekly}</option>
            <option value={AgreementInvoicingInterval.MONTHLY_AT_END}>{lang.invoicingIntervalMonthlyAtEnd}</option>
            <option value={AgreementInvoicingInterval.QUARTERLY}>{lang.invoicingIntervalQuarterly}</option>
          </DropdownFormInput>
          <TextFormInput name="invoiceReference" label={lang.invoiceReference} />
          {submitFailure && (
            <Message skin="danger" data-test-id="submit-failure" style={{ marginTop: "2rem" }}>
              <p>{lang.submitFailure}</p>
            </Message>
          )}
          <ButtonGroup>
            <Button variant="primary" type="submit" text={formLang.save} loading={loading} />
            <Button variant="cancel" type="button" text={formLang.cancel} data-test-id="cancel-button" onClick={onClose} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditAgreementModal;
