import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TicketCounterWhiteListLanguageResource {
  title: string,
  phoneNumber: string,
  name: string,
  invoiceReference: string,
  newUser: string,
  editUser: string,
  edit: string,
  deleteUser: string
  actions: string
}

const ticketCounterWhiteListLanguageResource: LanguageResource<TicketCounterWhiteListLanguageResource> = {
  nb: {
    title: "Bedriften betaler",
    phoneNumber: "Mobilnummer",
    name: "Navn",
    invoiceReference: "Faktura referanse",
    newUser: "Ny bruker",
    edit: "Endre",
    editUser: "Endre $p1$",
    deleteUser: "Slett $p1$",
    actions: "Handlinger",
  },
  en: {
    title: "Company covers",
    phoneNumber: "Phone number",
    name: "Name",
    invoiceReference: "Invoice reference",
    newUser: "New user",
    edit: "Edit",
    editUser: "Edit $p1$",
    deleteUser: "Delete $p1$",
    actions: "Actions",
  },
};

export default ticketCounterWhiteListLanguageResource;
