import moment, { Moment } from "moment";

interface DateValueAndText {
  date: Date;
  value: string;
  text: string;
}

const createDates = (date: Moment, ...monthIncrements: Array<number>): DateValueAndText[] =>
  monthIncrements.map((x) => {
    const d = date.clone().add(x, "month");
    return {
      date: d.toDate(),
      value: d.format("L"),
      text: d.format("L"),
    };
  });

export const getExpiryDates = (lastDayOfMonthYouCanStillOrderTickets = 6, today: string, numberOfOptions = 3): DateValueAndText[] => {
  const firstInThisMonth = moment().startOf("month");
  const dateWhenOrderingForThisMonthIsNotAllowed = firstInThisMonth.clone().add(lastDayOfMonthYouCanStillOrderTickets, "days");
  const canOrderTicketFromToday = dateWhenOrderingForThisMonthIsNotAllowed.isAfter(moment());

  const monthIncrements = new Array(numberOfOptions).fill(0).map((x, i) => i + 1);

  if (canOrderTicketFromToday) {
    return [
      {
        date: moment().toDate(),
        value: moment().format("L"),
        text: today,
      },
      ...createDates(firstInThisMonth, ...monthIncrements.slice(0, -1)),
    ];
  }

  return createDates(firstInThisMonth, ...monthIncrements);
};

export const ensureExpiryDateIsNotInThePastAndFormatForBackend = (expiryDate: Date | string, buffer = 30): Date => {
  const expiryDateAsMoment = moment(expiryDate, "L");
  const cuttoffPoint = moment().add(buffer, "seconds");

  if (expiryDateAsMoment.isBefore(cuttoffPoint)) {
    return cuttoffPoint.toDate();
  }

  return expiryDateAsMoment.toDate();
};
