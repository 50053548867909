import moment from "moment";

const formatter = {
  date: {
    monthAndYear: (year: number, month: number): string => {
      if (year.toFixed(0) !== year.toString()) {
        throw new Error(`year must be an integer between 1850 and 2100, provided value was "${year}"`);
      }
      if (!(year >= 1850 && year <= 2100)) {
        throw new Error(`year must be an integer between 1850 and 2100, provided value was "${year}"`);
      }
      if (month.toFixed(0) !== month.toString()) {
        throw new Error(`month must be an integer between 1 and 12, provided value was "${month}"`);
      }
      if (!(month >= 1 && month <= 12)) {
        throw new Error(`month must be an integer between 1 and 12, provided value was "${month}"`);
      }

      return moment(new Date(year, month - 1, 1)).format("MMM YYYY");
    },
    shortDate: (date: Date) => {
      return moment(date).format("L");
    },
  },
  number: {
    integer: (value: number): string => {
      if (value.toFixed(0) !== value.toString()) {
        throw new Error(`value must be an integer, provided value was "${value}"`);
      }

      return new Intl.NumberFormat("nb-NO", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
        .format(value)
        .replace(/\u00a0/g, " "); // replaces with normal space, makes assertions easier
    },
    float: (value: number, numberOfDecimals: number): string => {
      const split = value.toString().split(".");
      const decimals = split[1]?.length || 0;

      if (decimals > numberOfDecimals) {
        throw new Error(
          `value cannot be truncated, remove decimals from value before using the formatter function, provided values: value=${value}, numberOfDecimals=${numberOfDecimals}`,
        );
      }

      return new Intl.NumberFormat("nb-NO", {
        minimumFractionDigits: numberOfDecimals,
        maximumFractionDigits: numberOfDecimals,
      })
        .format(value)
        .replace(/\u00a0/g, " "); // replaces with normal space, makes assertions easier;
    },
    currency: (value: number, formatWithDecimals = false): string => {
      const split = value.toString().split(".");
      const decimals = split[1]?.length || 0;
      const formatWithDecimalsCount = formatWithDecimals ? 2 : 0;

      if (decimals > 2) {
        throw new Error(`value can only have a maximum of two decimals, provided value was "${value}"`);
      }

      if (decimals > formatWithDecimalsCount) {
        throw new Error(
          `value cannot be truncated, remove decimals from value before using the formatter function, provided values: value=${value}, formatWithDecimals=${formatWithDecimals}`,
        );
      }

      return new Intl.NumberFormat("nb-NO", {
        style: "currency",
        currency: "NOK",
        minimumFractionDigits: formatWithDecimalsCount,
        maximumFractionDigits: formatWithDecimalsCount,
      })
        .format(value)
        .replace(/\u00a0/g, " ")
        .replace("NOK", "kr"); // replaces with normal space, makes assertions easier;
    },
  },
};

export default formatter;
