import { Button, ButtonGroup, FormGroup, Modal, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useState } from "react";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import successAlert, { failAlert } from "src/common/toastr";
import { CancelButton } from "src/components/common/buttons";
import { SchoolTicketTravelCard } from "src/types/schoolTicketAgreement/schoolTicket";
import "./ReplaceTicket.scss";
import shoolTicketDetailModalLanguageResource from "./lang-resource";
interface Props {
  hide: () => void;
  submitted: () => void;
  ticket: SchoolTicketTravelCard;
}

const ReplaceTicket: React.FC<Props> = ({ hide, submitted, ticket }) => {
  const lang = useLanguageResource(shoolTicketDetailModalLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const response = await apiClient.post(`/schoolticket-api/ticket/${ticket.id}/resend`, {});

    if (response.type === "success") {
      setLoading(false);
      successAlert(lang.replaceTicket.success);
      submitted();
    } else {
      failAlert(lang.replaceTicket.fail);
      setLoading(() => {
        throw response.error;
      });
    }
  };

  return (
    <Modal isOpen={true} title={lang.replaceTicket.title} handleClose={hide} data-test-id="replace-card-window">
      <p>{lang.replaceTicket.info}</p>
      <FormGroup>
        <ButtonGroup>
          <Button
            type="button"
            variant="primary"
            loading={loading}
            text={formLang.yes}
            onClick={handleSubmit}
            data-test-id="confirm-button"
          />
          <CancelButton onClick={() => hide()} data-test-id="cancel-button" />
        </ButtonGroup>
      </FormGroup>
    </Modal>
  );
};

export default ReplaceTicket;
