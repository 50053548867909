import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface AgreementTypeBadgeLanguageResource {
  companyAgreement: string;
  freeTicketAgreement: string;
  ticketCounterAgreement: string;
  schoolTicketAgreement: string;
  fixedPriceTravelCardAgreement: string;
  noAgreements: string;
  other: string;
  noSubscripion: string;
  travelCardSubscription: string;
}

export const agreementTypeBadgeLanguageResource: LanguageResource<AgreementTypeBadgeLanguageResource> = {
  nb: {
    companyAgreement: "Bedriftsbilletter",
    freeTicketAgreement: "Fribilletter",
    ticketCounterAgreement: "Billettluke",
    schoolTicketAgreement: "Skole oslo",
    fixedPriceTravelCardAgreement: "Fastpris reisekort",
    noAgreements: "Ingen avtaler",
    other: "Ukjent",
    noSubscripion: "Uten abo",
    travelCardSubscription: "Bedrift abonnement",
  },
  en: {
    companyAgreement: "Company",
    freeTicketAgreement: "Free ticket",
    ticketCounterAgreement: "Ticket counter",
    schoolTicketAgreement: "School Oslo",
    fixedPriceTravelCardAgreement: "Fixed price travel card",
    travelCardSubscription: "Company subscription",
    noAgreements: "No agreements",
    other: "Unknown",
    noSubscripion: "No subscription",
  },
};