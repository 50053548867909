import { Icon } from "@ruter-ds/rds-components";
import { ChevronRightIcon } from "@ruter-ds/rds-icons";
import React from "react";
import "./GotoColumn.scss";

const GotoColumn: React.FC = () => {
  return (
    <td className="goto-column">
      <Icon component={<ChevronRightIcon />} size="small" />
    </td>
  );
};

export default GotoColumn;
