import { FreeTicketProfile } from "../../../types/freeTicketAgreement/freeTicketProfile";
import { TicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import MediaType from "../../../types/mediaType";

interface FreeTicketFormData {
  agreement: string;
  mediaType: MediaType;
  startDate: string;
  ticketHolderType: TicketHolderType;
  employeeId: string;
  zoneFrom: string | null;
  zoneTo: string | null;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  phone: string;
  profile: FreeTicketProfile;
  cardNumber: string;
}

export enum GeneralErrorType {
  TRAVEL_CARD_NO_COMPANY_POST_ADDRESS = "TRAVEL_CARD_NO_COMPANY_POST_ADDRESS",
}

export enum PerTicketRequestErrorType {
  CONFLICTING_TICKET = "CONFLICTING_TICKET",
}

export interface CreateTicketValidationErrors {
  general: Array<GeneralErrorType>
  perRequest: Array<PerTicketRequestErrorType>
}

export type ErrorType = "CONFLICTING_TICKET" | "TRAVEL_CARD_NO_COMPANY_POST_ADDRESS" | "DUPLICATE_TICKET" | "UNKNOWN";


export default FreeTicketFormData;
