import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TicketHistoryLanguageResource {
  title: string;
  searchPlaceHolder: string;
  purchaseDate: string;
  ticketNumber: string;
  mediaId: string;
  product: string;
  status: string;
  invoiceReference: string;
  personalReference: string;
  unknownProduct: string;
}

const ticketHistoryLanguageResource: LanguageResource<TicketHistoryLanguageResource> = {
  nb: {
    title: "Billetthistorikk",
    searchPlaceHolder: "Filtrer på telefonnummer eller kommentar",
    purchaseDate: "Kjøpsdato",
    ticketNumber: "Billettnr.",
    mediaId: "Billettbærerid.",
    product: "Produkt",
    status: "Status",
    invoiceReference: "Fakturaref.",
    personalReference: "Kommentar",
    unknownProduct: "Ukjent produkt",
  },
  en: {
    title: "Ticket history",
    searchPlaceHolder: 'Filter by "phone number" or comment',
    purchaseDate: "Purchase ddate",
    ticketNumber: "Ticket number",
    mediaId: "Ticket media id",
    product: "Product",
    status: "Status",
    invoiceReference: "Invoice ref.",
    personalReference: "Comment",
    unknownProduct: "Unknown product",
  },
};

export default ticketHistoryLanguageResource;