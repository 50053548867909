import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface UsersLanguageResource {
  title: string;
  newUser: string;
  userName: string;
  firstName: string;
  lastName: string;
  phone: string;
  state: string;
  stateActive: string;
  stateDeleted: string;
  stateInactive: string;
  activateUser: string;
  editUser: string;
  deleteUser: string;
  deleteUserAreYourSure: string;
  cancel: string;
  yes: string;
  deleteUserFail: string;
  reactivateUser: string;
  reactivateUserAreYourSure: string;
  reactivateUserSuccess: string;
}

const usersLanguageResource: LanguageResource<UsersLanguageResource> = {
  nb: {
    title: "Brukere",
    newUser: "Ny bruker",
    userName: "Brukernavn",
    firstName: "Fornavn",
    lastName: "Etternavn",
    phone: "Telefon",
    state: "Status",
    stateActive: "Aktiv",
    stateDeleted: "Slettet",
    stateInactive: "Deaktiv",
    activateUser: "Aktiver bruker",
    editUser: "Endre bruker",
    deleteUser: "Deaktiver bruker",
    deleteUserAreYourSure: "Er du sikker på at du vil deaktivere bruker $firstName $lastName ($userName)?",
    reactivateUserAreYourSure: "Er du sikker på at du ønsker å sende e-post med aktiveringslink til $firstName $lastName ($userName)?",
    cancel: "Avbryt",
    yes: "Ja",
    deleteUserFail: "Det skjedde en feil ved deaktivering av bruker!",
    reactivateUser: "Send ny e-post med aktiveringslink",
    reactivateUserSuccess: "E-post med aktiveringslink sendt!",
  },
  en: {
    title: "Users",
    newUser: "New user",
    userName: "User name",
    firstName: "First name",
    lastName: "Last name",
    phone: "Phone",
    state: "Status",
    stateActive: "Active",
    stateDeleted: "Deleted",
    stateInactive: "Deactive",
    activateUser: "Activate user",
    editUser: "Edit user",
    deleteUser: "Deactivate user",
    deleteUserAreYourSure: "Are you sure that you want to deactivate user $firstName $lastName ($userName)?",
    reactivateUserAreYourSure: "Are you sure that you want to send a new email with activation link $firstName $lastName ($userName)?",
    cancel: "Cancel",
    yes: "Yes",
    deleteUserFail: "An error occured while deactivating user!",
    reactivateUser: "Send new email with activation link",
    reactivateUserSuccess: "Email with activation link sent!",
  },
};

export default usersLanguageResource;
