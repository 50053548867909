interface ContactFormReceiptJson {
  company: {
    companyId: string;
  };
  customer: {
    email: string;
    firstName: string;
    lastName: string;
  };
  description: string;
}

export interface ContactFormReceipt {
  companyId: string,
  email: string,
  firstName: string,
  lastName: string,
  description: string
}

export const ContactFormMapper = (json: ContactFormReceiptJson): ContactFormReceipt => ({
  companyId: json.company.companyId,
  description: json.description,
  firstName: json.customer.firstName,
  email: json.customer.email,
  lastName: json.customer.lastName,
});


export const getValidContactFormRecieptJson = (changes?: Partial<ContactFormReceiptJson>): ContactFormReceiptJson => ({
  company: {
    companyId: "1234567",
  },
  customer: {
    email: "kimhans184@gmail.com",
    firstName: "Kim",
    lastName: "Hansen",
  },
  description: "this is is a test message",
  ...changes,
});
