import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container } from "@ruter-ds/rds-components";
import React from "react";
import { useAuthContext } from "src/AuthContext";
import { UserRight } from "../../common/userRights";
import CallToActionLink from "../common/buttons/CallToActionLink";
import { toolsLanguageResource } from "./lang-resource";

const Tools: React.FC = () => {
  const authContext = useAuthContext();

  const hasAccessToWidget = authContext.authenticated && authContext.userData.userRights.some((x) => x === UserRight.BusinessAgreement);
  const language = useLanguageResource(toolsLanguageResource);

  return (
    <Container width="s" className="components-tools-tools" data-test-id="tools-page">
      <h1>{language.title}</h1>

      <div data-test-id="real-time-screen">
        <h2>{language.realTimeTitle}</h2>
        <p>{language.realTimeIngress}</p>
        <CallToActionLink text={language.realTimeLink} externalUrl="https://ruter.no/om-ruter/utviklerverktoy/egen-sanntidsskjerm/" showIcon={true} />
      </div>

      {hasAccessToWidget && (
        <>
          <div data-test-id="company-tickets-widget">
            <h2>{language.companyTicketsWidgetTitle}</h2>
            <p>{language.companyTicketsWidgetIngress}</p>
            <CallToActionLink path="/verktoy/widget" text={language.companyTicketsWidgetLink} showIcon={true} />
          </div>

          <div data-test-id="company-tickets-link">
            <h2>{language.companyTicketsLinkTitle}</h2>
            <p>{language.companyTicketsLinkIngress}</p>
            <CallToActionLink path="/verktoy/link" text={language.companyTicketsLinkLink} showIcon={true} />
          </div>
        </>
      )}
    </Container>
  );
};

export default Tools;
