import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface MassEndDateLanguageResource {
  title: string;
  agreementFilter: string;
  missingDepartmentName: string;
  cancelTickets: string;
  noTickets: string;
  confirmModalTitle: string;
  confirmModalText: string;
  confirmStatus: string;
  ticketsCancelSuccessToastr: string;
  allDepartments: string;

  freeTicketCount: string;
  cancelledTicketsCount: string;
  ticketsEligibleCount: string;

  ticketsFilteredByDepartment: string;
  ticketsFilteredByEndDate: string;

  fileHeader: string;
  fileDescriptionPreLink: string;
  fileDesciptionLink: string;
  fileDescriptionPostLink: string;

  exampleFileName: string;
  employeeNumber: string;
  birthDate: string;
  addFromCsv: string;

  rowInFileCount: string;
  invalidRowCount: string;
  validRowCount: string;
  unmatcedRows: string;
  remove: string;
}

export const massEndDateLanguageResource: LanguageResource<MassEndDateLanguageResource> = {
  nb: {
    title: "Avslutt billetter",
    agreementFilter: "Avdelingsfilter",
    missingDepartmentName: "Avdelingsnavn ikke satt",
    cancelTickets: "Avslutt billetter",
    noTickets: "Det finnes ingen billetter som kan avsluttes for valgt dato og avdeling",
    confirmModalTitle: "Bekreft avslutning av billetter",
    confirmModalText: "Er du sikker på at vil avslutte ${number} billetter",
    confirmStatus: "Kansellerer billett ${ticketNumber}/${totalTickets}",
    ticketsCancelSuccessToastr: "Kansellert ${ticketCount} billetter!",
    allDepartments: "Alle avdelinger",

    freeTicketCount: "Antall fribilletter",
    cancelledTicketsCount: "Avsluttede billetter",
    ticketsEligibleCount: "Billetter som kan avsluttes",

    ticketsFilteredByDepartment: "Billetters om tilhører annen avdeling",
    ticketsFilteredByEndDate: "Billetter avsluttet før ${endDate}",

    fileHeader: "Last opp fil",
    fileDescriptionPreLink: "Her kan du laste opp en fil med ansattnr og fødselsdato for de billettene du ønsker å avslutte. Trykk ",
    fileDesciptionLink: "her",
    fileDescriptionPostLink: " for å se et eksempel på hvordan filen skal se ut.",

    exampleFileName: "avslutning av fribillett eksempel",
    employeeNumber: "Ansattnr",
    birthDate: "Fødselsdato",
    addFromCsv: "Import fra CSV-fil",

    rowInFileCount: "Antall rader i fil",
    invalidRowCount: "Antall ugyldige rader",
    validRowCount: "Antall gyldige rader",
    unmatcedRows: "Rader uten treff i fribillett listen",
    remove: "Fjern",
  },
  en: {
    title: "Cancel tickets",
    agreementFilter: "Departmentf filter",
    missingDepartmentName: "Department name not set",
    cancelTickets: "Cancel tickets",
    noTickets: "There are no tickets to cancel for selected date and department",
    confirmModalTitle: "Confirm cancellation of tickets",
    confirmModalText: "Are you sure you want to cancel ${number} tickets",
    confirmStatus: "Cancelling ticket ${ticketNumber}/${totalTickets}",
    ticketsCancelSuccessToastr: "Cancelled ${ticketCount} tickets!",
    allDepartments: "All departments",

    freeTicketCount: "Freetickets",
    cancelledTicketsCount: "Cancelled tickets",
    ticketsEligibleCount: "Tickets eligible for cancellation ",

    ticketsFilteredByDepartment: "Tickets belonging to another department",
    ticketsFilteredByEndDate: "Tickets cancelled before ${endDate}",

    fileHeader: "Upload file",
    fileDescriptionPreLink: "You can upload a file with employee number and birt date for those tickets you want to cancel. Click ",
    fileDesciptionLink: "here",
    fileDescriptionPostLink: " to see an exampel of how the file should look like.",

    exampleFileName: "cancellation of free-ticket example",
    employeeNumber: "Employee number",
    birthDate: "Birth date",
    addFromCsv: "Import from CSV-file",

    rowInFileCount: "Number of rows in file",
    invalidRowCount: "Number of invalid rows",
    validRowCount: "Number of valid rows",
    unmatcedRows: "Number of rows without match in free-ticket list",
    remove: "Remove",
  },
};