interface WidgetJson {
  agreementNumbers: string[];
  authCompanyIds: string[];
  clientId: string;
  clientSecret: string;
  colourScheme: string;
  companyIds: string[];
  employeeIdHelpText: string;
  employeeIdRegex: string;
  format: string;
  id: string;
  logo: string;
  width: number;
}

export interface Widget {
  clientId: string;
  clientSecret: string;
}

export const mapWidget = (json: WidgetJson): Widget => ({
  clientId: json.clientId,
  clientSecret: json.clientSecret,
});



export const getValidWidgetJson = (changes?: Partial<WidgetJson>): WidgetJson => ({
  id: "99ad19ec-8ce3-4204-af75-f440e76a067e",
  clientId: "bcwidget-99ad19ec-8ce3-4204-af75-f440e76a067e@ruter.no",
  clientSecret: "ekVZcnObiUDKLBA3BN_TyD9a0IKqUr",
  companyIds: ["1226629"],
  authCompanyIds: ["1-1E8JX3N"],
  agreementNumbers: ["1-3369898706", "1-3059740591"],
  format: "horisontal",
  width: 320,
  colourScheme: "light",
  logo: "",
  employeeIdHelpText: "",
  employeeIdRegex: "",
  ...changes,
});
