import * as Sentry from "@sentry/react";
import React from "react";
import "react-day-picker/dist/style.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "src/AuthContext";
import getEnvironment, { EnvName } from "src/common/env";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRight } from "../../common/userRights";
import { Tenant, getTenant } from "../../types/Tenant";
import CompaniesPage from "../companyAdmin/companies/CompaniesPage";
import CompanyPage from "../companyAdmin/company/CompanyPage";
import CreateCompanyPage from "../companyAdmin/createCompany/CreateCompany";
import CreateTicketform from "../companyAgreement/createTicket/CreateTicketForm";
import EmployeeTicketsPage from "../companyAgreement/employeeTickets/EmployeeTicketsPage";
import EmployeesPage from "../companyAgreement/employees/EmployeesPage";
import AgreementInfo from "../companyAgreement/info/AgreementInfo";
import InvoicePage from "../companyAgreement/invoice/Invoice";
import InvoiceDetails from "../companyAgreement/invoiceDetails/InvoiceDetails";
import PayrollPage from "../companyAgreement/payroll/PayrollPage";
import Settings from "../companyAgreement/settings/Settings";
import TicketRequest from "../companyAgreement/ticketRequest/TicketRequest";
import LinkPage from "../companyAgreement/widget/LinkPage";
import WidgetPage from "../companyAgreement/widget/WidgetPage";
import CompanyInfoPage from "../companyInfo/MyPage";
import ContactUs from "../contactUs/ContactUs";
import FreeTicketForm from "../freeTicketAgreement/freeTicket/FreeTicketForm";
import FreeTickets from "../freeTicketAgreement/freeTickets/FreeTickets";
import FreeTicketsFamily from "../freeTicketAgreement/freeTicketsFamily/FreeTicketsFamily";
import FreeTicketInfo from "../freeTicketAgreement/info/FreeTicketInfo";
import FreeTicketInvoiceBasePage from "../freeTicketAgreement/invoiceBase/FreeTicketInvoiceBasePage";
import FreeTicketInvoiceBaseDetailsPage from "../freeTicketAgreement/invoiceBaseDetails/FreeTicketInvoiceBaseDetailsPage";
import MassChangeDepartment from "../freeTicketAgreement/massChangeDepartment/MassChangeDepartment";
import MassEndDate from "../freeTicketAgreement/massEndDate/MassEndDate";
import MassOrderCreate from "../freeTicketAgreement/massOrder/MassOrderCreate";
import MassOrders from "../freeTicketAgreement/massOrders/MassOrders";
import FreeTicketSettingsPage from "../freeTicketAgreement/settings/FreeTicketSettingsPage";
import TaxBase from "../freeTicketAgreement/taxBase/TaxBasePage";
import TaxBaseChangesPage from "../freeTicketAgreement/taxBaseChanges/TaxBaseChangesPage";
import TravelCardTodoPage from "../freeTicketAgreement/travelCard/TravelCardTodo";
import Home from "../home/Home";
import ObosTestCustomer from "../obosTestCustomer/ObosTestCustomer";
import OnboardingCallback from "../onboarding/OnboardingCallback";
import SchoolTicketDetail from "../schoolTicket/schoolTicketDetail/SchoolTicketDetail";
import SchoolTicketOrder from "../schoolTicket/schoolTicketOrder/SchoolTicketOrder";
import SchoolTicketOrderHistory from "../schoolTicket/schoolTicketOrderHistory/SchoolTicketOrderHistory";
import SimpleOnboarding from "../simpleOnboarding/SimpleOnboarding";
import RuterBedrift from "../terms/RuterBedrift";
import TermsAndConditions from "../terms/TermsAndConditions";
import TicketCounterInfo from "../ticketCounter/info/TicketCounterInfo";
import TicketCounterOrder from "../ticketCounter/order/TicketCounterOrder";
import OrderDetails from "../ticketCounter/orderDetails/orderDetails";
import OrderHistory from "../ticketCounter/orderHistory/OrderHistory";
import TicketCounterSettings from "../ticketCounter/settings/TicketCounterSettings";
import TicketHistory from "../ticketCounter/ticketHistory/TicketHistory";
import TicketCounterWhitelist from "../ticketCounter/ticketWhitelist/TicketCounterWhitelist";
import Tools from "../tools/Tools";
import TravelCardDetails from "../travelCardAdmin/travelCardDetails/TravelCardDetails";
import TravelCardSearchPage from "../travelCardAdmin/travelCardSearchPage/TravelCardSearchPage";
import UserPage from "../userAdmin/user/UserPage";
import UsersPage from "../userAdmin/users/UsersPage";
import "./App.scss";
import CypressTestData from "./CypressTestData";
import RootRedirect from "./RootRedirect";
import SecureComponent, { RequiredRoles, RequiredUserRight } from "./SecureComponent";
import Footer from "./footer/Footer";
import GenericError from "./genericError/GenericError";
import Header from "./header/Header";
import MissingRole from "./missingRole/MissingRole";
import MissingUserRights from "./missingUserRights/MissingUserRights";
import NoBusinessAgreement from "./noAgreement/NoBusinessAgreement";
import NoFreeTicketAgreement from "./noAgreement/NoFreeTicketAgreement";
import NoSchoolTicketAgreement from "./noAgreement/NoSchoolTicketAgreement";
import NoTicketAgreement from "./noAgreement/NoTicketAgreement";
import NotFoundComponent from "./notFound/NotFoundComponent";

const requireBusinessAgreement: RequiredUserRight = {
  userRight: [UserRight.BusinessAgreement],
  errorComponent: NoBusinessAgreement,
};

const requireActiveBusinessAgreement: RequiredUserRight = {
  userRight: [UserRight.BusinessAgreement],
  errorComponent: NoBusinessAgreement,
  requireActiveAgreement: true,
};

const invoiceUserRights: RequiredUserRight = {
  userRight: [UserRight.BusinessAgreement, UserRight.TicketCounterAgreeement, UserRight.SchoolTicketAdmin, UserRight.FreeTicketAgreeement],
  errorComponent: NoBusinessAgreement,
};

const requireActiveTicketCounterAgreement: RequiredUserRight = {
  userRight: [UserRight.TicketCounterAgreeement],
  errorComponent: NoTicketAgreement,
  requireActiveAgreement: true,
};

const requireTicketCounterAgreement: RequiredUserRight = {
  userRight: [UserRight.TicketCounterAgreeement],
  errorComponent: NoTicketAgreement,
  requireActiveAgreement: false,
};

const requireTicketAdminRole: RequiredRoles = {
  errorComponent: MissingRole,
  roles: [CompanyRoleType.TICKET_ADMIN],
};

const requireSingleRole: RequiredRoles = {
  errorComponent: MissingRole,
  roles: [CompanyRoleType.TICKET_ADMIN, CompanyRoleType.COMPANY_ADMIN, CompanyRoleType.USER_ADMIN],
};

const requireCompanyAdminOrUserAdminRole: RequiredRoles = {
  errorComponent: MissingRole,
  roles: [CompanyRoleType.USER_ADMIN, CompanyRoleType.COMPANY_ADMIN],
};


const requireUserAdmin: RequiredUserRight = {
  userRight: [UserRight.GlobalUserAdmin, UserRight.UserAdmin],
  errorComponent: MissingUserRights,
};

const requireFreeTickets: RequiredUserRight = {
  userRight: [UserRight.FreeTicketAgreeement],
  errorComponent: NoFreeTicketAgreement,
};

const requireActiveFreeTicketsAgreement: RequiredUserRight = {
  userRight: [UserRight.FreeTicketAgreeement],
  errorComponent: NoFreeTicketAgreement,
  requireActiveAgreement: true,
};

const requireGlobalFreeTicketAdminAndBrakar: RequiredUserRight = {
  userRight: [UserRight.GlobalTravelCardAdmin],
  errorComponent: MissingUserRights,
  tenants: [Tenant.Brakar],
};

const requireGlobalTravelCardAdminAndRuter: RequiredUserRight = {
  userRight: [UserRight.GlobalTravelCardAdmin],
  errorComponent: MissingUserRights,
  tenants: [Tenant.Ruter],
};

const requireCompanyAdmin: RequiredUserRight = {
  userRight: [UserRight.CompanyAdmin],
  errorComponent: MissingUserRights,
};

const requireSchoolTicketAdmin: RequiredUserRight = {
  userRight: [UserRight.SchoolTicketAdmin, UserRight.GlobalSchoolTicketAdmin],
  errorComponent: NoSchoolTicketAgreement,
};

const requireActiveSchoolTicketAgreement: RequiredUserRight = {
  userRight: [UserRight.SchoolTicketAdmin],
  errorComponent: NoSchoolTicketAgreement,
  requireActiveAgreement: true,
};

const App: React.FC = () => {
  const tenant = getTenant();
  const authContext = useAuthContext();
  const location = useLocation();

  if (tenant === Tenant.Brakar) {
    document.title = "BrakarBedrift";
  } else if (tenant === Tenant.Ruter) {
    document.title = "RuterBedrift";
  } else if (tenant === Tenant.Akt) {
    document.title = "AktBedrift";
  }

  const env = getEnvironment();

  const isTestCustomer = authContext.authenticated && authContext.userData.selectedCompany?.id === "1229668" && env !== EnvName.Production;

  return (
    <div id="ruter-bedrift-app">
      <ToastContainer className="toast-container" toastClassName="toast" />
      <Header />
      <main>
        <Sentry.ErrorBoundary key={location.key} fallback={() => <GenericError />}>
          <Routes>
            {/* public routes */}
            <Route path="/bedriftsavtale/info" element={<AgreementInfo />} />
            <Route path="/billettluka/info" element={<TicketCounterInfo />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/register" element={<Onboarding />} /> */}
            {tenant === Tenant.Ruter && <Route path="/register" element={<SimpleOnboarding />} />}
            <Route path="/register/verify-phone" element={<OnboardingCallback />} />
            <Route path="/verktoy" element={<Tools />} />
            <Route path="/vilkar" element={<TermsAndConditions />} />
            <Route path="/vilkar/ruterbedrift" element={<RuterBedrift />} />
            <Route path="/fribilletter/info" element={<FreeTicketInfo />} />

            {/* Requires SchoolTicketAgreement */}
            <Route path="/skole" element={
              <SecureComponent requiredUserRight={requireSchoolTicketAdmin}>
                <SchoolTicketOrderHistory />
              </SecureComponent>
            } />
            <Route path="/skole/bestill" element={
              <SecureComponent requiredUserRight={requireActiveSchoolTicketAgreement}>
                <SchoolTicketOrder />
              </SecureComponent>
            } />
            <Route path="/skole/:id" element={
              <SecureComponent requiredUserRight={requireSchoolTicketAdmin}>
                <SchoolTicketDetail />
              </SecureComponent>
            } />

            {/* Requires BusinessAgreement */}
            <Route path="/bedriftsavtale" element={
              <SecureComponent requiredUserRight={requireBusinessAgreement}>
                <EmployeesPage />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/faktura/:id" element={
              <SecureComponent requiredUserRight={invoiceUserRights}>
                <InvoiceDetails />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/lonnstrekk" element={
              <SecureComponent requiredUserRight={requireBusinessAgreement}>
                <PayrollPage />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/innstillinger" element={
              <SecureComponent requiredUserRight={requireBusinessAgreement}>
                <Settings />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/godkjenning" element={
              <SecureComponent requiredUserRight={requireBusinessAgreement}>
                <TicketRequest />
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/billett/opprett" element={
              <SecureComponent requiredUserRight={requireActiveBusinessAgreement}>
                {isTestCustomer
                  ? (<ObosTestCustomer />)
                  : (<CreateTicketform />)}
              </SecureComponent>
            } />
            <Route path="/bedriftsavtale/ansatt/:id" element={
              <SecureComponent requiredUserRight={requireBusinessAgreement}>
                <EmployeeTicketsPage />
              </SecureComponent>
            } />
            <Route path="/verktoy/widget" element={
              <SecureComponent requiredUserRight={requireBusinessAgreement}>
                <WidgetPage />
              </SecureComponent>
            } />
            <Route path="/verktoy/link" element={
              <SecureComponent requiredUserRight={requireBusinessAgreement}>
                <LinkPage />
              </SecureComponent>
            } />


            {/* Requires TicketCounterAgreement */}
            <Route path="/billettluka" element={
              <SecureComponent requiredUserRight={requireActiveTicketCounterAgreement} requiredRoles={requireTicketAdminRole}>
                <TicketCounterOrder />
              </SecureComponent>
            } />
            <Route path="/billettluka/ordrehistorikk" element={
              <SecureComponent requiredUserRight={requireTicketCounterAgreement} requiredRoles={requireSingleRole}>
                <OrderHistory />
              </SecureComponent>
            } />
            <Route path="/billettluka/ordrehistorikk/:id" element={
              <SecureComponent requiredUserRight={requireTicketCounterAgreement} requiredRoles={requireSingleRole}>
                <OrderDetails />
              </SecureComponent>
            } />
            <Route path="/billettluka/billetthistorikk/" element={
              <SecureComponent requiredUserRight={requireTicketCounterAgreement} requiredRoles={requireSingleRole}>
                <TicketHistory />
              </SecureComponent>
            } />
            <Route path="/billettluka/innstillinger/" element={
              <SecureComponent requiredUserRight={requireActiveTicketCounterAgreement} requiredRoles={requireCompanyAdminOrUserAdminRole}>
                <TicketCounterSettings />
              </SecureComponent>
            } />
            {env !== EnvName.Production && <Route path="/billettluka/bedriften-betaler" element={
              <SecureComponent requiredUserRight={requireActiveTicketCounterAgreement} requiredRoles={requireCompanyAdminOrUserAdminRole}>
                <TicketCounterWhitelist />
              </SecureComponent>
            } />}
            {/* Requires BusinessAgreement or TicketCounterAgreement */}
            <Route path="/faktura" element={
              <SecureComponent requiredUserRight={invoiceUserRights}>
                <InvoicePage />
              </SecureComponent>
            } />

            {/* Requires GlobalUserAdmin or UserAdmin */}
            <Route path="/brukere" element={
              <SecureComponent requiredUserRight={requireUserAdmin}>
                <UsersPage />
              </SecureComponent>
            } />
            <Route path="/brukere/ny" element={
              <SecureComponent requiredUserRight={requireUserAdmin}>
                <UserPage />
              </SecureComponent>
            } />
            <Route path="/brukere/:id" element={
              <SecureComponent requiredUserRight={requireUserAdmin}>
                <UserPage />
              </SecureComponent>
            } />

            {/* Requires GlobalUserAdmin and Brakar */}
            <Route path="/fribilletter/reisekort" element={
              <SecureComponent requiredUserRight={requireGlobalFreeTicketAdminAndBrakar}>
                <TravelCardTodoPage />
              </SecureComponent>
            } />

            {/* Requires FreeTicketAgreement */}
            <Route path="/fribilletter/massebestillinger" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <MassOrders />
              </SecureComponent>
            } />
            <Route path="/fribilletter/massebestillinger/ny" element={
              <SecureComponent requiredUserRight={requireActiveFreeTicketsAgreement}>
                <MassOrderCreate />
              </SecureComponent>
            } />
            <Route path="/fribilletter/massebestillinger/avslutt" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <MassEndDate />
              </SecureComponent>
            } />
            <Route path="/fribilletter/massebestillinger/byttavdeling" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <MassChangeDepartment />
              </SecureComponent>
            } />
            <Route path="/fribilletter" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <FreeTickets />
              </SecureComponent>
            } />
            <Route path="/fribilletter/ny" element={
              <SecureComponent requiredUserRight={requireActiveFreeTicketsAgreement}>
                <FreeTicketForm />
              </SecureComponent>
            } />
            <Route path="/fribilletter/endringer" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <TaxBaseChangesPage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/skattegrunnlag" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <TaxBase />
              </SecureComponent>
            } />
            <Route path="/fribilletter/fakturagrunnlag" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <FreeTicketInvoiceBasePage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/fakturagrunnlag/:id" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <FreeTicketInvoiceBaseDetailsPage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/innstillinger" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <FreeTicketSettingsPage />
              </SecureComponent>
            } />
            <Route path="/fribilletter/:id" element={
              <SecureComponent requiredUserRight={requireFreeTickets}>
                <FreeTicketsFamily />
              </SecureComponent>
            } />

            {/* Required TravelCardAdmin and tenant = ruter */}
            <Route path="/reisekort" element={
              <SecureComponent requiredUserRight={requireGlobalTravelCardAdminAndRuter}>
                <TravelCardSearchPage />
              </SecureComponent>
            } />
            <Route path="/reisekort/:companyId/:date" element={
              <SecureComponent requiredUserRight={requireGlobalTravelCardAdminAndRuter}>
                <TravelCardDetails />
              </SecureComponent>
            } />

            {/* Requires CompanyAdmin */}
            <Route path="/bedrifter/ny" element={
              <SecureComponent requiredUserRight={requireCompanyAdmin}>
                <CreateCompanyPage />
              </SecureComponent>
            } />
            <Route path="/bedrifter/:id" element={
              <SecureComponent requiredUserRight={requireCompanyAdmin}>
                <CompanyPage />
              </SecureComponent>
            } />
            <Route path="/bedrifter" element={
              <SecureComponent requiredUserRight={requireCompanyAdmin}>
                <CompaniesPage />
              </SecureComponent>
            } />

            {/* Requires that user is authenticated */}
            <Route path="/minside" element={
              <SecureComponent>
                <CompanyInfoPage />
              </SecureComponent>
            } />
            <Route path="/kontaktoss" element={
              <SecureComponent>
                <ContactUs />
              </SecureComponent>
            } />

            <Route path="/" element={<RootRedirect />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </Sentry.ErrorBoundary>
      </main>
      <Footer />
      <CypressTestData />
    </div>
  );
};

export default App;
