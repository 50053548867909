import { Navigate } from "react-router-dom";
import { useAuthContext } from "src/AuthContext";
import { hasValidAgreement } from "src/common/agreements";
import { USER_ROLE_TICKET_ADMIN } from "src/constants";
import { Tenant, getTenant } from "src/types/Tenant";

const RootRedirect: React.FC = () => {
  const authContext = useAuthContext();


  if (!authContext.authenticated) {
    return <Navigate to="/home" />;
  }

  const { contact, selectedCompany } = authContext.userData;
  const hasTicketAdminRole = selectedCompany.roles.some(role => role === USER_ROLE_TICKET_ADMIN);


  const tenant = getTenant();

  if (contact.roles.some((x) => x === "GLOBAL_TRAVEL_CARD_ADMIN")) {
    if (tenant === Tenant.Brakar) {
      return <Navigate to="/fribilletter/reisekort" />;
    } else {
      return <Navigate to="/reisekort" />;
    }
  }
  if (selectedCompany.ticketCounterAgreements.length > 0) {
    if (hasTicketAdminRole && hasValidAgreement(selectedCompany.ticketCounterAgreements)) {
      return <Navigate to="/billettluka" />;
    } else {
      return <Navigate to="/billettluka/ordrehistorikk" />;
    }
  }
  if (selectedCompany.businessTicketAgreements.length > 0) {
    return <Navigate to="/bedriftsavtale" />;
  }
  if (selectedCompany.freeTicketAgreements.length > 0) {
    return <Navigate to="/fribilletter" />;
  }
  if (selectedCompany.schoolTicketAgreements.length > 0) {
    return <Navigate to="/skole" />;
  }

  return <Navigate to="/home" />;
};

export default RootRedirect;