import { LanguageResource } from "@ruter-as/web-components-and-tools";
export interface ShoolTicketDetailLanguageResource {
  address: string;
  schoolName: string;
  phoneNumber: string;
  studentId: string;
  cancelActiveTicket: string;
  cancelPendingTicket: string;
  tickets: string;
  expired: string;
  yes: string;
  no: string;
  status: string;
  orderNumber: string;
  validFrom: string;
  validTo: string;
  pickedUp: string;
  pickupCode: string;
  appiId: string;
  editTicket: string;
  resendTicket: string;
  sendPickupCode: string;
  invoiceRef: string;
  statuses: {
    requested: string;
    created: string;
    deactivated: string;
    cancelled: string;
    anonymised: string;
  };
  unknown: string;
  productionStatus: string;
  travelCardNumber: string;
  manufacturedAt: string;
  pricePerMonth: string;
  orderReplacementCard: string;
  from: string;
  cancelActiveTicketInfoTitle: string;
  cancelActiveTicketInfoPrivacy: string;
  cancelActiveTicketSuccess: string;
  cancelActiveTicketFail: string;
  confirmPendingTicketCancel: string;
  cancelPendingTicketSuccess: string;
  cancelPendingTicketFail: string;
  sendPickCodeSuccess: string;
  sendPickCodeFail: string;
  resendTicketSuccess: string;
  resendTicketFail: string;
  confirmResendTicket: string;
  confirmSendPickupCode: string;
  confirmReplaceCardInfo: string;
  confirmReplaceCard: string;
  mediaType: string;
  travelCard: string;
  mobileTicket: string;
  name: string;
  changesRequireNewTicket: string;
  cardName: string;
  className: string;
  chooseMediaType: string;
  replaceTicket: string;
  zones: string;
}

const shoolTicketDetailLanguageResource: LanguageResource<ShoolTicketDetailLanguageResource> = {
  nb: {
    address: "Adresse",
    schoolName: "Skole",
    phoneNumber: "Telefonnummer",
    studentId: "Elev-id",
    cancelPendingTicket: "Kanseller billett",
    cancelActiveTicket: "Avslutt billett",
    tickets: "Billetter",
    expired: "Utløpt",
    yes: "Ja",
    no: "Nei",
    appiId: "App ID",
    invoiceRef: "Fakturaref.",
    orderNumber: "Billettnummer",
    pickedUp: "Hentet",
    pickupCode: "Hentekode",
    resendTicket: "Send billett på nytt",
    sendPickupCode: "Send hentekode",
    status: "Status",
    validFrom: "Gyldig fra",
    validTo: "Gyldig til",
    statuses: {
      requested: "Bestilt",
      created: "Produsert",
      deactivated: "Sperret",
      cancelled: "Kansellert",
      anonymised: "Anonymisert",
    },
    unknown: "Ukjent",
    manufacturedAt: "Produksjonsdato",
    orderReplacementCard: "Bestill erstatningskort",
    pricePerMonth: "Pris/mnd",
    productionStatus: "Produksjonsstatus",
    travelCardNumber: "Reisekort #",
    from: "Fra",
    cancelActiveTicketInfoTitle: "Vedrørende avslutning av skolebillett",
    cancelActiveTicketInfoPrivacy: "Billetten avsluttes $expiryDate$. Personopplysninger slettes 3 måneder senere.",
    cancelActiveTicketSuccess: "Billett avsluttet!",
    cancelActiveTicketFail: "Det skjedde en feil ved avslutting av billett",
    confirmPendingTicketCancel: "Er du sikker på at du ønsker å kansellere billetten?",
    cancelPendingTicketSuccess: "Billett kansellert!",
    cancelPendingTicketFail: "Det skjedde en feil ved kansellering av billett",
    sendPickCodeSuccess: "Hentekode sendt",
    sendPickCodeFail: "Klarte ikke sende hentekode",
    resendTicketSuccess: "Ticket resent",
    resendTicketFail: "Could not resend ticket",
    confirmResendTicket:
      "Vil du lage ny billett og sende ut SMS med hentekode til eleven? Den opprinnelige billetten vil bli avsluttet umiddelbart.",
    confirmSendPickupCode: "Vil du sende ut ny SMS med hentekode til eleven?",
    confirmReplaceCardInfo:
      "Nytt reisekort vil bli produsert og sendt ut så snart som mulig (faktureres 50 kroner). Gammelt reisekort vil sperres umiddelbart.",
    confirmReplaceCard: "Er du sikker på at du ønsker å bestille erstatningskort?",
    mediaType: "Billettbærer",
    travelCard: "Reisekort",
    mobileTicket: "Mobilbillett",
    name: "Navn",
    changesRequireNewTicket: "Følgende endringer gjør at vi må avslutte nåværende billett og opprette en ny:",
    chooseMediaType: "Velg billettbærer",
    replaceTicket: "Erstatt kort",
    cardName: "Kortnavn",
    className: "Klasse",
    editTicket: "Endre billett",
    zones: "Soner",
  },
  en: {
    address: "Address",
    schoolName: "School",
    phoneNumber: "Phone number",
    studentId: "Student-id",
    cancelActiveTicket: "Cancel active ticket",
    cancelPendingTicket: "Cancel pending ticket",
    tickets: "Tickets",
    expired: "Expired",
    yes: "Yes",
    no: "No",
    appiId: "App-ID",
    invoiceRef: "Agreement/department",
    orderNumber: "Ticket number",
    pickedUp: "Picked up",
    pickupCode: "Pickup code",
    resendTicket: "Resend ticket",
    sendPickupCode: "Send pickup code",
    status: "Status",
    validFrom: "Valid from",
    validTo: "Valid to",
    statuses: {
      requested: "Ordered",
      created: "Produced",
      deactivated: "Cancelled",
      cancelled: "Cancelled",
      anonymised: "Anonymised",
    },
    unknown: "Unknown",
    manufacturedAt: "Manufactured at",
    orderReplacementCard: "Order replacement card",
    pricePerMonth: "Price/month",
    productionStatus: "Production status",
    travelCardNumber: "Travel card #",
    from: "From",
    cancelActiveTicketInfoTitle: "Regarding cancellation of ticket.",
    cancelActiveTicketInfoPrivacy:
      "The ticket will be cancelled on $expiryDate$. Stored information about the employee holding this ticket, will be deleted 3 months later.",
    cancelActiveTicketSuccess: "Ticket cancelled!",
    cancelActiveTicketFail: "An error occured while we tried to cancel a ticket!",
    confirmPendingTicketCancel: "Are you sure you want to cancel the ticket?",
    cancelPendingTicketSuccess: "Ticket cancelled!",
    cancelPendingTicketFail: "An error occured while we tried to cancel a ticket!",
    sendPickCodeSuccess: "Pickup code sent",
    sendPickCodeFail: "Could not send pickup code",
    resendTicketSuccess: "Ticket resent",
    resendTicketFail: "Could not resend ticket",
    confirmResendTicket:
      "Are you sure you want to create a new ticket and send an SMS with a new pickup code to the employee? The original ticket will be cancelled immediately.",
    confirmSendPickupCode: "Are you sure you want to send an SMS with a new pickup code to the employee?",
    confirmReplaceCard: "Are you sure you want to order a replacement card?",
    confirmReplaceCardInfo:
      "A new travel card will be produced and sent as soon as possible (Fee of 50 kroner will apply). The old card will be cancelled effective immediately.",
    mediaType: "Media type",
    travelCard: "Travel card",
    mobileTicket: "Mobile ticket",
    name: "Name",
    changesRequireNewTicket: "The following changes requires a new ticket:",
    chooseMediaType: "Choose media type",
    replaceTicket: "Replace card",
    cardName: "Card name",
    className: "Class",
    editTicket: "Edit ticket",
    zones: "Zones",
  },
};

export default shoolTicketDetailLanguageResource;
