import { LanguageResource, OneParamLanguageResourceTemplate, languageResourceBuilder } from "@ruter-as/web-components-and-tools";

interface PayrollLanguageResource {
  title: string;
  customerNumber: string;
  montlyPrice: string;
  firstName: string;
  lastName: string;
  zones: string;
  invoiceReference: string;
  downloadAsCSV: string;
  month: string;
  startDate: string;
  phone: string;
  status: string;

  changeType: string;
  changeTypeNew: string;
  changeTypeCancelled: string;
  changeTypeChanged: string;
  noChanges: OneParamLanguageResourceTemplate;
}

const payrollLanguageResource: LanguageResource<PayrollLanguageResource> = {
  nb: {
    title: "Lønnstrekkendringer",
    customerNumber: "Kundenummer",
    montlyPrice: "Månedspris",
    firstName: "Fornavn",
    lastName: "Etternavn",
    zones: "Soner",
    invoiceReference: "Fakturareferanse",
    downloadAsCSV: "Last ned lønnsgrunnlag som CSV",
    month: "Måned",
    startDate: "Startdato",
    phone: "Telefonnr",
    status: "Status",
    changeType: "Årsak",
    changeTypeNew: "Ny",
    changeTypeCancelled: "Avsluttet",
    changeTypeChanged: "Endret",
    noChanges: languageResourceBuilder.oneParamTemplate("Ingen endringer i lønnstrekk for $p1$"),
  },
  en: {
    title: "Payroll changes",
    customerNumber: "Customer number",
    montlyPrice: "Price per month",
    firstName: "First name",
    lastName: "Last name",
    zones: "Zones",
    invoiceReference: "Invoice reference",
    downloadAsCSV: "Download as CSV",
    month: "Month",
    startDate: "Start date",
    phone: "Phone",
    status: "Status",

    changeType: "Cause",
    changeTypeNew: "New",
    changeTypeCancelled: "Cancelled",
    changeTypeChanged: "Changed",
    noChanges: languageResourceBuilder.oneParamTemplate("No changes in payroll for $p1$"),
  },
};

export default payrollLanguageResource;
