import { Button } from "@ruter-as/web-components-and-tools";
import React from "react";

interface Props {
  text: string;
  onClick: () => void;
  dataTestId: string;
  disabled?: boolean;
}

const LinkButton: React.FC<Props> = ({ text, onClick, dataTestId, disabled }) => {
  return <Button onClick={onClick} variant="primary" text={text} type="button" data-test-id={dataTestId} disabled={disabled} />;
};

export default LinkButton;
