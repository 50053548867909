import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TaxBaseLanguageResource {
  title: string;
  employeeNumber: string;
  firstName: string;
  lastName: string;
  taxBase: string;
  ticketCountEmployee: string;
  ticketCountAdult: string;
  ticketCountChild: string;
  ticketCountRetired: string;
  agreementDepartment: string;
  month: string;
  noTaxBaseCalculated: string;
  noTaxBaseForSelectedMonth: string;
  downloadAsCSV: string;
  csvFileName: string;
  missingDepartmentName: string;
  allDepartments: string;
}

export const taxBaseLanguageResource: LanguageResource<TaxBaseLanguageResource> = {
  nb: {
    title: "Skattegrunnlag",
    employeeNumber: "Ansattnr.",
    firstName: "Fornavn",
    lastName: "Etternavn",
    taxBase: "Skattegrunnlag",
    ticketCountEmployee: "Ant. ansatte",
    ticketCountAdult: "Ant. fam. voksen",
    ticketCountChild: "Ant. fam. barn/honnør",
    ticketCountRetired: "Ant. pensjonist inkl. familie",
    agreementDepartment: "Avdeling",
    month: "Måned",
    noTaxBaseCalculated: "Det er ikke kalkulert noe skattegrunnlag for valgt firma.",
    noTaxBaseForSelectedMonth: "Fant ingen skattegrunnlag for valgt avtale/avdeling og måned.",
    downloadAsCSV: "Last ned skattegrunnlag som CSV",
    csvFileName: "Skattegrunnlag for {department}, {period}.csv",
    missingDepartmentName: "Avdelingsnavn ikke satt",
    allDepartments: "Alle avdelinger",
  },
  en: {
    title: "Tax base",
    employeeNumber: "Employee no.",
    firstName: "First name",
    lastName: "Last name",
    taxBase: "Tax base",
    ticketCountEmployee: "Employees count",
    ticketCountAdult: "Fam. adult count",
    ticketCountChild: "Fam. child/reduced rate count",
    ticketCountRetired: "Retired inc fam count",
    agreementDepartment: "Department",
    month: "Month",
    noTaxBaseCalculated: "There is not calculated tax base for the selected company.",
    noTaxBaseForSelectedMonth: "Found no tax base for selected agreement/department and month",
    downloadAsCSV: "Download as CSV",
    csvFileName: "Tax base for {department}, {period}.csv",
    missingDepartmentName: "Department name not set",
    allDepartments: "All departments",
  },
};
