import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface MassOrdersLanguageResource {
  title: string;
  description: string;
  cancelTickets: string;
  ordersTitle: string;
  orderedBy: string;
  orderedAt: string;
  requestedTicketsCount: string;
  failedTicketsCount: string;
  ticketsCreatedCount: string;
  progress: string;
  newMassOrder: string;
  departmentShift: string;
}

export const massOrdersLanguageResource: LanguageResource<MassOrdersLanguageResource> = {
  nb: {
    title: "Massoppdatering",
    description: "Her kan du bestille og avslutte mange billetter i en operasjon",
    cancelTickets: "Avslutt alle billetter",
    ordersTitle: "Tidligere og pågående bestillinger",
    orderedBy: "Bestilt av",
    orderedAt: "Tidspunkt",
    requestedTicketsCount: "Billetter bestilt",
    failedTicketsCount: "Billetter feilet",
    ticketsCreatedCount: "Billetter opprettet",
    progress: "Fremgang",
    newMassOrder: "Ny massebestilling",
    departmentShift: "Bytt avdeling",
  },
  en: {
    title: "Former and ongoing orders",
    description: "Here you can order and cancel multiple tickets in one operation",
    cancelTickets: "Cancel tickets multiple tickets",
    ordersTitle: "Former and ongoing orders",
    orderedBy: "Ordered by",
    orderedAt: "Time",
    requestedTicketsCount: "Tickets ordred",
    failedTicketsCount: "Tickets failed",
    ticketsCreatedCount: "Tickets created",
    progress: "Fremgang",
    newMassOrder: "New mass order",
    departmentShift: "Change department",
  },
};
