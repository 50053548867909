interface ZoneJson {
  adjacentZoneIds: Array<string>;
  adjacentZoneNames: Array<string>;
  id: string;
  name: string;
  maxZones: number;
}

export interface AddOn {
  type: string;
  name: { nb: string; en: string };
  description: { nb: string; en: string };
  pricePerMonth: number;
}

export interface Zone {
  adjacentZoneIds: Array<string>;
  adjacentZoneNames: Array<string>;
  id: string;
  name: string;
  maxZones: number;
}

const mapZone = (json: ZoneJson): Zone => ({
  adjacentZoneIds: [...json.adjacentZoneIds],
  adjacentZoneNames: [...json.adjacentZoneNames],
  id: json.id,
  name: json.name,
  maxZones: json.maxZones,
});

export const mapZones = (json: ZoneJson[]): Zone[] => json.map(mapZone);

export const getRuterZoneResponse = (): Zone[] => [
  {
    id: "RUT:TariffZone:224",
    name: "4V",
    maxZones: 7,
    adjacentZoneIds: ["RUT:TariffZone:225"],
    adjacentZoneNames: ["3V"],
  },
  {
    id: "RUT:TariffZone:225",
    name: "3V",
    maxZones: 6,
    adjacentZoneIds: ["RUT:TariffZone:224", "RUT:TariffZone:226"],
    adjacentZoneNames: ["4V", "2V"],
  },
  {
    id: "RUT:TariffZone:226",
    name: "2V",
    maxZones: 5,
    adjacentZoneIds: ["RUT:TariffZone:225", "RUT:TariffZone:227"],
    adjacentZoneNames: ["3V", "1"],
  },
  {
    id: "RUT:TariffZone:227",
    name: "1",
    maxZones: 4,
    adjacentZoneIds: ["RUT:TariffZone:226", "RUT:TariffZone:228", "RUT:TariffZone:231"],
    adjacentZoneNames: ["2V", "2S", "2Ø"],
  },
  {
    id: "RUT:TariffZone:228",
    name: "2S",
    maxZones: 5,
    adjacentZoneIds: ["RUT:TariffZone:227", "RUT:TariffZone:230", "RUT:TariffZone:231"],
    adjacentZoneNames: ["1", "3S", "2Ø"],
  },
  {
    id: "RUT:TariffZone:230",
    name: "3S",
    maxZones: 6,
    adjacentZoneIds: ["RUT:TariffZone:228", "RUT:TariffZone:266"],
    adjacentZoneNames: ["2S", "4S"],
  },
  {
    id: "RUT:TariffZone:231",
    name: "2Ø",
    maxZones: 5,
    adjacentZoneIds: ["RUT:TariffZone:227", "RUT:TariffZone:228", "RUT:TariffZone:232"],
    adjacentZoneNames: ["1", "2S", "3Ø"],
  },
  {
    id: "RUT:TariffZone:232",
    name: "3Ø",
    maxZones: 6,
    adjacentZoneIds: ["RUT:TariffZone:231", "RUT:TariffZone:233", "RUT:TariffZone:274", "RUT:TariffZone:276"],
    adjacentZoneNames: ["2Ø", "4N", "4SØ", "4Ø"],
  },
  {
    id: "RUT:TariffZone:233",
    name: "4N",
    maxZones: 7,
    adjacentZoneIds: ["RUT:TariffZone:232"],
    adjacentZoneNames: ["3Ø"],
  },
  {
    id: "RUT:TariffZone:266",
    name: "4S",
    maxZones: 7,
    adjacentZoneIds: ["RUT:TariffZone:230"],
    adjacentZoneNames: ["3S"],
  },
  {
    id: "RUT:TariffZone:274",
    name: "4SØ",
    maxZones: 7,
    adjacentZoneIds: ["RUT:TariffZone:232"],
    adjacentZoneNames: ["3Ø"],
  },
  {
    id: "RUT:TariffZone:276",
    name: "4Ø",
    maxZones: 7,
    adjacentZoneIds: ["RUT:TariffZone:232"],
    adjacentZoneNames: ["3Ø"],
  },
];
