export enum TaxBaseChangeCause {
  SUBSCRIBED = "SUBSCRIBED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  PRICE_CHANGED = "PRICE_CHANGED",
}

export const parseTaxBaseChangeCause = (value: string): TaxBaseChangeCause => {
  const parsed = Object.values(TaxBaseChangeCause).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error("Could not determine TaxBaseChangeCause");
  }
  return parsed;
};
