import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface FreeTicketsLanguageResource {
  title: string;
  firstName: string;
  lastName: string;
  mediaType: string;
  createTicket: string;
  employeeId: string;
  relationType: string;
  employee: string;
  retired: string;
  familyMember: string;
  unknown: string;
  downloadActiveAndPendingAsCSV: string;
  downloadCancelledAsCSV: string;
  birthDate: string;
  relation: string;
  startDate: string;
  endDate: string;
  mobileNumber: string;
  zones: string;
  ticketProfile: string;
  department: string;
  zoneFrom: string;
  zoneTo: string;
  mobileTicket: string;
  travelCard: string;
  all: string;
  status: string;
  phoneOrTravelCardNumber: string

}

export const freeTicketsLanguageResource: LanguageResource<FreeTicketsLanguageResource> = {
  nb: {
    title: "Fribilletter",
    firstName: "Fornavn",
    lastName: "Etternavn",
    mediaType: "Billettbærer",
    createTicket: "Ny fribillett",
    employeeId: "Ansattnr.",
    relationType: "Relasjonstype",
    employee: "Ansatt",
    retired: "Pensjonist",
    familyMember: "Familie",
    unknown: "Ukjent",
    downloadActiveAndPendingAsCSV: "Last ned aktive og ventende fribilletter som CSV",
    downloadCancelledAsCSV: "Last ned avsluttede fribilletter som CSV",
    birthDate: "Fødselsdato",
    relation: "Relasjon",
    startDate: "Startdato",
    endDate: "Sluttdato",
    mobileNumber: "Mobilnummer",
    zones: "Soner",
    ticketProfile: "Reisende",
    department: "Avdeling",
    zoneFrom: "Sone fra",
    zoneTo: "Sone til",
    mobileTicket: "Mobil",
    travelCard: "Reisekort",
    all: "Alle",
    phoneOrTravelCardNumber: "Telefon-/Reisekortnummer",
    status: "Status",
  },
  en: {
    title: "Free tickets",
    firstName: "First name",
    lastName: "Last name",
    mediaType: "Media type",
    createTicket: "New free-ticket",
    employeeId: "Employee number",
    relationType: "Relation type",
    employee: "Employee",
    retired: "Retired",
    familyMember: "Family",
    unknown: "Unknown",
    downloadActiveAndPendingAsCSV: "Download active and pending tickets as CSV",
    downloadCancelledAsCSV: "Download cancelled tickets as  CSV",
    birthDate: "Birth date",
    relation: "Relation",
    startDate: "Start date",
    endDate: "End date",
    mobileNumber: "Mobile number",
    zones: "Zoner",
    ticketProfile: "Ticket profile",
    department: "Department",
    zoneFrom: "Zone from",
    zoneTo: "Zone to",
    mobileTicket: "Mobile ticket",
    travelCard: "Travel card",
    all: "All",
    phoneOrTravelCardNumber: "Phone-/Travelcard number",
    status: "Status",
  },
};
