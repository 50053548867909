enum AllowedActions {
  CANCEL = "CANCEL",
  CHANGE_ZONE = "CHANGE_ZONE",
  RESEND = "RESEND",
  RESEND_PICKUP_CODE = "RESEND_PICKUP_CODE",
  UPDATE_CARD_NAME = "UPDATE_CARD_NAME",
  UPDATE_CLASS_NAME = "UPDATE_CLASS_NAME",
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_EXPIRY = "UPDATE_EXPIRY",
  UPDATE_STUDENTID = "UPDATE_STUDENTID",
  UPDATE_ADDRESS = "UPDATE_ADDRESS",
}

export const mapAllowedActions = (values: Array<string>) => {
  const result = {} as { [key in AllowedActions]: boolean };
  Object.values(AllowedActions).map((action) => {
    if (values.includes(action)) result[action] = true;
    else result[action] = false;
  });
  return result;
};

export default AllowedActions;
