
interface ActiveSubscriptionJson {
  startDate: string,
  endDate: null | string,
  activeSince: null | string,
  info: {
    type: string | null,
    name: {
      nb: string | null,
      en: string | null
    },
    description: {
      nb: string | null
      en: string | null
    },
    pricePerMonth: number | null,
    vatPerMonth: number | null
  }
}

export interface ActiveSubscription {
  startDate: Date | null;
  endDate: Date | null;
  activeSince: Date | null;
  type: "OTTO";
  name: {
    nb: string;
    en: string;
  }
  description: {
    nb: string;
    en: string;
  }
  pricePerMonth: number;
  vatPerMonth: number;
}

interface ActiveSubscriptionResponseJson {
  subscriptions: ActiveSubscriptionJson[],
}

const mapActiveSubscription = (json: ActiveSubscriptionJson): ActiveSubscription => ({
  startDate: json.startDate ? new Date(json.startDate) : null,
  endDate: json.endDate ? new Date(json.endDate) : null,
  activeSince: json.activeSince ? new Date(json.activeSince) : null,
  type: "OTTO",
  name: {
    nb: json.info.name.nb || "",
    en: json.info.name.en || "",
  },
  description: {
    nb: json.info.description.nb || "",
    en: json.info.description.en || "",
  },
  pricePerMonth: json.info.pricePerMonth || 0,
  vatPerMonth: json.info.vatPerMonth || 0,
});

export const mapActiveSubscriptions = (json: ActiveSubscriptionResponseJson): ActiveSubscription[] => json.subscriptions.map(mapActiveSubscription);