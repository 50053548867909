import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface FreeTicketSettingsLanguageResource {
  freeTicketsAdult: string;
  freeTicketMaxChildrenAge: string;
  freeTicketExpiryType: string;
  settingsSaved: string;
  confirmTitle: string;
  yes: string;
  no: string;
  month: string;
  year: string;
  cancel: string;
  invoicing: string;
  pageTitle: string;
  requiredError: string;
  invoiceReference: string;
}

export const freeTicketSettingsLanguageResource: LanguageResource<FreeTicketSettingsLanguageResource> = {
  nb: {
    freeTicketsAdult: "Automatisk avslutning av barnebilletter",
    freeTicketMaxChildrenAge: "barnet fyller",
    freeTicketExpiryType: "Avsluttes ved utløpet av",
    settingsSaved: "Innstillinger lagret!",
    confirmTitle: "Lagre",
    yes: "Ja",
    no: "Nei",
    month: "Måneden",
    year: "Året",
    cancel: "Avbryt",
    invoicing: "Fakturering",
    pageTitle: "Instillinger for fribilletter",
    requiredError: "Dette feltet er påkrevt",
    invoiceReference: "Fakturareferanse",
  },
  en: {
    freeTicketsAdult: "Automatically end childrens tickets",
    freeTicketMaxChildrenAge: "when the child is",
    freeTicketExpiryType: "Expires at the end of the",
    settingsSaved: "Settings saved!",
    confirmTitle: "Save",
    yes: "Yes",
    no: "No",
    month: "Month",
    year: "Year",
    cancel: "Cancel",
    invoicing: "Invoicing",
    pageTitle: "Settings for free-tickets",
    requiredError: "This field is required",
    invoiceReference: "Invoice reference",
  },
};
