export enum EnvName {
  Local = "local",
  Development = "dev",
  Test = "test",
  Stage = "stage",
  Production = "prod",
}

const parseEnvName = (value: string): EnvName => {
  const parsed = Object.values(EnvName).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error("Could not determine EnvName");
  }
  return parsed;
};

export default function getEnvironment(): EnvName {
  return parseEnvName(window.envName);
}
