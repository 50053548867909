import { apiClient, ButtonGroup, Container, EmailFormInput, FieldValidationError, FormGroup, NumberFormInput, PhoneFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import onboardingApi from "src/common/api/onboarding/onboardingApi";
import { SimpleOnboardingContract, SimpleOnboardingFormData } from "src/common/api/onboarding/simpleOnboarding";
import successAlert from "src/common/toastr";
import { CancelButton, SubmitButton } from "../common/buttons";
import { simpleOnboardingLanguageResources } from "./lang-resource";

const SimpleOnboarding: React.FC = () => {
  const lang = useLanguageResource(simpleOnboardingLanguageResources);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [formIsSent, setFormIsSent] = useState(false);
  const navigate = useNavigate();
  const formMethods = useForm<SimpleOnboardingFormData>({
    defaultValues: {
      companyName: "",
      email: "",
      firstName: "",
      lastName: "",
      orgNumber: "",
      phoneNumber: "",
    },
  });

  const onSubmit = async (data: SimpleOnboardingFormData) => {
    setSubmitting(true);

    const phone = parsePhoneNumberFromString(data.phoneNumber);

    if (!phone || !phone.isValid()) {
      setSubmitting(() => {
        throw new Error("invalid phone number should be impossible as it is validated by the form");
      });
      return;
    }

    const contract: SimpleOnboardingContract = {
      companyName: data.companyName,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      organisationNumber: data.orgNumber,
      phone: phone.nationalNumber,
      phoneCountryCode: `+${phone.countryCallingCode}`,
    };

    const response = await apiClient.request(onboardingApi.requestAccess(contract));

    if (response.type === "success") {
      successAlert(lang.confirmation.finished);
      setFormIsSent(true);
    } else {
      setSubmitting(false);
      if (response.type === "HttpError") {
        if (response.responseStatus === 400) {
          setError(lang.errorInvalidOrgNumber);
          return;
        }
        if (response.responseStatus === 404) {
          setError(lang.errorCompanyNotFound);
          return;
        }
        if (response.responseStatus === 409 || response.responseStatus === 410) {
          setError(lang.errorCompanyUnsuitable);
          return;
        }
      }
      setError(lang.errorUnknown);
      Sentry.captureEvent(response.error as Error);
    }
  };

  return <Container width="s" data-test-id="simple-onboarding-components">
    {!formIsSent && <>
      <h1>{lang.title}</h1>
      <p>{lang.description}</p>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ marginTop: "1.5rem" }}>
          <TextFormInput name="firstName" label={lang.firstName} required />
          <TextFormInput name="lastName" label={lang.lastName} required />
          <EmailFormInput name="email" label={lang.emailLabel} required />
          <PhoneFormInput name="phoneNumber" label={lang.mobileLabel} required mobileOnly />
          <TextFormInput name="companyName" label={lang.companyName} required />
          <NumberFormInput name="orgNumber" label={lang.organizationNumberLabel} required min={100000000} max={999999999} integer />
          <FormGroup>
            <ButtonGroup>
              <SubmitButton text={lang.confirm} submitting={submitting} />
              <CancelButton onClick={() => navigate(-1)} />
            </ButtonGroup>
          </FormGroup>
          {error && (
            <FieldValidationError data-test-id="error-message" text={error} />
          )}
        </form>
      </FormProvider>
    </>}
    {formIsSent &&
      <>
        <h1>{lang.confirmation.title}</h1>
        <p>{lang.confirmation.description}</p>
      </>}
  </Container>;
};

export default SimpleOnboarding;
