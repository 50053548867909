export const splitName = (name: string) => {
  const nameParts = name.split(" ");
  let lastName = null;
  if (nameParts.length > 1) {
    lastName = nameParts.pop() as string;
  }
  const firstName = nameParts.join(" ");
  return { firstName, lastName };
};

export const concatName = (firstName: string, lastName: string): string => {
  return `${firstName} ${lastName}`.trim();
};
