import { useEffect, useState } from "react";
import { useAuthContext } from "src/AuthContext";

const CypressTestData: React.FC = () => {
  const authContext = useAuthContext();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const getValues = async () => {
      if (authContext.authenticated && window.Cypress) {
        const token = await authContext.getAccessToken();
        setAccessToken(token);
      }
    };

    getValues();
  }, [authContext]);

  if (!window.Cypress) {
    return null;
  }

  if (!authContext.authenticated) {
    return null;
  }

  return (
    <>
      <input type="hidden" data-test-id="cypress-selected-company-id" value={authContext.userData.selectedCompany.id} />
      <input type="hidden" data-test-id="cypress-access-token" value={accessToken} />
    </>
  );
};

export default CypressTestData;