import { Button, ButtonGroup, Container, FormGroup, Message, Modal, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { FreeTicket, FreeTicketTravelCard } from "../../../common/api/freeTicketApi/freeTicket";
import successAlert, { failAlert } from "../../../common/toastr";
import { TicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import MediaType from "../../../types/mediaType";
import { ButtonLink, CancelButton } from "../../common/buttons";
import CancelActiveTicketModal from "./CancelActiveTicketModal";
import CancelAllTicketsModal from "./CancelAllTicketsModal";
import "./FreeTicketsFamily.scss";
import MobileTicket from "./MobileTicket";
import TravelCardTicket from "./TravelCardTicket";
import { freeTicketsFamilyLanguageResource } from "./lang-resource";
import { filterActiveTickets } from "./utils";

const FreeTicketsFamily: React.FC = () => {
  const lang = useLanguageResource(freeTicketsFamilyLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const { id } = useParams();
  const [tickets, setTickets] = useState<FreeTicket[] | null>(null);
  const [cancelActiveTicket, setCancelActiveTicket] = useState<null | FreeTicket>(null);
  const [orderReplacementCard, setOrderReplacementCard] = useState<null | FreeTicketTravelCard>(null);
  const [cancelPendingTicket, setCancelPendingTicket] = useState<null | FreeTicket>(null);
  const [resendTicket, setResendTicket] = useState<null | FreeTicket>(null);
  const [sendPickupCode, setSendPickupCode] = useState<null | FreeTicket>(null);
  const [resendTicketInProgress, setResendTicketInProgress] = useState(false);
  const [sendPickupCodeInProgress, setSendPickupCodeInProgress] = useState(false);
  const [cancelPendingTicketInProgress, setCancelPendingTicketInProgress] = useState(false);
  const [ordreReplacementCardInProgress, setOrdreReplacementCardInProgress] = useState(false);
  const [cancelAllTickets, setCancelAllTickets] = useState<FreeTicket[]>();

  const fetch = useCallback(async () => {
    const response = await apiClient.request(freeTicketApi.ticket.forSameEmployeeAsThisOrder.get(id || ""));
    if (response.type === "success") {
      setTickets(response.result.matches);
    } else {
      setTickets(() => {
        throw response.error;
      });
    }
  }, [id]);

  const postCancelPendingTicket = async () => {
    if (!cancelPendingTicket) {
      throw new Error("no ticket to cancel");
    }
    setCancelPendingTicketInProgress(true);
    const response = await apiClient.request(freeTicketApi.ticket.id(cancelPendingTicket.id).delete());
    if (response.type === "success") {
      setCancelPendingTicketInProgress(false);
      setCancelPendingTicket(null);
      fetch();
      successAlert(lang.cancelPendingTicketSuccess);
    } else {
      failAlert(lang.cancelPendingTicketFail);
      setCancelPendingTicketInProgress(() => {
        throw response.error;
      });
    }
  };

  const postOrderReplacementCard = async () => {
    if (!orderReplacementCard) {
      throw new Error("no card to replace");
    }
    setOrdreReplacementCardInProgress(true);

    const response = await apiClient.request(freeTicketApi.ticket.travelCard.id(orderReplacementCard.id).replace());
    if (response.type === "success") {
      setOrdreReplacementCardInProgress(false);
      setOrderReplacementCard(null);
      fetch();
    } else {
      setOrdreReplacementCardInProgress(() => {
        throw response.error;
      });
    }
  };

  const postSendPickupCode = async () => {
    if (!sendPickupCode) {
      throw new Error("no pickup code to send");
    }
    setSendPickupCodeInProgress(true);

    const response = await apiClient.request(freeTicketApi.ticket.id(sendPickupCode.id).resendPickupCode());
    if (response.type === "success") {
      setSendPickupCodeInProgress(false);
      setSendPickupCode(null);
      fetch();
      successAlert(lang.sendPickCodeSuccess);
    } else {
      failAlert(lang.sendPickCodeFail);
      setSendPickupCodeInProgress(() => {
        throw response.error;
      });
    }
  };

  const postResendTicket = async () => {
    if (!resendTicket) {
      throw new Error("no ticket to resend");
    }
    setResendTicketInProgress(true);
    const response = await apiClient.request(freeTicketApi.ticket.id(resendTicket.id).resend());
    if (response.type === "success") {
      setResendTicketInProgress(false);
      setResendTicket(null);
      fetch();
      successAlert(lang.resendTicketSuccess);
    } else {
      failAlert(lang.resendTicketFail);
      setResendTicketInProgress(() => {
        throw response.error;
      });
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (tickets === null) {
    return null;
  }
  if (tickets.length === 0) {
    throw new Error(`No tickets found for id:${id}`);
  }

  const employeeTickets = tickets.filter((x) => x.ticketHolderType === TicketHolderType.EMPLOYEE);
  const familyTickets = tickets.filter((x) => x.ticketHolderType === TicketHolderType.FAMILY_MEMBER);
  const retiredTickets = tickets.filter((x) => x.ticketHolderType === TicketHolderType.RETIRED);
  const unkownTickets = tickets.filter((x) => x.ticketHolderType === TicketHolderType.UNKNOWN);

  const ticketGrouped = {
    [TicketHolderType.EMPLOYEE]: filterActiveTickets(employeeTickets),
    [TicketHolderType.FAMILY_MEMBER]: filterActiveTickets(familyTickets),
    [TicketHolderType.RETIRED]: filterActiveTickets(retiredTickets),
    [TicketHolderType.UNKNOWN]: filterActiveTickets(unkownTickets),
  };

  const expiredTickets = tickets
    .filter((ticket) => !filterActiveTickets(tickets).includes(ticket))
    .sort((a, b) => {
      if (!b.startDate) return -1;
      if (!a.startDate) return 1;
      else {
        const firstDate = a.startDate as Date;
        const secondDate = b.startDate as Date;
        return secondDate.getTime() - firstDate.getTime();
      }
    });

  const renderTicket = (ticket: FreeTicket) => {
    if (ticket.mediaType === MediaType.MOBILE_TICKET) {
      return (
        <MobileTicket
          key={ticket.id}
          ticket={ticket}
          cancelActiveTicket={setCancelActiveTicket}
          cancelPendingTicket={setCancelPendingTicket}
          resendTicket={setResendTicket}
          sendPickupCode={setSendPickupCode}
          refetch={fetch}
        />
      );
    }
    return (
      <TravelCardTicket
        key={ticket.id}
        ticket={ticket}
        cancelActiveTicket={setCancelActiveTicket}
        orderReplacementCard={setOrderReplacementCard}
        cancelPendingTicket={setCancelPendingTicket}
        refetch={fetch}
      />
    );
  };

  const ticketGroupedValues = Object.values(ticketGrouped);
  const flattenedTicketGrouped = ticketGroupedValues.flatMap(x => x);
  return (
    <Container
      width="l"
      className="components-freeticketagreement-freeticketsfamily"
      data-test-id="components-freeticketagreement-freeticketsfamily"
    >
      <h1>{lang.title.replace("$employeeId$", tickets[0].employeeId)}</h1>
      {ticketGrouped && ticketGroupedValues.some((ticketArray) => ticketArray.length) && (
        <div data-test-id="active-section">
          <h2>{lang.activeTickets}</h2>
          {flattenedTicketGrouped.filter(x => !x.expirationDate).length > 0 &&
            <ButtonLink
              dataTestId="cancel-all-tickets"
              text={lang.cancelAllTickets}
              onClick={() => { setCancelAllTickets(flattenedTicketGrouped.filter(x => !x.expirationDate)); }}
            />
          }
          <div className="tickets">
            {!!ticketGrouped.EMPLOYEE.length && ticketGrouped.EMPLOYEE.map(renderTicket)}
            {!!ticketGrouped.FAMILY_MEMBER.length && ticketGrouped.FAMILY_MEMBER.map(renderTicket)}
            {!!ticketGrouped.RETIRED.length && ticketGrouped.RETIRED.map(renderTicket)}
            {!!ticketGrouped.UNKNOWN.length && ticketGrouped.UNKNOWN.map(renderTicket)}
          </div>
        </div>
      )}

      {!!expiredTickets.length && (
        <div data-test-id="expired-section">
          <h2>{lang.expiredTickets}</h2>
          <div className="tickets">{expiredTickets.map(renderTicket)}</div>
        </div>
      )}

      <CancelActiveTicketModal
        cancelActiveTicket={cancelActiveTicket}
        onSuccess={async () => {
          setCancelActiveTicket(null);
          fetch();
          successAlert(lang.cancelActiveTicketSuccess);
        }}
        onCancel={() => {
          setCancelActiveTicket(null);
        }}
      />

      <CancelAllTicketsModal
        onCancel={() => { setCancelAllTickets(undefined); }}
        allActiveTickets={cancelAllTickets}
        onSuccess={async () => {
          setCancelAllTickets(undefined);
          fetch();
          successAlert(lang.cancelActiveTicketSuccess);
        }}
      />

      <Modal
        isOpen={Boolean(cancelPendingTicket)}
        title={lang.cancelPendingTicket}
        handleClose={() => setCancelPendingTicket(null)}
        data-test-id="pending-ticket-cancel-confirmation-window"
      >
        <p>{lang.confirmPendingTicketCancel}</p>
        <ButtonGroup>
          <Button
            variant="primary"
            type="button"
            loading={cancelPendingTicketInProgress}
            text={formLang.yes}
            onClick={postCancelPendingTicket}
            data-test-id="confirm-button"
          />
          <CancelButton onClick={() => setCancelPendingTicket(null)} data-test-id="cancel-button" />
        </ButtonGroup>
      </Modal>

      <Modal
        isOpen={Boolean(resendTicket)}
        title={lang.resendTicket}
        handleClose={() => setResendTicket(null)}
        data-test-id="pending-resend-ticket-confirmation-window"
      >
        <p>{lang.confirmResendTicket}</p>
        <ButtonGroup>
          <Button
            variant="primary"
            type="button"
            loading={resendTicketInProgress}
            text={formLang.yes}
            onClick={postResendTicket}
            data-test-id="confirm-button"
          />
          <CancelButton onClick={() => setResendTicket(null)} data-test-id="cancel-button" />
        </ButtonGroup>
      </Modal>

      <Modal
        isOpen={Boolean(sendPickupCode)}
        title={lang.sendPickupCode}
        handleClose={() => setSendPickupCode(null)}
        data-test-id="pending-send-pickup-code-confirmation-window"
      >
        <p>{lang.confirmSendPickupCode}</p>
        <ButtonGroup>
          <Button
            variant="primary"
            type="button"
            loading={sendPickupCodeInProgress}
            text={formLang.yes}
            onClick={postSendPickupCode}
            data-test-id="confirm-button"
          />
          <CancelButton onClick={() => setSendPickupCode(null)} data-test-id="cancel-button" />
        </ButtonGroup>
      </Modal>

      <Modal isOpen={Boolean(orderReplacementCard)} title={lang.orderReplacementCard} handleClose={() => setOrderReplacementCard(null)}>
        <FormGroup>
          <Message skin="info">
            <p>{lang.confirmReplaceCardInfo}</p>
          </Message>
        </FormGroup>
        <p style={{ marginTop: "1rem" }}>{lang.confirmReplaceCard}</p>
        <ButtonGroup>
          <Button
            variant="primary"
            type="button"
            loading={ordreReplacementCardInProgress}
            text={formLang.yes}
            onClick={postOrderReplacementCard}
          />
          <CancelButton onClick={() => setOrderReplacementCard(null)} />
        </ButtonGroup>
      </Modal>
    </Container>
  );
};

export default FreeTicketsFamily;
