import { AuthContextValues } from "src/AuthContext";

const getTicketOwnerFieldName = (authContext: AuthContextValues): string => {
  const defaultValue = "Ansattnr";

  if (authContext.authenticated) {
    return authContext.userData.selectedCompany.businessTicketAgreements[0].settings.employeeIdLabel || defaultValue;
  }
  return defaultValue;
};

export default getTicketOwnerFieldName;
