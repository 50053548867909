import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface MassOrderLanguageResource {
  title: string;
  agreement: string;
  addFromCsv: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  relationType: string;
  phoneNumber: string;
  mediaType: string;
  fromZone: string;
  toZone: string;
  profile: string;
  summary: string;
  invalidRowsHeader: string;
  validRowsHeader: string;
  remove: string;
  invalidRowsCount: string;
  validRowsCount: string;
  order: string;
  rowNumber: string;
  invalidRowsHelpText: string;
  errorExplainTitle: string;
  columnName: string;
  value: string;
  validationMessage: string;
  relationValidationMessage: string;
  mediaTypeValidationMessage: string;
  profileValidationMessage: string;
  zonesValidationMessage: string;
  zonesAllValidationMessage: string;
  zoneIsNotValid: string;
  profileMustBeChildWhenUserIsYoungerThan20ThisYearValidationMessage: string;
  profileMustBeRetiredIfPassengerIs67OrOlderValdiationMessage: string;
  profileCannotBeChildWhenTicketholderTypeIsRetiredOrEmployeeValidationMessage: string;
  profileCannotBeChildWhenAgeIs20OrMoreValidationMessage: string;
  existingTicketValidationMessage: string;
  profileMustBeAdultWhenTicketHolderTypeIsEmployeeValidationMessage: string;
  profileMustBeChildWhenTicketHolderTypeIsFamilyAndIfPassengerIsUnder18ValidationMessage: string;
  profileMustBeAdultWhenTicketHolderTypeIsFamilyAndIfPassengerIsOver18ValidationMessage: string;
  profileCannotBeRetiredWhenTicketHolderTypeIsFamilyMemberValidationMessage: string;
  profileShouldBeRetiredWhenTicketHolderTypeIsRetiredValidationMessage: string;
  unknownRecipientError: string;
  missingDepartmentName: string;
  equalLengthValidation: string;
  fileHeader: string;
  fileDescriptionPreLink: string;
  fileDesciptionLink: string;
  fileDescriptionPostLink: string;
  exampleFileName: string;
  travelCardNumber: string;
}

export const massOrderLanguageResource: LanguageResource<MassOrderLanguageResource> = {
  nb: {
    title: "Massebestilling av fribilletter",
    agreement: "Avdeling",
    addFromCsv: "Import fra CSV-fil",
    employeeId: "Ansattnr",
    firstName: "Fornavn",
    lastName: "Etternavn",
    birthDate: "Fødselsdato",
    relationType: "Relasjon",
    phoneNumber: "Telefonnummer",
    mediaType: "Billettbærer",
    travelCardNumber: "Reisekortnummer",
    fromZone: "Fra sone",
    toZone: "Til sone",
    profile: "Profil",
    summary: "Oppsummering",
    invalidRowsHeader: "Ugyldig rader",
    validRowsHeader: "Gyldige rader",
    remove: "Fjern",
    order: "Bestill $count$ billetter",
    invalidRowsCount: "$count$ ugyldige rader",
    validRowsCount: "$count$ gyldige rader",
    rowNumber: "Rad #",
    invalidRowsHelpText:
      "I listen under finner du alle feilene som ble oppdaget i filen du har lastet opp. For å se mer informasjon om feilen, kan du trykke på det røde spørsmålstegnet.",
    errorExplainTitle: "Forklaring av feilkode",
    columnName: "Kolonne",
    value: "Verdi",
    validationMessage: "Feilmelding",
    relationValidationMessage: "Relasjonstype må være en av følgende verdier: Ansatt, Familie eller Pensjonist",
    mediaTypeValidationMessage: "Billettbærer må være en av følgende verdier: Mobil eller Reisekort",
    profileValidationMessage: "Profil må være en av følgende verdier: Voksen, Barn eller Honnør",
    zonesValidationMessage: "$fieldName$ må være en av følgende verdier: $firstValues$ eller $lastValue$",
    zonesAllValidationMessage: 'Hvis $otherZone$ er lik "Alle" må også $thisZone$ være lik "Alle"',
    zoneIsNotValid: "$fieldName$ er ikke en gyldig sone",
    profileMustBeChildWhenUserIsYoungerThan20ThisYearValidationMessage:
      "Familiemedlemmer som fyller 19 i år eller er yngre, skal ha barnebillett",
    profileMustBeRetiredIfPassengerIs67OrOlderValdiationMessage: "Reisende som har fylt 67 år skal ha honnørbillet",
    profileCannotBeChildWhenTicketholderTypeIsRetiredOrEmployeeValidationMessage: "Ansatte og pensjonister kan ikke reise med barnebillett",
    profileCannotBeChildWhenAgeIs20OrMoreValidationMessage: "Personer som har fylt 20 år kan ikke ha barnebillet",
    existingTicketValidationMessage: "Denne billetten finnes allerede",
    profileMustBeAdultWhenTicketHolderTypeIsEmployeeValidationMessage: "Ansatte må ha voksenbillett",
    profileMustBeChildWhenTicketHolderTypeIsFamilyAndIfPassengerIsUnder18ValidationMessage:
      "Familiemedlemmer under 18 år skal ha barnebillett",
    profileMustBeAdultWhenTicketHolderTypeIsFamilyAndIfPassengerIsOver18ValidationMessage:
      "Familiemedlemmer over 18 år skal ha voksenbillett",
    profileCannotBeRetiredWhenTicketHolderTypeIsFamilyMemberValidationMessage: "Familiemedlemmer kan ikke reise på honnørbillett",
    profileShouldBeRetiredWhenTicketHolderTypeIsRetiredValidationMessage: "Pensjonister skal ha honnørbillett",
    unknownRecipientError: "Ukjent feil: $error$",
    missingDepartmentName: "Avdelingsnavn ikke satt",
    equalLengthValidation: "$fieldLabel$ må ha 9, 10 eller 16 tegn",
    fileHeader: "Last opp fil",
    fileDescriptionPreLink: "Her kan du laste opp en fil med billettene du ønsker å bestille. Trykk ",
    fileDesciptionLink: "her",
    fileDescriptionPostLink: " for å se et eksempel på hvordan filen skal se ut.",
    exampleFileName: "bestille fribilletter eksempel",
  },
  en: {
    title: "Mass order of free-tickets",
    agreement: "Department",
    addFromCsv: "Import from CSV-file",
    employeeId: "Employee id",
    firstName: "First name",
    lastName: "Last name",
    birthDate: "Date of birth",
    relationType: "Relation",
    phoneNumber: "Phone number",
    travelCardNumber: "Travel card number",
    mediaType: "Media type",
    fromZone: "From zone",
    toZone: "To zone",
    profile: "Profile",
    summary: "Summary",
    invalidRowsHeader: "Invalid rows",
    validRowsHeader: "Valid rows",
    remove: "Remove",
    order: "Order $count$ tickets",
    invalidRowsCount: "$count$ invalid rows",
    validRowsCount: "$count$ valid rows",
    rowNumber: "Row #",
    invalidRowsHelpText:
      "All errors detected in your file are listed below. To see more information regarding the error, click the red question mark.",
    errorExplainTitle: "Error explanation",
    columnName: "Column",
    value: "Value",
    validationMessage: "Validation error",
    relationValidationMessage: "Relation must be one of the following values: Ansatt, Familie or Pensjonist",
    mediaTypeValidationMessage: "Media type must be one of the following values: Mobil or Reisekort",
    profileValidationMessage: "Profile must be one of the following values: Voksen, Barn or Honnør",
    zonesValidationMessage: "$fieldName$ must be one of the following values: $firstValues$ or $lastValue$",
    zonesAllValidationMessage: 'If $otherZone$ is set to "Alle", then $thisZone$ must also be set to "Alle"',
    zoneIsNotValid: "$fieldName$ is not a valid zone",
    profileMustBeChildWhenUserIsYoungerThan20ThisYearValidationMessage:
      "All familymember who turns 19 this year or who are younger, should travel on ticket for children.",
    profileMustBeRetiredIfPassengerIs67OrOlderValdiationMessage:
      "Former employees or family member who have turned 67 years of age should travel on a reduced-rate ticket",
    profileCannotBeChildWhenTicketholderTypeIsRetiredOrEmployeeValidationMessage: "Former employees cannot travel on a ticket for children",
    profileCannotBeChildWhenAgeIs20OrMoreValidationMessage: "Passenger who are 20 years or older cannot have a ticket for chilren",
    existingTicketValidationMessage: "This ticket already exists",
    profileMustBeAdultWhenTicketHolderTypeIsEmployeeValidationMessage: "Employees must travel on an adult ticket",
    profileMustBeChildWhenTicketHolderTypeIsFamilyAndIfPassengerIsUnder18ValidationMessage:
      "Family members under 18 years old should have a ticket for children",
    profileMustBeAdultWhenTicketHolderTypeIsFamilyAndIfPassengerIsOver18ValidationMessage:
      "Family members over 18 years old should have a ticket for adults",
    profileCannotBeRetiredWhenTicketHolderTypeIsFamilyMemberValidationMessage: "Family members can not travel on a reduced-rate ticket",
    profileShouldBeRetiredWhenTicketHolderTypeIsRetiredValidationMessage: "Retired travelers should have a reduced-rate ticket",
    unknownRecipientError: "Unknown: $error$",
    missingDepartmentName: "Department name not set",
    equalLengthValidation: "$fieldLabel$ must equal 9, 10 or 16 characters",
    fileHeader: "Upload file",
    fileDescriptionPreLink: "You can upload a file the tickets you want to order. Click ",
    fileDesciptionLink: "here",
    fileDescriptionPostLink: " to see an exampel of how the file should look like.",
    exampleFileName: "order freeticket example",
  },
};
