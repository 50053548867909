import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface NoAgreementLanguageResource {
  titleBusinessAgreement: string;
  titleTicketAgreeemnt: string;
  titleFreeTicketAgreement: string;
  titleSchoolTicketAgreement: string;
  textBusinessAgreement: string;
  textTicketAgreement: string;
  textFreeTicketAgreement: string;
  textSchoolTicketAgreement: string;
  clickForContact: string;
}

export const noAgreementLanguageResource: LanguageResource<NoAgreementLanguageResource> = {
  nb: {
    titleBusinessAgreement: "Det valgte selskapet mangler en gyldig bedriftsavtale.",
    titleTicketAgreeemnt: "Det valgte selskapet mangler en gyldig billettlukeavtale.",
    titleFreeTicketAgreement: "Det valgte selskapet mangler en gyldig fribillettavtale.",
    titleSchoolTicketAgreement: "Det valgte selskapet mangler en gyldig skolebillettavtale.",
    textBusinessAgreement: "Kontakt kundestøtte for å sette opp en bedriftsavtale med ruter",
    textTicketAgreement: "Kontakt kundestøtte for å sette opp en billettlukeavtale med ruter",
    textFreeTicketAgreement: "Kontakt kundestøtte for å sette opp en fribillettavtale med ruter",
    textSchoolTicketAgreement: "Kontakt kundestøtte for å sette opp en skolebillettavtale med ruter",
    clickForContact: "Kontakt kundestøtte",
  },
  en: {
    titleBusinessAgreement: "The selected company does not have a valid agreement to access this page",
    titleTicketAgreeemnt: "The selected company does not have a valid agreement to access this page",
    titleFreeTicketAgreement: "The selected company does not have a valid agreement to access this page",
    titleSchoolTicketAgreement: "The selected company does not have a valid agreement to access this page",
    textBusinessAgreement: "Contact customer support to set up an agreement with Ruter",
    textTicketAgreement: "Contact customer support to set up an agreement with Ruter",
    textFreeTicketAgreement: "Contact customer support to set up an agreement with Ruter",
    textSchoolTicketAgreement: "Contact customer support to set up an agreement with Ruter",
    clickForContact: "Contact customer support",
  },
};