import { Button, ButtonGroup, Container, DateFormInput, FormGroup, Message, Modal, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import { TICKET_ACTIVE, TICKET_PENDING } from "src/constants";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend } from "../../../common/expiryDate";
import successAlert, { failAlert } from "../../../common/toastr";
import MediaType from "../../../types/mediaType";
import { SchoolTicket, mapSchoolTicket } from "../../../types/schoolTicketAgreement/schoolTicket";
import { CancelButton, SubmitButton } from "../../common/buttons";
import MobileTicket from "./MobileTicket";
import "./SchoolTicketDetail.scss";
import TravelCardTicket from "./TravelCardTicket";
import shoolTicketDetailLanguageResource from "./lang-resource";

interface EditTicketFormData {
  expiryDate: string;
}

const SchoolTicketDetail = () => {
  const authContext = useAuthContextAuthenticated();
  const { userData } = authContext;
  const { id } = useParams();
  const lang = useLanguageResource(shoolTicketDetailLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [ticket, setTicket] = useState<SchoolTicket | null>(null);
  const [cancelActiveTicket, setCancelActiveTicket] = useState<null | SchoolTicket>(null);
  const [resendTicket, setResendTicket] = useState<null | SchoolTicket>(null);
  const [cancelPendingTicket, setCancelPendingTicket] = useState<null | SchoolTicket>(null);
  const [resendTicketInProgress, setResendTicketInProgress] = useState(false);
  const [cancelActiveTicketInProgress, setCancelActiveTicketInProgress] = useState(false);
  const [cancelPendingTicketInProgress, setCancelPendingTicketInProgress] = useState(false);

  const cancelTicketFormMethods = useForm<EditTicketFormData>({
    defaultValues: {
      expiryDate: cancelActiveTicket?.expirationDate ? format(cancelActiveTicket?.expirationDate, "dd.MM.yyyy") : "",
    },
  });

  const newExpiryDate = useWatch({ name: "expiryDate", control: cancelTicketFormMethods.control });

  const getSchoolNameByAgreementId = (agreementNumber: string | undefined) => {
    if (!agreementNumber) return "-";
    const company = userData.companies.find((x) => x.schoolTicketAgreements.some((a) => a.id === agreementNumber));

    if (company) {
      return company.name;
    }
    return undefined;
  };

  const fetch = useCallback(async () => {
    const response = await apiClient.get(`/schoolticket-api/ticket/${id}`, mapSchoolTicket);
    if (response.type === "success") {
      const result = response.result;
      setTicket(result);
    } else {
      setTicket(() => {
        throw response.error;
      });
    }
  }, [id]);

  const postCancelActiveTicket = async (data: EditTicketFormData) => {
    if (!cancelActiveTicket) {
      throw new Error("no ticket to cancel");
    }
    setCancelActiveTicketInProgress(true);
    const expiry = ensureExpiryDateIsNotInThePastAndFormatForBackend(data.expiryDate, 0);
    const response = await apiClient.put(`/schoolticket-api/ticket/${cancelActiveTicket.id}/expiry/${expiry.toISOString()}`, {});
    if (response.type === "success") {
      setCancelActiveTicketInProgress(false);
      setCancelActiveTicket(null);
      fetch();
      successAlert(lang.cancelActiveTicketSuccess);
    } else {
      failAlert(lang.cancelActiveTicketFail);
      setCancelActiveTicketInProgress(() => {
        throw response.error;
      });
    }
  };

  const postCancelPendingTicket = async () => {
    if (!cancelPendingTicket) {
      throw new Error("no ticket to cancel");
    }
    setCancelPendingTicketInProgress(true);
    const response = await apiClient.delete(`/schoolticket-api/ticket/${cancelPendingTicket.id}`);
    if (response.type === "success") {
      setCancelPendingTicketInProgress(false);
      setCancelPendingTicket(null);
      await fetch();
      successAlert(lang.cancelActiveTicketSuccess);
    } else {
      failAlert(lang.cancelActiveTicketFail);
      setCancelPendingTicketInProgress(() => {
        throw response.error;
      });
    }
  };

  const postResendTicket = async () => {
    if (!resendTicket) {
      throw new Error("no ticket to resend");
    }
    setResendTicketInProgress(true);
    const response = await apiClient.post(`/schoolticket-api/ticket/${resendTicket.id}/resend`, {});
    if (response.type === "success") {
      setResendTicketInProgress(false);
      setResendTicket(null);
      await fetch();
      successAlert(lang.resendTicketSuccess);
    } else {
      failAlert(lang.resendTicketFail);
      setResendTicketInProgress(() => {
        throw response.error;
      });
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (ticket === null) {
    return null;
  }

  if (!ticket) {
    throw new Error(`No tickets found for id:${id}`);
  }

  const renderTicket = (t: SchoolTicket) => {
    if (t.mediaType === MediaType.MOBILE_TICKET) {
      return (
        <MobileTicket
          key={t.id}
          ticket={t}
          cancelActiveTicket={setCancelActiveTicket}
          resendTicket={setResendTicket}
          cancelPendingTicket={setCancelPendingTicket}
        />
      );
    } else if (t.mediaType === MediaType.TRAVEL_CARD)
      return (
        <TravelCardTicket
          key={t.id}
          ticket={t}
          cancelActiveTicket={setCancelActiveTicket}
          refetch={fetch}
          cancelPendingTicket={setCancelPendingTicket}
        />
      );
    else throw new Error("invalid mediaType");
  };

  return (
    <Container width="l" className="components-schoolticketdetails" data-test-id="components-schoolticketdetails">
      <h1>
        {ticket.firstName} {ticket.lastName}
      </h1>

      <div className="top">
        <div className="left">
          {ticket.mediaType === MediaType.MOBILE_TICKET && (
            <div className="student-info">
              <span className="label">{lang.phoneNumber}</span>
              <span className="value">{ticket.phone}</span>
            </div>
          )}
          <div className="student-info">
            <span className="label">{lang.schoolName}</span>
            <span className="value">{getSchoolNameByAgreementId(ticket.agreementNumber)}</span>
          </div>
          <div className="student-info">
            <span className="label">{lang.className}</span>
            <span className="value">{ticket.className}</span>
          </div>
          <div className="student-info">
            <span className="label">{lang.address}</span>
            <span className="value">{ticket.address}</span>
          </div>
        </div>
      </div>
      {ticket.status === TICKET_ACTIVE || ticket.status === TICKET_PENDING ? (
        <div data-test-id="active-section">
          <h2>{lang.tickets}</h2>
          <div className="tickets">{renderTicket(ticket)}</div>
        </div>
      ) : (
        <div data-test-id="expired-section">
          <h2>{lang.expired}</h2>
          <div className="tickets">{renderTicket(ticket)}</div>
        </div>
      )}

      <Modal
        isOpen={Boolean(cancelActiveTicket)}
        title={lang.cancelActiveTicket}
        handleClose={() => setCancelActiveTicket(null)}
        data-test-id="active-ticket-cancel-confirmation-window"
      >
        <FormProvider {...cancelTicketFormMethods}>
          <form onSubmit={cancelTicketFormMethods.handleSubmit(postCancelActiveTicket)}>
            <DateFormInput
              name="expiryDate"
              label={lang.from}
              required
              minDate={new Date()}
              maxDate={cancelActiveTicket?.expirationDate ? cancelActiveTicket.expirationDate : undefined}
            />
            <FormGroup>
              <Message skin="info" title={lang.cancelActiveTicketInfoTitle}>
                <p style={{ marginBottom: "1rem" }}>{lang.cancelActiveTicketInfoPrivacy.replace("$expiryDate$", newExpiryDate)}</p>
              </Message>
            </FormGroup>

            <FormGroup>
              <ButtonGroup>
                <SubmitButton text={formLang.yes} submitting={cancelActiveTicketInProgress} />
                <CancelButton onClick={() => setCancelActiveTicket(null)} data-test-id="cancel-button" />
              </ButtonGroup>
            </FormGroup>
          </form>
        </FormProvider>
      </Modal>

      <Modal
        isOpen={Boolean(cancelPendingTicket)}
        title={lang.cancelPendingTicket}
        handleClose={() => setCancelPendingTicket(null)}
        data-test-id="pending-ticket-cancel-confirmation-window"
      >
        <p>{lang.confirmPendingTicketCancel}</p>
        <FormGroup>
          <ButtonGroup>
            <Button
              type="button"
              variant="primary"
              loading={cancelPendingTicketInProgress}
              text={formLang.yes}
              onClick={postCancelPendingTicket}
              data-test-id="confirm-button"
            />
            <CancelButton onClick={() => setCancelPendingTicket(null)} data-test-id="cancel-button" />
          </ButtonGroup>
        </FormGroup>
      </Modal>

      <Modal
        isOpen={Boolean(resendTicket)}
        title={lang.resendTicket}
        handleClose={() => setResendTicket(null)}
        data-test-id="pending-resend-ticket-confirmation-window"
      >
        <p>{lang.confirmResendTicket}</p>
        <FormGroup>
          <ButtonGroup>
            <Button
              type="button"
              variant="primary"
              loading={resendTicketInProgress}
              text={formLang.yes}
              onClick={postResendTicket}
              data-test-id="confirm-button"
            />
            <CancelButton onClick={() => setResendTicket(null)} data-test-id="cancel-button" />
          </ButtonGroup>
        </FormGroup>
      </Modal>
    </Container>
  );
};

export default SchoolTicketDetail;
