import { Container, Dropdown, FormGroup, Label, Message, Table, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAuthContextAuthenticated } from "src/AuthContext";
import createAndDownloadCSV from "../../../common/csvFileCreator";
import formatter from "../../../common/formatter";
import { Period, mapTaxBaseDates } from "../../../types/freeTicketAgreement/taxBaseDates";
import { TaxBaseLine, mapTaxBaseLines } from "../../../types/freeTicketAgreement/taxBaseLine";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import { ButtonLink } from "../../common/buttons";
import InputSkeleton from "../../common/skeleton/InputSkeleton";
import "./TaxBasePage.scss";
import { taxBaseLanguageResource } from "./lang-resource";

const ALL_AGREEMENTS = "ALL_AGREEMENTS";

interface Option {
  value: string;
  text: string;
}

interface AgreementAndPeriods {
  agreementNumber: string;
  text: string;
  periods: SelectionPeriod[];
}

type SelectionPeriod = YearPeriod | MonthPeriod;
interface YearPeriod {
  type: "year";
  year: number;
  firstMonth: number;
  lastMonth: number;
}
interface MonthPeriod {
  type: "month";
  year: number;
  month: number;
}

const sortAgreements = (a: AgreementAndPeriods, b: AgreementAndPeriods) => {
  const createSortString = (agreementAndPeriods: AgreementAndPeriods) => {
    if (agreementAndPeriods.agreementNumber === ALL_AGREEMENTS) {
      return "0";
    }
    return `z${agreementAndPeriods.text.toLowerCase()}`;
  };

  const sortA = createSortString(a);
  const sortB = createSortString(b);

  if (sortA > sortB) {
    return 1;
  }
  if (sortA < sortB) {
    return -1;
  }
  return 0;
};

const sortPeriods = (a: SelectionPeriod, b: SelectionPeriod) => {
  const createSortString = (p: SelectionPeriod) => {
    if (p.type === "month") {
      return moment(new Date(p.year, p.month - 1, 1)).format("YYYY.MM");
    } else {
      return `${p.year}.13`;
    }
  };

  const sortA = createSortString(a);
  const sortB = createSortString(b);

  if (sortA > sortB) {
    return -1;
  }
  if (sortA < sortB) {
    return 1;
  }
  return 0;
};

const formatMonthAndYearToString = (value: SelectionPeriod) => {
  if (value.type === "month") {
    return moment(new Date(value.year, value.month - 1, 1)).format("YYYY.MM");
  }
  return value.year.toString();
};

const TaxBasePage: React.FC = () => {
  const lang = useLanguageResource(taxBaseLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const selectedCompany = authContext.userData.selectedCompany;
  const [fullLoading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [agreementsWithPeriods, setAgreementAndMonths] = useState<null | AgreementAndPeriods[]>(null);
  const [selectedAgreementNumber, setSelectedAgreementNumber] = useState<string>(ALL_AGREEMENTS);
  const [selectedPeriod, setSelectedPeriod] = useState<SelectionPeriod | null>(null);
  const [taxBaseLines, setTaxBaseLines] = useState<null | TaxBaseLine[]>(null);
  const [noTaxBaseCalculated, setNoTaxBaseCalculated] = useState(false);
  const freeTicketAgreements = authContext.userData.selectedCompany.freeTicketAgreements;

  const getDefaultPeriod = (periods: SelectionPeriod[]): MonthPeriod => {
    return periods.filter((x): x is MonthPeriod => x.type === "month")[0];
  };

  const getHumanReadablePeriod = (period: SelectionPeriod): string => {
    if (period.type === "month") {
      return moment(new Date(period.year, period.month - 1, 1)).format("MMMM YYYY");
    } else {
      const firstMonth = moment(new Date(period.year, period.firstMonth - 1, 1)).format("MMMM");
      const lastMonth = moment(new Date(period.year, period.lastMonth - 1, 1)).format("MMMM");

      return `${period.year} ${firstMonth} - ${lastMonth}`;
    }
  };

  const findInvoiceRef = useCallback(
    (agreementNumberInput: string) => {
      const agreement = freeTicketAgreements.find((x) => x.id === agreementNumberInput);
      return agreement?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumberInput})`;
    },
    [lang.missingDepartmentName, freeTicketAgreements],
  );

  const mapToSelectionPeriod = (periods: Period[]): SelectionPeriod[] => {
    const uniqueYears: number[] = [];
    const yearPeriods: YearPeriod[] = [];

    periods.forEach((period) => {
      if (uniqueYears.some((x) => x === period.year)) {
        return;
      }
      uniqueYears.push(period.year);
      const minMonth = Math.min(...periods.filter((x) => x.year === period.year).map((x) => x.month));
      const maxMonth = Math.max(...periods.filter((x) => x.year === period.year).map((x) => x.month));

      if (minMonth === maxMonth) {
        return;
      }

      yearPeriods.push({
        type: "year",
        firstMonth: minMonth,
        lastMonth: maxMonth,
        year: period.year,
      });
    });

    const monthPeriods = [...periods.map<MonthPeriod>((x) => ({ ...x, type: "month" }))];

    return [...monthPeriods, ...yearPeriods];
  };

  // fetch summaries
  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get(`/freeticket-api/tax-base/dates/by-company-id/${selectedCompany?.id}`, mapTaxBaseDates);

      if (response.error) {
        setLoading(() => {
          throw response.error;
        });
        return;
      }

      if (response.result.byCompany.length === 0) {
        setNoTaxBaseCalculated(true);
        setLoading(false);
        return;
      }

      const agreementsWithPeriodsTemp: AgreementAndPeriods[] = [];

      agreementsWithPeriodsTemp.push({
        agreementNumber: ALL_AGREEMENTS,
        periods: [...mapToSelectionPeriod(response.result.byCompany)],
        text: lang.allDepartments,
      });

      agreementsWithPeriodsTemp.push(
        ...response.result.byAgreement.map((x) => ({
          agreementNumber: x.agreement,
          periods: [...mapToSelectionPeriod(x.dates)],
          text: findInvoiceRef(x.agreement),
        })),
      );

      agreementsWithPeriodsTemp.sort(sortAgreements);
      agreementsWithPeriodsTemp.forEach((x) => x.periods.sort(sortPeriods));

      setAgreementAndMonths(agreementsWithPeriodsTemp);

      if (agreementsWithPeriodsTemp.length) {
        setSelectedPeriod(getDefaultPeriod(agreementsWithPeriodsTemp[0].periods));
      }

    };
    fetch();
  }, [selectedCompany?.id, findInvoiceRef, lang.allDepartments]);

  // fetch details
  useEffect(() => {
    if (!selectedPeriod) {
      return;
    }

    let url = `/freeticket-api/tax-base/lines/by-company-id/${selectedCompany?.id}?year=${selectedPeriod.year}`;

    if (selectedPeriod.type === "month") {
      url += `&month=${selectedPeriod.month}`;
    }

    if (selectedAgreementNumber !== ALL_AGREEMENTS) {
      url += `&agreement=${selectedAgreementNumber}`;
    }

    const fetch = async () => {
      setTableLoading(true);
      const response = await apiClient.get(url, mapTaxBaseLines);
      if (response.type === "success") {
        setTaxBaseLines(response.result);
        setLoading(false);
        setTableLoading(false);
      } else {
        setLoading(() => {
          throw response.error;
        });
      }

    };

    fetch();
  }, [selectedAgreementNumber, selectedCompany?.id, selectedPeriod]);

  const setPeriod = (periodAsString: string): void => {
    const agreement = agreementsWithPeriods?.find((x) => x.agreementNumber === selectedAgreementNumber);

    if (!agreement) {
      return;
    }

    const period = agreement.periods.find((x) => formatMonthAndYearToString(x) === periodAsString);

    if (period) {
      setSelectedPeriod(period);
    }
  };

  const onAgreementChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const oldPeriod = selectedPeriod;
    const newAgreement = agreementsWithPeriods?.find((x) => x.agreementNumber === event.target.value);

    const doesPeriodExistOnAgreement = (period: SelectionPeriod | null, agreement: AgreementAndPeriods | undefined) => {
      if (!period || !agreement) {
        return false;
      }

      if (period.type === "year" && agreement.periods.some((x) => x.year === period.year)) {
        return true;
      }
      if (
        period.type === "month" &&
        agreement.periods.some((x) => x.type === "month" && x.month === period.month && x.year === period.year)
      ) {
        return true;
      }
      return false;
    };

    const doesOldPeriodExistOnNewAgreement = doesPeriodExistOnAgreement(oldPeriod, newAgreement);

    setSelectedAgreementNumber(event.target.value);

    if (!doesOldPeriodExistOnNewAgreement) {
      if (!newAgreement?.periods[0]) {
        throw new Error("selected agreement has no periods");
      }

      setSelectedPeriod(getDefaultPeriod(newAgreement.periods));
    }
  };

  let agreementOptions: Option[] = [];
  let monthOptions: Option[] = [];

  if (agreementsWithPeriods?.length) {
    agreementOptions = agreementsWithPeriods.map((x) => ({
      value: x.agreementNumber,
      text: x.text,
    }));

    const agreement = agreementsWithPeriods.find((x) => x.agreementNumber === selectedAgreementNumber);

    if (agreement) {
      monthOptions = agreement.periods.map((x) => ({
        value: formatMonthAndYearToString(x),
        text: getHumanReadablePeriod(x),
      }));
    }
  }

  const download = () => {
    if (!taxBaseLines) {
      return;
    }

    const csvModel = taxBaseLines.map((x) => [
      findInvoiceRef(x.agreementNumber),
      x.employeeId,
      x.lastName,
      x.firstName,
      formatter.number.float(x.totalPrice, 2),
      selectedPeriod?.type === "month" ? x.ticketCountEmployee.toString() : "",
      selectedPeriod?.type === "month" ? x.ticketCountFamilyAdult.toString() : "",
      selectedPeriod?.type === "month" ? x.ticketCountFamilyChildAndReducedRate.toString() : "",
      selectedPeriod?.type === "month" ? x.ticketCountRetired.toString() : "",
    ]);

    const headers = [
      lang.agreementDepartment,
      lang.employeeNumber,
      lang.lastName,
      lang.firstName,
      lang.taxBase,
      selectedPeriod?.type === "month" ? lang.ticketCountEmployee : "",
      selectedPeriod?.type === "month" ? lang.ticketCountAdult : "",
      selectedPeriod?.type === "month" ? lang.ticketCountChild : "",
      selectedPeriod?.type === "month" ? lang.ticketCountRetired : "",
    ];

    let fileName = "";

    const agreement = agreementsWithPeriods?.find((x) => x.agreementNumber === selectedAgreementNumber);
    if (agreement) {
      if (selectedPeriod) {
        fileName = lang.csvFileName.replace("{department}", agreement.text).replace("{period}", getHumanReadablePeriod(selectedPeriod));
      }
    }

    createAndDownloadCSV(fileName, headers, csvModel);
  };

  const renderRow = (row: TaxBaseLine) => (
    <tr key={row.employeeId}>
      <td>{findInvoiceRef(row.agreementNumber)}</td>
      <td>{row.employeeId}</td>
      <td>{row.lastName}</td>
      <td>{row.firstName}</td>
      <PriceColumn price={row.totalPrice} showDecimals={true} />

      {selectedPeriod?.type === "month" && (
        <>
          <td style={{ textAlign: "right" }}>{row.ticketCountEmployee}</td>
          <td style={{ textAlign: "right" }}>{row.ticketCountFamilyAdult}</td>
          <td style={{ textAlign: "right" }}>{row.ticketCountFamilyChildAndReducedRate}</td>
          <td style={{ textAlign: "right" }}>{row.ticketCountRetired}</td>
        </>
      )}
    </tr>
  );

  const renderFilter = () => (
    <div className="filter">
      {fullLoading ? (
        <div className="inputs">
          <InputSkeleton />
          <InputSkeleton />
        </div>
      ) : (
        <>
          <div className="inputs">
            <FormGroup>
              <Label text={lang.agreementDepartment} >
                <Dropdown name="agreement-select" onChange={onAgreementChange} value={selectedAgreementNumber}>
                  {agreementOptions.map((a) => (
                    <option key={a.value} value={a.value}>
                      {a.text}
                    </option>
                  ))}
                </Dropdown>
              </Label>

            </FormGroup>
            <FormGroup>
              <Label text={lang.month}>
                <Dropdown
                  name="months-select"
                  onChange={(event) => setPeriod(event.target.value)}
                  value={selectedPeriod ? formatMonthAndYearToString(selectedPeriod) : undefined}
                >
                  {monthOptions.map((m) => (
                    <option key={m.value} value={m.value}>
                      {m.text}
                    </option>
                  ))}
                </Dropdown>
              </Label>
            </FormGroup>
          </div>
          <div className="download">
            <ButtonLink text={lang.downloadAsCSV} onClick={download} dataTestId="download-button" />
          </div>
        </>
      )}
    </div>
  );

  return (
    <Container width="xl" data-test-id="components-freeticketagreement-taxbase" className="components-freeticketagreement-taxbase">
      <h1>{lang.title}</h1>
      {noTaxBaseCalculated ? (
        <Message skin="info" data-test-id="no-tax-base-calculated-message">
          <p>{lang.noTaxBaseCalculated}</p>
        </Message>
      ) : (
        <>
          {renderFilter()}
          <Table
            loading={tableLoading || fullLoading}
          >
            <thead>
              <tr>
                <th>{lang.agreementDepartment}</th>
                <th>{lang.employeeNumber}</th>
                <th>{lang.lastName}</th>
                <th>{lang.firstName}</th>
                <th style={{ textAlign: "right", width: "140px" }}>{lang.taxBase}</th>

                {selectedPeriod?.type === "month" && (
                  <>
                    <th style={{ textAlign: "right", width: "120px" }}>{lang.ticketCountEmployee}</th>
                    <th style={{ textAlign: "right", width: "150px" }}>{lang.ticketCountAdult}</th>
                    <th style={{ textAlign: "right", width: "190px" }}>{lang.ticketCountChild}</th>
                    <th style={{ textAlign: "right", width: "225px" }}>{lang.ticketCountRetired}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>{taxBaseLines?.map(renderRow)}</tbody>
          </Table>
        </>
      )}
    </Container>
  );
};

export default TaxBasePage;
