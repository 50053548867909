import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Container } from "@ruter-ds/rds-components";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./SubMenu.scss";
import { HeaderLanguageResource, headerLanguageResource } from "./languageResource";
import { MenuState } from "./menuState";

function getSubMenu(menu: MenuState, path: string) {
  let topMenuItem = menu.items.find((x) => path.toLowerCase().indexOf(x.path.toLowerCase()) !== -1);
  if (topMenuItem === undefined) {
    topMenuItem = menu.items.find((item) => item.subMenu?.some(x => x.path.toLowerCase() === path.toLowerCase()));
  }

  if (topMenuItem && topMenuItem.subMenu && topMenuItem.subMenu.length > 0) {
    return topMenuItem.subMenu;
  }

  return [];
}

interface Props {
  menu: MenuState,
}

const SubMenu: React.FC<Props> = ({ menu }) => {
  const location = useLocation();
  const language = useLanguageResource(headerLanguageResource);

  const subMenuItems = getSubMenu(menu, location.pathname);

  if (subMenuItems.length === 0) {
    return null;
  }

  return (
    <div className="sub-menu">
      <Container width="l">
        {subMenuItems.map((item) => (
          <NavLink key={item.id} to={item.path} data-test-topmenu-id={item.id} className={item.focus ? "focused" : undefined}>
            <span className="text">{language[item.id as keyof HeaderLanguageResource] as string}</span>
            <div className="badge">{item.focus}</div>
          </NavLink>
        ))}
      </Container>
    </div>
  );
};

export default SubMenu;
