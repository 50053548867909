import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface InvoiceLanguageResource {
  title: string;
  month: string;
  numberOfTickets: string;
  invoiceNumber: string;
  date: string;
  invoiceRef: string;
  paymentStatus: string;
  dueDate: string;
  amount: string;
  downloadAsPDF: string;
  agreementType: string;
  agreementBusinessTicket: string;
  agreementTicketCounter: string;
  agreementFreeTicket: string;
  agreementSchoolTicket: string;
}

const invoiceLanguageResource: LanguageResource<InvoiceLanguageResource> = {
  nb: {
    title: "Fakturagrunnlag",
    invoiceNumber: "Fakturanummer",
    date: "Fakturadato",
    month: "Måned",
    numberOfTickets: "Ant. billetter",
    invoiceRef: "Fakturareferanse",
    paymentStatus: "Bet.status",
    dueDate: "Forfallsdato",
    amount: "Totalbeløp",
    downloadAsPDF: "Last ned PDF",
    agreementType: "Avtaletype",
    agreementBusinessTicket: "Bedriftsavtale",
    agreementTicketCounter: "Billettluke",
    agreementFreeTicket: "Fribillett",
    agreementSchoolTicket: "Skole Oslo",
  },
  en: {
    title: "Invoice",
    invoiceNumber: "Invoice number",
    date: "invoiceDate",
    month: "Month",
    numberOfTickets: "No. tickets",
    invoiceRef: "Invoice refereence",
    paymentStatus: "Payment status",
    dueDate: "Due date",
    amount: "Amount",
    downloadAsPDF: "Download PDF",
    agreementType: "Agreement type",
    agreementBusinessTicket: "Company agreement",
    agreementTicketCounter: "Ticket counter",
    agreementFreeTicket: "Free-ticket agreement",
    agreementSchoolTicket: "School Oslo",
  },
};

export default invoiceLanguageResource;
