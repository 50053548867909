import React from "react";
import "./Detail.scss";

interface Props {
  loading: boolean;
  label: string | undefined;
  value: string | undefined;
}

const DetailComponent: React.FC<Props> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.loading === false) {
    const { label, value } = props;
    return (
      <div className="components-common-detail">
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </div>
    );
  }

  return (
    <div className="components-common-detail skeleton">
      <div className="inner" />
    </div>
  );
};

export default DetailComponent;
