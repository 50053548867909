import { Button, Container, IconButton, Table, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import getEnvironment, { EnvName } from "src/common/env";
import successAlert from "src/common/toastr";
import AgreementTypeColumn from "src/components/common/Table/AgreementTypeColumn/AgreementTypeColumn";
import EditCompanyAddress from "src/components/common/modal/editCompanyAddress/EditCompanyAddress";
import AddressesCard from "src/components/companyInfo/AddressesCard";
import CompanyCard from "src/components/companyInfo/CompanyCard";
import { AgreementInvoicingInterval } from "src/types/AgreementInvoicingInterval";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { Agreement, Company, mapCompany } from "src/types/Company";
import { CompanyType } from "src/types/CompanyType";
import "./CompanyPage.scss";
import DeleteAgreementModal from "./DeleteAgreementModal";
import DeleteCompanyModal from "./DeleteCompanyModal";
import EditAgreementModal from "./EditAgreementModal";
import companyPageLanguageResource from "./lang-resource";

const CompanyPage: React.FC = () => {
  const { id } = useParams();
  const [company, setCompany] = useState<Company | null>(null);
  const lang = useLanguageResource(companyPageLanguageResource);
  const [editAddress, setEditAddress] = useState(false);
  const [deleteCompany, setDeleteCompany] = useState(false);
  const [editAgreement, setEditAgreement] = useState<Agreement | null>(null);
  const [deleteAgreement, setDeleteAgreement] = useState<Agreement | null>(null);
  const navigate = useNavigate();


  const fetch = useCallback(async () => {
    const response = await apiClient.get(`/onboarding/company/${id}`, mapCompany);
    if (response.type === "success") {
      setCompany(response.result);
    } else {
      setCompany(() => { throw response.error; });
    }

  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!company) {
    return null;
  }

  const onModalSuccess = () => {
    setEditAddress(false);
    setEditAgreement(null);
    setDeleteAgreement(null);
    fetch();
  };

  const onCompanyDeleteSuccess = () => {
    setDeleteCompany(false);
    successAlert(lang.deleteCompanySuccess(company.name));
    navigate("/bedrifter");
  };

  const allowEdit = company.type !== CompanyType.RUTER_CONTRACTOR;
  const showDeleteButton = getEnvironment() !== EnvName.Production && false && allowEdit;

  const renderAgreementRow = (agreement: Agreement) => {
    return (
      <tr key={agreement.id}>
        <td>{agreement.id}</td>
        <td>{agreement.invoiceReference}</td>
        <td><AgreementTypeColumn agreements={[agreement]} /></td>
        <td>
          <div className="actions">
            <IconButton variant="PencilIcon" data-test-id="edit-agreement-button" title={lang.editAgreementButton} onClick={() => setEditAgreement(agreement)} aria-label={lang.editAgreementButton} />
            {/* <IconButton variant="CrossIcon" data-test-id="delete-agreement-button" title={lang.deleteAgreementButton} onClick={() => setDeleteAgreement(agreement)} aria-label={lang.deleteAgreementButton} /> */}
          </div>
        </td>
      </tr>
    );
  };

  const openNewAgreementModal = () => {
    const agreement: Agreement = {
      endDate: null,
      fromDate: null,
      invoiceReference: "",
      id: "",
      subTypeId: AgreementSubTypeId.TICKETOFFICE,
      invoicingInterval: AgreementInvoicingInterval.MONTHLY_AT_END,
      paymentDeadline: 30,
    };
    setEditAgreement(agreement);
  };

  let companyName = company.name;
  if (company.type === CompanyType.RUTER_CONTRACTOR) {
    companyName = lang.operatorCompanyName(company.name);
  }

  return (
    <Container width="s" data-test-id="company-admin-company-page" className="company-admin-company-page">
      <h1>{companyName}</h1>
      <CompanyCard customerNumber={company.id} orgNumber={company.organisationNumber} />
      <AddressesCard companyType={company.type} companyAddresses={company.addresses} onChangeClick={() => setEditAddress(true)} />

      <div className="agreement-header">
        <h2>{lang.agreements}</h2>
        <Button variant="primary" text={lang.newAgreement} type="button" data-test-id="new-agreement-button" onClick={openNewAgreementModal} />
      </div>
      <Table>
        <thead>
          <tr>
            <th>{lang.agreementNumber}</th>
            <th>{lang.agreementInvoideRef}</th>
            <th>{lang.agreementType}</th>
            <th style={{ width: "100px" }}></th>
          </tr>
        </thead>
        <tbody>
          {company?.allAgreements.map(renderAgreementRow)}
        </tbody>
      </Table>

      {showDeleteButton && (
        <div className="delete-company-button">
          <Button type="button" variant="danger" data-test-id="delete-company-button" text={lang.deleteCompany} onClick={() => setDeleteCompany(true)} />
        </div>
      )}


      {deleteCompany && <DeleteCompanyModal company={company} onClose={() => setDeleteCompany(false)} onSuccess={onCompanyDeleteSuccess} />}
      {editAddress && <EditCompanyAddress companyType={company.type} type="saveToBackend" onClose={() => setEditAddress(false)} onSuccess={onModalSuccess} companyAddresses={company.addresses} companyId={company.id} />}
      {editAgreement && <EditAgreementModal type="saveToBackend" agreement={editAgreement} onClose={() => setEditAgreement(null)} onSuccess={onModalSuccess} companyId={company.id} />}
      {deleteAgreement && <DeleteAgreementModal agreement={deleteAgreement} onClose={() => setDeleteAgreement(null)} onSuccess={onModalSuccess} company={company} />}
    </Container>
  );
};

export default CompanyPage;