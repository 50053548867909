enum AgreementInvoicingInterval {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY_AT_END = "MONTHLY_AT_END",
  QUARTERLY = "QUARTERLY",
  OTHER = "OTHER",
}

const parseAgreementInvoicingInterval = (value: string | undefined | null): AgreementInvoicingInterval | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }

  const parsed = Object.values(AgreementInvoicingInterval).find((x) => x === value);

  if (parsed === undefined) {
    return AgreementInvoicingInterval.OTHER;
  }
  return parsed;
};

export { AgreementInvoicingInterval, parseAgreementInvoicingInterval };

