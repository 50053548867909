import { LanguageResource } from "@ruter-as/web-components-and-tools";

export interface TravelCardDetailsLanguageResource {
  title: string;
  travelCarNumber: string
  productionDate: string
  cardName: string
  productionStatus: string
  company: string,
  address: string,
  status: string
  startDate: string,
  statuses: {
    requested: string;
    created: string;
    deactivated: string;
    cancelled: string;
    anonymised: string;
    unknown: string;
  }
}

const travelCardDetailsLanguageResource: LanguageResource<TravelCardDetailsLanguageResource> = {
  nb: {
    title: "Reisekort",
    productionStatus: "Produksjonstatus:",
    productionDate: "Produksjonsdato:",
    cardName: "Kortnavn",
    travelCarNumber: "Reisekort #",
    address: "Adresse",
    company: "Skole/bedrift:",
    status: "Status:",
    startDate: "Startdato",
    statuses: {
      requested: "Bestilt",
      created: "Produsert",
      deactivated: "Sperret",
      cancelled: "Kansellert",
      anonymised: "Anonymisert",
      unknown: "Ukjent",
    },
  }, en: {
    title: "Travel cards",
    cardName: "Card name",
    productionDate: "Production date:",
    productionStatus: "Producation status:",
    travelCarNumber: "Travel card #",
    address: "Address",
    company: "Company/school:",
    status: "Status:",
    startDate: "Start date",
    statuses: {
      requested: "Requested",
      created: "Created",
      deactivated: "Deactivated",
      cancelled: "Cancelled",
      anonymised: "Anonymised",
      unknown: "Unknown",
    },
  },
};

export default travelCardDetailsLanguageResource;