import { SearchResult } from "../../../types/SearchResult";

export enum UserState {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Inactive = "INACTIVE",
}

const parseUserState = (value: string): UserState => {
  const parsed = Object.values(UserState).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error("Could not determine UserState");
  }
  return parsed;
};

interface ContactReferenceJson {
  id: string;
  type: string;
}

interface SourceJson {
  providerId: string;
  providerType: string;
  userId: string;
}

interface ModifierJson {
  id: string;
  source: SourceJson;
}

interface UserJson {
  contact: ContactReferenceJson | null;
  createdAt: string;
  createdBy: ModifierJson | null;
  email: string;
  firstName: string;
  id: string;
  lastModifiedAt: string;
  lastModifiedBy: ModifierJson | string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  source: SourceJson;
  state: string;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  state: UserState;
}

export const mapUser = (json: UserJson): User => ({
  id: json.id,
  email: json.email,
  firstName: json.firstName,
  lastName: json.lastName,
  phone: json.phone,
  phoneCountryCode: json.phoneCountryCode,
  state: parseUserState(json.state),
});

export const mapUserSearchResult = (json: SearchResult<UserJson>): SearchResult<User> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapUser),
});
