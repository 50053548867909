import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface MassChangeDepartmentLanguageResource {
  title: string;
  noTickets: string;
  confirmModalTitle: string;
  confirmModalText: string;
  confirmStatus: string;
  ticketsChangeSuccessToastr: string;

  freeTicketCount: string;
  ticketsEligibleCount: string;

  ticketsFilteredByChangeDate: string;

  fileHeader: string;
  fileDescriptionPreLink: string;
  fileDesciptionLink: string;
  fileDescriptionPostLink: string;

  exampleFileName: string;
  employeeNumber: string;
  birthDate: string;
  addFromCsv: string;

  rowInFileCount: string;
  invalidRowCount: string;
  validRowCount: string;
  unmatcedRows: string;
  remove: string;
  department: string;
  changeTickets: string;
  changeNotRequired: string;
  changeDate: string;
}

export const massChangeDepartmentLanguageResource: LanguageResource<MassChangeDepartmentLanguageResource> = {
  nb: {
    title: "Endre avdeling for fribilletter",
    noTickets: "Det finnes ingen billetter som kan endres",
    confirmModalTitle: "Bekreft endring av avdeling",
    confirmModalText: "Er du sikker på at du vil endre avdeling på ${number} billetter",
    confirmStatus: "Endrer billett ${ticketNumber}/${totalTickets}",
    ticketsChangeSuccessToastr: "Endret ${ticketCount} billetter!",

    freeTicketCount: "Antall fribilletter",
    ticketsEligibleCount: "Billetter som kan endres",

    ticketsFilteredByChangeDate: "Billetter avsluttet før ${changeDate}",

    fileHeader: "Last opp fil",
    fileDescriptionPreLink:
      "Her kan du laste opp en fil med ansattnr, fødselsdato og ny avdeling for de billettene du ønsker å endre. Trykk ",
    fileDesciptionLink: "her",
    fileDescriptionPostLink: " for å se et eksempel på hvordan filen skal se ut.",

    exampleFileName: "endring av avdeling eksempel",
    employeeNumber: "Ansattnr",
    birthDate: "Fødselsdato",
    addFromCsv: "Import fra CSV-fil",

    rowInFileCount: "Antall rader i fil",
    invalidRowCount: "Antall ugyldige rader",
    validRowCount: "Antall gyldige rader",
    unmatcedRows: "Rader uten treff i vår database",
    remove: "Fjern",
    department: "Avdeling",
    changeTickets: "Endre billetter",
    changeNotRequired: "Rader uten endring",
    changeDate: "Endringsdato",
  },
  en: {
    title: "Change department for free-tickets",
    noTickets: "There are no tickets to change",
    confirmModalTitle: "Confirm changing of departments",
    confirmModalText: "Are you sure you want to change department for ${number} tickets",
    confirmStatus: "Changing department for ticket ${ticketNumber}/${totalTickets}",
    ticketsChangeSuccessToastr: "Changed ${ticketCount} tickets!",

    freeTicketCount: "Freetickets",
    ticketsEligibleCount: "Tickets eligible for change ",

    ticketsFilteredByChangeDate: "Tickets cancelled before ${changeDate}",

    fileHeader: "Upload file",
    fileDescriptionPreLink:
      "You can upload a file with employee number, birth date and new departmant for those tickets you want to change. Click ",
    fileDesciptionLink: "here",
    fileDescriptionPostLink: " to see an exampel of how the file should look like.",

    exampleFileName: "change department example",
    employeeNumber: "Employee number",
    birthDate: "Birth date",
    addFromCsv: "Import from CSV-file",

    rowInFileCount: "Number of rows in file",
    invalidRowCount: "Number of invalid rows",
    validRowCount: "Number of valid rows",
    unmatcedRows: "Number of rows without match in out database",
    remove: "Remove",
    department: "Department",
    changeTickets: "Change tickets",
    changeNotRequired: "Rows without changes",
    changeDate: "Change date",
  },
};
