import { Container, IconButton, Table, apiClient, formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { InvoiceOrCustomerOrder } from "src/common/api/invoice/Invoice";
import { InvoiceLine, InvoiceOrCustomerOrderLine } from "src/common/api/invoice/InvoiceLine";
import invoiceApi from "src/common/api/invoice/invoiceApi";
import { getMonth } from "src/common/date";
import { UserRight } from "src/common/userRights";
import GotoColumn from "src/components/common/Table/GotoColumn/GotoColumn";
import PriceColumn from "src/components/common/Table/PriceColumn/PriceColumn";
import { getAgreementSubTypeIdText } from "src/components/common/badges/agreement/AgreementTypeBadge";
import { agreementTypeBadgeLanguageResource } from "src/components/common/badges/agreement/lang-resource";
import { ButtonLink } from "src/components/common/buttons";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { Tenant, getTenant } from "src/types/Tenant";
import RefundModal from "./RefundModal";
import "./invoiceDetails.scss";
import { invoiceDetailsLanguageResource } from "./lang-resource";

const InvoiceDetails: React.FC = () => {
  const lang = useLanguageResource(invoiceDetailsLanguageResource);
  const navigate = useNavigate();
  const authContext = useAuthContextAuthenticated();
  const rights = authContext.userData.userRights;
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceOrCustomerOrder>();
  const [pdfLink, setPdfLink] = useState<string | null>();
  const [downloading, setDownloading] = useState(false);
  const [refundInvoiceLine, setRefundInvoiceLine] = useState<InvoiceLine>();
  const isCustomerSupportRole = rights.find((right) => right === UserRight.CompanyAdmin);
  const agreementSubTypeText = useLanguageResource(agreementTypeBadgeLanguageResource);
  const { id } = useParams();
  const tenant = getTenant();
  const isInvoice = invoiceDetails?.type === "INVOICE";

  const month = isInvoice ? getMonth(invoiceDetails?.invoiceDate) : getMonth(invoiceDetails?.createdAt);

  const fetch = useCallback(async () => {
    if (id) {
      const invoiceOrCustomerOrderResponse = tenant === Tenant.Akt ? await apiClient.request(invoiceApi.invoiceAdminApi.invoiceBase.byInvoiceId.get(id)) :
        await apiClient.request(invoiceApi.api.invoice.getByInvoiceId(id || ""));
      const pdfLinkResponse = await apiClient.request(invoiceApi.api.invoice.getPdfLinkById(id || ""));

      if (pdfLinkResponse.type === "success") {
        setPdfLink(pdfLinkResponse.result.pdfLink);
      }

      if (invoiceOrCustomerOrderResponse.type !== "success") {
        setInvoiceDetails(() => {
          throw invoiceOrCustomerOrderResponse.error;
        });
      } else {
        let invoiceOrOrder: InvoiceOrCustomerOrder = invoiceOrCustomerOrderResponse.result;
        const invoiceNumber = invoiceOrOrder.invoiceNumber;
        if (invoiceNumber) {
          const invoiceResponse = await apiClient.request(invoiceApi.invoiceAdminApi.invoice.byInvoiceId.get(invoiceNumber));
          if (invoiceResponse.type === "success") {
            invoiceOrOrder = invoiceResponse.result;
          }
        }
        setInvoiceDetails(invoiceOrOrder);
      }
    }
    return () => {
      setPdfLink(undefined);
      setInvoiceDetails(undefined);
    };
  }, [id, tenant]);

  useEffect(() => {
    fetch();
    return () => {
      setPdfLink(undefined);
      setInvoiceDetails(undefined);
    };
  }, [fetch]);

  const renderRow = (line: InvoiceOrCustomerOrderLine) => {
    const isCompanyAgreement = invoiceDetails?.agreementSubTypeId === AgreementSubTypeId.SUBSCRIPTIONS;
    const isInvoiceLine = line.type === "INVOICE";
    const amount = line.price * line.quantity;
    const creditableAmount = amount;
    let refundedAmount = 0;
    if (isInvoiceLine) {
      const refunds = line.refunds;
      refunds.forEach(refund => {
        refundedAmount = refundedAmount + refund.amount;
      });
    }
    const isRefundable = Boolean(creditableAmount - refundedAmount > 0);
    return (
      <tr
        style={{ cursor: isCompanyAgreement ? "pointer" : "auto" }}
        key={line.id}
        data-employee-id={line.ticketId}
        onClick={() => {
          if (isCompanyAgreement) {
            navigate(`/bedriftsavtale/ansatt/${line.ticketId}`);
          }
        }}
      >
        <td>{line.description}</td>
        <PriceColumn price={line.price} showDecimals />
        <td style={{ textAlign: "right" }}>{line.quantity}</td>
        <PriceColumn price={amount} showDecimals />
        {isInvoiceLine && (
          <>
            <PriceColumn price={refundedAmount} showDecimals />
            {isCustomerSupportRole && <td style={{ textAlign: "right" }}>
              <IconButton
                aria-label={lang.refundInvoiceLine}
                onClick={(e) => {
                  e.stopPropagation();
                  setRefundInvoiceLine(line);
                }}
                variant="ArrowBackIcon"
                data-test-id="refund-button"
                disabled={!isRefundable}
              />
            </td>}
          </>
        )}
        {isCompanyAgreement ? <GotoColumn /> : <td></td>}
      </tr>
    );
  };


  const downloadAsPdf = () => {
    if (downloading || !pdfLink) return;
    setDownloading(true);
    const link = document.createElement("a");
    link.setAttribute("href", pdfLink);
    link.setAttribute("download", "faktura.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
    setDownloading(false);
  };

  const invoicingDate = isInvoice ? invoiceDetails.invoiceDate : invoiceDetails?.invoicingDate;
  const invoiceReference = isInvoice ? invoiceDetails.theirReference : invoiceDetails?.reference;
  const agreementSubTypeId = (invoiceDetails?.agreementSubTypeId || undefined);
  const totalPrice = isInvoice ? invoiceDetails.totalAmount : invoiceDetails?.totalPrice;
  const totalVat = invoiceDetails?.totalVat || 0;

  return <Container width="m" data-test-id="components-ticketcounter-invoice-details">
    <h1 data-test-id="header">{isInvoice ? lang.title(invoiceDetails?.invoiceNumber ?? "") : lang.invoiceBase}</h1>
    <p data-test-id="payment-status">
      <b>{lang.paymentStatus}:</b> {invoiceDetails?.paymentStatus}
    </p>
    <p data-test-id="date">
      <b>{lang.invoiceDate}:</b> {invoicingDate ? formatter.date.toShortDateString(invoicingDate) : "–"}
    </p>
    <p data-test-id="invoice-ref">
      <b>{lang.invoiceRef}:</b> {invoiceReference || "–"}
    </p>
    <p data-test-id="due-date">
      <b>{lang.dueDate}:</b> {invoiceDetails?.dueDate ? formatter.date.toShortDateString(invoiceDetails?.dueDate) : "–"}
    </p>
    <p data-test-id="agreement-type">
      <b>{lang.agreementType}:</b> {getAgreementSubTypeIdText(agreementSubTypeId, agreementSubTypeText)}
    </p>
    <h2 data-test-id="date-details-header">{lang.sumTitle(month)}</h2>

    {pdfLink && <ButtonLink text={lang.downloadAsPdf} onClick={downloadAsPdf} />}
    <div className="components-agreement-ticket-ticket">
      <div className={`status ${invoiceDetails?.reminderDueDate}`} />
      <div className="info">
        <div className="row">
          <div className="label">{lang.amount}</div>
          <div className="value" data-test-id="total-price-value">
            {formatter.number.currency(totalPrice || 0, true)}
          </div>
        </div>
        <div className="row">
          <div className="label">{lang.mva}</div>
          <div className="value" data-test-id="total-vat-value">
            {formatter.number.currency(totalVat, true)}
          </div>
        </div>
      </div>
    </div>

    <Table
      breakpoint="600px"
      loading={!Boolean(invoiceDetails)}
    >
      <thead>
        <tr>
          <th scope="col">
            {lang.description}
          </th>
          <th scope="col" style={{ textAlign: "right", width: "120px" }}>
            {lang.price}
          </th>
          <th scope="col" style={{ textAlign: "right", width: "120px" }}>
            {lang.quantity}
          </th>
          <th scope="col" style={{ textAlign: "right", width: "135px" }}>
            {lang.amount}
          </th>
          {isInvoice &&
            <>
              <th style={{ textAlign: "right", width: "120px" }}>{lang.refunded}</th>
              {isCustomerSupportRole && <th style={{ textAlign: "right", width: "100px" }}>{lang.refund}</th>}
            </>
          }
          <th className="goto-column" />
        </tr>
      </thead>
      <tbody>{invoiceDetails?.invoiceLines?.map(renderRow)}</tbody>
    </Table>

    {refundInvoiceLine && isInvoice &&
      <RefundModal
        handleClose={() => { setRefundInvoiceLine(undefined); }}
        handleSubmit={async () => {
          await fetch();
          setRefundInvoiceLine(undefined);
        }}
        invoiceLine={refundInvoiceLine}
        invoice={invoiceDetails} />
    }
  </Container>;
};

export default InvoiceDetails;
