import { mapDateFromIso, mapDateFromIsoOrNull, mapDateOnly } from "../commonMapper";
import { SearchResult } from "../SearchResult";
import { parseTravelCardState, TravelCardState } from "../TravelCardState";
import { parseTicketHolderType, TicketHolderType } from "./ticketHolderType";

export interface TravelCardRequestJson {
  id: string;
  requestedAt: string;
  replacementCount: number;
  manufacturedAt: string | null;
  cardNumber: string | null;
  ownerId: number;
  companyId: string;
  agreementNumber: string;
  productId: number;
  productTemplateId: number;
  profileId: number;
  startDate: string;
  expirationDate: string | null;
  ticketHolderType: string;
  employeeId: string | null;
  lastName: string | null;
  firstName: string | null;
  dateOfBirth: string;
  zoneFrom: string;
  zoneTo: string | null;
  deliveryTo: {
    companyName: string;
    attention: string | null;
    address: string;
    addressLine2: string | null;
    postCode: string;
    postArea: string;
  };
  state: string;
}

export interface TravelCardRequest {
  id: string;
  requestedAt: Date;
  replacementCount: number;
  manufacturedAt: Date | null;
  cardNumber: string;
  ownerId: number;
  companyId: string;
  agreementNumber: string;
  productId: number;
  productTemplateId: number;
  profileId: number;
  startDate: Date;
  expirationDate: Date | null;
  ticketHolderType: TicketHolderType;
  employeeId: string;
  lastName: string;
  firstName: string;
  dateOfBirth: Date;
  state: TravelCardState;
  zoneFrom: string;
  zoneTo: string | null;
}

const mapTravelCardRequest = (json: TravelCardRequestJson): TravelCardRequest => ({
  id: json.id,
  requestedAt: mapDateFromIso(json.requestedAt),
  replacementCount: json.replacementCount,
  manufacturedAt: mapDateFromIsoOrNull(json.manufacturedAt),
  cardNumber: json.cardNumber || "",
  ownerId: json.ownerId,
  companyId: json.companyId,
  agreementNumber: json.agreementNumber,
  productId: json.productId,
  productTemplateId: json.productTemplateId,
  profileId: json.profileId,
  startDate: mapDateFromIso(json.startDate),
  expirationDate: mapDateFromIsoOrNull(json.expirationDate),
  ticketHolderType: parseTicketHolderType(json.ticketHolderType),
  employeeId: json.employeeId || "",
  lastName: json.lastName || "",
  firstName: json.firstName || "",
  dateOfBirth: mapDateOnly(json.dateOfBirth),
  state: parseTravelCardState(json.state),
  zoneFrom: json.zoneFrom,
  zoneTo: json.zoneTo,
});

export const mapTravelCardRequestSearchResult = (json: SearchResult<TravelCardRequestJson>): SearchResult<TravelCardRequest> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapTravelCardRequest),
});

export const getDefaultTravelCardRequestJson = (): TravelCardRequestJson => ({
  id: "6cf433e7-68a3-494a-a00f-cfb61ba304f3",
  requestedAt: "2021-04-26T11:54:10Z",
  replacementCount: 0,
  manufacturedAt: null,
  cardNumber: null,
  ownerId: 102,
  companyId: "1102",
  agreementNumber: "BRAKARBEDRIFT-FRIBILLETTER-1",
  productId: 16090,
  productTemplateId: 5655,
  profileId: 1,
  startDate: "2021-04-30T22:00:00Z",
  expirationDate: null,
  ticketHolderType: "EMPLOYEE",
  employeeId: "05",
  lastName: "Jensen",
  firstName: "Ronny",
  dateOfBirth: "1988-01-01",
  zoneFrom: "Modum",
  zoneTo: "Drammen",
  deliveryTo: {
    companyName: "Steffens Kosmetikk avd Buskerud",
    attention: "Kåre Nævdal",
    address: "Post Address 1 It2",
    addressLine2: "Post Address 2 It2",
    postCode: "7922",
    postArea: "post city it2",
  },
  state: "REQUESTED",
});
