import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface FreeTicketInvoiceBaseDetailsLanguageResource {
  title: string;
  missingDepartmentName: string;
  description: string;
  quantity: string;
  amountIncVat: string;
  amountVat: string;
  totalAmountIncVat: string;
  totalAmountVat: string;
  totalSumIncVat: string;
  downloadAsCSV: string;
  employeeId: string;
}

export const freeTicketInvoiceBaseDetailsLanguageResource: LanguageResource<FreeTicketInvoiceBaseDetailsLanguageResource> = {
  nb: {
    title: "Fakturagrunnlag for {department}, {period}",
    missingDepartmentName: "Avdelingsnavn ikke satt",
    description: "Beskrivelse",
    quantity: "Antall",
    amountIncVat: "Pris per stk (inkl mva)",
    amountVat: "Mva per stk",
    totalAmountIncVat: "Total pris (inkl mva)",
    totalAmountVat: "Total mva",
    totalSumIncVat: "Total sum (inkl mva)",
    downloadAsCSV: "Last ned fakturagrunnlag som CSV",
    employeeId: "Ansattnummer",
  },
  en: {
    title: "Invoice base for {department}, {period}",
    missingDepartmentName: "Department name not set",
    description: "Description",
    quantity: "Quantity",
    amountIncVat: "Price per item (inc vat)",
    amountVat: "Vat per item",
    totalAmountIncVat: "Total price (inc vat)",
    totalAmountVat: "Total vat",
    totalSumIncVat: "Total sum (inc vat)",
    downloadAsCSV: "Download invoice base as CSV",
    employeeId: "Employee-Id",
  },
};
