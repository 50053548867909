import { CustomerOrder } from "src/common/api/invoice/CustomerOrder";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { InvoiceLanguageResource } from "./lang-resource";

export const getInvoiceId = (invoice: CustomerOrder) => {
  if (invoice.dueDate === null) return "";
  return invoice.invoiceNumber;
};

export const getAgreementType = (agreementSubTypeId: AgreementSubTypeId | null, lang: InvoiceLanguageResource) => {
  switch (agreementSubTypeId) {
    case AgreementSubTypeId.SUBSCRIPTIONS:
      return lang.agreementBusinessTicket;
    case AgreementSubTypeId.TICKETOFFICE:
      return lang.agreementTicketCounter;
    case AgreementSubTypeId.FREETICKET:
      return lang.agreementFreeTicket;
    case AgreementSubTypeId.SCHOOLTICKET:
      return lang.agreementSchoolTicket;
    default:
      return "";
  }
};
