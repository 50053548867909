/* eslint-disable jsx-a11y/control-has-associated-label */
import { MediaInfoMobile, Order, OrderAndTicket, ProductTemplate, SearchResult, TicketApiClient } from "@ruter-as/billettluke-frontend";
import { Button, ButtonGroup, Container, formatter, IconButton, Message, Modal, ProgressRadial, SearchInput, Table, TablePagination, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import createAndDownloadCSV from "src/common/csvFileCreator";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import { UserRight } from "src/common/userRights";
import { ButtonLink } from "src/components/common/buttons";
import { getTenant, Tenant } from "src/types/Tenant";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import successAlert, { failAlert } from "../../../common/toastr";
import { TICKET_PENDING } from "../../../constants";
import PhoneColumn from "../../common/Table/PhoneColumn/PhoneColumn";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import StatusColumn from "../../common/Table/StatusColumn/StatusColumn";
import YesNoColumn from "../../common/Table/YesNoColumn/YesNoColumn";
import { TicketZonesComponent } from "../../common/text/ticketZones/TicketZones";
import orderDetailsLanguageResource from "./lang-resource";
import "./orderDetails.scss";

let fetchOrdersAndTicketsTimout: NodeJS.Timeout;

const OrderDetails: React.FC = () => {
  const tenant = getTenant();
  const authContext = useAuthContextAuthenticated();
  const roles = authContext.userData.selectedCompany.roles;
  const userRights = authContext.userData.userRights;
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [ticketsResult, setTicketsResult] = useState<SearchResult<OrderAndTicket> | undefined>(undefined);
  const [productTemplates, setProductTemplates] = useState<ProductTemplate[] | undefined>(undefined);
  const [resendingTicketId, setResendingTicketId] = useState<undefined | string>(undefined);
  const [cancelTicketId, setCancelTicketId] = useState<undefined | string>(undefined);
  const [cancelTicketFailed, setCancelTicketFailed] = useState(false);
  const [cancelTicketInProgress, setCancelTicketInProgress] = useState(false);
  const [refundTicketId, setRefundTicketId] = useState<undefined | string>();
  const [refundTicketInProgress, setRefundTicketInProgress] = useState(false);
  const [refundFailed, setRefundFailed] = useState(false);
  const [refundedTickets, setRefundedTickets] = useState<SearchResult<OrderAndTicket>>();
  const [showDownloadError, setDownloadError] = useState(false);

  const { throttledQuery, query, setQuery, pagination } = usePaginationAndQuery();
  const { page, pageSize } = pagination;
  const isRuterMailOrder = tenant === Tenant.Ruter && (order?.mediaType === "TRAVEL_CARD_ULTRALIGHT" || order?.mediaType === "TRAVEL_CARD");
  const { selectedLanguage } = useLanguageContext();

  const loading = !order || !productTemplates;
  const { getAccessToken } = useAuthContextAuthenticated();
  const ticketApi = useMemo(() => {
    return new TicketApiClient({ getAccessToken }, "/ticket-api");
  }, [getAccessToken]);
  const { id: orderId } = useParams();

  const language = useLanguageResource(orderDetailsLanguageResource);

  const hasTicketAdminRole = roles?.some(role => role === CompanyRoleType.TICKET_ADMIN);
  const hasGlobalCompanyAdmin = userRights.some(rights => rights === UserRight.CompanyAdmin);
  const orderIsComplete = ticketsResult?.totalEntries === order?.ticketCount;

  if (!orderId) {
    throw new Error(`${orderId} is not a valid order id`);
  }

  const fetchOrdersAndTickets = useCallback(async () => {
    if (fetchOrdersAndTicketsTimout) {
      clearTimeout(fetchOrdersAndTicketsTimout);
    }

    try {
      const result = await Promise.all([
        ticketApi.tickets.getByOrderId(orderId, throttledQuery, page, pageSize),
        ticketApi.orders.getById(orderId),
      ]);
      setTicketsResult(result[0]);
      setOrder(result[1]);

      if (result[0].totalEntries !== result[1].ticketCount) {
        fetchOrdersAndTicketsTimout = setTimeout(() => fetchOrdersAndTickets(), 1000);
      }
    } catch (e) {
      setOrder(() => {
        throw e;
      });
    }
  }, [page, pageSize, orderId, ticketApi, throttledQuery]);

  const fetchRefundedTickets = useCallback(async () => {
    if (hasGlobalCompanyAdmin) {
      try {
        const response = await ticketApi.invoice.getRefundedTickets(orderId);
        setRefundedTickets(response);
      } catch (e) {
        setRefundedTickets(() => {
          throw e;
        });
      }
    }
  }, [orderId, ticketApi.invoice, hasGlobalCompanyAdmin]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await ticketApi.productTemplates.getAll(false);
        setProductTemplates(response);
      } catch (e) {
        setOrder(() => {
          throw e;
        });
      }
    };
    fetch();
    fetchRefundedTickets();
  }, [orderId, ticketApi, fetchRefundedTickets]);

  useEffect(() => {
    fetchOrdersAndTickets();
  }, [fetchOrdersAndTickets]);

  if (loading) {
    return null;
  }

  const cancelTicket = async (): Promise<void> => {
    setCancelTicketFailed(false);
    setCancelTicketInProgress(true);

    if (!cancelTicketId) {
      throw new Error("cancelTicketId is undefined");
    }

    try {
      await ticketApi.tickets.cancelPending(cancelTicketId);
      setCancelTicketInProgress(false);
      fetchOrdersAndTickets();
      successAlert(language.ticketCancelled);
      setCancelTicketId(undefined);
    } catch (e) {
      setCancelTicketFailed(true);
      setCancelTicketInProgress(false);
    }
  };

  const getMedium = (): string => {
    switch (order.mediaType) {
      case "TRAVEL_CARD_ULTRALIGHT": return language.IMPULSCARD;
      case "MOBILE_TICKET": return language.MOBILETICKET;
      case "TRAVEL_CARD": return language.TRAVELCARD;
    }
  };

  const getProduct = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    const { productTemplateId } = order;

    const productTemplate = productTemplates.find((x) => x.id === productTemplateId);

    if (!productTemplate) {
      return "";
    }
    if (selectedLanguage === "en") {
      return productTemplate.name.en;
    }
    return productTemplate.name.nb;
  };

  const getProfile = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    return order.profileName;
  };

  const formatDate = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    return formatter.date.toShortDateString(order.purchaseDate);
  };

  const cancelTicketButtonClick = (event: MouseEvent<HTMLButtonElement>, ticketId: string) => {
    event.stopPropagation();
    setCancelTicketId(ticketId);
  };

  const resendCode = async (event: MouseEvent<HTMLButtonElement>, ticketId: string): Promise<void> => {
    event.stopPropagation();
    setResendingTicketId(ticketId);
    try {
      await ticketApi.tickets.resendPickupCode(ticketId);
      successAlert(language.resendPickupCodeSuccess);
    } catch (e) {
      failAlert(language.resendPickupCodeFail);
    }

    setResendingTicketId(undefined);
  };

  const renderTicketCount = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    if (isRuterMailOrder) {
      return order.passengerCount;
    }
    if (order.ticketCount === ticketsResult?.totalEntries) {
      return order.ticketCount;
    }
    return (
      <div className="ticket-count">
        <span data-test-id="ticket-count-label" className="ticket-count-label" style={{ marginRight: "0.5rem" }}>
          {language.ticketCountStatus((ticketsResult?.totalEntries || 0).toString(), order.ticketCount.toString())}
        </span>
        <ProgressRadial type="Detirmate" min={0} max={order.ticketCount} value={ticketsResult?.totalEntries || 0} />
      </div>
    );
  };


  const renderRow = (ticketAndOrder: OrderAndTicket) => {
    const resendingInProgress = resendingTicketId === ticketAndOrder.ticket.id;
    const mediaInfo = ticketAndOrder.ticket.mediaInfo;
    return (
      <tr key={ticketAndOrder.ticket.id}>
        <td>{ticketAndOrder.ticket.id}</td>
        <PriceColumn price={ticketAndOrder.ticket.productPrice || 0} />

        {mediaInfo.type === "MOBILE_TICKET" && (
          <>
            <td>{mediaInfo.pickupCode}</td>
            <PhoneColumn phone={mediaInfo.phone} phoneCountryCode={mediaInfo.phoneCountryCode} />
            <YesNoColumn yes={mediaInfo.downloadedToPhone} />
          </>
        )}
        {mediaInfo.type === "TRAVEL_CARD" && (
          <td>{mediaInfo.travelCardNumber}</td>
        )}

        <StatusColumn status={ticketAndOrder.ticket.status} />

        <td className="actions">
          <ButtonGroup className="group">
            {ticketAndOrder.ticket.status === TICKET_PENDING && ticketAndOrder.order.mediaType === "MOBILE_TICKET" && (
              <IconButton
                variant="CellPhoneArrowRightIcon"
                loading={resendingInProgress}
                onClick={(event) => { resendCode(event, ticketAndOrder.ticket.id); }}
                data-test-id="resend-button"
                title={language.resendCode}
                aria-label={language.resendCode}
                disabled={mediaInfo.type === "MOBILE_TICKET" && !mediaInfo.phone}
              />
            )}
            {ticketAndOrder.ticket.status === TICKET_PENDING && hasTicketAdminRole && (
              <IconButton
                variant="CrossIcon"
                data-test-id="cancel-ticket-button"
                title={language.cancelTicket}
                onClick={(event) => cancelTicketButtonClick(event, ticketAndOrder.ticket.id)}
                aria-label={language.cancelTicket}
              />
            )}
            {ticketAndOrder.ticket.status === TICKET_PENDING && hasGlobalCompanyAdmin && (
              <IconButton
                data-test-id="refund-ticket-button"
                variant="ArrowBackIcon"
                aria-label={language.refund}
                title={language.cancelTicket}
                onClick={() => setRefundTicketId(ticketAndOrder.ticket.id)}
                disabled={refundedTickets?.matches.some(x => x.ticket.id === ticketAndOrder.ticket.id)}
              />
            )}
          </ButtonGroup>
        </td>
      </tr>
    );
  };

  const download = async () => {
    try {
      setDownloadError(false);
      const response = await ticketApi.tickets.getByOrderId(orderId, throttledQuery, 1, 10000);

      const tickets = response.matches.map(ordersAndTickets => ordersAndTickets.ticket);
      const mediaInfos = tickets.map(ticket => ticket.mediaInfo);
      const filteredMediaInfos = mediaInfos.filter(mediaInfo => mediaInfo.type === "MOBILE_TICKET") as MediaInfoMobile[];
      const pickupCodes = filteredMediaInfos.filter((mediaInfo) => !mediaInfo.downloadedToPhone).map((mediaInfo) => mediaInfo.pickupCode);

      if (pickupCodes.length === 0) {
        setDownloadError(true);
        return;
      }

      const csvModel = pickupCodes.map((pickupCode) => [pickupCode || ""]);
      const headers = [language.pickUpCode];
      const dateAsString = formatter.date.toShortDateString(new Date());

      const fileName = `Ubrukte hentekoder per ${dateAsString}.csv`;

      createAndDownloadCSV(fileName, headers, csvModel);
    } catch (e) {
      throw e;
    }
  };

  const refundTicket = async (id: string) => {
    setRefundTicketInProgress(true);
    setRefundFailed(false);
    try {
      await ticketApi.invoice.refundTicket(id);
      successAlert(language.refundSuccess);
      fetchRefundedTickets();
      setRefundTicketInProgress(false);
      setRefundTicketId(undefined);
    } catch (e) {
      setRefundTicketInProgress(false);
      setRefundFailed(true);
      sentry.captureException(e);
    }
  };

  return (
    <Container width="m" className="components-ticketcounter-order-details" data-test-id="components-ticketcounter-order-details">
      <h1>{language.title}</h1>
      <div className="details" data-test-id="details">
        <div className="row">
          <div className="label">{language.orderedBy}</div>
          <div className="value" data-test-id="ordered-by">
            {order.orderedBy}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.date}</div>
          <div className="value" data-test-id="date">
            {formatDate()}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.medium}</div>
          <div className="value" data-test-id="medium">
            {getMedium()}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.product}</div>
          <div className="value" data-test-id="product">
            {getProduct()}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.profile}</div>
          <div className="value" data-test-id="profile">
            {getProfile()}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.zones}</div>
          <div className="value" data-test-id="zones">
            <TicketZonesComponent zoneType={order.zoneType} nrOfZones={order.nrOfZones} zones={order.zones} />
          </div>
        </div>
        <div className="row">
          <div className="label">{language.count}</div>
          <div className="value" data-test-id="ticket-count">
            {renderTicketCount()}
          </div>
        </div>
        {isRuterMailOrder && (
          <div className="row">
            <div className="label">{language.ticketPrice}</div>
            <div className="value" data-test-id="ticket-price">
              {formatter.number.currency(order.productPrice || 0)}
            </div>
          </div>
        )}

        {isRuterMailOrder && (
          <div className="row">
            <div className="label">{language.orderPrice}</div>
            <div className="value" data-test-id="order-price">
              {formatter.number.currency(order.totalPrice)}
            </div>
          </div>
        )}

        <div className="row">
          <div className="label">{language.invoiceReference}</div>
          <div className="value" data-test-id="invoice-reference">
            {order.invoiceReference}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.personalReference}</div>
          <div className="value" data-test-id="personal-reference">
            {order.personalReference}
          </div>
        </div>
      </div>
      {isRuterMailOrder && hasGlobalCompanyAdmin && ticketsResult?.matches && ticketsResult?.matches.length === 1 &&
        <ButtonGroup>
          <Button
            text={language.refundOrder}
            type="button"
            variant="generic"
            onClick={() => {
              const matches = ticketsResult?.matches;
              setRefundTicketId(matches[0].ticket.id);
            }}
            disabled={ticketsResult?.matches[0].ticket.status !== "pending" || refundedTickets?.matches.some(x => x.ticket.id === ticketsResult.matches[0].ticket.id)}
            data-test-id="refund-impuls-button"
          />
        </ButtonGroup>
      }

      {!isRuterMailOrder && (
        <div data-test-id="tickets-section">
          <h2>{language.tickets}</h2>
          <SearchInput
            placeholder={language.filter}
            value={query}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
          />

          <div className="table-container">
            {!orderIsComplete && <div data-test-id="order-incomplete-warning" className="order-incomplete-warning">{language.orderIsIncompleteWarning}</div>}
            <div className="download-button-container">
              <div style={{ display: "inline-block" }}>
                <ButtonLink
                  text={language.downloadNonFetchedCodeAsCsv}
                  onClick={() => download()}
                  dataTestId="download-unused-pickup-codes"
                />
                {showDownloadError &&
                  <p className="error-message" role="alert" data-test-id="download-unused-error">{language.downloadErrorMessage}</p>
                }
              </div>
            </div>
            <Table breakpoint="600px">
              <thead>
                <tr>
                  <th style={{ width: "120px" }} scope="col">
                    {language.ticketNumber}
                  </th>
                  <th style={{ width: "100px", textAlign: "right" }} scope="col">
                    {language.price}
                  </th>
                  {order.mediaType === "MOBILE_TICKET" && (
                    <>
                      <th style={{ width: "130px" }} scope="col">
                        {language.pickUpCode}
                      </th>
                      <th style={{ width: "180px" }} scope="col">
                        {language.phone}
                      </th>
                      <th style={{ width: "100px" }} scope="col">
                        {language.downloaded}
                      </th>
                    </>
                  )}
                  {order.mediaType === "TRAVEL_CARD" && (
                    <th style={{ width: "180px" }}>Reisekortnummer</th>
                  )}

                  <th style={{ width: "145px" }} scope="col">
                    {language.status}
                  </th>
                  <th style={{ width: "130px" }} className="actions" />
                </tr>
              </thead>
              <tbody>{ticketsResult?.matches.map(renderRow)}</tbody>
            </Table>
            <TablePagination pagination={pagination} totalRecords={ticketsResult?.totalEntries} />
          </div>
        </div>
      )}


      <Modal
        data-test-id="cancel-pending-ticket-modal"
        isOpen={Boolean(cancelTicketId)}
        title={language.cancelTicket}
        handleClose={() => setCancelTicketId(undefined)}
        className="cancel-pending-ticket-modal"
      >
        <p>{language.confirmPendingTicketCancel}</p>
        {cancelTicketFailed && (
          <Message style={{ marginTop: "2rem" }} skin="danger" title={language.cancelTicketFail} data-test-id="error-message" />
        )}
        <ButtonGroup className="button-group">
          <Button
            text={language.yes}
            type="button"
            variant="primary"
            data-test-id="confirm-button"
            onClick={cancelTicket}
            loading={cancelTicketInProgress}
          />
          <Button text={language.cancel} variant="cancel" type="button" data-test-id="cancel-button" onClick={() => setCancelTicketId(undefined)} />
        </ButtonGroup>
      </Modal>

      {refundTicketId &&
        <Modal
          isOpen
          handleClose={() => setRefundTicketId(undefined)}
          title={language.refund}
          data-test-id="refund-modal" >
          <p>{language.confirmRefund}</p>
          {refundFailed && (
            <Message style={{ marginTop: "2rem" }} skin="danger" title={language.refundFailed} data-test-id="error-message" />
          )}
          <ButtonGroup>
            <ButtonGroup className="button-group">
              <Button
                text={language.yes}
                type="button"
                variant="primary"
                data-test-id="confirm-button"
                onClick={() => { refundTicket(refundTicketId); }}
                loading={refundTicketInProgress}
              />
              <Button text={language.cancel} variant="cancel" type="button" data-test-id="cancel-button" onClick={() => setCancelTicketId(undefined)} />
            </ButtonGroup>
          </ButtonGroup>
        </Modal>}
    </Container>
  );
};
export default OrderDetails;
