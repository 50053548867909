import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface ShoolTicketDetailModalLanguageResource {
  editTicket: {
    title: string;
    fail: string;
    success: string;
    cardName: string;
    className: string;
    address: string;
    name: string;
  };
  replaceTicket: {
    title: string;
    fail: string;
    success: string;
    info: string;
  };
}

const shoolTicketDetailModalLanguageResource: LanguageResource<ShoolTicketDetailModalLanguageResource> = {
  nb: {
    editTicket: {
      title: "Endre billett",
      fail: "Det har skjedd en feil ved endring av billett",
      success: "Billett endret!",
      cardName: "Kortnavn",
      className: "Klasse",
      address: "Adresse",
      name: "Navn",
    },
    replaceTicket: {
      title: "Erstatt kort",
      fail: "Det har skjedd en feil ved bestilling av nytt kort",
      success: "Nytt erstatningskort er bestilt",
      info: "Vil du lage ny billett og sende ut nytt reisekort til eleven? Det opprinnelige reisekortet og billetten vil bli avsluttet",
    },
  },
  en: {
    editTicket: {
      title: "Endre billett",
      fail: "An error occured while trying to edit the ticket",
      success: "Ticket was edited successfully!",
      cardName: "Card name",
      className: "Class",
      address: "Address",
      name: "Name",
    },
    replaceTicket: {
      title: "Replace travel card",
      fail: "An error has occured while ordering a replacement card",
      success: "A new replacement card is successfully ordered",
      info: "By accepting you will be replacing the current ticket with a new ticket",
    },
  },
};

export default shoolTicketDetailModalLanguageResource;
