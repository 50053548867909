import { ButtonGroup, Container, EmailFormInput, FormGroup, Message, PhoneFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fiels-language-resource";
import apiClient from "../../../common/apiClient/apiClient";
import { UserRight } from "../../../common/userRights";
import { UserRoleType } from "../../../types/user/UserRoleType";
import { mapUserRoles } from "../../../types/user/UserRoles";
import { CancelButton, SubmitButton } from "../../common/buttons";
import CheckboxInput from "../../common/form-hooks/CheckboxInput";
import { User, mapUser } from "../users/user";
import RolesInput, { CompanyWithRoles } from "./RolesInput";
import "./UserPage.scss";
import userLanguageResource from "./lang-resource";
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  globalUserAdmin: boolean;
  globalTravelCardAdmin: boolean;
  globalCustomerSupport: boolean;
  globalSchoolTicketAdmin: boolean
  companies: CompanyWithRoles[];
}

const UserPage: React.FC = () => {
  const lang = useLanguageResource(userLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const userData = authContext.userData;
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(true);
  const navigate = useNavigate();
  const formMethods = useForm<FormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      globalUserAdmin: false,
      globalTravelCardAdmin: false,
      companies: [],
    },
  });
  const [submitClicked, setSubmitClicked] = useState(false);
  const formValues = formMethods.watch();

  const checkIfUserHasAnyRoles = useCallback((data: FormData) => {
    const hasAnyGlobalRoles = data.globalCustomerSupport || data.globalSchoolTicketAdmin || data.globalTravelCardAdmin || data.globalUserAdmin;
    const hasAnyLocalRoles = data.companies.some(x => x.roles.length > 0);
    return hasAnyGlobalRoles || hasAnyLocalRoles;
  }, []);

  const userHasAnyRoles = checkIfUserHasAnyRoles(formValues);
  const { setValue } = formMethods;
  let { id: userId } = useParams();
  const [user, setUser] = useState<User | null>(null);

  const isGlobalUserAdmin = userData.userRights.some((r) => r === UserRight.GlobalUserAdmin);

  const onSubmit = async (data: FormData) => {
    setSubmitClicked(true);

    if (!checkIfUserHasAnyRoles(data)) {
      return;
    }

    setSubmitting(true);

    try {
      if (!userId) {
        const phone = parsePhoneNumberFromString(data.phone);

        if (!phone || !phone.isValid()) {
          throw new Error("invalid phone number should be impossible as it is validated by the form");
        }

        const userContract = {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: phone.nationalNumber,
          phoneCountryCode: `+${phone.countryCallingCode}`,
        };

        const result = await apiClient.post("/user-api/user/create", userContract, mapUser);
        userId = result.id;
      }

      const roles = [];

      if (data.globalUserAdmin) {
        roles.push(UserRoleType.GLOBAL_USER_ADMIN);
      }
      if (data.globalTravelCardAdmin) {
        roles.push(UserRoleType.GLOBAL_TRAVEL_CARD_ADMIN);
      }
      if (data.globalCustomerSupport) {
        roles.push(UserRoleType.GLOBAL_COMPANY_ADMIN);
      }
      if (data.globalSchoolTicketAdmin) {
        roles.push(UserRoleType.GLOBAL_SCHOOLTICKET_ADMIN);
      }

      const rolesContract = {
        roles,
        companies: (data.companies || []).map((c) => ({
          companyId: c.companyId,
          roles: c.roles,
        })),
      };

      await apiClient.put(`/user-api/user/${userId}/roles`, rolesContract);
      await authContext.userData.reload();
    } catch (e) {
      setSubmitting(() => {
        throw e;
      });
      return;
    }

    setSubmitting(false);
    navigate("/brukere");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResult = await apiClient.get(`/user-api/user/${userId}`, mapUser);
        const userRoles = await apiClient.get(`/user-api/user/${userId}/roles`, mapUserRoles);

        const globalUserAdmin = userRoles.roles.some((x) => x === UserRoleType.GLOBAL_USER_ADMIN);
        const globalTravelCardAdmin = userRoles.roles.some((x) => x === UserRoleType.GLOBAL_TRAVEL_CARD_ADMIN);
        const globalCustomerSupport = userRoles.roles.some((x) => x === UserRoleType.GLOBAL_COMPANY_ADMIN);
        const globalSchoolTicketAdmin = userRoles.roles.some((x) => x === UserRoleType.GLOBAL_SCHOOLTICKET_ADMIN);

        const companies: CompanyWithRoles[] = userRoles.companies.map((role) => ({
          companyId: role.companyId,
          name: role.companyName,
          roles: role.roles,
          allAgreements: userData.companies.find((company) => company.id === role.companyId)?.allAgreements || [],
        }));

        setUser(userResult);
        setValue("firstName", userResult.firstName);
        setValue("lastName", userResult.lastName);
        setValue("email", userResult.email);
        setValue("phone", userResult.phoneCountryCode + userResult.phone);
        setValue("globalUserAdmin", globalUserAdmin);
        setValue("globalTravelCardAdmin", globalTravelCardAdmin);
        setValue("globalCustomerSupport", globalCustomerSupport);
        setValue("globalSchoolTicketAdmin", globalSchoolTicketAdmin);
        setValue("companies", companies);
        setFetching(false);
      } catch (e) {
        setFetching(() => {
          throw e;
        });
      }
    };

    if (userId) {
      fetchData();
    } else {
      setFetching(false);
    }
  }, [userId, setValue, userData.companies]);

  if (fetching) {
    return null;
  }

  const title = user
    ? lang.titleEdit.replace("$firstName", user.firstName).replace("$lastName", user.lastName).replace("$userName", user.email)
    : lang.titleNew;

  return (
    <Container width="s" className="components-useradmin-user-userpage">
      <h1>{title}</h1>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="narrow">
            <TextFormInput name="firstName" label={formLang.firstName} required minLength={2} maxLength={100} disabled={Boolean(userId)} />
            <TextFormInput name="lastName" label={formLang.lastName} required minLength={2} maxLength={100} disabled={Boolean(userId)} />
            <EmailFormInput name="email" label={formLang.email} required disabled={Boolean(userId)} />
            <PhoneFormInput name="phone" label={formLang.phone} required disabled={Boolean(userId)} />

            {isGlobalUserAdmin && (
              <FormGroup>
                <div className="rds-label">{lang.globalRoles}</div>
                <CheckboxInput name="globalUserAdmin" label={lang.globalUserAdmin} />
                <CheckboxInput name="globalTravelCardAdmin" label={lang.globalTravelCardAdmin} />
                <CheckboxInput name="globalCustomerSupport" label={lang.globalCustomerSupport} />
                <CheckboxInput name="globalSchoolTicketAdmin" label={lang.globalSchoolTicketAdmin} />
              </FormGroup>
            )}
          </div>

          <RolesInput />

          <FormGroup style={{ marginTop: "4rem" }}>
            {submitClicked && !userHasAnyRoles && (
              <Message skin="danger" title={lang.noRoles} data-test-id="required-roles-validation-message" />
            )}

            <ButtonGroup>
              <SubmitButton text={formLang.save} submitting={submitting} />
              <CancelButton onClick={() => navigate("/brukere")} />
            </ButtonGroup>
          </FormGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default UserPage;
