export enum TicketHolderType {
  EMPLOYEE = "EMPLOYEE",
  RETIRED = "RETIRED",
  FAMILY_MEMBER = "FAMILY_MEMBER",
  UNKNOWN = "UNKNOWN",
}

export const parseTicketHolderType = (value: string | null): TicketHolderType => {
  if (value === null) {
    return TicketHolderType.UNKNOWN;
  }

  const parsed = Object.values(TicketHolderType).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error(`"${value}" is not a valid ticketHolderType`);
  }
  return parsed;
};
