import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TicketCounterInfoLanguageResource {
  title: string
  ruterLink: string
  ruterLinkText: string
  preamble: {
    text1: string
    text2: string
    text3: string
    text4: string
    text5: string
  },
  advantages: {
    title: string
    text1: string
    text2: string
    text3: string
  },
  ticketTypes: {
    title: string
    singleTicket: string
    periodTicket: {
      "24-hour": string
      "7-days": string
      "30-days": string
      "365-days": string
    },
    rufus: string
  },
  orderingMobileTicket: {
    title: string
    subTitle: string
    sms: {
      title: string
      text: string
    },
    notSms: {
      title: string
      text1: string
      text2: string
      text3: string
    },
    invoice: {
      title: string,
      text: string
    }
  },
  orderingTravelCard: {
    title: string
    price: {
      title: string
      text: string
      url: string
      urlText: string
    },
    delivery: {
      title: string,
      text1: string,
      text2: string,
      text3: string,
    },
    invoice: {
      title: string
      text1: string
      text2: string
      text3: string
    },
    lostOrDestroyed: {
      title: string
      text1: string
      text2: string
      url: string,
      urlText: string
    },
    cancelSubscription: {
      title: string
      text1: string
      text2: string
      url: string
      urlText: string
    },
    refund: {
      title: string
      text: string
      url: string
      urlText: string
    }
  }
}

export const ticketCounterInfoLanguageResource: LanguageResource<TicketCounterInfoLanguageResource> = {
  nb: {
    title: "Billettluka",
    ruterLinkText: "www.ruter.no",
    ruterLink: "https://www.ruter.no",
    preamble: {
      text1: "RuterBedrift kan med glede tilby mobilbilletter for alle bedrifter!",
      text2: "Billettluka erstatter nå det gamle bestillingsskjemaet på",
      text3: " for skoler, barnehager og bedrifter.",
      text4: "All administrering og bestilling skjer i RuterBedrift-portalen, hvor dere får egne brukere.",
      text5: "Alle bruker er knyttet til navn, e-post og telefonnummer og kan ikke opprettes for avdelinger, grupper o.l.",

    },
    advantages: {
      title: "Tenk over følgende fordeler ved velge mobilbilletter fremfor reisekort",
      text1: "Besparing av fraktkostnader og miljøet.",
      text2: "Leverings- og behandlingstid bortfaller. Mobilbilletter leveres umiddelbart.",
      text3: "Retur av billetter per post bortfaller- Dere mottar kun faktura for billetter dere faktisk trenger, og dere slipper å sende ubenyttede billetter i retur per post.",
    },
    ticketTypes: {
      title: "Billettypene som tilbys via Billettluka",
      singleTicket: "Enkeltbillett",
      periodTicket: {
        "24-hour": "24-timersbillett",
        "7-days": "7-dagersbillett",
        "30-days": "30-dagersbillett",
        "365-days": "365-dagersbillett",
      },
      rufus: "Rufusbillett (kun på impulskort)",
    },
    orderingMobileTicket: {
      title: "Slik fungerer bestilling av mobilbilletter",
      subTitle: "Alternativer bestillingsmåte mobilbilletter",
      sms: {
        title: "1. Mottaker mottar sms",
        text: "Ved bestilling av billetter via Billettluka legger dere inn billettype, sone, reisende (barn/voksen/honnør) og mottakers telefonnummer." +
          " Ved fullført bestilling det vil umiddelbart sendes ut en sms til mottakers telefonnummer, for nedlasting av billetten(e) i Ruter-appen. " +
          "Billettene hentes via sms og startes i appen av mottaker selv. Billettene startes innen dato valgt for kansellering ved bestilling (maks. 90 dager frem i tid)." +
          " Dersom billettene ikke hentes innen valgt dato for kansellering, vil dette skje automatisk dersom billettene ikke er hentet i appen.",
      },
      notSms: {
        title: "2. Mottaker mottar ikke sms",
        text1: "Dere kan også velge å distribuere hentekodene selv, via valget «Jeg ønsker å distribuere hentekodene selv»." +
          " Da vil det heller lages en liste med hentekoder etter endt bestilling, som dere kan distribuere internt og sende til mottaker, uavhengig av mobilnummer." +
          " Mottaker vil da ikke motta sms, men kan da åpne Ruter-appen og legge inn hentekoden direkte inn i appen.",
        text2: "Dere kan sende ut x antall billetter per bestilling, samt flere mottakere/hentekoder for samme billettype." +
          " Skal det sendes forskjellige billettyper må de bestilles i flere omganger (en bestilling per billettype)." +
          " Ved bestilling for et høyt antall mottakere kan dere importere en CVS-fil ved bestilling." +
          " Ved oppsummering per bestilling kan dere oppgi fakturareferanse om ønskelig.",
        text3: "Detaljer for billetthistorikk og ordrehistorikk vil til enhver tid være tilgjengelig i RuterBedrift.",
      },
      invoice: {
        title: "Faktura",
        text: "Vi sender en samlefaktura for utstedte billetter per måned." +
          " Faktura mottas siste kalenderdag per måned, med 30 dagers forfall." +
          " Fakturering skjer via bedriftens/barnehagens/skolens offisielle fakturaadresse." +
          " Fakturaarkivet vil dere finne i RuterBedrift, i menyvalget «Faktura».",
      },
    },
    orderingTravelCard: {
      title: "Slik fungerer bestilling av reisekort og Impulskort",
      price: {
        title: "Pris",
        text: "Dere betaler 50 kroner per reisekort. Hver billett/reisepenger ligger på et eget reisekort." +
          " Unntaket er enkeltbilletter, 24-timersbilletter og Rufusbilletter, som ligger på engangskortet Impuls. Impulskortet er gratis.",
        url: "https://ruter.no/kjop-billett/billetter-og-priser/",
        urlText: "Billetter og priser",
      },
      delivery: {
        title: "Levering",
        text1: "Dere mottar billettene på reisekort ca. 7 virkedager etter bestilling." +
          " Vi sender alle fysiske billetter med budbil til adresser i Oslo og Akershus." +
          " Frakt betales av bedriften som bestiller.",
        text2: "Husk å oppgi leveringsadresse!",
        text3: "Bestillinger til andre postnummer sendes rekommandert. Kostnaden for den rekommanderte sendingen betales av bedriften.",
      },
      invoice: {
        title: "Fakturering",
        text1: "Vi sender en samlefaktura for utsendte billetter per kalendermåned. " +
          "Faktura mottas siste kalenderdag per måned. Vi fakturerer kun for 1 stk. frakt per dag, uavhengig om det plasseres flere bestillinger samme dag.",
        text2: "Betalingsfristen er 30 dager.",
        text3: "Fakturaen må sendes til skolens, bedriftens eller barnehagens offisielle fakturaadresse.Vi kan dessverre ikke sende faktura til private adresser.",
      },
      lostOrDestroyed: {
        title: "Mistet eller ødelagt reisekort",
        text1: "Dere kan melde inn mistede og ødelagte reisekort via bestillingsskjemaet her på",
        text2: "Vi sperrer da reisekortet, og sender et nytt i posten. Husk at eieren av reisekortet må kjøpe ordinær billett mens han eller hun venter på nytt kort.",
        urlText: "Meld inn mistet eller ødelagt reisekort",
        url: "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/fa-erstatningskort-eller-avslutte-abonnement-pa-reisekort/#page=form&sessionId=f314f0ea-a88a-459e-91b1-b3f630c2c181",
      },
      cancelSubscription: {
        title: "Si opp 30-dagersbillett/reisepenger med abonnement",
        text1: "Dere kan si opp reisekort med abonnement via bestillingsskjemaet her på",
        text2: "Reisekortet med 30-dagersbilletten eller reisepengene blir sperret umiddelbart etter at dere har sendt inn skjemaet," +
          " og dere får ikke brukt billetten eller reisepengene som måtte være igjen." +
          " Dersom det er dager/reisepenger igjen i reisekortet vil dette bli kreditert mot et ekspedisjonsgebyr på 100 kroner, som trekkes fra gjenværende verdi i reisekortet.",
        url: "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/fa-erstatningskort-eller-avslutte-abonnement-pa-reisekort/#page=form&sessionId=2b971a53-fc6c-4fbd-ae80-e60cc5cd5d14",
        urlText: "Si opp abonnement",
      },
      refund: {
        title: "Refusjon",
        text: "Fra og med 26. januar 2020 er det nye regler for refusjon av billetter til bedrift, skoler og barnehager.",
        urlText: "Få oversikt over refusjonsreglene",
        url: "https://ruter.no/kjop-billett/storkunde/refusjon/",
      },
    },
  },
  en: {
    title: "Billettluka",
    ruterLinkText: "www.ruter.no",
    ruterLink: "https://www.ruter.no",
    preamble: {
      text1: "RuterBedrift kan med glede tilby mobilbilletter for alle bedrifter!",
      text2: "Billettluka erstatter nå det gamle bestillingsskjemaet på",
      text3: " for skoler, barnehager og bedrifter.",
      text4: "All administrering og bestilling skjer i RuterBedrift-portalen, hvor dere får egne brukere.",
      text5: "Alle bruker er knyttet til navn, e-post og telefonnummer og kan ikke opprettes for avdelinger, grupper o.l.",

    },
    advantages: {
      title: "Tenk over følgende fordeler ved velge mobilbilletter fremfor reisekort",
      text1: "Besparing av fraktkostnader og miljøet.",
      text2: "Leverings- og behandlingstid bortfaller. Mobilbilletter leveres umiddelbart.",
      text3: "Retur av billetter per post bortfaller- Dere mottar kun faktura for billetter dere faktisk trenger, og dere slipper å sende ubenyttede billetter i retur per post.",
    },
    ticketTypes: {
      title: "Billettypene som tilbys via Billettluka",
      singleTicket: "Enkeltbillett",
      periodTicket: {
        "24-hour": "24-timersbillett",
        "7-days": "7-dagersbillett",
        "30-days": "30-dagersbillett",
        "365-days": "365-dagersbillett",
      },
      rufus: "Rufusbillett leveres per d.d. ikke i Ruter-appen og vil kunne bestilles som impulsbilletter, og sendes per post.",
    },
    orderingMobileTicket: {
      title: "Slik fungerer bestilling av mobilbilletter",
      subTitle: "Alternativer bestillingsmåte mobilbilletter",
      sms: {
        title: "1. Mottaker mottar sms",
        text: "Ved bestilling av billetter via Billettluka legger dere inn billettype, sone, reisende (barn/voksen/honnør) og mottakers telefonnummer." +
          " Ved fullført bestilling det vil umiddelbart sendes ut en sms til mottakers telefonnummer, for nedlasting av billetten(e) i Ruter-appen. " +
          "Billettene hentes via sms og startes i appen av mottaker selv. Billettene startes innen dato valgt for kansellering ved bestilling (maks. 90 dager frem i tid)." +
          " Dersom billettene ikke hentes innen valgt dato for kansellering, vil dette skje automatisk dersom billettene ikke er hentet i appen.",
      },
      notSms: {
        title: "2. Mottaker mottar ikke sms",
        text1: "Dere kan også velge å distribuere hentekodene selv, via valget «Jeg ønsker å distribuere hentekodene selv»." +
          " Da vil det heller lages en liste med hentekoder etter endt bestilling, som dere kan distribuere internt og sende til mottaker, uavhengig av mobilnummer." +
          " Mottaker vil da ikke motta sms, men kan da åpne Ruter-appen og legge inn hentekoden direkte inn i appen.",
        text2: "Dere kan sende ut x antall billetter per bestilling, samt flere mottakere/hentekoder for samme billettype." +
          " Skal det sendes forskjellige billettyper må de bestilles i flere omganger (en bestilling per billettype)." +
          " Ved bestilling for et høyt antall mottakere kan dere importere en CVS-fil ved bestilling." +
          " Ved oppsummering per bestilling kan dere oppgi fakturareferanse om ønskelig.",
        text3: "Detaljer for billetthistorikk og ordrehistorikk vil til enhver tid være tilgjengelig i RuterBedrift.",
      },
      invoice: {
        title: "Faktura",
        text: "Vi sender en samlefaktura for utstedte billetter per måned." +
          " Faktura mottas siste kalenderdag per måned, med 30 dagers forfall." +
          " Fakturering skjer via bedriftens/barnehagens/skolens offisielle fakturaadresse." +
          " Fakturaarkivet vil dere finne i RuterBedrift, i menyvalget «Faktura».",
      },
    },
    orderingTravelCard: {
      title: "Slik fungerer bestilling av reisekort og Impulskort",
      price: {
        title: "Pris",
        text: "Dere betaler 50 kroner per reisekort. Hver billett/reisepenger ligger på et eget reisekort." +
          " Unntaket er enkeltbilletter, 24-timersbilletter og Rufusbilletter, som ligger på engangskortet Impuls. Impulskortet er gratis.",
        url: "https://ruter.no/kjop-billett/billetter-og-priser/",
        urlText: "Billetter og priser",
      },
      delivery: {
        title: "Levering",
        text1: "Dere mottar billettene på reisekort ca. 7 virkedager etter bestilling." +
          " Vi sender alle fysiske billetter med budbil til adresser i Oslo og Akershus." +
          " Frakt betales av bedriften som bestiller.",
        text2: "Husk å oppgi leveringsadresse!",
        text3: "Bestillinger til andre postnummer sendes rekommandert. Kostnaden for den rekommanderte sendingen betales av bedriften.",
      },
      invoice: {
        title: "Fakturering",
        text1: "Vi sender en samlefaktura for utsendte billetter per kalendermåned. " +
          "Faktura mottas siste kalenderdag per måned. Vi fakturerer kun for 1 stk. frakt per dag, uavhengig om det plasseres flere bestillinger samme dag.",
        text2: "Betalingsfristen er 30 dager.",
        text3: "Fakturaen må sendes til skolens, bedriftens eller barnehagens offisielle fakturaadresse.Vi kan dessverre ikke sende faktura til private adresser.",
      },
      lostOrDestroyed: {
        title: "Mistet eller ødelagt reisekort",
        text1: "Dere kan melde inn mistede og ødelagte reisekort via bestillingsskjemaet her på",
        text2: "Vi sperrer da reisekortet, og sender et nytt i posten. Husk at eieren av reisekortet må kjøpe ordinær billett mens han eller hun venter på nytt kort.",
        urlText: "Meld inn mistet eller ødelagt reisekort",
        url: "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/fa-erstatningskort-eller-avslutte-abonnement-pa-reisekort/#page=form&sessionId=f314f0ea-a88a-459e-91b1-b3f630c2c181",
      },
      cancelSubscription: {
        title: "Si opp 30-dagersbillett/reisepenger med abonnement",
        text1: "Dere kan si opp reisekort med abonnement via bestillingsskjemaet her på",
        text2: "Reisekortet med 30-dagersbilletten eller reisepengene blir sperret umiddelbart etter at dere har sendt inn skjemaet," +
          " og dere får ikke brukt billetten eller reisepengene som måtte være igjen." +
          " Dersom det er dager/reisepenger igjen i reisekortet vil dette bli kreditert mot et ekspedisjonsgebyr på 100 kroner, som trekkes fra gjenværende verdi i reisekortet.",
        url: "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/fa-erstatningskort-eller-avslutte-abonnement-pa-reisekort/#page=form&sessionId=2b971a53-fc6c-4fbd-ae80-e60cc5cd5d14",
        urlText: "Si opp abonnement",
      },
      refund: {
        title: "Refusjon",
        text: "Fra og med 26. januar 2020 er det nye regler for refusjon av billetter til bedrift, skoler og barnehager.",
        urlText: "Få oversikt over refusjonsreglene",
        url: "https://ruter.no/kjop-billett/storkunde/refusjon/",
      },
    },
  },
};
