import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface MyPageLanguageResource {
  title: string;
  personalInfo: string;
  companyInfo: string;
  addresses: string;
  name: string;
  company: string;
  phone: string;
  mobile: string;
  email: string;
  customerNumber: string;
  organizationMumber: string;
  visitAddress: string;
  postAddress: string;
  billAddress: string;
  messages: {
    updated: string;
  };
  buttons: {
    cancel: string;
    save: string;
    edit: string;
  };
}

const myPageLanguageResource: LanguageResource<MyPageLanguageResource> = {
  nb: {
    title: "Min side",
    personalInfo: "Personopplysninger",
    companyInfo: "Bedriftsopplysninger",
    addresses: "Adresser",
    name: "Navn",
    company: "Selskap",
    phone: "Telefonnummer",
    mobile: "Mobilnummer",
    email: "E-postadresse",
    customerNumber: "Kundenummer",
    organizationMumber: "Organisasjonsnummer",
    visitAddress: "Besøksadresse",
    postAddress: "Postadresse",
    billAddress: "Fakturaadresse",
    messages: {
      updated: "Endringer er oppdatert.",
    },
    buttons: {
      cancel: "Avbryt",
      save: "Lagre",
      edit: "Endre",
    },
  },
  en: {
    title: "My page",
    personalInfo: "Your info",
    companyInfo: "Company info",
    addresses: "Addresses",
    name: "Name",
    company: "Company",
    phone: "Phone number",
    mobile: "Mobile number",
    email: "E-mail address",
    customerNumber: "Customer number",
    organizationMumber: "Organization number",
    visitAddress: "Visit address",
    postAddress: "Post address",
    billAddress: "Invoice address",
    messages: {
      updated: "Your changes are saved.",
    },
    buttons: {
      cancel: "Cancel",
      save: "Save",
      edit: "Edit",
    },
  },
};


export default myPageLanguageResource;