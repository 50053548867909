import { Icon } from "@ruter-ds/rds-components";
import { ProfileIcon } from "@ruter-ds/rds-icons";
import React from "react";
import "./SignInButton.scss";

interface Props {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SignInButton: React.FC<Props> = ({ text, onClick }) => (
  <button className="components-app-header-signinbutton" type="button" onClick={onClick} data-test-id="login">
    <Icon component={<ProfileIcon />} />
    <div>{text}</div>
  </button>
);

export default SignInButton;
