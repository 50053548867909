import {
  USER_ROLE_COMPANY_ADMIN,
  USER_ROLE_FREETICKET_ADMIN,
  USER_ROLE_GLOBAL_SCHOOLTICKET_ADMIN,
  USER_ROLE_SCHOOLTICKET_ADMIN,
  USER_ROLE_SUBSCRIPTION_ADMIN,
  USER_ROLE_TICKET_ADMIN,
  USER_ROLE_USER_ADMIN,
} from "../constants";
import { AgreementSubTypeId } from "./AgreementSubTypeId";
import { Agreement, AgreementJson, CommonCompany, CommonCompanyJson, mapAgreement, mapCommonCompany } from "./Company";
import { Contact } from "./Contact";

export type WidgetAccessOptions = "DISABLED" | "ALLOW_TICKET_CREATION" | "ALLOW_TICKET_REQUESTS";

export interface AgreementSettings {
  agreementNumber: string;
  emailForTicketRequests: string | null;
  emailForInvoicePreview: string | null;
  widgetAccess: WidgetAccessOptions;
  employeeIdLabel: string;
}

interface AuthAgreementJson extends AgreementJson {
  settings: AgreementSettings;
}

export interface AuthAgreement extends Agreement {
  settings: AgreementSettings;
}

export interface AuthCompanyJson extends CommonCompanyJson {
  agreements: Array<AuthAgreementJson>;
  roles: Array<string>;
}

export interface AuthCompany extends CommonCompany {
  businessTicketAgreements: Array<AuthAgreement>;
  ticketCounterAgreements: Array<AuthAgreement>;
  freeTicketAgreements: Array<AuthAgreement>;
  schoolTicketAgreements: Array<AuthAgreement>;
  allAgreements: Array<AuthAgreement>;
  roles: Array<string>;
}

export interface SimpleCompany {
  id: string;
  name: string;
  organisationNumber: string;
  allAgreements: Agreement[];
}

export const mapAuthAgreement = (json: AuthAgreementJson): AuthAgreement => ({
  ...mapAgreement(json),
  settings: json.settings,
});

export const mapAuthCompany = (json: AuthCompanyJson, contact: Contact): AuthCompany => {
  const businessTicketAgreements: Array<AuthAgreement> = [];
  const ticketCounterAgreements: Array<AuthAgreement> = [];
  const freeTicketAgreements: Array<AuthAgreement> = [];
  let schoolTicketAgreements: Array<AuthAgreement> = [];

  json.roles.forEach((role) => {
    if (role === USER_ROLE_SUBSCRIPTION_ADMIN || role === USER_ROLE_COMPANY_ADMIN) {
      businessTicketAgreements.push(
        ...json.agreements.filter((agreement) => agreement.subTypeId === AgreementSubTypeId.SUBSCRIPTIONS).map(mapAuthAgreement),
      );
    }
    if (role === USER_ROLE_TICKET_ADMIN || role === USER_ROLE_COMPANY_ADMIN || role === USER_ROLE_USER_ADMIN) {
      ticketCounterAgreements.push(
        ...json.agreements.filter((agreement) => agreement.subTypeId === AgreementSubTypeId.TICKETOFFICE).map(mapAuthAgreement),
      );
    }
    if (role === USER_ROLE_FREETICKET_ADMIN || role === USER_ROLE_COMPANY_ADMIN) {
      freeTicketAgreements.push(
        ...json.agreements.filter((agreement) => agreement.subTypeId === AgreementSubTypeId.FREETICKET).map(mapAuthAgreement),
      );
    }
    if (role === USER_ROLE_SCHOOLTICKET_ADMIN) {
      schoolTicketAgreements.push(
        ...json.agreements.filter((agreement) => agreement.subTypeId === AgreementSubTypeId.SCHOOLTICKET).map(mapAuthAgreement),
      );
    }
  });

  contact.roles.forEach((role) => {
    if (role === USER_ROLE_GLOBAL_SCHOOLTICKET_ADMIN) {
      schoolTicketAgreements = [...json.agreements.filter((agreement) => agreement.subTypeId === AgreementSubTypeId.SCHOOLTICKET).map(mapAuthAgreement)];
    }
  });

  return {
    ...mapCommonCompany(json),
    businessTicketAgreements,
    ticketCounterAgreements,
    freeTicketAgreements,
    schoolTicketAgreements,
    allAgreements: json.agreements.map(mapAuthAgreement),
    roles: json.roles,
  };
};
export const getValidAuthCompanyJson = (): AuthCompanyJson => ({
  roles: ["COMPANY_ADMIN"],
  id: "1226629",
  type: "SIEBEL_CUSTOMER_NUMBER",
  organisationNumber: "111122223",
  name: "Steffens Kosmetikk",
  agreements: [
    {
      id: "1-3779173843",
      invoiceReference: "KIM",
      endDate: undefined,
      fromDate: undefined,
      subTypeId: "FREETICKET",
      invoicingInterval: "MONTHLY_AT_END",
      paymentDeadline: 30,
      settings: {
        agreementNumber: "1-3779173843",
        emailForTicketRequests: null,
        emailForInvoicePreview: null,
        widgetAccess: "DISABLED",
        employeeIdLabel: "Ansattnr.",
      },
    },
    {
      id: "1-3058717731",
      invoiceReference: "KÅRE",
      endDate: undefined,
      fromDate: undefined,
      subTypeId: "FREETICKET",
      invoicingInterval: "MONTHLY_AT_END",
      paymentDeadline: 30,
      settings: {
        agreementNumber: "1-3058717731",
        emailForTicketRequests: null,
        emailForInvoicePreview: null,
        widgetAccess: "DISABLED",
        employeeIdLabel: "Ansattnr.",
      },
    },
  ],
  visitAddressLine1: "Visit Address 1 It2",
  visitAddressLine2: "Visit Address 2 It2",
  visitAddressCity: "visit city it2",
  visitAddressCountry: null,
  visitAddressPostCode: "7921",
  billAddressLine1: "Bill Address 1 It2",
  billAddressLine2: "Bill Address 2 It2",
  billAddressCity: "bill city it2",
  billAddressCountry: null,
  billAddressPostCode: "7923",
  postAddressLine1: "Post Address 1 It2",
  postAddressLine2: "Post Address 2 It2",
  postAddressCity: "post city it2",
  postAddressCountry: null,
  postAddressPostCode: "7922",
});

export const getValidAuthAgreementJson = (json: Partial<AuthAgreementJson>): AuthAgreementJson => ({
  id: "1-3779173843",
  invoiceReference: "KIM",
  endDate: undefined,
  fromDate: undefined,
  subTypeId: "FREETICKET",
  invoicingInterval: "MONTHLY_AT_END",
  paymentDeadline: 30,
  settings: {
    agreementNumber: "1-3779173843",
    emailForTicketRequests: null,
    emailForInvoicePreview: null,
    widgetAccess: "DISABLED",
    employeeIdLabel: "Ansattnr.",
  },
  ...json,
});
