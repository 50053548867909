export enum PayrollChangeType {
  SUBSCRIBED = "SUBSCRIBED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  PRICE_CHANGED = "PRICE_CHANGED",
}

export const parsePayrollChangeType = (value: string): PayrollChangeType => {
  const parsed = Object.values(PayrollChangeType).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error(`could not parse payrollchangetype="${value}"`);
  }
  return parsed;
};
