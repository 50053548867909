enum CompanyType {
  RUTER_CUSTOMER = "RUTER_CUSTOMER",
  RUTER_CONTRACTOR = "RUTER_CONTRACTOR",
  OTHER = "OTHER",

}

const parseCompanyType = (value: string): CompanyType => {
  const parsed = Object.values(CompanyType).find((x) => x === value);

  if (parsed === undefined) {
    return CompanyType.OTHER;
  }
  return parsed;
};

export { CompanyType, parseCompanyType };

