import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface HomeLanguageResource {
  product1Title: string;
  product1Text: string;
  product2Title: string;
  product2Text: string;
  product2ButtonText: string;
  product3Title: string;
  product3Text: string;
  product3Text2: string;
  product3ButtonText: string;
  productTicketCounterTitle: string;
  productTicketCounterText: string;
  productTicketCounterText2: string;
  productTicketCounterText3: string;
  productTicketCounterText4: string;
  productTicketCounterButtonText: string;
  ruterLink: string
  ruterLinkText: string
}

export const homeLanguageResource: LanguageResource<HomeLanguageResource> = {
  nb: {
    product1Title: "Billetter",
    product1Text:
      "Nå er det litt lettere å kjøpe og administrere kollektivbilletter når du reiser i jobbsammenheng. " +
      "Billettene betales med faktura, og dere slipper arbeidet med reiseregninger og tilbakebetaling av utlegg.",
    product2Title: "Bedriftsavtale",
    product2Text:
      "Bedriftsavtalen gir alle ansatte tilgang til en enklere og rimeligere avtale for kollektivtransport. " +
      "Som bedrift kan dere tilby billettene som en fordel til de ansatte, og som en del av arbeidet med profil og miljøsertifiseringer. ",
    product2ButtonText: "Slik fungerer bedriftsavtalen",
    product3Title: "Miljø",
    product3Text:
      "Dette kjenner du sikkert til, men vi sier det uansett: Å reise kollektivt, sykle eller gå er noen av de beste hverdagstiltakene vi kan gjøre for byen vår.",
    product3Text2:
      "Ved å velge bort bilen bidrar dere til å redusere partikkelutslipp, NOx og veistøv – som igjen gir bedre luft og en grønnere by. " +
      "Kollektivreiser til, fra og underveis i arbeidsdagen er også en viktig faktor for miljøsertifiseringer som ISO 14001 og Miljøfyrtårn.",
    product3ButtonText: "Slik jobber vi med bærekraft ",
    productTicketCounterTitle: "Billettluka i RuterBedrift",
    productTicketCounterText: "RuterBedrift kan med glede tilby mobilbilletter for alle bedrifter!",
    productTicketCounterText2: "Billettluka erstatter nå det gamle bestillingsskjemaet på ",
    productTicketCounterText3: " for skoler, barnehager og bedrifter.",
    productTicketCounterText4: "All administrering og bestilling skjer i RuterBedrift-portalen, hvor dere har fått egne brukere. " +
      "Alle bruker er knyttet til navn, e-post og telefonnummer og kan ikke opprettes for avdelinger, grupper etc.",
    productTicketCounterButtonText: "Slik fungerer billettluka",
    ruterLinkText: "www.ruter.no",
    ruterLink: "https://www.ruter.no",
  },
  en: {
    product1Title: "Tickets",
    product1Text:
      "You can buy public transportation tickets for your employees to cover all their transportation needs. Tickets are paid by monthly invoice to greatly reduce" +
      " administration for your company and employees.",
    product2Title: "Company agreement",
    product2Text:
      "By signing up with Ruter, your employees can enjoy easier administration and cheaper public transport. Your company can use employee tickets " +
      "as an employee benefit or in order to comply with your environmental profile or certifications",
    product2ButtonText: "Read more about company agreement",
    product3Title: "Environment",
    product3Text:
      "Focusing on public transport for your employees is an important factor in your company's environemental profile. " +
      'Public transport is an important factor in complying with the certifications "Miljøfyrtårn" and ISO 14001.',
    product3Text2: "",
    product3ButtonText: "Read more about environment",
    productTicketCounterTitle: "Ticket counter in RuterBedrift",
    productTicketCounterText: "Ticket counter will now be replacing the old order form for schools, kindergardens, and companies on ruter.no",
    productTicketCounterText2: "",
    productTicketCounterButtonText: "",
    productTicketCounterText3: "",
    productTicketCounterText4: "",
    ruterLink: "",
    ruterLinkText: "",
  },
};
