import { SearchResult } from "../../../types/SearchResult";
import { TravelCardState, parseTravelCardState } from "../../../types/TravelCardState";
import { mapDateFromIsoOrNull, mapDateOnlyOrNull } from "../../../types/commonMapper";
import { FreeTicketProfile, parseFreeTicketProfileFromNumber } from "../../../types/freeTicketAgreement/freeTicketProfile";
import { TicketHolderType, parseTicketHolderType } from "../../../types/freeTicketAgreement/ticketHolderType";
import { TicketStatus, parseTicketStatus } from "../../../types/freeTicketAgreement/ticketStatus";
import MediaType from "../../../types/mediaType";

export interface FreeTicketJson {
  agreementNumber: string;
  allZones: boolean;
  appId: null | string;
  creationConfirmed: boolean;
  dateOrdered: string;
  downloadedToPhone: boolean;
  employeeBirthDate: string | null;
  employeeId: string;
  expirationDate: null | string;
  firstName: string;
  id: string;
  invoiceReference: string | null;
  lastName: string;
  manufacturedAt: string | undefined | null;
  mediaType: string;
  nodOrderStatusId: null;
  nrOfZones: null | number;
  orderStatusId: null;
  ownerId: number;
  parentOrderId: null;
  payerId: string;
  phone: null | string;
  phoneCountryCode: null | string;
  pickupCode: null | string;
  pricePerMonth: number | null;
  productId: number;
  productPrice: null;
  productTemplateId: number;
  productVatAmount: null;
  profileId: number;
  salaryId: string;
  startDate: string;
  status: string;
  taxAmount: number | null;
  ticketHolderType: string | null;
  totalPrice: null | number;
  travelCardNumber: null | string;
  travelCardState: null | string;
  zoneFrom: string;
  zoneTo: null | string;
  zoneVia: null | string;
  zones: string[];
}

export interface FreeTicketPhone {
  appId: string | null;
  agreementNumber: string;
  allZones: boolean;
  id: string;
  downloadedToPhone: boolean;
  employeeId: string;
  employeeBirthDate: Date | null;
  expirationDate: Date | null;
  firstName: string;
  invoiceReference: string | null;
  lastName: string;
  nrOfZones: null | number;
  phone?: string;
  phoneCountryCode?: string;
  pickupCode: string | null;
  pricePerMonth: number | null;
  profile: FreeTicketProfile;
  startDate: Date | null;
  status: TicketStatus;
  taxAmount: number | null;
  ticketHolderType: TicketHolderType;
  mediaType: typeof MediaType.MOBILE_TICKET;
  zoneFrom: string;
  zoneTo: string;
}

export interface FreeTicketTravelCard {
  agreementNumber: string;
  allZones: boolean;
  id: string;
  employeeId: string;
  employeeBirthDate: Date | null;
  expirationDate: Date | null;
  firstName: string;
  invoiceReference: string | null;
  lastName: string;
  manufacturedAt: Date | null;
  nrOfZones: null | number;
  pricePerMonth: number | null;
  profile: FreeTicketProfile;
  startDate: Date | null;
  status: TicketStatus;
  taxAmount: number | null;
  travelCardState: TravelCardState | null;
  travelCardNumber: string | null;
  ticketHolderType: TicketHolderType;
  mediaType: typeof MediaType.TRAVEL_CARD;
  zoneFrom: string;
  zoneTo: string;
}

export type FreeTicket = FreeTicketTravelCard | FreeTicketPhone;

const mapMobileTicket = (json: FreeTicketJson): FreeTicketPhone => ({
  agreementNumber: json.agreementNumber,
  allZones: json.allZones,
  appId: json.appId,
  id: json.id,
  downloadedToPhone: json.downloadedToPhone,
  employeeId: json.employeeId,
  employeeBirthDate: mapDateOnlyOrNull(json.employeeBirthDate),
  expirationDate: mapDateFromIsoOrNull(json.expirationDate),
  firstName: json.firstName,
  invoiceReference: json.invoiceReference,
  lastName: json.lastName,
  nrOfZones: json.nrOfZones || 1,
  phone: json.phone || "",
  phoneCountryCode: json.phoneCountryCode || "",
  pricePerMonth: json.pricePerMonth,
  profile: parseFreeTicketProfileFromNumber(json.profileId),
  pickupCode: json.pickupCode,
  status: parseTicketStatus(json.status),
  startDate: mapDateFromIsoOrNull(json.startDate),
  taxAmount: json.taxAmount,
  ticketHolderType: parseTicketHolderType(json.ticketHolderType),
  mediaType: MediaType.MOBILE_TICKET,
  zoneFrom: json.zoneFrom,
  zoneTo: json.zoneTo || "",
});

const mapTravelCardTicket = (json: FreeTicketJson): FreeTicketTravelCard => ({
  agreementNumber: json.agreementNumber,
  allZones: json.allZones,
  id: json.id,
  employeeId: json.employeeId,
  employeeBirthDate: mapDateOnlyOrNull(json.employeeBirthDate),
  expirationDate: mapDateFromIsoOrNull(json.expirationDate),
  firstName: json.firstName,
  invoiceReference: json.invoiceReference,
  lastName: json.lastName,
  manufacturedAt: mapDateFromIsoOrNull(json.manufacturedAt),
  nrOfZones: json.nrOfZones || 1,
  profile: parseFreeTicketProfileFromNumber(json.profileId),
  pricePerMonth: json.pricePerMonth,
  travelCardState: json.travelCardState ? parseTravelCardState(json.travelCardState) : null,
  startDate: mapDateFromIsoOrNull(json.startDate),
  status: parseTicketStatus(json.status),
  travelCardNumber: json.travelCardNumber,
  taxAmount: json.taxAmount,
  ticketHolderType: parseTicketHolderType(json.ticketHolderType),
  mediaType: MediaType.TRAVEL_CARD,
  zoneFrom: json.zoneFrom,
  zoneTo: json.zoneTo || "",
});

const mapFreeTicket = (json: FreeTicketJson) => {
  if (json.mediaType === MediaType.TRAVEL_CARD) {
    return mapTravelCardTicket(json);
  }
  if (json.mediaType === MediaType.MOBILE_TICKET) {
    return mapMobileTicket(json);
  }

  throw new Error(`${json.mediaType} is not a valid mediatype`);
};

export const mapFreeTicketSearchResult = (json: SearchResult<FreeTicketJson>): SearchResult<FreeTicket> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapFreeTicket),
});

export const getValidFreeTicket = (): FreeTicketJson => ({
  allZones: false,
  id: "4321b183-8fd1-4f93-b246-3d1b58568555",
  ownerId: 3,
  travelCardNumber: null,
  travelCardState: "REQUESTED",
  salaryId: "",
  agreementNumber: "1-3779173843",
  invoiceReference: "KIM",
  dateOrdered: "2020-12-21T07:46:32Z",
  ticketHolderType: "EMPLOYEE",
  mediaType: "TRAVEL_CARD",
  payerId: "1226629",
  employeeId: "81d28ef1-a55f-44b4-8bf9-26b0f3b5b32e",
  employeeBirthDate: "1940-10-21",
  lastName: "0008bd82-c313-4253-a861-94694ccd2512",
  manufacturedAt: undefined,
  firstName: "9955912b-8997-4284-a9ba-68a519b180c6",
  phone: null,
  phoneCountryCode: null,
  productPrice: null,
  taxAmount: 645.0,
  productVatAmount: null,
  totalPrice: null,
  pricePerMonth: 8,
  productTemplateId: 97,
  profileId: 1,
  productId: 21991,
  appId: null,
  startDate: "2020-12-31T23:00:00Z",
  expirationDate: null,
  zoneFrom: "1",
  zoneTo: "2Ø",
  zoneVia: null,
  nrOfZones: 2,
  zones: ["1", "2Ø"],
  nodOrderStatusId: null,
  orderStatusId: null,
  parentOrderId: null,
  creationConfirmed: true,
  pickupCode: null,
  status: "active",
  downloadedToPhone: false,
});
