import { Button, ButtonGroup, Container, ProgressRadial, Table, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import LinkButton from "src/components/common/buttons/LinkButton";
import { FreeTicketMassOrder, mapMassOrderSearchResult } from "../../../common/api/freeTicketApi/freeTicketMassOrders";
import { SearchResult } from "../../../types/SearchResult";
import PageHeaderComponent from "../../common/layout/PageHeader";
import "./MassOrders.scss";
import { massOrdersLanguageResource } from "./lang-resource";

const MassOrders: React.FC = () => {
  const lang = useLanguageResource(massOrdersLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const companyId = authContext.userData.selectedCompany.id;
  const [massOrders, setMassOrders] = useState<SearchResult<FreeTicketMassOrder> | null>(null);
  const loading = massOrders === null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get(`/freeticket-api/ticket/bulk-requests/by-company-id/${companyId}`, mapMassOrderSearchResult);
      if (response.type === "success") {
        setMassOrders(response.result);
      } else {
        setMassOrders(() => {
          throw response.error;
        });
      }
    };

    fetch();
    const interval = setInterval(fetch, 1000);

    return () => {
      clearInterval(interval);
      setMassOrders(null);
    };
  }, [companyId]);

  const renderRow = (row: FreeTicketMassOrder) => {
    const ticketsProcessed = row.ticketCount + row.failedTicketCount;

    return (
      <tr>
        <td>{row.orderedBy}</td>
        <td>{moment(row.requestedAt).format("DD.MM.YYYY HH:mm:ss")}</td>
        <td>
          {`${ticketsProcessed}/${row.requestedTicketCount}`}
          <ProgressRadial value={ticketsProcessed} min={0} max={row.requestedTicketCount} type="Detirmate" />
        </td>
        <td>{row.ticketCount}</td>
        <td>{row.failedTicketCount}</td>
        <td>{row.requestedTicketCount}</td>
      </tr>
    );
  };

  return (
    <Container width="l" className="components-freeticketagreement-massorders">
      <PageHeaderComponent text={lang.title} loading={false} />
      <p>{lang.description}</p>
      <ButtonGroup className="buttons">
        <Button
          variant="danger"
          type="button"
          text={lang.cancelTickets}
          data-test-id="cancel-button"
          onClick={() => navigate("/fribilletter/massebestillinger/avslutt")}
        />
        <Button
          variant="generic"
          type="button"
          text={lang.departmentShift}
          data-test-id="change-department-button"
          onClick={() => navigate("/fribilletter/massebestillinger/byttavdeling")}
        />
        <LinkButton
          onClick={() => {
            navigate("/fribilletter/massebestillinger/ny");
          }}
          text={lang.newMassOrder}
          dataTestId="create-mass-order-button"
        />
      </ButtonGroup>

      <Table loading={loading}>
        <thead>
          <tr>
            <th>{lang.orderedBy}</th>
            <th>{lang.orderedAt}</th>
            <th>{lang.progress}</th>
            <th>{lang.ticketsCreatedCount}</th>
            <th>{lang.failedTicketsCount}</th>
            <th>{lang.requestedTicketsCount}</th>
          </tr>
        </thead>
        <tbody>{massOrders?.matches.map(renderRow)}</tbody>
      </Table>
    </Container>
  );
};

export default MassOrders;
