import { SubstituteDictionary } from "@ruter-as/web-components-and-tools";
import { Tenant, getTenant } from "../types/Tenant";

const brakarSubstitutionDictionaryList: SubstituteDictionary[] = [
  { language: "nb", replaceWith: "ansattbillett", search: "fribillett" },
  { language: "en", replaceWith: "employee-ticket", search: "free-ticket" },
  { language: "en", replaceWith: "employee ticket", search: "free ticket" },
];

export const getSubstituteDictionaryList = (): SubstituteDictionary[] => {
  const tenant = getTenant();

  switch (tenant) {
    case Tenant.Brakar:
      return brakarSubstitutionDictionaryList;
    default:
      return [];
  }
};