import { LanguageResource, OneParamLanguageResourceTemplate, languageResourceBuilder } from "@ruter-as/web-components-and-tools";

interface FreeTicketLanguageResource {
  title: string;
  chooseMediaType: string;
  mobileTicket: string;
  travelcard: string;
  employeeId: string;
  employee: string;
  retired: string;
  familyMember: string;
  adult: string;
  child: string;
  reducedRate: string;
  ticketProfile: string;
  duplicateTicket: string;
  agreement: string;
  missingDepartmentName: string;
  orderTicket: string;
  orderTicketSuccess: string;
  orderTicketFail: string;
  cardNumber: string
  missingCompanyPostAddress: string
  genericError: string
  conflictingTicket: {
    employeeRelation: string
    general: string
  },
  additionalZoneInformation: OneParamLanguageResourceTemplate;
}

export const freeTicketLanguageResource: LanguageResource<FreeTicketLanguageResource> = {
  nb: {
    title: "Ny fribillett",
    chooseMediaType: "Velg billettbærer",
    mobileTicket: "Mobil",
    travelcard: "Reisekort",
    employeeId: "Ansattnr.",
    employee: "Ansatt",
    retired: "Pensjonist",
    familyMember: "Familie",
    adult: "Voksen",
    child: "Barn",
    reducedRate: "Honnør",
    ticketProfile: "Reisende",
    duplicateTicket: "Denne personen har allerede en fribillet i aktuelt tidsrom.",
    agreement: "Avdeling",
    missingDepartmentName: "Avdelingsnavn ikke satt",
    orderTicket: "Bestill billett",
    orderTicketSuccess: "Billett sendt!",
    orderTicketFail: "Bestilling av billetten feilet!",
    cardNumber: "Reisekortnr.",
    missingCompanyPostAddress: "Bedriften mangler Post adresse",
    genericError: "Det skjedde en feil ved bestilling av billetten",
    conflictingTicket: {
      employeeRelation: "Dette ansattnummeret har allerede billett",
      general: "Det er allerede fribillett for denne brukeren.",
    },
    additionalZoneInformation: languageResourceBuilder.oneParamTemplate("$p1$ er inkludert i denne sonen"),
  },
  en: {
    title: "Ny fribillett",
    chooseMediaType: "Choose ticket media type",
    mobileTicket: "Mobile phone",
    travelcard: "Travel card",
    employeeId: "Employee number",
    employee: "Employee",
    retired: "Retired",
    familyMember: "Family member",
    adult: "Adult",
    child: "Child",
    reducedRate: "Reduced-rate",
    ticketProfile: "Ticket Profile",
    duplicateTicket: "This ticket already exists",
    agreement: "Department",
    missingDepartmentName: "Department name not set",
    orderTicket: "Order new ticket",
    orderTicketSuccess: "Ticket ordered successfully!",
    orderTicketFail: "Failed to order ticket!",
    cardNumber: "Travel card number",
    missingCompanyPostAddress: "The company is missing a post address",
    genericError: "A error happened when ordering the ticket",
    conflictingTicket: {
      employeeRelation: "This employee number already has a ticket",
      general: "There are conflicting tickets with this request",
    },
    additionalZoneInformation: languageResourceBuilder.oneParamTemplate("$p1$ is included in this zone"),
  },
};
