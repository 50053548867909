import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import bus from "../../../gfx/illustrasjon-buss-404.svg";
import { CallToActionLink } from "../../common/buttons";
import "./MissingRole.scss";
import missingRoleLanguageResource from "./lang-resource";

const MissingRole: React.FC = () => {
  const lang = useLanguageResource(missingRoleLanguageResource);

  return (
    <Container width="m" className="components-app-missing-role" data-test-id="components-app-missing-role">
      <img src={bus} alt="" aria-hidden tabIndex={-1} />
      <h1>{lang.title}</h1>
      <p className="text">{lang.text}</p>
      <div className="actions">
        <CallToActionLink path="/kontaktoss" text={lang.clickForContact} showIcon={true} />
      </div>
    </Container>
  );
};

export default MissingRole;
