import { Container, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React from "react";
import { getTenant, Tenant } from "src/types/Tenant";
import "./FreeTicketInfo.scss";
import { freeTicketInfoLanguageResource } from "./lang-resource";

const FreeTicketInfo: React.FC = () => {
  const lang = useLanguageResource(freeTicketInfoLanguageResource);
  const tenant = getTenant();

  const getPrivacyLink = () => {
    switch (tenant) {
      case Tenant.Akt:
        return lang.privacyLink.akt;
      default:
        return lang.privacyLink.ruter;
    }
  };

  return (
    <Container width="s" className="free-ticket-info-page">
      <h1>{lang.title}</h1>
      <p>{lang.privacyText}</p>

      <h2>{lang.purposeTitle}</h2>
      <p>{lang.purposeText}</p>
      <ul>
        <li>{lang.purposeBullet1}</li>
        <li>{lang.purposeBullet2}</li>
        <li>{lang.purposeBullet3}</li>
      </ul>

      <h2>{lang.legalTitle}</h2>
      <p>{lang.legalText}</p>

      <h2>{lang.dataTreatedTitle}</h2>
      <p>{lang.dataTreatedText}</p>
      <ul>
        <li>{lang.dataTreatedBullet1}</li>
        <li>{lang.dataTreatedBullet2}</li>
        <li>{lang.dataTreatedBullet3}</li>
      </ul>
      <p>{lang.dataTreatedText2}</p>

      <h2>{lang.accessTitle}</h2>
      <p>{lang.accessText1}</p>
      <a href={getPrivacyLink()} target="_blank" rel="noopener noreferrer">
        {lang.accessLink}
      </a>
      <p>{lang.accessText2}</p>

      <h2>{lang.storageTitle}</h2>
      <p>{lang.storageText}</p>
      <ul>
        <li>{lang.storageBullet1}</li>
        <li>{lang.storageBullet2}</li>
      </ul>
    </Container>
  );
};

export default FreeTicketInfo;
